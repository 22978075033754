import './App.css';
import { Routes, Route,Navigate} from 'react-router-dom'
// import Landingpage from './Landing page/Landingpage';
// import ExistingUser from './ExistingUser/ExistingUser';
// import NewUser from './NewUser/NewUser';
// import Concent from './Concent/Concent';
// import InsuranceInfo from './InsuranceInfo/InsuranceInfo';
// import Tables from './Tables/Table';
// import Final from './Final/Final';
// import Survey from './Survey/Survey';
// import NewLandingpage from './NewLandingPage/NewLandingpage';
// import Email from './email/email';
// import UserTable from './UserTable/UserTable';
// import Document from './Document/Document';
// import Authform from './Authform/Authform';
// import Pverify from './Pverify/Pverify';
// import Summary from  './summary/summary'
import Pverifyy from './Pverify/Pverifyy';
// import Sidebar from './Pverify/Sidebar';
// import Table from './Pverify/Table';
// import MTable from './Pverify/Table';
// import Change from './Pverify/change';
import Header from './Pverify/Header';
// import Login from './Pverify/Login';
import Newlogin from './Newlogin/Newlogin'

// import Newlogins from './Newlogin/Newlogins'
import Newloginss from './Newlogin/Newloginss'
// import Main from './Pverify/Main';
// import Hcpedit from './Pverify/Hcpedit';
// import Graph from './Pverify/Dashboard';



// import HcpOffic from './Pverify/HcpOffic';
// import HcpOfiice from './Pverify/HcpOfiice';
// import Main from './Pverify/Main';
// import Main2 from './Pverify/Main2';
import Pop from './Pverify/popupfull';
import Main1 from './Pverify/Main';
import NewHeader from './Pverify/NewHeader';
import Sidebar1 from './Pverify/Sidebar1';
import Personalinfo from './Pverify/NewScreens/Persionalinfo/personalinfo';
import AddNewUsers from './Pverify/NewScreens/User/AddNewUsers';
import AddNewHospital from './Pverify/NewScreens/Hospital/AddNewHospital';
import  NewTable from './Pverify/NewScreens/Addusertable/NewTable';
import  NewUsers from './Pverify/NewScreens/Addhospital/NewUsers';
import  Priortable from './Pverify/NewScreens/Addhospital/PriorTable';
// import { Email } from '@mui/icons-material';
// import { Table } from 'antd';
import Hospitalinfo from './Pverify/NewScreens/Hospitalinfo/hospitalinfo';
import Ebvinfo from './Pverify/NewScreens/ebvinfo/ebvinfo';
import EditEvbRequest from './Pverify/NewScreens/Pverifyrequest/editevbrequest';
import Graph from './Pverify/NewScreens/Dashboard/Dashboard';
import AddPlan from './Pverify/NewScreens/Addplan/Addplan';
import CreateTable from './Pverify/NewScreens/Supportcreater/createtable';
import CreateMain from './SupportCreateinfo/SupportCreatemain';
import Approver from './Approver/approve';
import CreateDeny from './Denyinfo/createdeny';
import CreatePlan from './Pverify/NewScreens/Planpayerchanges/createplan';
import DenyTable from './Pverify/NewScreens/Deny/Deny';
import EbvRequest from './Pverify/NewScreens/Planchangeinfo/ebvrequest';
import Ebvgateway from './Pverify/NewScreens/ebvgateway/ebvgateway';
import Gatewayconfig from './Pverify/NewScreens/Gatewayconfig/gatewayconfig';
import Hcpconfig from './Pverify/NewScreens/Gatewayconfig/hcpconfig';
import EditAddNewHospital from './Pverify/NewScreens/Hospital/EditAddNewHospital';
import EditAddNewUsers from './Pverify/NewScreens/User/EditAddNewUser';
import Prior from './Pverify/NewScreens/Hospital/PriorAuthorization';
import PriorRequest from './Pverify/NewScreens/Hospital/PriorRequest';
import Authorization from './Pverify/NewScreens/Hospital/Authorization';


import DrugPverify from './Pverify/Drugpverify'
import DrugsPverify from './Pverify/Drugspverify'
import {AuthProvider,useAuth} from './Newlogin/Authprovider'
import Dropdown from './Newlogin/dropdown'

const App = () => {

  
  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
};

const Main=()=>{
  const { isAuthenticated } = useAuth();
 console.log("isAuthenticated1",isAuthenticated())

  return (  
    <div className="App">
    <Routes>

    <Route
        exact path="/"
          element={
            isAuthenticated() ? (
              <>
                
              </>
            ) : (
              <Newlogin/>
            )
          }
        />
         <Route exact path="newloginss" element={<Newloginss/>}></Route>
      
       {/* <Route exact path="/" element={<Landingpage/>}/> */}
       {/* <Route exact path="/NewLandingpage" element={<NewLandingpage/>}/>   */}
       {/* <Route exact path="ExistingUser" element={<ExistingUser />}></Route> */}
       {/* <Route exact path="NewUser" element={<NewUser />}></Route> */}
       {/* <Route exact path="Consent" element={<Concent />}></Route> */}
       {/* <Route exact path="InsuranceInfo" element={<InsuranceInfo />}></Route> */}
       {/* <Route exact path="Copay" element={<Tables />}></Route> */}
       {/* <Route exact path="Final" element={<Final/>}></Route> */}
       {/* <Route exact path="Survey" element={<Survey/>}></Route> */}
       {/* <Route exact path="userTable" element={<UserTable/>}></Route> */}
       {/* <Route exact path="Authform" element={<Authform/>}></Route> */}
       {/* <Route exact path="Pverify" element={<Pverify/>}></Route> */}
       {/* <Route exact path="summary" element={<Summary/>}></Route> */}
       <Route exact path="Pverifyy" element={<Pverifyy/>}></Route>
       {/* <Route exact path="side" element={<Sidebar/>}></Route> */}
        {/* <Route exact path="table" element={<MTable/>}></Route> */}
        {/* <Route exact path="Login" element={<Login/>}></Route> */}
        <Route exact path="/" element={<Newlogin/>}></Route>
        {/* <Route exact path="newlogins" element={<Newlogins/>}></Route> */}
       
        {/* <Route exact path="main" element={<Main/>}></Route> */}
        {/* <Route exact path="hcpedit" element={<Hcpedit/>}></Route> */}
        {/* <Route exact path="email" element={<Email/>}></Route> */}
        <Route exact path="header" element={<Header/>}></Route>
        {/* <Route exact path="hcp" element={<HcpOfiice/>}/> */}
        {/* <Route exact path="main" element={<Main/>}/> */}
        <Route exact path="main1" element={<Main1/>}/>
        {/* <Route exact path="main2" element={<Main2/>}/> */}
        {/* <Route exact path="/popupfull" element={<Pop/>}/> */}
        <Route exact path="/header1" element={<NewHeader/>}/>
        <Route exact path="/sidebar" element={<Sidebar1/>}/>
        <Route exact path="/Personalinfo" element={<Personalinfo/>}/>
        <Route exact path="/Hospitalinfo" element={<Hospitalinfo/>}/>
        <Route exact path="/addusers" element={<AddNewUsers/>}/>
        {/* <Route exact path="addhospital" element={<AddNewHospital/>}/> */}
        <Route exact path="tablenew" element={<NewTable/>}/>
        <Route exact path="hospitalnew" element={<NewUsers/>}/>
        <Route exact path="ebvtable" element={<EditEvbRequest/>}/>
        <Route exact path="graph" element={<Graph/>}/>
        <Route exact path="addplan" element={<AddPlan/>}/>
        <Route exact path="createplan" element={<CreatePlan/>}/>
        <Route exact path="ebvinfo" element={<Ebvinfo/>}/>
        <Route exact path="createmain" element={<CreateMain/>}/>
        <Route exact path="createtable" element={<CreateTable/>}/>
        <Route exact path="approver" element={<Approver/>}/>
        <Route exact path="deny" element={<DenyTable/>}/>
        <Route exact path="createdeny" element={<CreateDeny/>}/>
        <Route exact path="ebvrequest" element={<EbvRequest/>}/>
        <Route exact path="ebvgatway" element={<Ebvgateway/>}/>
        <Route exact path="Gatewayconfig" element={<Gatewayconfig/>}/>
        <Route exact path="hcpconfig" element={<Hcpconfig/>}/>
        <Route path="/editadduser/:param1/:param2" element={<EditAddNewUsers/>} />
        <Route exact path="addhospital" element={<AddNewHospital/>}/>
        <Route exact path="/editaddhospital/:param1/:param2" element={<EditAddNewHospital/>}/>
        <Route exact path="drugpverify" element={<DrugPverify/>}/>
        <Route exact path="drugspverify" element={<DrugsPverify/>}/>
        <Route exact path="dropdown" element={<Dropdown/>}/>
        <Route exact path="PriorAuthorization" element={<Prior/>}/>
        <Route exact path="Priorrequest" element={<PriorRequest/>}/>
        <Route exact path="Priortable" element={< Priortable/>}/>
       
        <Route exact path="Authorization" element={<Authorization/>}/>
       
          
       {/* <Route exact path="document" element={<Document/>}></Route> */}
      

    </Routes>
    </div>
  );
}



export default App;
