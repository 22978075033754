import React, { useEffect, useState,useRef } from 'react';
import NewHeader from '../../NewHeader';
import "./NewTable.css";
import Sidebar1 from '../../Sidebar1';
import { Col, Row } from 'antd';
import Pagination from '@mui/material/Pagination';
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { Modal } from 'antd';
import { BaseUrl } from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'
import IdleTimeout from '../../../Newlogin/IdleTimeout';


const NewTable = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }
 
  // const Navigate = useNavigate();

  const managnment = window.location.href;

  const my_list = managnment.split("/");

  const [data, setdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [editingData, setEditingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [last, setlast] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    navigate(`/tablenew?/superadmin`)
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
  };
  const [searchText, setSearchText] = useState('');

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage - 1); 
  // };

  // const startIndex = page * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };

  useEffect(() => {
    getData()

  }, [])

  const handleEdit = (row) => {
    setEditingData(row);
    const editUrl = `/editadduser/${my_list[4]}/${row.masterId}`;
    navigate(editUrl);
  }

  const url6 = `${BaseUrl}/hospitals/last-inserted-hospital-name`;
       axios
        .get(url6)
        .then((res,) => {
          console.log(res,"aaaaaa")
          setlast(res.data.lastInsertedHospitalName
          )
        })
        console.log(last,"last")

       
      

       
        
        useEffect(() => {
          if (my_list[4] === 'HCP%20Admin') {
            // Assuming data is an array of users and you want to filter based on hospital name
            const updatedFilteredData = data.filter(user =>
              
              user.hcpOffice === last
            );
            console.log(updatedFilteredData)
            setFilteredData(updatedFilteredData);
          } else {
            setFilteredData([]);
          }
        }, [data, my_list, last]);
        


  const getData = () => {

    const url8 = `${BaseUrl}/add-users/findAll`;


    axios
      .post(url8)
      .then((res) => {
        setdata(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/add-users/delete`;
    const requestBody = {
      masterId: id,
    };

    axios
      .post(deleteUrl, requestBody)
      .then((response) => {
        getData();
        navigate(`/tablenew?/superadmin`);
      })
      .catch((e) => {
        console.error("Error deleting:", e);
      });
  };

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  // useEffect(() => {
  //   let timeoutId;

  //   const resetTimeout = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       // logout(); // Logout the user
  //       navigate('/'); // Redirect to login page
  //     }, 2 * 60 * 1000); // 5 minutes
  //   };

  //   const handleUserActivity = () => {
  //     resetTimeout();
  //   };

  //   resetTimeout(); // Initial call to start the timeout

  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('mousedown', handleUserActivity);
  //   window.addEventListener('keypress', handleUserActivity);
  //   window.addEventListener('touchstart', handleUserActivity);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('mousedown', handleUserActivity);
  //     window.removeEventListener('keypress', handleUserActivity);
  //     window.removeEventListener('touchstart', handleUserActivity);
  //   };
  // }, [navigate]);

  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);


  // const navigate = useNavigate();

  const handlenavigate = () => {
    // navigate("/addUsers?/" + my_list[4]);
    window.location.href = "/addUsers?/" + my_list[4];
  };
  const [filteredData, setFilteredData] = useState([])

  // const handleSearchChange = (value) => {
  //   setSearchText(value);
  // };
  // const [searchText, setSearchText] = useState('');
 const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.role.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };




  return (
    <div className='picontainer'
    //  style={{ overflow: "scroll" }}
    >
      <NewHeader />
      <Sidebar1 />
      <div className='miniheadtable'>
        <div className='miniheadtitle'>
          <Col>
            <h2 className='newtablemed'>
              User
            </h2>
          </Col>
        </div>
        <Row className='miniheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}
              placeholder='Search For Role Name'
              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>
         

          <Col className='addnewusecol' style={{
            left: "30%",
            paddingTop: "2%",
            // left: "8%"
          }}>
            <div className='addnewusecoldiv' onClick={handlenavigate} >
              Add New User
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <TableContainer className="tablecont" style={{ width: "90%" }} component={Paper}>
          {loading ? (<Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
          </Box>) :
            (<>
              <Table>
                <TableHead className="tablerowm">
                  <TableRow>
                    <TableCell><b>S.No</b></TableCell>
                    <TableCell><b>Role</b></TableCell>
                    <TableCell><b>First Name</b></TableCell>
                    <TableCell><b>Last Name</b></TableCell>
                    <TableCell><b>Email ID</b></TableCell>
                    <TableCell><b>Actions</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id}>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>
                          {row.role}
                        </TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell>{row.emailId}</TableCell>
                        <TableCell>
                          <Tooltip title="Description">
                            <IconButton aria-label="View">
                              <Visibility onClick={(e) => navigate("/Personalinfo?/" + my_list[4] + "/" + row.masterId)} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={() => handleEdit(row)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {/* <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                            <Delete onClick={() => handleDelete(row.masterId)} />
                          </IconButton > */}
                        </TableCell>
                      </TableRow>))) : (
                    data.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id}>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>
                          {row.role}
                        </TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell>{row.emailId}</TableCell>
                        <TableCell>
                          <Tooltip title="Description">
                            <IconButton aria-label="View">
                              <Visibility onClick={(e) => navigate("/Personalinfo?/" + my_list[4] + "/" + row.masterId)} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={() => handleEdit(row)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Delete">
                            <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                              <Delete onClick={() => handleDelete(row.masterId)} />
                            </IconButton>
                          </Tooltip>
                           <>
                            <Modal title="Are you sure you want to delete?"
                              open={isModalOpen}
                              onOk={() => handleDelete(row.masterId)}
                              onCancel={handleCancel}>
                            </Modal>
                           </> */}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>

              <Row></Row>

            </>)}
        </TableContainer>
      </div>

      {/* <div className='pagination'>
        <div style={{ marginLeft: "38%", justifyContent: "center", textAlign: "center", paddingTop: "1%", paddingBottom: "1%" }}>
          <Pagination color="primary" style={{ backgroundColor: "white", width: "40%", borderRadius: "10px" }} count={Math.ceil(data.length / rowsPerPage)} onChange={handlePageChange} page={page + 1} />
        </div>
      </div> */}
      <div className='pagination'>
        <div>
          <Pagination color="primary"
            // count={Math.ceil(data.length / rowsPerPage)}
            // count={Math.ceil(filteredData.length / rowsPerPage)}
            count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
            onChange={handlePageChange}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
          />
        </div>
      </div>
    </div>
  );
}

export default NewTable;
