import React, { useState,useEffect,useRef } from 'react'
import { Col, Row,Modal } from 'antd';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router';
import './Newlogin.css'
import { TextField } from '@mui/material';
import { message, Upload } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import OtpInput from 'react-otp-input';
import { Margin } from '@mui/icons-material';
import { Card } from "antd";
import {BaseUrl} from '../config'
import Newlogin from './Newlogin';
import { useAuth } from '../Newlogin/Authprovider';

const Newloginss = () => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    
  // }, [isAuthenticated, navigate]);



  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [email, setemail] = useState('');
  const [otp, setotp] = useState('');
  const [status, setstatus] = useState('');
  const [response, setresponse] = useState('');
  // const [ hideOtpBtn, setHideOtpBtn ] = useState(true)
  const [searchLoading, setSearchLoading] = useState(false);
  // const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    message.open({
      type: "success",
      content: "  Super Admin Login Successfully",
    });
  }

  const successs = () => {
    message.open({
      type: "success",
      content: "Hcp Admin Login Successfully",
    });
  }

  const successss = () => {
    message.open({
      type: "success",
      content: "HCP Executive Login Successfully",
    });
  }

  const successsss = () => {
    message.open({
      type: "success",
      content: "Support Executive - Reviewer Login Successfully",
    });
  }

  const successssss = () => {
    message.open({
      type: "success",
      content: "Support Executive - Creator Login Successfully",
    });
  }
  //     
  const error = () => {
    message.open({
      type: "error",
      content: "Invalid OTP or OTP Expired ",
    });
  };

 
    let expires =  (localStorage.getItem("expires"));
    let gmail =  (localStorage.getItem("gmail"));
    let role =  (localStorage.getItem("role"));
    console.log(expires,"expiresjjjj")

    useEffect(() => {
      const managnment = window.location.href
      console.log(managnment, "id")
      const my_list = managnment.split("/")
      console.log(my_list[7], "my_list")
      const statusss = my_list[6]
      // console.log(typeof role, "ggggg", role)
      setstatus(statusss)
    }, []);

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          // navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

//     const timeoutRef = useRef(null);

// const logout = () => {
//     console.log('Logging out due to inactivity');
//     localStorage.clear();
//     navigate('/');
// };

// const resetTimeout = () => {
//     clearTimeout(timeoutRef.current);
//     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
// };

// const handleUserActivity = () => {
//     console.log('User activity detected, resetting idle timeout');
//     resetTimeout();
// };

// useEffect(() => {
//     resetTimeout();

//     window.addEventListener('mousemove', handleUserActivity);
//     window.addEventListener('mousedown', handleUserActivity);
//     window.addEventListener('keypress', handleUserActivity);
//     window.addEventListener('touchstart', handleUserActivity);

//     return () => {
//         clearTimeout(timeoutRef.current);
//         window.removeEventListener('mousemove', handleUserActivity);
//         window.removeEventListener('mousedown', handleUserActivity);
//         window.removeEventListener('keypress', handleUserActivity);
//         window.removeEventListener('touchstart', handleUserActivity);
//     };
// }, []);


    
    
   
    const Navigate = useNavigate();  // Move useNavigate outside of useEffect

   
    // const Navigate = useNavigate();

    const handleModalOk = () => {
      // Redirect to login page or handle logout logic here
      setShowSessionTimeoutModal(false);
      Navigate('/');
    };
  
    const handleModalCancel = () => {
      setShowSessionTimeoutModal(false);


    };

    // const differenceInMilliseconds = expires - isoDateString;

    // const differenceInMinutes = differenceInMilliseconds / (1000 * 60)
    // console.log(differenceInMinutes,"differenceInMinutes")
    




  const searchParams = new URLSearchParams(window.location.search);
  const id = window.location.href
  // const Navigate = useNavigate();

  const handleClick = (e) => {
    let value = "superadmin";

    if (id) {
      const my_list = id.split("/");
      const role = my_list[5];
      let url2 = `${BaseUrl}/otp-verify`;
      let body = {
        "username": gmail,
        "otp": otp,
      };
      axios
        .post(url2, body)
        .then((res) => {
          if (res.data.body.status === "ok") {
            // success();

            if (role === "") {
              Navigate("/graph?/" + value + "");
              success();
            } else if (role === "HCP%20Admin") {
              Navigate("/graph?/" + my_list[5] + "");
              successs();
            } else if (role === "HCP%20Executive") {
              Navigate("/ebvtable?/" + my_list[5] + "");
              successss();
            } else if (role === "Support%20Executive%20-%20Reviewer") {
              Navigate("/addplan?/" + my_list[5] + "");
              successsss();
            } else if (role === "Support%20Executive%20-%20creator") {
              Navigate("/createtable?/" + my_list[5] + "");
              successssss();
            } else {
              error();
            }
          } else if (res.data.body.status === "error") {
            error()
          }
        })
        .catch((e) => {
          error();
        });
    }
  };

  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
  //   useEffect(() => {
  //       const sessionTimeout = setTimeout(() => {
  //         setShowSessionTimeoutModal(true);
  //       }, 2 * 60 * 1000);
    
  //       return () => clearTimeout(sessionTimeout);
  //     }, []);
    
      // const handleModalOk = () => {
      //   // Redirect to login page or handle logout logic here
      //   setShowSessionTimeoutModal(false);
      //   Navigate('/');
      // };
    
      // const handleModalCancel = () => {
      //   setShowSessionTimeoutModal(false);
      // };
    

  return (
    <>
      <div className='loginNewLandingpage'>
        <img style={{ height: "100vh" , width:"100%" }} src='Mask group 1.jpg'></img>
      </div>
      <Row className='loginabs'
       >
          <div className='logincontentmain' style={{ height: '300px', width: '500px', backgroundColor: 'white' }}>
            <div className='loginimage'>
              <img style={{ height: '500px' }} src="logoipsum.jpg" alt="" />
            </div>
            <div className='loginOTP'>
              <span >OTP Verification</span>
            </div>
            <br />
            <Row style={{ marginTop: "7%", marginBottom: '4%', justifyContent: "center" }}>
              <div className="loginotpBoxInput">
                <OtpInput
                  Width={100}
                  height={100}
                  value={otp}
                  onChange={setotp}
                  numInputs={4}
                  //   gap={10}
                  renderInput={(props) => <input {...props} />} />
              </div>
            </Row>
            <br />
            <Row >
              <LoadingButton style={{ marginLeft: '150px', width: '220px', backgroundColor: '#1DA0E1' }}
                disabled={searchLoading || (!response && otp === "")}
                className="newUser"
                loadingPosition="start"
                variant="contained"
                onClick={() => handleClick("Submit")}
                loading={searchLoading}
              //   style={{
              //     marginTop: "10%",
              //     marginLeft: "-5px",
              //     // marginBottom: "50px",
              //   }}
              >
                Submit
              </LoadingButton>
            </Row>
            <Modal
        title="Session Timeout"
        visible={showSessionTimeoutModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Your session has timed out. Please log in again.</p>
      </Modal>
          </div>
      </Row>
    </>
  )
}


export default Newloginss