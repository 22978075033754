// import { useState } from "react";
// import MenuIcon from '@mui/icons-material/Menu';
import { UploadOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { VerifiedOutlined } from "@mui/icons-material";
import { Avatar, Card, Col, Popover, Row } from "antd";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Modal } from 'antd';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { green } from '@ant-design/colors';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState,useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
// import { Col, Row } from 'antd';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
// import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { json, useNavigate, useParams } from "react-router";
import axios from "axios";
import "./Pverify.css";
import TextArea from "antd/es/input/TextArea";
import Autocomplete from "@mui/material/Autocomplete";
import { WestOutlined } from '@mui/icons-material';
import { useEffect } from "react";  
import { Switch, Checkbox } from "antd";
import { red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Header from "./Header";
// import Footer from './Footer';
// import Button from '@mui/material/Button';
// import MenuIcon from '@mui/icons-material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Sidebar.css";
import "./sidebar1.css";


// import "./Pverify.css";
import Dragger from "antd/es/upload/Dragger";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import Sidebar from "./Sidebar";
import { VerifiedUserOutlined } from "@mui/icons-material";
import NewHeader from "./NewHeader";
import Sidebar1 from "./Sidebar1";
import { Progress } from 'antd';
import QRCode from 'qrcode.react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CryptoJS from 'crypto-js';
import { BaseUrl } from '../config'
import { useAuth } from '.././Newlogin/Authprovider'

const PureReact = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);
  const [isOpened, setIsOpened] = useState(false);

  const [ArrayBuffer, setArrayBuffer] = useState();
  const [InsuranceType, setInsuranceType] = useState();
  const [InsuranceNumber, setInsuranceNumber] = useState("");
  const [payerCode, setpayerCode] = useState("");
  const [copayStatus, setcopayStatus] = useState("");
  const [payerName, setpayerName] = useState("");
  const [balance, setbalance] = useState("");
  const [remainDeduct, setRemainDeduct] = useState("");
  const [percentDeduct, setPercentDeduct] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [insuId, setinsuId] = useState("");
  const [ImageUrl, setImageUrl] = useState("");
  const [planname, setPlanName] = useState("");
  const [drugname, setDrugName] = useState("");
  const [payer, setPayer] = useState([]);
  const [cigna, setCigna] = useState([]);
  const [emblemhealth, setemblemhealth] = useState([]);
  const [anthembluecrossblueshield, setanthembluecrossblueshield] = useState(
    []
  );
  const [upmc, setupmc] = useState([]);
  const [geisinger, setgeisinger] = useState([]);
  const [amerihealth, setamerihealth] = useState([]);
  const [imperialhealth, setimperialhealth] = useState([]);
  const [hyperlinks, setlink] = useState([]);
  const [byalors, setbyalors] = useState([]);
  const [clover, setclover] = useState([]);
  const [wellcare, setwellcare] = useState([]);
  const [health, sethealth] = useState([]);
  const [aetna, setaetna] = useState([]);
  const [ambetter, setambetter] = useState([]);
  const [unitedhealthcare, setunitedhealthcare] = useState([]);
  const [plan, setPlan] = useState([]);
  const [payerlist, setPayerlist] = useState([]);
  const [responseCovered, setresponseCovered] = useState("");
  const [color, setcolor] = useState("");
  const [state, setstate] = useState([]);
  const [icon1, seticon1] = useState(false);
  const [icon2, seticon2] = useState(false);
  const [icon3, seticon3] = useState(false);
  const [icon4, seticon4] = useState(false);
  const [icon5, seticon5] = useState(false);
  const [subscriberid, setSubscriberid] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [dob, setdob] = useState("");
  const [dragger, setdragger] = useState("Auto");
  const [newyork, setnewyork] = useState([]);
  const [newjersey, setjersey] = useState([]);
  const [pennsylvania, setpennsylvania] = useState([]);
  const [michigan, setmichigan] = useState([]);
  const [texas, settexas] = useState([]);
  const [illinois, setillinois] = useState([]);
  const [illatena, setillatena] = useState([]);
  const [illamber, setillamber] = useState([]);
  const [illablue, setillablue] = useState([]);
  const [illacigna, setillacigna] = useState([]);
  const [illaoscar, setillaoscar] = useState([]);
  const [illaunited, setillaunited] = useState([]);
  const [illawell, setillawell] = useState([]);
  const [migaetna, setmigaetna] = useState([]);
  const [migamb, setmigamb] = useState([]);
  const [migblue, setmigblue] = useState([]);
  const [mighap, setmighap] = useState([]);
  const [migoscar, setmigoscar] = useState([]);
  const [migunited, setmigunited] = useState([]);
  const [migwell, setmigwell] = useState([]);
  const [newaetna, setnewaetna] = useState([]);
  const [newamb, setnewamb] = useState([]);
  const [newblue, setnewblue] = useState([]);
  const [newcigna, setnewcigna] = useState([]);
  const [newclover, setnewclover] = useState([]);
  const [newoscar, setnewoscar] = useState([]);
  const [newunited, setnewunited] = useState([]);
  const [newwell, setnewwell] = useState([]);
  const [newamer, setnewamer] = useState([]);
  const [yorkcigna, setyorkcigna] = useState([]);
  const [yorkemb, setyorkemb] = useState([]);
  const [yorkoscar, setyorkoscar] = useState([]);
  const [yorkwell, setyorkwell] = useState([]);
  const [yorkaetna, setyorkaetna] = useState([]);
  const [yorkheal, setyorkheal] = useState([]);
  const [yorkunited, setyorkunited] = useState([]);
  const [penaetna, setpenaetna] = useState([]);
  const [penamb, setpenamb] = useState([]);
  const [penblue, setpenblue] = useState([]);
  const [pencigna, setpencigna] = useState([]);
  const [penclover, setpenclover] = useState([]);
  const [penoscar, setpenoscar] = useState([]);
  const [penupmc, setpenupmc] = useState([]);
  const [penunited, setpenunited] = useState([]);
  const [penges, setpenges] = useState([]);
  const [texaetna, settexaetna] = useState([]);
  const [texamb, settexamb] = useState([]);
  const [texbay, settexbay] = useState([]);
  const [texblue, settexblue] = useState([]);
  const [texcigna, settexcigna] = useState([]);
  const [texclover, settexclover] = useState([]);
  const [teximp, setteximp] = useState([]);
  const [texoscar, settexoscar] = useState([]);
  const [texunited, settexunited] = useState([]);
  const [texwell, settexwell] = useState([]);
  // const [responseCovered, setResponseCovered] = useState('');
  const [pverify, setPverify] = useState('');
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressStatus, setProgressStatus] = useState('normal');
  const [pcode, setpcode] = useState("");
  const [progress, setProgress] = useState(0);
  const [roles, setrole] = useState("");
  const [pracode, setpracode] = useState("");
  const [familyind, setfamilyind] = useState("");
  const [familyindrem, setfamilyindrem] = useState("");
  const [isSubscriberPatient, setIsSubscriberPatient] = useState("true");
  const [ebv, setebv] = useState("");
  const [ebvId, setebvId] = useState("");
  const [ebvendpoint, setebvendpoint] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  const [ambebv, setambebv] = useState("");
  const [embebv, setembebv] = useState("");
  const [geisebv, setgeiebv] = useState("");
  const [bayebv, setbayebv] = useState("");
  const [upebv, setupebv] = useState("");
  const [clebv, setclebv] = useState("");
  const [ciebv, setciebv] = useState("");
  const [unebv, setunebv] = useState("");
  const [amebv, setamebv] = useState("");
  const [atebv, setatebv] = useState("");
  const [imebv, setimebv] = useState("");
  const [welebv, setwelebv] = useState("");
  const [haebv, sethaebv] = useState("");
  const [blebv, setblebv] = useState("");
  const [heebv, setheebv] = useState("");
  const [ocebv, setocebv] = useState("");
  const [ebvresponse, setebvresponse] = useState("");
  const [ebvlink, setebvlink] = useState("");

  const [State, setState] = useState({
    npiId: '',
    address: '',
    qrCodeData: '',
  });


  useEffect(() => {
    if (timer > 0) {
      const timerId = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      setIsButtonDisabled(true);
    }
  }, [timer]);

  const encryptPayer = (data) => {
    const secretKey = '123567890';
    const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encryptedData;
  };

  const UnitedHealthCare = [

    { label: "UnitedHealthcare Dual Complete Choice (PPO D-SNP)", id: 1 },
    { label: "United Healthcare Medicare Advantage Choice plan1(Regional PPO)", id: 2 },
    { label: "UnitedHealthcare Dual Complete (HMO-POS D-SNP)", id: 3 },
  ]

  const Aetnaopt = [
    { label: "Aetna Medicare Premier (HMO-POS)", id: 1 },
    { label: " Aetna Medicare Value (PPO)", id: 2 },
    { label: "Aetna Medicare Prime Plan (HMO)", id: 3 },
    { label: "Aetna Medicare Select Plan (HMO)", id: 4 },

  ]

  const [isBlackTheme, setIsBlackTheme] = useState(false);

  const toggleTheme = () => {
    setIsBlackTheme(!isBlackTheme);
  };
  const generateQRCodeData = () => {
    // Generate QR code data based on hospital information
    const qrData = `http://52.87.151.167:9000/`; // Add necessary data
    setState(prevState => ({
      ...prevState,
      qrCodeData: qrData,
    }));
  };

  useEffect(() => {
    generateQRCodeData();
  }, [State]);
  useEffect(() => {
    let urls = `${BaseUrl}/ebvgateway-mapping-insurance-name/` + payer;
    axios.post(urls)
      .then((res) => {
        setebv(res.data.data)
      })
  }, [payer])

  const [selectedDate, setSelectedDate] = useState(null);


  const Navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("ID");
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const showModal1 = () => {
    setOpen1(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen1(false);
    }, 3000);
  };

  const handleOk1 = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen1(false);
  };

  const handleCancel1 = () => {
    setOpen1(false);
  };

  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")
    const role = my_list[4]
    setrole(role)
    // const pra = my_list[5]
    // setpracode(pra)
  }, [])

  const success = () => {
    message.open({
      type: "success",
      content: "Pverify Verified Successfully",
    });
  };
  const error = () => {
    message.open({
      type: "error",
      content: "Your Subscriber Id Not Match For Selected Insurance Name",
    });
  }

  const successs = () => {
    message.open({
      type: "success",
      content: "Coverage Details Verified Successfully",
    });
    //     messageApi.success("Logged in successfully")
  };

  useEffect(() => {
    let map =
      `${BaseUrl}/states-mapping`;

    axios.get(map).then((res) => {
      const blueCrossBlueShieldValue = res.data.data.Illinois['BlueCross BlueShield']
      const cignaValue = res.data.data.Illinois['Cigna Healthcare']
      const oscarValue = res.data.data.Illinois['Oscar Health']
      const unitedvalue = res.data.data.Illinois['United Healthcare']
      const blueCrossmig = res.data.data.Michigan['BlueCross BlueShield']
      const migoscarValue = res.data.data.Michigan['Oscar Health']
      const migunitedvalue = res.data.data.Michigan['United Healthcare']
      const newAetnaValue = res.data.data['New Jersey'].Aetna
      const newambValue = res.data.data['New Jersey'].Ambetter
      const newbluevalue = res.data.data['New Jersey']['BlueCross BlueShield']
      const newcignavalue = res.data.data['New Jersey']['Cigna Healthcare']
      const newclovervalue = res.data.data['New Jersey']['Clover Health']
      const newoscarvalue = res.data.data['New Jersey']['Oscar Health']
      const newunitedvalue = res.data.data['New Jersey']['United Healthcare']
      const newwellvalue = res.data.data['New jersey'].Wellcare
      const newamervalue = res.data.data['New jersey'].AmeriHealth
      const yorkcignavalue = res.data.data['New York']['Cigna Healthcare']
      const yorkembvalue = res.data.data['New York']['Emblem Health']
      const yorkoscarvalue = res.data.data['New York']['Oscar Health']
      const yorkwellvalue = res.data.data['New York'].Wellcare
      const yorkaetnavalue = res.data.data['New york'].Aetna
      const yorkhealvalue = res.data.data['New york']['Health First']
      const yorkunitedvalue = res.data.data['New york']['United Healthcare']
      const blueCrosspen = res.data.data.Pennsylvania['BlueCross BlueShield']
      const cignapen = res.data.data.Pennsylvania['Cigna Healthcare']
      const cloverpen = res.data.data.Pennsylvania['Clover Health']
      const oscarpen = res.data.data.Pennsylvania['Oscar Health']
      const upmcpen = res.data.data.Pennsylvania['UPMC Health']
      const unitedcpen = res.data.data.Pennsylvania['United Healthcare']
      const gespen = res.data.data.Pennslyvania['Geisinger Health']
      const texbayvalue = res.data.data.Texas['Baylor white and scott']
      const texbluevalue = res.data.data.Texas['BlueCross BlueShield']
      const texcignavalue = res.data.data.Texas['Cigna Healthcare']
      const texclovervalue = res.data.data.Texas['Clover Health']
      const teximpvalue = res.data.data.Texas['Imperial Health']
      const texoscarvalue = res.data.data.Texas['Oscar Health']
      const texunitedvalue = res.data.data.Texas['United Healthcare']

      setillatena(res.data.data.Illinois.Aetna)
      setillamber(res.data.data.Illinois.Ambetter)
      setillablue(blueCrossBlueShieldValue)
      setillacigna(cignaValue)
      setillaoscar(oscarValue)
      setillaunited(unitedvalue)
      setillawell(res.data.data.Illinois.Wellcare)
      setmigaetna(res.data.data.Michigan.Aetna)
      setmigamb(res.data.data.Michigan.Ambetter)
      setmigblue(blueCrossmig)
      setmighap(res.data.data.Michigan.HAP)
      setmigoscar(migoscarValue)
      setmigunited(migunitedvalue)
      setmigwell(res.data.data.Michigan.Wellcare)
      setnewaetna(newAetnaValue)
      setnewamb(newambValue)
      setnewblue(newbluevalue)
      setnewcigna(newcignavalue)
      setnewclover(newclovervalue)
      setnewoscar(newoscarvalue)
      setnewunited(newunitedvalue)
      setnewwell(newwellvalue)
      setnewamer(newamervalue)
      setyorkcigna(yorkcignavalue)
      setyorkemb(yorkembvalue)
      setyorkoscar(yorkoscarvalue)
      setyorkwell(yorkwellvalue)
      setyorkaetna(yorkaetnavalue)
      setyorkheal(yorkhealvalue)
      setyorkunited(yorkunitedvalue)
      setpenaetna(res.data.data.Pennsylvania.Aetna)
      setpenamb(res.data.data.Pennsylvania.Ambetter)
      setpenblue(blueCrosspen)
      setpencigna(cignapen)
      setpenclover(cloverpen)
      setpenoscar(oscarpen)
      setpenupmc(upmcpen)
      setpenunited(unitedcpen)
      setpenges(gespen)
      settexaetna(res.data.data.Texas.Aetna)
      settexamb(res.data.data.Texas.Ambetter)
      settexbay(texbayvalue)
      settexblue(texbluevalue)
      settexcigna(texcignavalue)
      settexclover(texclovervalue)
      setteximp(teximpvalue)
      settexoscar(texoscarvalue)
      settexunited(texunitedvalue)
      settexwell(res.data.data.Texas.Wellcare)
    })
  }, [])

  useEffect(() => {

    if (state == "Illinois" && payer == "Aetna") {
      setPlan(illatena)
    } else if (state == "Illinois" && payer == "Ambetter") {
      setPlan(illamber)
    } else if (state == "Illinois" && payer == "BluecrossBlueshield") {
      setPlan(illablue)
    } else if (state == "Illinois" && payer == "Cigna") {
      setPlan(illacigna)
    } else if (state == "Illinois" && payer == "OscarHealth") {
      setPlan(illaoscar)
    } else if (state == "Illinois" && payer == "UnitedHealthCare") {
      setPlan(illaunited)
    } else if (state == "Illinois" && payer == "Wellcare") {
      setPlan(illawell)
    } else if (state == "Michigan" && payer == "Aetna") {
      setPlan(migaetna)
    } else if (state == "Michigan" && payer == "Ambetter") {
      setPlan(migamb)
    } else if (state == "Michigan" && payer == "BluecrossBlueshield") {
      setPlan(migblue)
    } else if (state == "Michigan" && payer == "HAP") {
      setPlan(mighap)
    } else if (state == "Michigan" && payer == "OscarHealth") {
      setPlan(migoscar)
    } else if (state == "Michigan" && payer == "UnitedHealthCare") {
      setPlan(migunited)
    } else if (state == "Michigan" && payer == "Wellcare") {
      setPlan(migwell)
    } else if (state == "New Jersey" && payer == "Aetna") {
      setPlan(newaetna)
    } else if (state == "New Jersey" && payer == "Ambetter") {
      setPlan(newamb)
    } else if (state == "New Jersey" && payer == "BluecrossBlueshield") {
      setPlan(newblue)
    } else if (state == "New Jersey" && payer == "Cigna") {
      setPlan(newcigna)
    } else if (state == "New Jersey" && payer == "CloverHealth") {
      setPlan(newclover)
    } else if (state == "New Jersey" && payer == "OscarHealth") {
      setPlan(newoscar)
    } else if (state == "New Jersey" && payer == "UnitedHealthCare") {
      setPlan(newunited)
    } else if (state == "New Jersey" && payer == "Wellcare") {
      setPlan(newwell)
    } else if (state == "New Jersey" && payer == "Amerihealth") {
      setPlan(newamer)
    } else if (state == "New York" && payer == "Cigna") {
      setPlan(yorkcigna)
    } else if (state == "New York" && payer == "EmblemHealth") {
      setPlan(yorkemb)
    } else if (state == "New York" && payer == "OscarHealth") {
      setPlan(yorkoscar)
    } else if (state == "New York" && payer == "Wellcare") {
      setPlan(yorkwell)
    } else if (state == "New York" && payer == "Aetna") {
      setPlan(yorkaetna)
    } else if (state == "New York" && payer == "HealthFirst") {
      setPlan(yorkheal)
    } else if (state == "New York" && payer == "UnitedHealthCare") {
      setPlan(yorkunited)
    } else if (state == "Pennsylvania" && payer == "Aetna") {
      setPlan(penaetna)
    } else if (state == "Pennsylvania" && payer == "Ambetter") {
      setPlan(penamb)
    } else if (state == "Pennsylvania" && payer == "BluecrossBlueshield") {
      setPlan(penblue)
    } else if (state == "Pennsylvania" && payer == "Cigna") {
      setPlan(pencigna)
    } else if (state == "Pennsylvania" && payer == "CloverHealth") {
      setPlan(penclover)
    } else if (state == "Pennsylvania" && payer == "OscarHealth") {
      setPlan(penoscar)
    } else if (state == "Pennsylvania" && payer == "UPMChealth") {
      setPlan(penupmc)
    } else if (state == "Pennsylvania" && payer == "UnitedHealthCare") {
      setPlan(penunited)
    } else if (state == "Pennsylvania" && payer == "GeisingerHealth") {
      setPlan(penges)
    } else if (state == "Texas" && payer == "Aetna") {
      setPlan(texaetna)
    } else if (state == "Texas" && payer == "Ambetter") {
      setPlan(texamb)
    } else if (state == "Texas" && payer == "BaylorScottandWhite") {
      setPlan(texbay)
    } else if (state == "Texas" && payer == "BluecrossBlueshield") {
      setPlan(texblue)
    } else if (state == "Texas" && payer == "Cigna") {
      setPlan(texcigna)
    } else if (state == "Texas" && payer == "CloverHealth") {
      setPlan(texclover)
    } else if (state == "Texas" && payer == "ImperialHealth") {
      setPlan(teximp)
    } else if (state == "Texas" && payer == "OscarHealth") {
      setPlan(texoscar)
    } else if (state == "Texas" && payer == "UnitedHealthCare") {
      setPlan(texunited)
    } else if (state == "Texas" && payer == "Wellcare") {

      setPlan(texwell)
    }

  }, [state, payer])

  useEffect(() => {
    let url5 =
      `${BaseUrl}/plan-list`;

    axios.get(url5).then((res) => {
      setCigna(res.data.data.cigna_list);
      setaetna(res.data.data.aetna_list);
      setemblemhealth(res.data.data.emblem_list);
      setanthembluecrossblueshield(
        res.data.data.blue_cross
      );
      setambetter(
        res.data.data.ambetter_list
      );
      setunitedhealthcare(
        res.data.data.united_healthcare_list
      );
      setupmc(
        res.data.data.upmc_list
      );
      setgeisinger(
        res.data.data.geisinger_list
      );
      setamerihealth(
        res.data.data.amerihealth_list
      );
      setimperialhealth(
        res.data.data.imperial_list
      );
      setbyalors(
        res.data.data.byalors_list
      );
      setclover(
        res.data.data.clover_health
      );
      setwellcare(
        res.data.data.wellcare
      );
      sethealth(
        res.data.data.health_first
      );
    });
  }, []);

  useEffect(() => {
    let url8 =
      `${BaseUrl}/state-list`;

    axios.get(url8).then((res) => {
      setnewyork(res.data.data.new_york);
      setjersey(res.data.data.new_jersey);
      setpennsylvania(res.data.data.pennsylvania);
      setmichigan(
        res.data.data.michigan
      );
      settexas(
        res.data.data.texas
      );
      setillinois(
        res.data.data.illinois
      );
    });
  }, []);

  useEffect(() => {
    if (state === "New York") {
      setPayerlist(newyork);
    }
    else if (state === "New Jersey") {
      setPayerlist(newjersey);
    }
    else if (state === "Pennsylvania") {
      setPayerlist(pennsylvania);
    }
    else if (state === "Michigan") {
      setPayerlist(michigan);
    }
    else if (state === "Texas") {
      setPayerlist(texas);
    }
    else if (state === "Illinois") {
      setPayerlist(illinois);
    }

  }, [state]);

  useEffect(() => {
    let urls = `${BaseUrl}/hospitals/last-inserted-servicetype`;
    axios.get(urls)
      .then((res) => {
        setpracode(res.data.lastInsertedServiceType)
      })
  }, [])
  useEffect(() => {

    if (ebv === "Change Healthcare") {
      setebvId("CH0001")
      setebvendpoint("/ChangeHealthcare")
      setebvlink("https://www.changehealthcare.com/")
    }
    else if (ebv === "Availity") {
      setebvId("AV0001")
      setebvendpoint("/Availity")
      setebvlink("https://www.availity.com/")
    }
    else if (ebv === "Zirmed") {
      setebvId("ZD0001")
      setebvendpoint("/Zirmed")
      setebvlink("https://login.zirmed.com/ui")
    }
    else if (ebv === "pVerify") {
      setebvId("PV0001")
      setebvendpoint("/pVerify")
      setebvlink("https://www.pverify.com/")
    }
    else if (ebv === "Office Ally") {
      setebvId("OA0001")
      setebvendpoint("/Officeally")
      setebvlink("https://cms.officeally.com/")
    }
  }, [ebv])

  // useEffect(()=>{
  let url4 = `${BaseUrl}/hospitals/get-id`;
  let body4 = {
    "code": pracode
  };

  axios.post(url4, body4)
    .then((res) => {
      setpcode(res.data)
    })
  // },[])

  const handleClick = (e) => {
    setSearchLoading(true);

    if (e === "Submit") {
      let emblem;

      if (payer === "EmblemHealth") {
        emblem = "000101";
        setpayerCode(emblem);
      }
      else if (payer === "UnitedHealthCare") {
        emblem = "00192";
        setpayerCode(emblem);
      }

      let url4 = `${BaseUrl}/pverify`;

      let body4 = {
        firstName: firstname,
        dob: dob,
        lastName: lastname,
        subscriberID: subscriberid,
        payerCode: emblem,
        payerName1: payer,
        practicecode: pcode,
        isSubscriberPatient: isSubscriberPatient
      };

      axios.post(url4, body4)
        .then((res) => {
          let copay = res.data.CopayStatus;
          setcopayStatus(res.data.data[0].CopayStatus);
          let IndividualOOP_InNet = res.data.IndividualOOP_InNet;
          setbalance(res.data.data[0].IndividualOOP_InNet);
          let IndividualOOPRemainingInNet = res.data.IndividualOOPRemainingInNet;
          setRemainDeduct(res.data.data[0].IndividualOOPRemainingInNet);
          let Coinsurancerelatedinformation = res.data.Coinsurancerelatedinformation;
          setPercentDeduct(res.data.data[0].Coinsurancerelatedinformation);
          setebvresponse(res.data.data[0].message)
          setPverify(res.data.data[0].message
          );
          setfamilyind(res.data.data[0].FamilyIndividualOOP_InNet)
          setfamilyindrem(res.data.data[0].FamilyIndividualOOPRemainingInNet)

          if (res.data.data[0].message === 'Pverify Verified Sucessfully') {
            setProgressPercent(60)
            // percentDeduct(60)
            success();
            if (payer === "UnitedHealthCare") {
              setPlanName("UnitedHealthcare Medicare Advantage Choice Plan 1 (Regional PPO)")
            }
            else if (payer === "EmblemHealth") {
              setPlanName("EmblemHealth VIP Gold Plus (HMO)")
            }
            let url2 = `${BaseUrl}/coverage-details`;
            let planName = payer == 'UnitedHealthCare' ? 'UnitedHealthcare Medicare Advantage Choice Plan 1 (Regional PPO)' : 'EmblemHealth VIP Gold Plus (HMO)';
            let DrugName = drugname;
            let payer1 = payer;
            let body = {
              PlanName: planName,
              PayerName: payer1,
              DrugName: "EUFLEXXA",
              State: state,
            };

            axios.post(url2, body)
              .then((res) => {
                let response = res.data.data[0].Response;
                setresponseCovered(response);
                let description = res.data.data[0].Description;
                setlink(res.data.data[0].link[0]);
                setSummary(description);
                if (res.data) {
                  setProgressPercent(40 + 60)
                  successs()
                  // percentDeduct(40)
                }
              })

              .catch((e) => {
                error();
              })
              .finally(() => {
                setSearchLoading(false);
              });
          }
        })
        .catch((err) => {
          console.error('Error in coverage details API:', err);
          error();
        });
    }
  }


  useEffect(() => {
    if (responseCovered == "Covered") {
      // setcolor("red")
      seticon1(true);
      seticon2(false);
      seticon3(false);
      seticon4(false)
      seticon5(false)
    } else if (responseCovered == "Covered with Conditions") {
      seticon1(false);
      seticon2(true);
      seticon3(false);
      seticon4(false)
      seticon5(false)
    } else if (responseCovered == "Covered with Condition") {
      seticon1(false);
      seticon2(false);
      seticon3(false);
      seticon4(true)
      seticon5(false)
    } else if (responseCovered == "Not Covered") {
      seticon1(false);
      seticon2(false);
      seticon3(true);
      seticon4(false)
      seticon5(false)
    } else {
      seticon1(false);
      seticon2(false);
      seticon3(false);
      seticon4(false)
      seticon5(true)

    }
  }, [responseCovered]);

  const handleChange = (event) => {
    setIsSubscriberPatient(event.target.checked);
  };
  // ]

  const handleReset = () => {
    setPayer("");
    setDrugName("");
    setPlanName("");
  };

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const toggleHistory = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  const handle = () => {
    setLoading(true);
    if (id) {
      const my_list = id.split(",");
      let UpdateUrl = `https://3a5zllfhz25x3w3npn4bmxcx4a0aqxml.lambda-url.us-east-1.on.aws/`;

      let body = {
        // "Id": my_list[0],
        Insurance__c: InsuranceType,
        Insurance_company_name__c: "001Dn00000PuD5RIAV",
        Policyholder_same_as_the_patient__c: "yes",
        Policy_ID__c: InsuranceNumber,
        Lead__c: my_list[1],
      };
      axios.post(UpdateUrl, JSON.stringify(body)).then((res) => {
        setLoading(false);
        if (res.data.status === "Ok") {
          //   Navigate("/InsuranceInfo ")
          success();
        } else {
          error();
        }
      });
    } else {
      let FirstName = localStorage.getItem("firstName");
      let LastName = localStorage.getItem("lastName");
      let PhoneNumber = localStorage.getItem("PhoneNumber");
      let MOC = localStorage.getItem("MOC");
      let Dob = localStorage.getItem("Dob");
      let Email = localStorage.getItem("Email");
      let Street = localStorage.getItem("Street");
      let City = localStorage.getItem("City");
      let State = localStorage.getItem("State");
      let PinCode = localStorage.getItem("PinCode");
      let Country = localStorage.getItem("Country");
      let Doc = localStorage.getItem("Doc");

      let IMAGEURL = `https://56ozi7f5v4gcnefxk34263duhy0mgqql.lambda-url.us-east-1.on.aws/`;
      axios
        .post(
          IMAGEURL,
          JSON.stringify({
            imagename: insuId,
          })
        )
        .then((res) => {
          const imagee = res.data;
          setImageUrl(imagee);
          const URL = `https://iafkrrf5icamvacwr62l56famm0icgvu.lambda-url.us-east-1.on.aws/`;
          let requestData = {
            LastName: "",
            Status: "",
            MobilePhone: "",
            Email: "",
            FirstName: "",
            HealthCloudGA__BirthDate__c: "",
            Street: "",
            Country: "",
            PostalCode: "",
            City: "",
            State: "",
            Care_Program__c: "",
            LeadSource: "",
            Registered_By__c: "",
            Is_the_Patient_Insured__c: "",
            HealthCloudGA__Gender__c: "",
            Preferred_Method_of_Contact__c: "",
          };
          const reqbody = JSON.stringify(requestData);
          let requestHeaders = {
            "Content-Type": "application/json",
          };
          axios.post(URL, JSON.stringify(requestData)).then((res) => {
            setLoading(false);
            const Token = res.data.id;
            if (res.data.status == "ok" || "ERROR") {
              Navigate("/NewUser");
              success();
              let CONSENT_URL = `https://r7arhgcvxmhmsioku52d6fz7ta0edaje.lambda-url.us-east-1.on.aws/`;
              let Body = {
                Consent_Name__c: "0cIDn000000tUOoMAM",
                Date_Of_The_Consent__c: Doc,
                Lead__c: Token,
              };
              axios.post(CONSENT_URL, JSON.stringify(Body)).then((res) => {
                if (res.data.status == "ok" || "ERROR") {
                  success();
                } else {
                  error();
                }
              });
            }
            if (res.data.status == "ERROR") {
              error();
            }
          });
        });
    }
  };
  // }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      setLoading(true);
      reader.readAsDataURL(file);
      reader.onload = () => {
        // resolve(reader.result);
        // setArrayBuffer(reader.result)
        let S3_URL = `https://p44t3bnlhrchrz67hg33wkpawy0pqmel.lambda-url.us-east-1.on.aws/`;
        axios
          .post(
            S3_URL,
            JSON.stringify({
              file: reader.result.split("base64,")[1],
            })
          )
          .then((res) => {
            const imagegeturl = res.data.uploadResult.key;
            const insuid = imagegeturl.split("/")[1];
            setinsuId(insuid);
            if (insuid) {
              setProgress(60)
            }

            axios
              .get(
                `https://6kia4hf4gqt277xfeihcw3a7je0xnuxy.lambda-url.us-east-1.on.aws/`
              )
              .then((res) => {
                setLoading(false);
                if (res.data.Member_id) {
                  setSubscriberid(res.data.Member_id);
                }
                else if (res.data.Member_id) {
                  setSubscriberid(res.data.Member_ID);
                }
                else if (res.data.Health_ID) {
                  setSubscriberid(res.data.Health_ID);
                }
                else if (res.data.POLICY_NUMBER) {
                  setSubscriberid(res.data.POLICY_NUMBER);
                }
                else if (res.data.MEDICAL_CLAIM_NUMBER) {
                  setSubscriberid(res.data.MEDICAL_CLAIM_NUMBER);
                }
                else if (res.data.Member_ID) {
                  setSubscriberid(res.data.Member_ID);
                }
                else if (res.data.UnitedHealthcare_Group_Number) {
                  setSubscriberid(res.data.UnitedHealthcare_Group_Number);
                }
                else if (res.data.ID_) {
                  setSubscriberid(res.data.ID_);
                }
                else if (res.data.Medical_Record_No) {
                  setSubscriberid(res.data.Medical_Record_No);
                }
                else if (res.data.Subscriber_Policy_ID_) {
                  setSubscriberid(res.data.Subscriber_Policy_ID_);
                }
                else if (res.data.ID_NUMBER) {
                  setSubscriberid(res.data.ID_NUMBER);
                }
                setdragger("Mannual");

                if (subscriberid) {
                  setProgress(40)
                }
                const insur = res.data.insurer_name
                if (res.data.insurer_name == "United Healthcare") {
                  setPayer("UnitedHealthCare")
                }
                const fullName = res.data.Member;
                if (fullName) {
                  const nameParts = fullName.split(" ");
                  setfirstname(nameParts[0]);
                  setlastname(nameParts.slice(1).join(" "));
                  // setpayerName(insurerName)
                }
              });
          });
      };
      reader.onerror = (error) => reject(error);

      if (ArrayBuffer) {
      }
    });
  };

  const props = {
    name: "file",
    multiple: true,

    onChange(info) {
      let file = info.file;
      let array_length = info.fileList.length;
      if (info.file.status === "error") {
        console.log(
          getBase64(info.fileList[array_length - 1].originFileObj),
          "base64"
        );
      }
      let base64image = getBase64(
        info.fileList[array_length - 1].originFileObj
      );
      let base64Image = getBase64(file);
    },

    onDrop(e) { },
  };


  const options1 = [
    { label: "EmblemHealth", id: 1 },
    { label: "UnitedHealthCare", id: 2 },
    { label: "Cigna", id: 3 },
    { label: "BluecrossBlueshield", id: 4 },
    { label: "Aetna", id: 5 },
    { label: "Ambetter", id: 6 },
    { label: "UPMChealth", id: 7 },
    { label: "GeisingerHealth", id: 8 },
    { label: "Amerihealth", id: 9 },
    { label: "ImperialHealth", id: 10 },
    { label: "BaylorScottandWhite", id: 11 },
    { label: "CloverHealth", id: 12 },
    { label: "Wellcare", id: 13 },
    { label: "HealthFirst", id: 14 },
  ];
  const options2 = [
    { label: "EmblemHealth Platinum", id: 1 },
    { label: "EmblemHealth Gold", id: 2 },
    { label: "EmblemHealth Silver", id: 3 },
    { label: "EmblemHealth Bronze", id: 4 },
    { label: "EmblemHealth Basic (Catastrophic)", id: 5 },
    { label: "Aetna Medicare Primier (HMO-POS)", id: 6 },
    { label: "Aetna Medicare Value (PPO)", id: 7 },
    { label: "Aetna Medicare Premier Plus (PPO)", id: 8 },
    { label: "Aetna Medicare Eagle (PPO)", id: 9 },
    { label: "SilverScript SmartSaver (PDP)", id: 10 },
    { label: "SilverScript Plus (PDP)", id: 11 },
    { label: "Aetna Medicare Prime Plan (HMO)", id: 12 },
    { label: "Aetna Medicare Select Plan (HMO)", id: 13 },
    { label: "Aetna Medicare Plus Plan (HMO)", id: 14 },
    { label: "Aetna Medicare Choice Plan (PPO)", id: 15 },
    { label: "Aetna Medicare Elite Plan 3 (PPO)", id: 16 },
    { label: "Aetna Medicare Assure Plan (HMO D-SNP)", id: 17 },
    { label: "Aetna Medicare Essential Plan (PPO)", id: 18 },
    { label: "Aetna Medicare Eagle Plus Plan (PPO)", id: 19 },
    { label: "Aetna Medicare Elite (PPO)", id: 20 },
    { label: "Aetna Medicare Prime (HMO-POS)", id: 21 },
  ];

  const states = [
    { label: "New York", id: 1 },
    { label: "New Jersey", id: 2 },
    { label: "Pennsylvania", id: 3 },
    { label: "Michigan", id: 4 },
    { label: "Texas", id: 5 },
    { label: "Illinois", id: 6 },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const navigate = useNavigate();

  const content = (
    <>
      <div className="accountPopover">
        <h3>Account</h3>
        <h3 onClick={() => navigate("/")}>Logout</h3>
      </div>
      {/* <div>Logout</div> */}
    </>
  );
  const onChange = (e) => {
    console.log(`switch to ${e.target.value}`);
    setdragger(e.target.value);
  };
  return (
    <div className="pverifyApp" style={{ overflow: "scroll" }}>
      {/* style={ overflow: "scroll" } */}
      <NewHeader />
      <Sidebar1 />

      <div className='minihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/ebvtable?/" + roles + "")} />
        </div>
        <div className='miniheadtitle'>
          Add eBV New Request
        </div>
      </div>
      <div className="container">
        {/* <aside className={`${isOpened ? "opened" : ""} drawer`}></aside> */}
        <div
          className={`main ${isBlackTheme ? 'black-theme' : 'white-theme'}`}
        >
          <div>
            <Grid
              container
              //  className={`form ${isBlackTheme ? 'black-theme' : 'white-theme'}`}
              className="form"
              spacing={2}
              style={{ margin: "auto", justifyContent: "center" }}
            >
              <Grid item xs={12} md={4} lg={4}>
                <FormLabel id="demo-radio-buttons-group-label" style={{}}>
                  <div className="theme-toggle" style={{ marginLeft: "950px" }}>
                    <Switch
                      checked={isBlackTheme}
                      onChange={toggleTheme}
                      color="Primary"
                      name="themeToggle"
                      inputProps={{ 'aria-label': 'toggle theme' }}
                    />
                    {/* <span>{isBlackTheme ? 'Dark Theme' : 'Light Theme'}</span> */}
                  </div>
                  Insurance Info
                </FormLabel>


                {/* <Checkbox onChange={onChange}>Enter Manually</Checkbox> */}
                {roles == "superadmin" ? (
                  <>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Auto"

                      name="radio-buttons-group"
                      onChange={onChange}
                      disabled={true}
                    >
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value="Auto"
                          control={<Radio />}
                          label="Auto"
                          disabled={true}
                        />
                        <FormControlLabel
                          value="Mannual"
                          control={<Radio />}
                          label="Manual"
                          disabled={true}
                        />
                      </div>
                    </RadioGroup>
                  </>
                )
                  :
                  <><RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Auto"
                    name="radio-buttons-group"
                    onChange={onChange}
                  >
                    <div style={{ display: "flex" }}>
                      <FormControlLabel
                        value="Auto"
                        control={<Radio />}
                        label="Auto"
                      />
                      <FormControlLabel
                        value="Mannual"
                        control={<Radio />}
                        label="Manual"
                      />
                    </div>
                  </RadioGroup></>
                }

                {dragger === "Auto" ? (

                  <>
                    {roles === "superadmin" ? (
                      <>
                        <Row className="shield-div" style={{}}>
                          <Col>
                            <Dragger disabled={true} style={{ margin: 0 }}>
                              <p style={{ margin: 0 }}>
                                Please Upload PNG, JPEG or PDF within 5MB
                              </p>
                              <div className="border">
                                <p className="ant-upload-drag-icon">
                                  <Player
                                    style={{ width: "42%" }}
                                    src="https://lottie.host/b48fc081-a04a-4101-a78f-0b3d709fe41b/Ryh4hpyN6Q.json"
                                    className="player"
                                    loop
                                    autoplay
                                  />
                                </p>
                                <p className="ant-upload-text">Select file</p>
                              </div>
                            </Dragger>
                          </Col>
                        </Row>
                      </>
                    ) :
                      <><Row className="shield-div" style={{}}>
                        <Col>
                          <Dragger {...props} style={{ margin: 0 }}>
                            <p style={{ margin: 0 }}>
                              Please Upload PNG, JPEG or PDF within 5MB
                            </p>
                            <div className="border">
                              <p className="ant-upload-drag-icon">
                                <Player
                                  style={{ width: "80%" }}
                                  src="https://lottie.host/b48fc081-a04a-4101-a78f-0b3d709fe41b/Ryh4hpyN6Q.json"
                                  className="player"
                                  loop
                                  autoplay
                                />
                              </p>
                              <p className="ant-upload-text">Select file</p>
                            </div>
                          </Dragger>
                        </Col>
                      </Row></>
                    }


                    <br />
                    <div className="progress">

                      <Progress percent={progress} steps={5} strokeColor={['orange', 'orange', 'orange']} />
                    </div>
                  </>
                ) : (
                  <>
                    <Box sx={{}}>
                      <TextField
                        sx={{ minWidth: 400 }}
                        fullWidth
                        label="Subscriber Id"
                        variant="outlined"
                        value={subscriberid}
                        onChange={(e) => setSubscriberid(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{ mt: 2, minWidth: 400 }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TextField
                        onChange={(e) => setfirstname(e.target.value)}
                        sx={{ width: "100%" }}
                        value={firstname}
                        label=" First Name"
                        variant="outlined"
                      />
                      <TextField
                        value={lastname}
                        onChange={(e) => setlastname(e.target.value)}
                        sx={{ width: "100%", marginLeft: "10px" }}
                        fullWidth
                        label=" Last Name"
                        variant="outlined"
                      />
                    </Box>
                    {/* <FormControl sx={{ mt: 3 }}>
                    <Box className="pverifyDatepicker" sx={{ mt: 1, width: "100px" }}> */}
                    {/* <TextField  sx={{ minWidth: 400 }} fullWidth label="DOB" variant="outlined" /> */}

                    {/* <LocalizationProvider
                        sx={{ minWidth: 300 }}
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disableFuture
                            inputFormat="MM/DD/YYYY"
                            onChange={(newValue) =>
                              setdob(newValue.format("MM/DD/YYYY"))
                            }
                            label="DOB"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    </FormControl> */}


<Box className="pverifyDatepicker" sx={{ mt: 1 }}>
                      {/* <TextField  sx={{ minWidth: 400 }} fullWidth label="DOB" variant="outlined" /> */}

                      <LocalizationProvider
                        sx={{ minWidth: 300 }}
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disableFuture
                            inputFormat="MM/DD/YYYY"
                            onChange={(newValue) =>
                              setdob(newValue.format("MM/DD/YYYY"))
                            }
                            label="DOB"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>


                    <FormControl sx={{ mt: 3 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={states}
                        disabled={roles === "superadmin"}
                        sx={{ width: 413 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            onSelect={(e) => setstate(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={payerlist}
                        disabled={roles === "superadmin"}

                        value={payer}
                        sx={{ width: 413 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Insurance Name"
                            onSelect={(e) => setPayer(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={plan}
                        value={planname}
                        // disabled={true}
                        disabled={roles === "superadmin" || "HCP%20Executive"}
                        sx={{ width: 413, marginBottom: "10px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Plan Type"
                            onSelect={(e) => setPlanName(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                    <Box
                      sx={{ mt: 2, minWidth: 413 }}
                      style={{ display: "flex", flexDirection: "row", width: "200px" }}
                    >
                      <TextField
                        disabled={true}
                        // onChange={(e) => setfirstname(e.target.value)}
                        sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                        value={ebv}
                        inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                        label="eBV Gateway"
                        variant="outlined"
                      />
                    </Box>
                    <FormControlLabel
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      value="end"
                      control={<Checkbox style={{ marginRight: "5px" }} checked={isSubscriberPatient} onChange={handleChange} />}
                      label="The Subscriber is Patient"
                      labelPlacement="end"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={4} style={{ marginTop: 5 }}>
                <Tooltip title="Configure">
                  <ReportGmailerrorredIcon style={{ marginLeft: "500px", fontSize: "30px", color: "darkgreen" }} onClick={showModal1} />
                </Tooltip>
                <Col span={8}>
                  <div style={{ marginLeft: "25px" }}>
                    {/* <h3 style={{marginRight:"50px"}}>Coverage Details</h3> */}
                    {/* <div>
                <a onClick={showModal}>details</a>
                </div> */}
                    <div style={{ marginTop: 25 }}>
                      <Row span={10}>
                        {icon1 ? (
                          <span style={{ marginBottom: "9px" }}>
                            <CheckIcon style={{ gap: "30px", color: "green", position: "relative", top: "7px" }} />
                            <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                              Covered{" "}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {icon2 ? (
                          <span style={{ marginBottom: "9px" }}>
                            <WarningAmberIcon
                              style={{ gap: "30px", color: "orange", position: "relative", top: "7px" }}
                            />
                            <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                              Covered with Conditions
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {icon4 ? (
                          <span style={{ marginBottom: "9px" }}>
                            <WarningAmberIcon
                              style={{ gap: "30px", color: "orange", position: "relative", top: "7px" }}
                            />
                            <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                              Covered with Condition
                            </span>
                          </span>
                        ) : (
                          ""
                        )}

                        {icon3 ? (
                          <span style={{ marginBottom: "9px" }}>
                            <CloseIcon
                              style={{ gap: "30px", color: "red", top: "10px", position: "relative", top: "7px" }}
                            />
                            <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                              {" "}
                              Not Covered
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {icon5 ? (
                          <span style={{ marginBottom: "9px" }}>
                            <DoNotDisturbIcon
                              style={{ gap: "30px", color: "red", top: "10px", position: "relative", top: "7px" }}
                            />
                            <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                              {" "}
                              Detail Not available
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </Row>


                      <div></div>
                    </div>
                    <Card className="pverifyCard" style={{ width: "500px", marginRight: "10%", height: "160px", border: "1px solid #1da0e1" }} >
                      <div>
                        <div>
                          {summary}
                        </div>
                        <div style={{ width: "90%", overflow: "hidden" }}>
                          <a href={hyperlinks} target="_blank" rel="">
                            {hyperlinks}
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <Col span={24}>
                      <div style={{ marginTop: "27%", marginLeft: "25px" }} className="leftdiv">
                        {/* <Row className="induranceInfo">
                            <h3> Insurance Info </h3>
                          </Row> */}

                        <Card className="pverifyCard" style={{ width: "500px", border: "1px solid #1da0e1" }}>
                          <p className="descriptionPara">Copay Amount <span className="descriptionSpan">{copayStatus}</span></p>
                          <p className="descriptionPara">Individual Deductible <span className="descriptionSpan">{balance}</span></p>
                          <p className="descriptionPara">Remaining Deductible <span className="descriptionSpan">{remainDeduct}</span></p>
                          <p className="descriptionPara">Family Deductible <span className="descriptionSpan">{remainDeduct}</span></p>
                          <p className="descriptionPara">Family Remaining Deductible <span className="descriptionSpan">{remainDeduct}</span></p>
                          <p className="descriptionPara">Coinsurance <span className="descriptionSpan">{percentDeduct}</span></p>
                        </Card>
                      </div>
                    </Col>
                  </div>
                </Col>
                <div className="savenxtdiv">
                  <LoadingButton
                    // disabled={response === !"" ? true : false}
                    className="savenxt"
                    loadingPosition="start"
                    variant="contained"
                    onClick={() => handleClick("Submit")}
                    loading={searchLoading}
                    style={{
                      marginTop: "5%",
                      marginLeft: "180px",
                      display: "flex",
                      justifyContent: "center",
                      background: "green",
                      textTransform: "none",
                      // marginBottom: "50px",
                    }}
                  >
                    Submit eBV Request
                  </LoadingButton>
                  <Progress style={{ width: '200px', marginLeft: '160px' }} percent={progressPercent} strokeColor="orange" />
                </div>
                <div>
                </div>
              </Grid>
            </Grid>

            <Modal
              open={open}
              title="coverage Details"
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  submit
                </Button>
              ]}
            >
              <Box
                sx={{ mt: 2, minWidth: 400 }}
                style={{ display: "flex", flexDirection: "row", width: "200px" }}
              >
                <TextField
                  // disabled={true}
                  // onChange={(e) => setfirstname(e.target.value)}
                  sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                  //  value={ebvId}
                  inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                  label="Annual Income"
                  variant="outlined"
                />
              </Box>
            </Modal>

            <Modal
              open={open1}
              title="eBV Gateway Config"
              onOk={handleOk1}
              onCancel={handleCancel1}
              footer={[
                <Button key="back" onClick={handleCancel1}>
                  Return
                </Button>,
                // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                //   Submit
                // </Button>,
                <Button
                  key="link"
                  href={ebvlink}
                  type="primary"
                  loading={loading}
                  onClick={handleOk1}
                >
                  Search on Google
                </Button>,
              ]}
            >
              <Box
                sx={{ mt: 2, minWidth: 400 }}
                style={{ display: "flex", flexDirection: "row", width: "200px" }}
              >
                <TextField
                  // disabled={true}
                  // onChange={(e) => setfirstname(e.target.value)}
                  sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                  value={ebvId}
                  inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                  label="eBV Gateway ID"
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{ mt: 2, minWidth: 400 }}
                style={{ display: "flex", flexDirection: "row", width: "200px" }}
              >
                <TextField
                  // disabled={true}
                  // onChange={(e) => setfirstname(e.target.value)}
                  sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                  value={ebv}
                  inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                  label="eBV Vendor Name"
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{ mt: 2, minWidth: 400 }}
                style={{ display: "flex", flexDirection: "row", width: "200px" }}
              >
                <TextField
                  // disabled={true}
                  // onChange={(e) => setfirstname(e.target.value)}
                  sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                  value={ebvendpoint}
                  inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                  label="eBV Vendor Endpoint"
                  variant="outlined"
                />
              </Box>
            </Modal>
          </div>
        </div>
      </div>
      <div className="bottom" style={{ marginTop: "20px" }}></div>

    </div>
  );
};

export default PureReact;