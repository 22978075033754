// // import React from 'react';
// import "./AddNewHospital.css"
// import NewHeader from '../../NewHeader';
// import Sidebar1 from '../../Sidebar1';
// import { json, useNavigate, useParams } from "react-router";
// // import "../main.css"
// // import "../Sidebar.css";
// import TextField from "@mui/material/TextField";
// import Select from "@mui/material/Select";
// // import { LocalizationProvider } from '@mui/x-date-pickers';
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Box, Button, Grid, MenuItem, Stack, Tooltip } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
// import InputLabel from "@mui/material/InputLabel";
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { Col, Row } from 'antd';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { WestOutlined } from '@mui/icons-material';
// import axios from "axios";
// import React, { useEffect, useState } from 'react';
// import {BaseUrl} from '../../../config'
// import { useAuth } from '../../../Newlogin/Authprovider'


// import { message, Upload } from "antd";



// const customStyles = {
//   width: 500, // Adjust the width as needed
//   height: 60, // Adjust the height as needed
//   position: 'relative', // Needed for label placement
// };

// const customInputLabelStyles = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   fontSize: "14px",
//   color: "#797E81",

//   transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
//   pointerEvents: 'none', // To allow clicking through the label
// };
// const inputStyle2 = {

//   width: '500px',
//   // height:"50px",
//   marginTop: "1%",
//   // height:"100px",
//   backgroundColor: 'white',
//   // margin:'auto', // Adjust the width as needed
//   //     justifyContent:"center"
// };

// const inputStyle = {
//   width: '500px',
//   backgroundColor: 'white',
// //   textAlign:'center',
//   height: '40px',
// };

// const containerStyle = {
//   width: "90%",
//   margin: 'auto',
//   marginTop: "1%",
//   marginLeft: "7%",
//   justifyContent: "center",
//   backgroundColor: "white",
//   borderRadius: "8px",
//   // height: "65vh"
// };

// const stackStyle = {
//   display: "flex",
//   // justifyContent:"space-between"
//   gap: "5px",
//   // marginTop: "2%",
//   // margin:" 0 auto",
//   justifyContent: "center",
//   marginTop: "1%"
// }

// const stackStyle2 = {
//   display: "flex",
//   gap: "5px",
//   justifyContent: "center",
//   marginTop: "0%",
// }

// const stackStyleTop = {
//   display: "flex",
//   gap: "5px",
//   justifyContent: "center",
//   alignItems: "center",
//   marginTop: "1%",
// }

// const stackStyledrop = {
//   display: "flex",
//   margin: "auto",
//   marginTop: "2%",
//   marginLeft: "4%"
// }

// const stackStylebot = {
//   display: "flex",
//   justifyContent: "left",
//   display: "flex",
//   gap: "5px",
//   margin: "auto",
//   marginTop: "2%"
// }

// const EditAddNewHospital = () => {
//   const success = () => {
//     message.open({
//       type: "success",
//       content: "SuccessFully Updated",
//     });
//   };
  
//   const errors = () => {
//     message.open({
//       type: "error",
//       content: "Failed to Updated",
//     });
//   };
//   const requiredError = () => {
//     message.open({
//       type: "error",
//       content: "Please fill the required field",
//     });
//   };

//   const handleNpiIdChange = (e) => {
//     const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
//     const truncatedValue = sanitizedValue.slice(0, 10);

//     setnpiId(truncatedValue);

//     if (sanitizedValue.length > 10) {
//       setError('Max 10 characters allowed');
//     } else {
//       setError('');
//     }
//   };

//   const handleNumberChange = (e) => {
//     const sanitizedValue1 = e.target.value.replace(/[^0-9]/g, '');
//     const truncatedValue1 = sanitizedValue1.slice(0, 10);

//     setnumber(truncatedValue1);

//     if (sanitizedValue1.length > 10) {
//       setError('Max 10 characters allowed');
//     } else {
//       setError('');
//     }
//   };



//   const handleChange = (event) => {
//     setstatus(event.target.value);
//   };

//   const { isAuthenticated } = useAuth();
//   const navigate = useNavigate();
//   // useEffect(() => {
//     if (!isAuthenticated()) {
  
//       navigate('/');
//     }
//   // const handleChange1 = (event) => {
//   //   setcontact(event.target.value);
//   // };
//   const [isValidateNpiId, setIsValidateNpiId] = useState(true)
//   // const [isEmailValid, setIsEmailValid] = useState(true);
//   const [isstatusValid, setIsstatusValid] = useState(true);
//   const [isHospitalValid, setIsHospitalValid] = useState(true);
//   const [isAddressValid, setIsAddressValid] = useState(true);
//   const [isZipCodeValid, setIsZipCodeValid] = useState(true);
//   const [isStateValid, setIsStateValid] = useState(true);
//   const [isCityValid, setIsCityValid] = useState(true);
//   const [isNumberValid, setIsNumberValid] = useState(true);
//   const [isServiceTypeValid, setIsServiceTypeValid] = useState(true);
//   const validate = (value) => {
//     const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
//     return lettersOnlyRegex.test(value);
//   };
//   const npiIdValidate = (v) => {
//     const NumberRegex = /^\d{1,10}$/;
//     return NumberRegex.test(v)
//   }

//   const numberValidate = (value) => {
//     const phoneNumberRegex = /^\d{10}$/;
//     return phoneNumberRegex.test(value)
//   }
  


//   const [address, setaddress] = useState('');
//   const [npiId, setnpiId] = useState('');
//   const [hospitalname, sethospitalname] = useState('');
//   const [city, setcity] = useState('');
//   const [hcp, sethcp] = useState('');
//   const [zipcode, setzipcode] = useState('');
//   const [role, setrole] = useState('');
//   const [state, setstate] = useState('');
//   const [status, setstatus] = useState('');
//   const [number, setnumber] = useState('');
//   const [email, setemail] = useState('');
//   const [hospitaltype, sethospitaltype] = useState("");
//   const [error, setError] = useState('');
//   const [isEmailValid, setIsEmailValid] = useState(true);
  

//   const Navigate = useNavigate();

//   const managnment = window.location.href

//   const my_list = managnment.split("/")

//   const handleClick = (e) => {
//     let masterId = my_list[5]
//     if (masterId) {
//       const url8 = `${BaseUrl}/hospitals/update`;
//       const body = {
//         "status": status,
//         "address": address,
//         "npiId": npiId,
//         "hcpId": hcp,
//         "hospitalName": hospitalname,
//         "city": city,
//         "zipCode": zipcode,
//         "state": state,
//         "phoneNumber": number,
//         "email": email,
//         "serviceType": hospitaltype.label,
//         masterId: my_list[5]
//       }
//       axios
//         .post(url8, body)
//         .then((res) => {

//           if (res.data.status === "Ok") {
//             success();
//             //  Navigate("/pverifyy?/"+ my_list[4]+ "/" + hospitaltype);
//             Navigate("/hospitalnew?/" + my_list[4] + "");
//             // alert("Login Successfull")
//             // console.log(response)
//           }
//           else {
//             // Navigate("/pverifyy");
//             errors()
//             // message.error("Invalid username or OTP");
//           }

//         }).catch((e) => {
//           errors()
//         }).finally(() => {
//           // setSearchLoading(false);
//           // Navigate("/pverifyy");
//         });
//     } else {
//       const form = validateForm()
//       if (form) {
//         const url8 = `${BaseUrl}/hospitals/create`;

//         const body = {
//           "status": status,
//           "address": address,
//           "npiId": npiId,
//           "hcpId": hcp,
//           "hospitalName": hospitalname,
//           "city": city,
//           "zipCode": zipcode,
//           "state": state,
//           "phoneNumber": number,
//           "email": email,
//           "serviceType": hospitaltype.label
//         };

//         axios
//           .post(url8, body)
//           .then((res) => {
//             if (res.data.status === "Ok") {
//               success();
//               //  Navigate("/pverifyy?/"+ my_list[4]+ "/" + hospitaltype);
//               Navigate("/hospitalnew?/" + my_list[4] + "");
//               // alert("Login Successfull")
//               // console.log(response)
//             }
//             else {
//               // Navigate("/pverifyy");
//               errors()
//               // message.error("Invalid username or OTP");
//             }

//           }).catch((e) => {
//             errors()

//           }).finally(() => {
//             // setSearchLoading(false);
//             // Navigate("/pverifyy");
//           });
//       } else {
//         requiredError()
//       }
//     }

//   }

//   if (zipcode) {
//     const url1 = `${BaseUrl}/hospitals/state-city`;

//     const body = {
//       "zipCode": zipcode,
//     };

//     axios
//       .post(url1, body)
//       .then((res) => {
//         setcity(res.data.city)
//         setstate(res.data.state)
//       })
//   }
//   useEffect(() => {
//     const masterId = my_list[5]
//     console.log("wertyujik", masterId)
//     if (masterId) {
//       const getOneHospital = async () => {
//         const url = `${BaseUrl}/hospitals/findOne`;
//         let response = await axios.post(url, { masterId: my_list[5] })
//         const dataArray = response.data.data[0]

//         setaddress(dataArray.address);
//         setnpiId('');
//         sethospitalname(dataArray.hospitalName);
//         setcity(dataArray.city);
//         sethcp('');
//         setzipcode(dataArray.zipCode);
//         setrole('');
//         setstate(dataArray.state);
//         setstatus(dataArray.status);
//         setnumber(dataArray.phoneNumber);
//         setemail(dataArray.email);
//         sethospitaltype('');
//       }
//       getOneHospital()
//     }
//   }, [])

//   useEffect(() => {

//     const url8 = `${BaseUrl}/hospitals/generate-hcp-office`;

//     axios
//       .get(url8)
//       .then((res) => {
//         console.log(res, "aaaaaaa")
//         sethcp(res.data.hcpid)
//         // setcity(res.data.city)
//         // setstate(res.data.state)
//       })
//   }, [])

//   const hospital = [
//     { label: "Chemotherapy", id: 1 },
//     { label: "PrimaryCare", id: 2 },
//     { label: "Chiropractic", id: 3 },
//     { label: "GeneralOfficeBenefits30", id: 4 },
//     { label: "Psychotherapy", id: 5 },
//     { label: "Allergies", id: 6 },
//     { label: "GeneralOfficeBenefitsMaternity", id: 7 },
//     { label: "Infertility83", id: 8 },
//     { label: "GynecologicalBT", id: 9 },
//     { label: "HomeHealthCare42", id: 10 },
//     { label: "Anesthesia", id: 11 },
//     { label: "Telemedicine", id: 12 },
//     { label: "Pharmacy", id: 13 },
//     { label: "PsychiatricA4", id: 14 },
//     { label: "XRay", id: 15 },
//   ];
  

//   const validateEmail = (email) => {
//     // Regular expression for a simple email validation
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const handleEmailChange = (e) => {
//     const newEmail = e.target.value;
//     setemail(newEmail);
//     setIsEmailValid(validateEmail(newEmail));
//   };

//   const validateForm = () => {
//     let isValid = true;

//     if (!validateEmail(email)) {
//       console.log(1)
//       setIsEmailValid(false);
//       isValid = false;
//     } else {
//       console.log(2)
//       setIsEmailValid(true);
//     }

//     if (!npiIdValidate(npiId)) {
//       setIsValidateNpiId(false);
//       isValid = false;
//     } else {
//       setIsValidateNpiId(true);
//     }

//     if (!validate(hospitalname)) {
//       console.log(3)
//       setIsHospitalValid(false);
//       isValid = false;
//     } else {
//       console.log(4)
//       setIsHospitalValid(true);
//     }

//     if (!validate(status)) {
//       console.log(5)
//       console.log(status)
//       setIsstatusValid(false);
//       isValid = false;
//     } else {
//       console.log(6)
//       setIsstatusValid(true);
//     }
//     if (!validate(address)) {
//       console.log(7)
//       setIsAddressValid(false);
//       isValid = false;
//     } else {
//       console.log(8)
//       setIsAddressValid(true);
//     }

//     if (!validate(zipcode)) {
//       console.log(9)
//       setIsZipCodeValid(false);
//       isValid = false;
//     } else {
//       console.log(10)
//       setIsZipCodeValid(true);
//     }


//     if (!validate(state)) {
//       console.log(11)
//       setIsStateValid(false);
//       isValid = false;
//     } else {
//       console.log(12)
//       setIsStateValid(true);
//     }
//     if (!validate(city)) {
//       console.log(13)
//       setIsCityValid(false);
//       isValid = false;
//     } else {
//       console.log(14)
//       setIsCityValid(true);
//     }
//     if (!numberValidate(number)) {
//       console.log(15)
//       setIsNumberValid(false);
//       isValid = false;
//     } else {
//       console.log(16)
//       setIsNumberValid(true);
//     }

//     if (!validate(hospitaltype)) {
//       console.log(17)
//       console.log(hospitaltype)
//       setIsServiceTypeValid(false);
//       isValid = false;
//     } else {
//       console.log(18)
//       setIsServiceTypeValid(true);
//     }
//     return isValid;
//   };


//   const resetForm = () => {
//     setnpiId('');
//     // sethcp('');
//     setstatus('');
//     sethospitalname('');
//     setaddress('');
//     setcity('');
//     setzipcode('');
//     setstate('');
//     setnumber('');
//     setemail('');
//     sethospitaltype(""); // Reset hospitaltype here
//     setError('');
//     setIsEmailValid(true);
//     setIsValidateNpiId(true);
//     setIsHospitalValid(true);
//     setIsServiceTypeValid(true)
//     setIsNumberValid(true)
//     setIsCityValid(true)
//     setIsStateValid(true)
//     setIsZipCodeValid(true)
//     setIsAddressValid(true)
//     setIsstatusValid(true)
//   };
//   return (
//     <div className='addnewhospitalpicontainer'
//       // style={{ overflow: "scroll" }}
//     >
//       <NewHeader />
//       <Sidebar1 />
//       <div className='addnewhospitalminihead'>
//         <div style={{ marginTop: "0.4%" }}>
//           <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")} />
//         </div>
//         <div className='addnewhospitalminiheadtitle'>
//           Edit Hospital
//         </div>
//       </div>
//       <div>
//         <div style={containerStyle} >
//           <div style={{ paddingTop: "20px", marginLeft: "6%" }}><b>Hospital Information</b></div>
//           {/* <div className="containerlogin"
//            style={containerStyle} 
//            > */}
//           <Grid className='addnewhospitallogingrid'
//           // style={{ overflow: "scroll" }}
//           >
//            <Stack direction="row" spacing={1} style={stackStyleTop} className='top'>
//               <TextField
//                 sx={{ width: '41%' }}
//                 style={inputStyle}
//                 label="NPI ID"
//                 id="email"
//                 variant="outlined"
//                 value={npiId}
//                 // size="small"
//                 placeholder="Enter your NPI ID"
//                 onChange={handleNpiIdChange}
//                 error={!isValidateNpiId}
//                 // helperText={!isValidateNpiId ? 'required field !' : ''}
//                 defaultValue="Small"
//                 size="small"
//               />

//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 label="HCP ID"
//                 disabled={true}
//                 id="npi"
//                 variant="outlined"
//                 value={hcp}
//                 placeholder="Enter your HCP ID"
//                 onChange={(e) => sethcp(e.target.value)}
//                 defaultValue="Small"
//                 size="small"
//               />

//             </Stack>
//             <Stack direction="row" spacing={1} style={stackStyle} className='top'>

//               <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
//                 <InputLabel>Status</InputLabel>
//                 <Select
//                   id="option1"
//                   style={inputStyle}
//                   sx={{ width: '100%' }}
//                   value={status}
//                   // slotProps={{ textField: { size: 'small' , fullWidth: true } }}
//                   label="status"
//                   error={!isstatusValid}
//                   // helperText={!isstatusValid ? 'required field !' : ''}
//                   onChange={handleChange}
//                 >
//                   <MenuItem value="Active">Active</MenuItem>
//                   <MenuItem value="In Active">Inactive</MenuItem>
//                 </Select>
//               </FormControl>

//               <TextField
//                 style={inputStyle}
//                 label="Hospital Name"
//                 id="npi"
//                 variant="outlined"
//                 value={hospitalname}
//                 error={!isHospitalValid}
//                 // helperText={!isHospitalValid ? 'required field !' : ''}
//                 placeholder="Enter your Hospital Name"
//                 onChange={(e) => sethospitalname(e.target.value)}
//                 defaultValue="Small"
//                 size="small"
//               />

//             </Stack>

//             <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 label="Address"
//                 error={!isAddressValid}
//                 // helperText={!isAddressValid ? 'required field !' : ''}
//                 id="email"
//                 variant="outlined"
//                 value={address}
//                 placeholder="Enter your Address"
//                 onChange={(e) => setaddress(e.target.value)}
//                 defaultValue="Small"
//                 size="small"
//               />

//               <Tooltip title="Zipcode Map With State & City">

//                 <TextField
//                   sx={{ width: '100%' }}
//                   style={inputStyle}
//                   label="Zip Code"
//                   id="npi"
//                   error={!isZipCodeValid}
//                   // helperText={!isZipCodeValid ? 'required field !' : ''}
//                   variant="outlined"
//                   value={zipcode}
//                   placeholder="Enter your Zip Code"
//                   onChange={(e) => setzipcode(e.target.value)}
//                   defaultValue="Small"
//                   size="small"
//                 />
//               </Tooltip>
//             </Stack>

//             <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 // error={!isStateValid}
//                 // helperText={!isStateValid ? 'required field !' : ''}
//                 disabled={true}
//                 label="State"
//                 id="email"
//                 variant="outlined"
//                 value={state}
//                 placeholder="Enter your State"
//                 onChange={(e) => setstate(e.target.value)}
//                 defaultValue="Small"
//                 size="small"
//               />

//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 label="City"
//                 id="npi"
//                 variant="outlined"
//                 // error={!isCityValid}
//                 // helperText={!isCityValid ? 'required field !' : ''}
//                 disabled={true}
//                 value={city}
//                 placeholder="Enter your City"
//                 onChange={(e) => setcity(e.target.value)}
//                 defaultValue="Small"
//                 size="small"
//               />
//             </Stack>

//             <Stack direction="row" spacing={1} style={stackStyle}>
//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 label="Email ID"
//                 id="email"
//                 variant="outlined"
//                 value={email}
//                 error={!isEmailValid}
//                 // helperText={!isEmailValid ? 'Invalid email format' : ''}
//                 placeholder="Enter your Email"
//                 onChange={handleEmailChange}
//                 defaultValue="Small"
//                 size="small"
//               />

//               <TextField
//                 sx={{ width: '100%' }}
//                 style={inputStyle}
//                 label="Mobile Number"
//                 id="email"
//                 variant="outlined"
//                 value={number}
//                 error={!isNumberValid}
//                 // helperText={!isNumberValid ? 'Invalid number format' : ''}
//                 placeholder="Enter your Mobile Number"
//                 onChange={handleNumberChange}
//                 defaultValue="Small"
//                 size="small"
//               />
//             </Stack>

//             <Stack direction="row" spacing={1} style={stackStyle2}>
//               <Autocomplete
//                 // options={hospital}
//                 // getOptionLabel={(option) => option.label}
//                 // renderInput={(params) => <TextField {...params} />
//                 options={hospital}
//                 getOptionLabel={(option) => option.label}
//                 getOptionSelected={(option, value) => option.label === value.label} // Add this line
//                 value={hospitaltype || null}
//                 onChange={(_, newValue) => sethospitaltype(newValue)}
//                 renderInput={(params) => (
//                   <TextField {...params}
//                     error={!isServiceTypeValid}
//                     value={hospitaltype}
//                     // onChange={(_, newValue) => sethospitaltype(newValue)}
//                     // value={hospitaltype}
//                     label="ServiceType" size="small"
//                   // onSelect={(e) => sethospitaltype(e.target.value)}
//                   />
//                 )}
//                 style={{ height: "40px !important", minWidth: 500, margin: 8, marginRight: "540px", marginLeft: "25px" }}
//               />
//             </Stack>

//             <Stack direction="row" spacing={3} style={{ marginTop: "1%", justifyContent: "center", paddingBottom: "1%" }}>
//               <Grid>
//                 <button className='addnewhospitalclear' onClick={resetForm}>Reset</button>
//               </Grid>

//               <Grid>
//                 <button className='addnewhospitaladd' onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")}>Cancel</button>
//               </Grid>

//               <Grid>
//                 <button className="addnewhospitalsubmit" onClick={() => handleClick("Submit")}>Update</button>
//               </Grid>
//             </Stack>
//           </Grid>
//           {/* <div> */}
//         </div>
//       </div>
//       <div>
//       </div>
//     </div>
//     // </div>
//     // </div>
//   )
// }

// export default EditAddNewHospital


// import React from 'react';
import "./AddNewHospital.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import { json, useNavigate, useParams } from "react-router";
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import { BaseUrl } from '../../../config'


import { message, Upload } from "antd";
import { useAuth } from '../../../Newlogin/Authprovider'



const customStyles = {
  width: 500, // Adjust the width as needed
  height: 60, // Adjust the height as needed
  position: 'relative', // Needed for label placement
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
  pointerEvents: 'none', // To allow clicking through the label
};
const inputStyle2 = {

  width: '500px',
  // height:"50px",
  marginTop: "1%",
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};

const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
  //   textAlign:'center',
  height: '40px',
};

const containerStyle = {
  width: "90%",
  margin: 'auto',
  marginTop: "1%",
  marginLeft: "7%",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "8px",
  // height: "65vh"
};

const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  // marginTop: "2%",
  // margin:" 0 auto",
  justifyContent: "center",
  marginTop: "1%"
}

const stackStyle2 = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  marginTop: "0%",
}

const stackStyleTop = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1%",
}

const stackStyledrop = {
  display: "flex",
  margin: "auto",
  marginTop: "2%",
  marginLeft: "4%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "left",
  display: "flex",
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const EditAddNewHospital = () => {
  const success = () => {
    message.open({
      type: "success",
      content: "SuccessFully Updated",
    });
  };
  const errors = () => {
    message.open({
      type: "error",
      content: "Failed to Updated",
    });
  };

  const handleNpiIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue = sanitizedValue.slice(0, 10);

    setnpiId(truncatedValue);

    if (sanitizedValue.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };

  const handleNumberChange = (e) => {
    const sanitizedValue1 = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue1 = sanitizedValue1.slice(0, 10);

    setnumber(truncatedValue1);

    if (sanitizedValue1.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }


  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);
  // const handleChange1 = (event) => {
  //   setcontact(event.target.value);
  // };

  const [address, setaddress] = useState('');
  const [npiId, setnpiId] = useState('');
  const [hospitalname, sethospitalname] = useState('');
  const [city, setcity] = useState('');
  const [hcp, sethcp] = useState('');
  const [zipcode, setzipcode] = useState('');
  const [role, setrole] = useState('');
  const [state, setstate] = useState('');
  const [status, setstatus] = useState('');
  const [number, setnumber] = useState('');
  const [email, setemail] = useState('');
  const [hospitaltype, sethospitaltype] = useState('');
  const [service, setservice] = useState('');
  const [error, setError] = useState('');
  const [isValidateNpiId, setIsValidateNpiId] = useState(true)
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [isstatusValid, setIsstatusValid] = useState(true);
  const [isHospitalValid, setIsHospitalValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [isStateValid, setIsStateValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isServiceTypeValid, setIsServiceTypeValid] = useState(true);

  const Navigate = useNavigate();
  const requiredError = () => {
    message.open({
      type: "error",
      content: "Please Fill The Required Field",
    });
  };
  const managnment = window.location.href

  const my_list = managnment.split("/")
  const validate = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
    return lettersOnlyRegex.test(value);
  };
  const hcpValidate = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
    return lettersOnlyRegex.test(value) && value !== null;;
  }
  const npiIdValidate = (v) => {
    const NumberRegex = /^\d{1,10}$/;
    return NumberRegex.test(v)
  }

  const numberValidate = (value) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(value)
  }
  const validateForm = () => {
    let isValid = true;

    if (!validateEmail(email)) {
      console.log(1)
      setIsEmailValid(false);
      isValid = false;
    } else {
      console.log(2)
      setIsEmailValid(true);
    }

    if (!npiIdValidate(npiId)) {
      setIsValidateNpiId(false);
      isValid = false;
    } else {
      setIsValidateNpiId(true);
    }

    if (!validate(hospitalname)) {
      console.log(3)
      setIsHospitalValid(false);
      isValid = false;
    } else {
      console.log(4)
      setIsHospitalValid(true);
    }

    if (!validate(status)) {
      console.log(5)
      console.log(status)
      setIsstatusValid(false);
      isValid = false;
    } else {
      console.log(6)
      setIsstatusValid(true);
    }
    if (!validate(address)) {
      console.log(7)
      setIsAddressValid(false);
      isValid = false;
    } else {
      console.log(8)
      setIsAddressValid(true);
    }

    if (!validate(zipcode)) {
      console.log(9)
      setIsZipCodeValid(false);
      isValid = false;
    } else {
      console.log(10)
      setIsZipCodeValid(true);
    }


    if (!validate(state)) {
      console.log(11)
      setIsStateValid(false);
      isValid = false;
    } else {
      console.log(12)
      setIsStateValid(true);
    }
    if (!validate(city)) {
      console.log(13)
      setIsCityValid(false);
      isValid = false;
    } else {
      console.log(14)
      setIsCityValid(true);
    }
    if (!numberValidate(number)) {
      console.log(15)
      setIsNumberValid(false);
      isValid = false;
    } else {
      console.log(16)
      setIsNumberValid(true);
    }
    if (!hcpValidate(hospitaltype)) {
      console.log(17)
      console.log(hospitaltype)
      setIsServiceTypeValid(false);
      isValid = false;
    } else {
      console.log(18)
      setIsServiceTypeValid(true);
    }



    return isValid;
  };
  const handleClick = (e) => {
    let masterId = my_list[5]
    if (masterId) {
      const form = validateForm()
      if (form) {
        const url8 = `${BaseUrl}/hospitals/update`;
        const body = {
          "status": status,
          "address": address,
          "npiId": npiId,
          "hcpId": hcp,
          "hospitalName": hospitalname,
          "city": city,
          "zipCode": zipcode,
          "state": state,
          "phoneNumber": number,
          "email": email,
          "serviceType": hospitaltype,
          masterId: my_list[5]
        }
        axios
          .post(url8, body)
          .then((res) => {

            if (res.data.status === "Ok") {
              success();
              //  Navigate("/pverifyy?/"+ my_list[4]+ "/" + hospitaltype);
              Navigate("/hospitalnew?/" + my_list[4] + "");
              // alert("Login Successfull")
              // console.log(response)
            }
            else {
              // Navigate("/pverifyy");
              errors()
              // message.error("Invalid username or OTP");
            }

          }).catch((e) => {
            errors()
          }).finally(() => {
            // setSearchLoading(false);
            // Navigate("/pverifyy");
          });
      } else {
        requiredError()
      }
    } else {
      const url8 = `${BaseUrl}/hospitals/create`;

      const body = {
        "status": status,
        "address": address,
        "npiId": npiId,
        "hcpId": hcp,
        "hospitalName": hospitalname,
        "city": city,
        "zipCode": zipcode,
        "state": state,
        "phoneNumber": number,
        "email": email,
        "serviceType": hospitaltype
      };

      axios
        .post(url8, body)
        .then((res) => {
          if (res.data.status === "Ok") {
            success();
            //  Navigate("/pverifyy?/"+ my_list[4]+ "/" + hospitaltype);
            Navigate("/hospitalnew?/" + my_list[4] + "");
            // alert("Login Successfull")
            // console.log(response)
          }
          else {
            // Navigate("/pverifyy");
            errors()
            // message.error("Invalid username or OTP");
          }

        }).catch((e) => {
          errors()

        }).finally(() => {
          // setSearchLoading(false);
          // Navigate("/pverifyy");
        });
    }

  }

  if (zipcode) {
    const url1 = `${BaseUrl}/hospitals/state-city`;

    const body = {
      "zipCode": zipcode,
    };

    axios
      .post(url1, body)
      .then((res) => {
        setcity(res.data.city)
        setstate(res.data.state)
      })
  }
  useEffect(() => {
    const masterId = my_list[5]
    console.log("wertyujik", masterId)
    if (masterId) {
      const getOneHospital = async () => {
        const url = `${BaseUrl}/hospitals/findOne`;
        let response = await axios.post(url, { masterId: my_list[5] })
        const dataArray = response.data.data[0]

        setaddress(dataArray.address);
        setnpiId(dataArray.npiId);
        sethospitalname(dataArray.hospitalName);
        setcity(dataArray.city);
        sethcp(dataArray.hcp);
        setzipcode(dataArray.zipCode);
        setrole('');
        setstate(dataArray.state);
        setstatus(dataArray.status);
        setnumber(dataArray.phoneNumber);
        setemail(dataArray.email);
        sethospitaltype(dataArray.serviceType);
      }
      getOneHospital()
    }
  }, [])

  console.log(hospitaltype, "hospitaltype")

  useEffect(() => {

    const url8 = `${BaseUrl}/hospitals/generate-hcp-office`;

    axios
      .get(url8)
      .then((res) => {
        console.log(res, "aaaaaaa")
        sethcp(res.data.hcpid)
        // setcity(res.data.city)
        // setstate(res.data.state)
      })
  }, [])

  const hospital = [
    { label: "Chemotherapy", id: 1 },
    { label: "PrimaryCare", id: 2 },
    { label: "Chiropractic", id: 3 },
    { label: "GeneralOfficeBenefits30", id: 4 },
    { label: "Psychotherapy", id: 5 },
    { label: "Allergies", id: 6 },
    { label: "GeneralOfficeBenefitsMaternity", id: 7 },
    { label: "Infertility83", id: 8 },
    { label: "GynecologicalBT", id: 9 },
    { label: "HomeHealthCare42", id: 10 },
    { label: "Anesthesia", id: 11 },
    { label: "Telemedicine", id: 12 },
    { label: "Pharmacy", id: 13 },
    { label: "PsychiatricA4", id: 14 },
    { label: "XRay", id: 15 },
  ];
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const resetForm = () => {
    setnpiId('');
    sethcp('');
    setstatus('');
    sethospitalname('');
    setaddress('');
    setcity('');
    setzipcode('');
    setstate('');
    setnumber('');
    setemail('');
    sethospitaltype('');
    setError('');
    setIsEmailValid(true);
  };

  return (
    <div className='addnewhospitalpicontainer'
    // style={{ overflow: "scroll" }}
    >
      <NewHeader />
      <Sidebar1 />
      <div className='addnewhospitalminihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")} />
        </div>
        <div className='addnewhospitalminiheadtitle'>
          Edit Hospital
        </div>
      </div>
      <div>
        <div style={containerStyle} >
          <div style={{ paddingTop: "20px", marginLeft: "6%" }}><b>Hospital Information</b></div>
          {/* <div className="containerlogin"
           style={containerStyle} 
           > */}
          <Grid className='addnewhospitallogingrid'
          // style={{ overflow: "scroll" }}
          >
            <Stack direction="row" spacing={1} style={stackStyleTop} className='top'>
              <TextField
                sx={{ width: '41%' }}
                style={inputStyle}
                label="NPI ID"
                id="email"
                variant="outlined"
                value={npiId}
                // size="small"
                placeholder="Enter your NPI ID"
                onChange={handleNpiIdChange}
                error={!isValidateNpiId}
                // error={error !== ''}
                // helperText={error}
                defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '41%' }}
                style={inputStyle}
                label="HCP ID"
                disabled={true}
                id="npi"
                variant="outlined"
                value={hcp}
                placeholder="Enter your HCP ID"
                onChange={(e) => sethcp(e.target.value)}
                defaultValue="Small"
                size="small"
              />

            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'>

              <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  id="option1"
                  style={inputStyle}
                  sx={{ width: '100%' }}
                  value={status}
                  error={!isstatusValid}

                  //   slotProps={{ textField: { size: 'small' , fullWidth: true } }}
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="In Active">Inactive</MenuItem>
                </Select>
              </FormControl>

              <TextField
                style={inputStyle}
                label="Hospital Name"
                id="npi"
                variant="outlined"
                value={hospitalname}
                error={!isHospitalValid}
                placeholder="Enter your Hospital Name"
                onChange={(e) => sethospitalname(e.target.value)}
                defaultValue="Small"
                size="small"
              />

            </Stack>


            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Address"
                id="email"
                variant="outlined"
                value={address}
                error={!isAddressValid}
                placeholder="Enter your Address"
                onChange={(e) => setaddress(e.target.value)}
                defaultValue="Small"
                size="small"
              />

              <Tooltip title="Zipcode Map With State & City">

                <TextField
                  sx={{ width: '100%' }}
                  style={inputStyle}
                  label="Zip Code"
                  id="npi"
                  variant="outlined"
                  value={zipcode}
                  error={!isZipCodeValid}
                  placeholder="Enter your Zip Code"
                  onChange={(e) => setzipcode(e.target.value)}
                  defaultValue="Small"
                  size="small"
                />
              </Tooltip>
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                // disabled={true}
                label="State"
                id="email"
                variant="outlined"
                value={state}
                placeholder="Enter your State"
                onChange={(e) => setstate(e.target.value)}
                defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="City"
                id="npi"
                variant="outlined"
                // disabled={true}
                value={city}
                placeholder="Enter your City"
                onChange={(e) => setcity(e.target.value)}
                defaultValue="Small"
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Email ID"
                id="email"
                variant="outlined"
                value={email}
                error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                placeholder="Enter your Email"
                onChange={handleEmailChange}
                defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Mobile Number"
                id="email"
                variant="outlined"
                value={number}
                placeholder="Enter your Mobile Number"
                onChange={handleNumberChange}
                error={!isNumberValid}
                // helperText={error}
                defaultValue="Small"
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle2}>
              {/* <Autocomplete
                options={hospital}
                value={hospitaltype}
                getOptionLabel={(option) => option.label}
                // renderInput={(params) => <TextField {...params} />
                renderInput={(params) => (
                  <TextField {...params} label="ServiceType"  size="small" onSelect={(e) => sethospitaltype(e.target.value)} />
                )}
                style={{height:"40px !important", minWidth: 500, margin: 8, marginRight: "540px", marginLeft: "25px" }}
              /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // options={hospital}
                // value={hospitaltype}
                options={hospital}
                // getOptionLabel={(option) => option.label}
                // getOptionSelected={(option, value) => option.label === value.label} // Add this line
                value={hospitaltype || null}
                onSelect={(e) => sethospitaltype(e.target.value)}
                renderInput={(params) => (
                  <TextField {...params}
                    error={!isServiceTypeValid}
                    value={hospitaltype}
                    label="ServiceType"
                    size="small"
                  // onSelect={(e) => sethospitaltype(e.target.value)}
                  />
                )}
                style={{ height: "40px !important", minWidth: 500, margin: 8, marginRight: "540px", marginLeft: "25px" }}
              />


            </Stack>

            <Stack direction="row" spacing={3} style={{ marginTop: "1%", justifyContent: "center", paddingBottom: "1%" }}>
              <Grid>
                <button className='addnewhospitalclear' onClick={resetForm}>Reset</button>
              </Grid>

              <Grid>
                <button className='addnewhospitaladd' onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")}>Cancel</button>
              </Grid>

              <Grid>
                <button className="addnewhospitalsubmit" onClick={() => handleClick("Submit")}>Update</button>
              </Grid>
            </Stack>
          </Grid>
          {/* <div> */}
        </div>
      </div>
      <div>
      </div>
    </div>
    // </div>
    // </div>
  )
}

export default EditAddNewHospital