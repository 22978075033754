import React, { useState,useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js
import NewHeader from '../../NewHeader';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Box,TextField } from '@mui/material'
import Sidebar1 from '../../Sidebar1';
import { Col , Select, Row, Tabs, } from 'antd';
import { WestOutlined } from '@mui/icons-material';
import { Column } from '@ant-design/plots';
import axios from "axios";
import "./ebvgateway.css"
import SearchBar from "material-ui-search-bar";
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { Delete, Edit, FilterAltOffOutlined, Visibility ,Save, Cancel} from '@mui/icons-material'
import Pagination from '@mui/material/Pagination';
import SaveIcon from '@mui/icons-material/Save';
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { message, Upload } from "antd";
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'




const Ebvgateway = () => {
  const { isAuthenticated } = useAuth();
  const navigate= useNavigate()
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }
  // }, [isAuthenticated, navigate]);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filteredData,setFilteredData] =useState([])
  const [loading, setLoading] = useState(true);
  const [ebv, setebv] = useState("");

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);


  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);


  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1); 
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const managnment = window.location.href
    const my_list = managnment.split("/")
    const success = () => {
    message.open({
          type: "success",
          content: "Updated Successfully",
        });
    //     messageApi.success("Logged in successfully")
      };

  useEffect(() => {
    getData()
  },[])
  const getData = () => {
  const url8 = `${BaseUrl}/ebvgateway-mapping-insurance-name/findAll`;
  axios
    .get(url8)
    .then((res) => {
      setLoading(false);
      setdata(res.data.data)
    })
    .catch((error) => {
    });

  }

  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/hospitals/delete`;
    const requestBody = {
  masterId: id
   };
  
    axios.post(deleteUrl, requestBody)
      .then((response) => {
          getData()
      })
      .catch(e => {
        console.error("Error deleting:", e);
      });
  };

 
  const handlenavigate=()=>{
    navigate("/pverifyy?/"+ my_list[4]+"")
  }

  const handleSearchChange = (value) => {
    setSearchText(value); 
  };
  const [searchText, setSearchText] = useState(''); 

const tableSearch = () => {
  if (!searchText) {
    setFilteredData([]);
  } else {
    const lowerCaseValue = searchText.toLowerCase();
    const filteredRows = data.filter((row) =>
      row.insuranceName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredRows);
  }
};

const [editingRowId, setEditingRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  const handleEditClick = (rowId) => {
    setEditingRowId(rowId.masterId);
    // setEditedValues({ ...editedValues, [rowId]: data.find((row) => row.masterId == rowId).ebvGateway });
  };

  const states = [
    { label: "pVerify", id: 1 },
    { label: "Change Healthcare", id: 2 },
    { label: "Availity", id: 3 },
    { label: "Office Ally", id: 4 },
    { label: "Zirmed", id: 5 },
  ];


  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditedValues({});
  };

  const handleSaveClick = () => {
  const url8 = `${BaseUrl}/ebvgateway-mapping-insurance-name/update`;

const body = {
  "masterId":editingRowId,
//   "insuranceName" :"EmblemHealth",
  "ebvGateway":ebv
};

axios
  .post(url8, body)
  .then((res) => {
    if(res.data.status === "Ok"){
        success()
        setEditingRowId(null);  
    }
  })
}
  
  return (
    <div className='ebvgatewaypicontainer'>
        <NewHeader/>
        <Sidebar1/>
        <div className='ebvgatewayminiheadtable'>
            <div className='ebvgatewayminiheadtitle'>
            <Col>
             eBV Gateway
             </Col>
             </div>
             <Row className='ebvgatewayminiheadright'>
             <Col>
             <SearchBar
            value={searchText}
            onChange={(newValue) => handleSearchChange(newValue)}
            onRequestSearch={tableSearch}
         
          style={{
            margin: '0 auto',
            width: 300,
            height: "38px",
            backgroundColor: "#C8EDFF",
            borderRadius: "10px"
          }}
        />
             </Col>
             {/* <Col>
             <div  className="filterdiv" >
              <div  className='filtertitle'>Filter</div>
              <FilterAltOffOutlined  className='filtericon'/>
             </div>
             </Col> */}

             <Col className='ebvgatewayaddnewusecol'>
              <div className='ebvgatewayaddnewusecoldiv' onClick={handlenavigate}>
             eBV Gateway
             </div>
             </Col>
             </Row>
        </div>
        <div>
        <TableContainer  className="ebvgatewaytablecont"  style={{width:"90%"}} component={Paper}>
        {loading?(<Box sx={{ display: 'flex' }}>
      <CircularProgress  style={{margin:"auto", justifyContent:"center", alignItems:"center",marginTop:"5%"}} />
    </Box> ):
(<>
        <Table>
          <TableHead className="ebvgatewaytablerowm" >
            <TableRow >
              <TableCell>S.No</TableCell>
              <TableCell>InsuranceName</TableCell>
              {/* <TableCell>Hcp Office</TableCell> */}
              {/* <TableCell>HCP ID</TableCell> */}
              <TableCell>eBVGateway</TableCell>
              {/* <TableCell>State</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredData.length > 0 ? (
              filteredData.map((row,index) => (
                <TableRow key={row.id} >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.insuranceName}</TableCell>
                {/* <TableCell>{row.hcpId}</TableCell> */}
                <TableCell>
              {editingRowId === row.masterId ? (
                <FormControl sx={{ mt: 8 }}>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={states}
                    // disabled = {roles =="superadmin"}
                    sx={{ width: 200}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="eBV Gateway"
                        onSelect={(e) => setebv(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              ) : (
                row.ebvGateway
              )}
            </TableCell>
            <TableCell>
              {editingRowId === row.id ? (
                <>
                  <IconButton aria-label="Save" onClick={handleSaveClick}>
                    <Save />
                  </IconButton>
                  <IconButton aria-label="Cancel" onClick={handleCancelClick}>
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton aria-label="Edit" style={{ color: '#00ADF2' }} onClick={() => handleEditClick(row)}>
                    <Edit />
                  </IconButton>
                  {/* <IconButton aria-label="Delete" style={{ color: '#FF4500' }} onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton> */}
                </>
              )}
            </TableCell>
              </TableRow>))):(
           data.slice(startIndex, endIndex).map((row, index) => (
              <TableRow key={row.id} >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.insuranceName}</TableCell>
                {/* <TableCell>{row.hcpId}</TableCell> */}
                <TableCell>
              {editingRowId === row.masterId ? (
                <FormControl sx={{ mt: 8 }}>
                <InputLabel id="demo-simple-select-label"></InputLabel>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                //   disabled = {roles =="superadmin"}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="eBV Gateway"
                      onSelect={(e) => setebv(e.target.value)}
                    />
                  )}
                />
              </FormControl>
              ) : (
                row.ebvGateway
              )}
            </TableCell>
            <TableCell>
              {editingRowId === row.id ? (
                <>
                  <IconButton aria-label="Save" onClick={() => handleSaveClick(row.masterId)}>
                    <Save />
                  </IconButton>
                  <IconButton aria-label="Cancel" onClick={handleCancelClick}>
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton aria-label="Edit" style={{ color: '#00ADF2' }} onClick={() => handleEditClick(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton aria-label="Delete" style={{ color: '#FF4500' }} onClick={handleSaveClick}>
                    <SaveIcon />
                  </IconButton> 
                </>
              )}
            </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
        </>)}
      </TableContainer>
        </div>
        <div className='ebvgatewaypagination'>
        <div style={{ marginLeft: "45%", justifyContent: "center", textAlign: "center", paddingTop: "1%", paddingBottom: "1%" }}>
          <Pagination color="primary" style={{ backgroundColor: "white", width: "180px", borderRadius: "10px" }} count={Math.ceil(data.length / rowsPerPage)} onChange={handlePageChange} page={page + 1} />
        </div>
      </div>
    </div>
  )
}


export default Ebvgateway;