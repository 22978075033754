// import React from 'react';
import "./hospitalinfo.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import { json, useNavigate, useParams } from "react-router";
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'


const customStyles = {
  width: 500, 
  height: 60, 
  position: 'relative',
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', 
  pointerEvents: 'none', 
};


const inputStyle = {

  width: '500px',
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};
const containerStyle = {

  // alignItems: 'center',
  minHeight: '65vh',
  width: "90%",
  margin: 'auto',
  marginTop: "3%",
  marginLeft: "7%",
  backgroundColor: "white",
  borderRadius: "8px"
};
const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}
const stackStyleTop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",

  marginTop: "5%",
  // marginBottom:"5px"
}
const stackStyledrop = {

  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "right",
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"

}

const Hospitalinfo = () => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);


  const [npiid, setnpi] = useState([]);
  const [hcpid, sethcpid] = useState([]);
  const [status, setstatus] = useState([]);
  const [hospital, sethospital] = useState([]);
  const [address, setaddress] = useState([]);
  const [city, setcity] = useState([]);
  const [state, setstate] = useState([]);
  const [zipcode, setzipcode] = useState([]);
  const [number, setnumber] = useState([]);
  const [email, setemail] = useState([]);
  const managnment = window.location.href
  const my_list = managnment.split("/")

  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")

    let url2 = `${BaseUrl}/hospitals/findOne`
    let body = {
      // "Id": my_list[0],
      "masterId": my_list[5]
    }


    axios
      .post(url2, body)
      .then((res) => {

        setnpi(res.data.data[0].npiId)
        setstatus(res.data.data[0].status)
        sethospital(res.data.data[0].hospitalName)
        setaddress(res.data.data[0].address)
        setcity(res.data.data[0].city)
        setstate(res.data.data[0].state)
        setzipcode(res.data.data[0].zipCode)
        setnumber(res.data.data[0].phoneNumber)
        setemail(res.data.data[0].email)
        sethcpid(res.data.data[0].hcpId)
      })
  }, [])
  const Navigate = useNavigate();

  return (
    <div className='hospitalinfopicontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='hospitalinfominihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")} />
        </div>
        <div className='hospitalinfominiheadtitle'>
          Hospital Details
        </div>
      </div>

      <div>
        <div className="hospitalinfocontainerlogin" style={{
          width: "90%",
          margin: 'auto',
          marginTop: "3%",
          marginLeft: "7%",
          backgroundColor: "white",
          borderRadius: "8px"
        }} >
          <div>

            <div className='hospitalinfopersonal'>Hospital Information</div>
            <Row className='hospitalinfouserdet'>

              <Col span={6} className='hospitalinfouserdetCol'>
                <div className='hospitalinfouserdetCol1'>NPI ID</div>
                <div className='hospitalinfouserdetCol2'>{npiid}</div>
              </Col>
              <Col span={6} className='hospitalinfouserdetCol'>
                <div className='hospitalinfouserdetCol1'>HCP ID</div>
                <div className='hospitalinfouserdetCol2'>{hcpid}</div>
              </Col>
              <Col span={6} className='hospitalinfouserdetCol'>
                <div className='userdetCol1'>Status</div>
                <div className='userdetCol2'>{status}</div>
              </Col>
              <Col span={6} className='userdetCol'>
                <div className='hospitalinfouserdetCol1'>Hospital Name</div>
                <div className='hospitalinfouserdetCol2'>{hospital}</div>
              </Col>

            </Row>
          </div>
          <hr style={{ backgroundColor: "#C8EDFF", height: "4px", border: "none", marginTop: "3%" }} />


          <Row className='hospitalinfouserdet2'>

            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>Address</div>
              <div className='hospitalinfouserdetCol2'>{address}</div>
            </Col>
            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>City</div>
              <div className='hospitalinfouserdetCol2'>{city}</div>
            </Col>
            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>State</div>
              <div className='hospitalinfouserdetCol2'>{state}</div>
            </Col>

            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>Zip Code</div>
              <div className='hospitalinfouserdetCol2'>{zipcode}</div>
            </Col>
          </Row>

          <hr style={{ backgroundColor: "#C8EDFF", height: "4px", border: "none", marginTop: "3%" }} />

          <Row className='hospitalinfouserdet3'>

            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>Phone Number</div>
              <div className='hospitalinfouserdetCol2'>{number}</div>
            </Col>
            <Col span={6} className='hospitalinfouserdetCol'>
              <div className='hospitalinfouserdetCol1'>Email</div>
              <div className='hospitalinfouserdetCol2'>{email}</div>
            </Col>
          </Row>
          <Row className='hospitalinfouserdet4'>

            {/* <Col  span={6}  className='userdetCol11'>
                <div className='userdetCol11' >Edit Hospital</div>
        </Col> */}
          </Row>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hospitalinfo