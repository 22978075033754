// import React from 'react';
import "./personalinfo.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import { json, useNavigate, useParams } from "react-router";
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'




const customStyles = {
  width: 500, // Adjust the width as needed
  height: 60, // Adjust the height as needed
  position: 'relative', // Needed for label placement
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
  pointerEvents: 'none', // To allow clicking through the label
};


const inputStyle = {
  width: '500px',
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};
const containerStyle = {
  // alignItems: 'center',
  minHeight: '57vh',
  width: "90%",
  margin: 'auto',
  marginTop: "3%",
  marginLeft: "7%",
  backgroundColor: "white",
  borderRadius: "8px"
};
const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}
const stackStyleTop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "5%",
  // marginBottom:"5px"
}
const stackStyledrop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "right",
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const Personalinfo = () => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);
  const Navigate = useNavigate();
  const [mailid, setmail] = useState([]);
  const [fname, setfname] = useState([]);
  const [lname, setlname] = useState([]);
  const [dob, setdob] = useState([]);
  const [status, setstatus] = useState([]);
  const [hcp, sethcp] = useState([]);
  const [role, setrole] = useState([]);

  const managnment = window.location.href
  const my_list = managnment.split("/")
  useEffect(() => {

    let url2 = `${BaseUrl}/add-users/findOne`
    let body = {
      // "Id": my_list[0],
      "masterId": my_list[5]
    }
    axios
      .post(url2, body)
      .then((res) => {
        setmail(res.data.data[0].emailId)
        setfname(res.data.data[0].firstName)
        setlname(res.data.data[0].lastName)
        setdob(res.data.data[0].dateOfBirth)
        setstatus(res.data.data[0].status)
        sethcp(res.data.data[0].hcpOffice)
        setrole(res.data.data[0].role)
      })
  }, [])


  return (
    <div className='picontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='minihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/tablenew?/" + my_list[4] + "")} />
        </div>
        <div className='miniheadtitle'>
          User Details
        </div>
      </div>
      <div>
        <div className="containerlogin" style={containerStyle} >
          <div>
            <div className='personal'>Personal Information</div>
            <Row className='userdet'>
              <Col span={6} className='userdetCol'>
                <div className='userdetCol1'>Email Id</div>
                <div className='userdetCol2'>{mailid}</div>
              </Col>
              <Col span={6} className='userdetCol'>
                <div className='userdetCol1'> First Name</div>
                <div className='userdetCol2'>{fname}</div>
              </Col>
              <Col span={6} className='userdetCol'>
                <div className='userdetCol1'> Last Name</div>
                <div className='userdetCol2'>{lname}</div>
              </Col>
              <Col span={6} className='userdetCol'>
                <div className='userdetCol1'>Dob</div>
                <div className='userdetCol2'>{dob}</div>
              </Col>

            </Row>
          </div>
          <hr style={{ backgroundColor: "#C8EDFF", height: "4px", border: "none", marginTop: "3%" }} />
          <Row
            // className='userdet2'
            style={{
              display: "flex",
              /* justify-content: space-between; */
              marginLeft: "3%",
              width: "98%",
            }}>

            <Col span={6} className='userdetCol'>
              <div className='userdetCol1'>Status</div>
              <div className='userdetCol2'>{status}</div>
            </Col>
            <Col span={6} className='userdetCol'>
              <div className='userdetCol1'> HCP Office</div>
              <div className='userdetCol2'>{hcp}</div>
            </Col>
            <Col span={6} className='userdetCol'>
              <div className='userdetCol1'> Role</div>
              <div className='userdetCol2'>{role}</div>
            </Col>
          </Row>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personalinfo