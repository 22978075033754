import React, { useState, useEffect } from 'react';
import './sidebar1.css';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { GroupOutlined } from '@ant-design/icons';
import { json, useNavigate, useParams } from "react-router";
import {
  AddBusinessOutlined,
  AddToPhotosOutlined,
  AssignmentTurnedInOutlined,
  PeopleAltOutlined,
} from '@mui/icons-material';
import NewHeader from './NewHeader';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function Sidebar1() {
  const [showMenuNames, setShowMenuNames] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [roles, setrole] = useState('');
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleMenuIconClick = () => {
    setShowMenuNames(!showMenuNames);
  };

  useEffect(() => {
    const managnment = window.location.href
    console.log(managnment, "id")
    const my_list = managnment.split("/")
    console.log(my_list[4], "my_list")
    const role = my_list[4]
    console.log(typeof role, "ggggg", role)
    setrole(role)
  }, []);
  const Navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        Navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [Navigate]);

  // useEffect(() => {
  //   let timeoutId;

  //   const resetTimeout = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       // logout(); // Logout the user
  //       Navigate('/'); // Redirect to login page
  //     }, 2 * 60 * 1000); // 5 minutes
  //   };

  //   const handleUserActivity = () => {
  //     resetTimeout();
  //   };

  //   resetTimeout(); // Initial call to start the timeout

  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('mousedown', handleUserActivity);
  //   window.addEventListener('keypress', handleUserActivity);
  //   window.addEventListener('touchstart', handleUserActivity);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('mousedown', handleUserActivity);
  //     window.removeEventListener('keypress', handleUserActivity);
  //     window.removeEventListener('touchstart', handleUserActivity);
  //   };
  // }, [Navigate]);
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      {isSidebarOpen ?
        <div onClick={toggleSidebar} style={{ cursor: 'pointer', }} title='Collapse'>
          {/* <ArrowBackIosIcon style={{ color: '#1DA0E1', position: "absolute", top: "4px", right: "1px" }} /> */}
        </div>
        :
        <div onClick={toggleSidebar} style={{ cursor: 'pointer', }} title='Expand'>
          {/* <ArrowForwardIosIcon style={{ color: '#1DA0E1', position: "absolute", top: "4px", right: "1px" }} /> */}
        </div>
      }
      <ul className="nav-list">
        {roles == "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <DashboardOutlinedIcon
                  className="bx bx-grid-alt"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/graph?/superadmin`)}
                />
                <span className="tooltip" >Dashboard</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/graph?/superadmin`)} className="menu-name">Dashboard</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <SupervisedUserCircleIcon
                  className="bx bx-grid-alt"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/Gatewayconfig?/superadmin`)}
                />
                <span className="tooltip"  >Gateway</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/Gatewayconfig?/superadmin`)} className="menu-name">Gateway</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles === "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <ListAltIcon
                  className="bx bx-grid-alt"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/hcpconfig?/superadmin`)}
                />
                <span className="tooltip" >Hcp config</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/hcpconfig?/superadmin`)} className="menu-name">Hcp config</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "HCP%20Admin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <DashboardOutlinedIcon
                  className="bx bx-grid-alt"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/graph?/HCP%20Admin`)}
                />
                <span className="tooltip"  >Dashboard</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/graph?/superadmin`)} className="menu-name">Dashboard</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <PeopleAltOutlined
                  className="bx bx-user"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/tablenew?/superadmin`)}
                />
                <span className="tooltip"  >User</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/tablenew?/superadmin`)} className="menu-name">User</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "HCP%20Admin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <PeopleAltOutlined
                  className="bx bx-user"
                  style={{ fontSize: '30px' }} onClick={(e) => window.location.href = "/tablenew?/HCP%20Admin"}
                />
                <span className="tooltip"  >User</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/tablenew?/HCP%20Admin`)} className="menu-name">User</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <AddToPhotosOutlined
                  className="bx bx-chat"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/hospitalnew?/superadmin`)}
                />
                <span className="tooltip"  >Hospital</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/hospitalnew?/superadmin`)} className="menu-name">Hospital</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "HCP%20Executive" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <AssignmentTurnedInOutlined
                  className="bx bx-pie-chart-alt-2"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/ebvtable?/HCP%20Executive`)}
                />
                <span className="tooltip" >ebv Transaction Report</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/ebvtable?/HCP%20Executive`)} className="menu-name">ebv Transaction Report</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "superadmin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <AssignmentTurnedInOutlined
                  className="bx bx-pie-chart-alt-2"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/ebvtable?/superadmin`)}
                />
                <span className="tooltip" >eBV Request</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/ebvtable?/superadmin`)} className="menu-name">eBV Request</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "HCP%20Admin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <AssignmentTurnedInOutlined
                  className="bx bx-pie-chart-alt-2"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/ebvtable?/HCP%20Admin`)}
                />
                <span className="tooltip" >eBV Request</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/ebvtable?/HCP%20Admin`)} className="menu-name">EBV Request</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {/* {roles == "HCP%20Admin" ? (<>
          <li>
            <div className="list-item">
              <div className="icon-container">
                <AddBusinessOutlined
                  className="bx bx-folder"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/createplan?/HCP%20Admin`)}
                />
                <span className="tooltip" > Payer/Plan Change</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/createplan?/HCP%20Admin`)} className="menu-name">Payer/Plan Change</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        } */}
        {/* {roles == "superadmin" ? (<>

          <li>
            <div className="list-item">
              <div className="icon-container">
                <AddBusinessOutlined
                  className="bx bx-folder"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/createplan?/superadmin`)}
                />
                <span className="tooltip" > Payer/Plan Change</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/createplan?/superadmin`)} className="menu-name">Payer/Plan Change</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        } */}
        {roles == "Support%20Executive%20-%20creator" ? (<>

          <li>
            <div className="list-item">
              <div className="icon-container">
                <AddBusinessOutlined
                  className="bx bx-folder"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/createtable?/Support%20Executive%20-%20creator`)}
                />
                <span className="tooltip" > Payer/Plan Change</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/createtable?/Support%20Executive%20-%20creator`)} className="menu-name">Payer/Plan Change</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
        {roles == "Support%20Executive%20-%20Reviewer" ? (<>

          <li>
            <div className="list-item">
              <div className="icon-container">
                <AddBusinessOutlined
                  className="bx bx-folder"
                  style={{ fontSize: '30px' }} onClick={(e) => Navigate(`/addplan?/Support%20Executive%20-%20Reviewer`)}
                />
                <span className="tooltip"  > Payer/Plan Change</span>
                {isSidebarOpen && (
                  <span onClick={(e) => Navigate(`/addplan?/Support%20Executive%20-%20Reviewer`)} className="menu-name">Payer/Plan Change</span>
                )}
              </div>
            </div>
          </li>
        </>)
          :
          ""
        }
      </ul>
    </div>

  );
}
export default Sidebar1;

