import React, { useEffect, useState,useRef } from 'react';
import Header from '../Pverify/Header';
import { HistoryOutlined, NotificationFilled, NotificationOutlined, PlusCircleOutlined, ProfileOutlined, ProfileTwoTone, SettingOutlined } from '@ant-design/icons';
import { Col, Row, Badge } from 'antd';
import "./createmain.css"
import { useNavigate } from 'react-router-dom';
import { AccountBoxOutlined, AccountCircleOutlined, MailOutline, MailOutlineOutlined, NotificationAddOutlined, Notifications, Settings } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
// import "./main.css";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Grid, Container, MenuItem, Select, Stack } from '@mui/material';
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Document, Page, pdfjs } from 'react-pdf';
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { message, Upload } from "antd";
// import NewHeader from './NewHeader';
import Sidebar1 from '../Pverify/Sidebar1';
import Sidebar from '../Pverify/Sidebar';
// import NewHeader from './NewHeader';
import { useBadge } from '../Pverify/NewScreens/Badgecontext';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton, Popover, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {BaseUrl} from '../config'
import { useAuth } from '.././Newlogin/Authprovider'
const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
};

const CreateMain = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);

  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [pdfFile, setPdfFile] = useState(null); // Store the PDF file here
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [payer, setpayer] = useState("")

  const [plantype, setplantype] = useState("")

  const [state, setstate] = useState("")
  const [description, setdescription] = useState("")
  // const [link,setlink] = useState("")
  const [cigna, setCigna] = useState([]);
  const [emblemhealth, setemblemhealth] = useState([]);
  const [anthembluecrossblueshield, setanthembluecrossblueshield] = useState(
    []
  );
  const [plan, setPlan] = useState([]);
  const [upmc, setupmc] = useState([]);
  const [geisinger, setgeisinger] = useState([]);
  const [amerihealth, setamerihealth] = useState([]);
  const [imperialhealth, setimperialhealth] = useState([]);
  const [hyperlinks, setlink] = useState([]);
  const [byalors, setbyalors] = useState([]);
  const [aetna, setaetna] = useState([]);
  const [ambetter, setambetter] = useState([]);
  const [unitedhealthcare, setunitedhealthcare] = useState([]);
  const [payerlist, setPayerlist] = useState([]);
  const [newyork, setnewyork] = useState([]);
  const [newjersey, setjersey] = useState([]);
  const [pennsylvania, setpennsylvania] = useState([]);
  const [michigan, setmichigan] = useState([]);
  const [texas, settexas] = useState([]);
  const [illinois, setillinois] = useState([]);
  // const [plans, setplans] = useState("");
  // const[statee,setstatee] = useState("")
  const [newdescription, setnewdescription] = useState("")
  const [clover, setclover] = useState([]);
  const [wellcare, setwellcare] = useState([]);
  const [health, sethealth] = useState([]);
  const [oscar, setoscar] = useState([]);
  const [hap, sethap] = useState([]);
  const { setBadgeCount } = useBadge();
  const [apiPdfLinks, setApiPdfLinks] = useState([]);
  // const [badgeCount, setBadgeCountt] = useState(0);
  const [roles, setrole] = useState('');
  const [status, setstatus] = useState('');


 
  const success = () => {
    message.open({
      type: "success",
      content: "SuccessFully Updated",
    });
  };
  const error = () => {
    message.open({
      type: "error",
      content: "Failed to Updated",
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    navigate("/")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")
    const role = my_list[4]
    setrole(role)
  }, [])

  const managnment = window.location.href
  const my_list = managnment.split("/")
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    if (files.length > 0) {
      setPdfFile(files[0]);
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!isFullScreen) {
      handle.enter();
    } else {
      handle.exit();
    }
  };



  const states = [
    { label: "New York", id: 1 },
    { label: "New Jersey", id: 2 },
    { label: "Pennsylvania", id: 3 },
    { label: "Michigan", id: 4 },
    { label: "Texas", id: 5 },
    { label: "Illinois", id: 6 },
  ];

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setSelectedFiles(droppedFiles);
    if (droppedFiles.length > 0) {
      setPdfFile(droppedFiles[0]);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleClickss = () => {
    navigate("/")
  }
  useEffect(() => {
    let url5 =
      "https://xwuolpeyrsa2lrq7f6tvhbpd740rzgdy.lambda-url.us-east-1.on.aws/";

    axios.get(url5).then((res) => {
      setCigna(res.data.cigna_list.filter((e) => e != '\\N'));
      setaetna(res.data.aetna_list.filter((e) => e != '\\N'));
      setemblemhealth(res.data.emblem_list.filter((e) => e != '\\N'));
      setanthembluecrossblueshield(
        res.data.blue_cross.filter((e) => e != '\\N')
      );
      setambetter(
        res.data.ambetter_list.filter((e) => e != '\\N')
      );
      setunitedhealthcare(
        res.data.united_healthcare_list.filter((e) => e != '\\N')
      );
      setupmc(
        res.data.upmc_list.filter((e) => e != '\\N')
      );
      setgeisinger(
        res.data.geisinger_list.filter((e) => e != '\\N')
      );
      setamerihealth(
        res.data.amerihealth_list.filter((e) => e != '\\N')
      );
      setimperialhealth(
        res.data.imperial_list.filter((e) => e != '\\N')
      );
      setbyalors(
        res.data.byalors_list.filter((e) => e != '\\N')
      );
      setclover(
        res.data.clover_health
      );
      setwellcare(
        res.data.wellcare
      );
      sethealth(
        res.data.health_first

      );
      setoscar(
        res.data.oscar_health
      );
      sethap(
        res.data.hap
      );
    });
  }, []);
  useEffect(() => {
    if (payer === "EmblemHealth") {
      setPlan(emblemhealth);
    }
    else if (payer === "UnitedHealthCare") {
      setPlan(unitedhealthcare);
    }
    else if (payer === "Cigna") {
      setPlan(cigna);
    }
    else if (payer === "BluecrossBlueshield") {
      setPlan(anthembluecrossblueshield);
    }
    else if (payer === "Aetna") {
      setPlan(aetna);
    }
    else if (payer === "Ambetter") {
      setPlan(ambetter);
    }
    else if (payer === "UPMChealth") {
      setPlan(upmc);
    }
    else if (payer === "GeisingerHealth") {
      setPlan(geisinger);
    }
    else if (payer === "Amerihealth") {
      setPlan(amerihealth);
    }
    else if (payer === "ImperialHealth") {
      setPlan(imperialhealth);
    }
    else if (payer === "BaylorScottandWhite") {
      setPlan(byalors);
    }
    else if (payer === "CloverHealth") {
      setPlan(clover);
    }
    else if (payer === "Wellcare") {
      setPlan(wellcare);
    }
    else if (payer === "HealthFirst") {
      setPlan(health);
    }
    else if (payer === "OscarHealth") {
      setPlan(oscar);
    }
    else if (payer === "HAP") {
      setPlan(hap);
    }
  }, [payer]);

  useEffect(() => {
    let url8 =
      "https://xq6ayj6uipoz5gppwuahrfle7y0lkepy.lambda-url.us-east-1.on.aws/";

    axios.get(url8).then((res) => {
      setnewyork(res.data.new_york);
      setjersey(res.data.new_jersey);
      setpennsylvania(res.data.pennsylvania);
      setmichigan(
        res.data.michigan
      );
      settexas(
        res.data.texas
      );
      setillinois(
        res.data.illinois
      );
    });
  }, []);

  useEffect(() => {
    if (state === "New York") {
      setPayerlist(newyork);
    }
    else if (state === "New Jersey") {
      setPayerlist(newjersey);
    }
    else if (state === "Pennsylvania") {
      setPayerlist(pennsylvania);
    }
    else if (state === "Michigan") {
      setPayerlist(michigan);
    }
    else if (state === "Texas") {
      setPayerlist(texas);
    }
    else if (state === "Illinois") {
      setPayerlist(illinois);
    }

  }, [state]);

  useEffect(() => {
    const url8 = `${BaseUrl}/coverage-details/findOne`;

    const body = {
      "masterId": my_list[5]
    };

    axios
      .post(url8, body)
      .then((res) => {
        console.log(res,"uuuuuu")
        setdescription(res.data.data[0].Description
        )
        setpayer(res.data.data[0].insuranceName
        )
        setstate(res.data.data[0].State)
        setstatus(res.data.data[0].status)
        if (res.data.data[0].link.length == 69) {
          setApiPdfLinks(res.data.data[0].link)
        } else {
          const link = res.data.data[0].link
          const cleanedString = link.replace(/[{}"]/g, '');
          const linksArray = cleanedString.split(',');
          const hyperlink = linksArray[1]
          setApiPdfLinks(hyperlink);
        }
        setplantype(res.data.data[0].PlanName
        )
      })

      .catch((error) => {
      });
  }, [])
  const handlenavigatepage = (e) => {
    // navigate("/addplan")
  }
  // useEffect(()=>  {
  const handleClick = (e) => {
    const url8 = `${BaseUrl}/coverage-details/update`;

    const body = {
      "masterId": my_list[5],
      "Response": "Covered with Condition",
      "Description": newdescription,
      "insuranceName": payer,
      "PlanName": plantype,
      "State": state

    };

    axios
      .post(url8, body)
      .then((res) => {
        if (res.data.status === "Ok") {
          success();
        }
        else {
          error()
        }
        const url8 = `${BaseUrl}/dev-approver/create`;

        const body = {
          "insuranceName": payer,
          "planName": plantype,
          "state": state,
          "newContent": newdescription,
          "oldContent": description,
          "link": apiPdfLinks,
          "status":status
        };

        axios
          .post(url8, body)
          .then((res) => {
            setBadgeCount((prevCount) => prevCount + 1);
          })
      }).catch((e) => {
        error()

      }).finally(() => {
        // setSearchLoading(false);
        // Navigate("/pverifyy");
      });
  }
  const handleTextClick = (textId) => {
    const textElement = document.getElementById(textId);
    if (textElement) {
      const { top } = textElement.getBoundingClientRect();
      const scrollPosition = window.pageYOffset + top;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const downloadAndDisplayPDF = (pdfLink) => {
    axios({
      url: pdfLink,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        setApiPdfLinks(pdfUrl);
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };

  return (
    <div className='picontainer' style={{ overflow: "scroll" }}>
      {/* <Sidebar1/> */}
      <div className="Hcontainer">
        <Row >
          <Col span={5} className='leftPattCol' >
            <div className="leftContain">
              {/* <h1 >MEDICAL EBV</h1>
                     <p>Patient support program</p> */}
              <div>
                <img className='headerlog' src='logoipsum-297 (2) 1.png' />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <h2 className='med' >Electronic Benefit verification</h2>
            </div></Col>
          <Col offset={6} span={7} className='rightPattCol'>
            <div className='RightPatt'>
              <Row className='rightPattRow'>
                <Col span={4}>
                  <div className='plu' >
                    <Settings className='headerIc' style={{ fontSize: "25px" }} />
                    {/* <p >New</p> */}
                  </div >
                </Col>
                <Col span={4}>
                  <div className='plu'>
                    <MailOutline className='headerIc' style={{ fontSize: "25px" }} />
                    {/* <p >History</p> */}
                  </div>
                </Col>
                <Col span={4}>
                  <div className='plu' onClick={handlenavigatepage}>
                    <Badge >
                      <Notifications className='headerIc' style={{ fontSize: "25px" }} />
                    </Badge>
                    {/* <p >History</p> */}
                  </div>
                </Col>
                <Col offset={1} span={8}>
                  <div>
                    <IconButton className='admins' onClick={handleClick1}>
                      <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                      <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                        Support Creator
                      </div>
                    </IconButton>

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        {/* Content of the dropdown */}
                        <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                          Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Sidebar1 />
      <div>
        <div className='titlename'>
          <div style={{ marginTop: "1%", paddingTop: "1%" }}>
            Payer/Plan Changes
          </div>
        </div>

        <Container className="doccont" maxWidth="lg" style={{ marginTop: "3%" }}>
          <Grid className='doccontgrid' container spacing={2}>
            {/* Left side with 5 input boxes */}
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                backgroundColor: "white",
                // margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // marginBottom:"4%"
              }}
            >
              <div className='lefttitle'>Insurance Information</div>
              <FormControl sx={{ mt: 2 }}>
                <div className='inputtitlem'>State</div>
                {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                  value={state}
                  // disabled = {roles =="superadmin" || "HCP%20Admin"}
                  style={{
                    width: '500px',
                    height: '50px',
                    // border: '1px solid #E1E5E8',
                    borderRadius: '0',
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="State"
                      onSelect={(e) => setstate(e.target.value)}
                    />
                  )}
                />
              </FormControl>

              <FormControl sx={{ mt: 3 }}>
                {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}

                <div className='inputtitlem'>Insurance Name</div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={payerlist}
                  value={payer}
                  // disabled = {roles =="superadmin" || "HCP%20Admin"}

                  style={{
                    width: '500px',
                    height: '50px',
                    // border: '1px solid #E1E5E8',
                    borderRadius: '0',
                    marginTop: "2px"

                    // marginBottom:'7%'
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="InsuranceName"

                      onSelect={(e) => setpayer(e.target.value)}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: 3 }}>
                {/* <InputLabel id="demo-simple-select-label"></InputLabel>
                  
                  */}
                <div className='inputtitlem'>Plan Type</div>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={plan}
                  value={plantype}
                  // disabled = {roles =="superadmin" || "HCP%20Admin"}
                  style={{
                    width: '500px',
                    height: '50px',
                    // border: '1px solid #E1E5E8',
                    borderRadius: '0',
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Plan Type"
                      onSelect={(e) => setplantype(e.target.value)}
                    />
                  )}
                />
              </FormControl>

              <Stack fullWidth style={{ marginTop: "3.5%" }}>
                <div className='inputtitlem'>Summary / Coverage Notes</div>
                <TextareaAutosize
                  value={description}
                  // disabled = {roles =="superadmin" || "HCP%20Admin"}
                  style={{
                    width: '490px',
                    height: '150px',
                    border: '2.5px solid #E1E5E8',
                    borderRadius: '0',
                  }}
                  placeholder="Enter text here"
                />
              </Stack>

              <Stack fullWidth style={{ marginTop: "3.5%" }}>
                <div className='inputtitlem'>Summary / Coverage Notes Changes</div>
                <TextareaAutosize
                  style={{
                    width: '490px',
                    height: '140px',
                    border: '2.5px solid #E1E5E8',
                    borderRadius: '0',
                  }}
                  //   disabled = {roles =="superadmin" || "HCP%20Admin"}
                  placeholder="Enter text here"
                  onChange={(e) => setnewdescription(e.target.value)}
                />
              </Stack>
              <Stack
                fullWidth
                style={{
                  width: '490px',
                  height: '140px',
                  border: '2.5px dashed #1DA0E1',
                  borderRadius: '0',
                  marginTop: '3.5%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
              >
                <form onSubmit={handleSubmit}>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />


                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => document.querySelector('input[type="file"]').click()}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleFileDrop}
                  >
                    <img style={{ width: '50px', height: '50px' }} src="/add.png" alt="" />
                    <div type="submit">
                      <span className='chosefilespan'>Choose file </span> or Drag file here</div>
                  </div>

                </form>
                {uploadedFileNames.length > 0 && (
                  <div>
                    <h3>Uploaded Files:</h3>
                    <ul>
                      {uploadedFileNames.map((fileName, index) => (
                        <li key={index}>{fileName}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Stack>

              <Stack direction="row" spacing={3} style={{ marginTop: "10%", justifyContent: "center", marginBottom: "5%" }}>
                <Grid>
                  <button className='add '>Cancel</button>
                </Grid>

                <Grid>
                  <button className="submit" onClick={() => handleClick("Submit")}>Submit</button>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction="column"

                spacing={2}
                style={{}}>

                {pdfFile && (
                  <Grid item style={{}}>
                    <FullScreen handle={handle}>
                      <Paper
                        elevation={3}
                        style={{ height: '200px', padding: '16px', overflow: "auto", marginBottom: "2%" }}
                      >
                        {pdfFile && (
                          <div >
                            <div className='newdoc'> New Document</div>
                            <iframe
                              src={URL.createObjectURL(pdfFile)}
                              title="Existing Document PDF"
                              style={{ height: '800px', width: "100%", marginTop: "2%" }}
                            ></iframe>
                          </div>
                        )}
                      </Paper>
                    </FullScreen>
                  </Grid>
                )}
                <Grid item>
                  <Paper elevation={3} style={{ minHeight: '500px', overflow: 'auto' }}>
                    <div
                      style={{
                        height: '100%',
                        overflow: 'auto',
                      }}
                    >
                      <div className='HEADDINGFOREXIST'>
                        <Col offset={8} span={8} className='newdoc'>Existing Document</Col>
                        <Col offset={6} span={6} className='newdocc'>  <a href={apiPdfLinks} target="">   <ZoomOutMapIcon /></a></Col>
                      </div>
                      <iframe
                        src={apiPdfLinks}
                        title="Existing Document PDF"
                        style={{ height: '800px', width: '99.4%', marginTop: '2%' }}
                      >
                        <div
                          id="overlay-text-1"
                          style={{
                            position: 'absolute',
                            top: '100px',
                            left: '50px',
                            backgroundColor: 'rgba(255, 0, 0, 0.5)',
                            color: 'white',
                            cursor: 'pointer',
                          }}
                        // onClick={() => handleTextClick('overlay-text-1')}
                        >
                          Click me!
                        </div>

                        {/* Other overlay text divs */}
                        {/* Add more overlay text divs as needed */}
                      </iframe>

                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default CreateMain;
