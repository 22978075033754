// const config = {
//     apiUrl: "http://localhost:3000",
//     // apiUrl: "http://52.87.151.167:9000/",

//     // Other configuration variables...
//   };

//   export default config;

//  export const DocUrl = 'http://52.87.151.167:8503/' // local url
 export const DocUrl = 'https://api-s1-ebi.healthitplatform.com/' // local url

 
  // export const BaseUrl = 'http://localhost:3005' // local url
  export const BaseUrl = 'https://api-ebi.valuehealthsolutions.com' // Prod url
    // export const BaseUrl = 'http://52.87.151.167:3005' // UAT Url


export const REACT_APP_ACESS_KEY_ID =  "AKIAVMTVZLEFFWK7M5GY" 
export const REACT_APP_SECRET_ACESS_KEY =  "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" 
export const REACT_APP_REGION = "us east"