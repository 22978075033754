import { HistoryOutlined, NotificationFilled, NotificationOutlined, PlusCircleOutlined, ProfileOutlined, ProfileTwoTone, SettingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import React, { useState, useEffect } from 'react';
import "./newheader.css"
import { useNavigate } from 'react-router-dom';
import { AccountBoxOutlined, AccountCircleOutlined, MailOutline, MailOutlineOutlined, NotificationAddOutlined, Notifications, Settings } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton, Popover, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const NewHeader = () => {

  const navigate = useNavigate();

  const [roles, setrole] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
     localStorage.removeItem("expires")
   window.location.href='/'
  
  };

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  const ebvGateway = () => {
    navigate("/ebvgatway?/HCP%20Executive")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")
    const role = my_list[4]
    setrole(role)
  }, []);


  const handleHistory = () => {
    const location = window.location.href;

    const slicedLocation = location.split('/')[3];

    if (slicedLocation == 'Pverifyy') {
      window.location.reload();
    }
    else {
      navigate('')
    }
  }

  return (
    <>
      <div>
        <div className="Hcontainer">
          <Row >
            <Col span={2} className='leftPattCol' >
              <div className="leftContain">
                {/* <h1 >MEDICAL EBV</h1>
                     <p>Patient support program</p> */}
                <div>
                  <img className='headerlog' src='/EBV Logo.png '/>
                </div>
              </div>
            </Col>
            <Col span={5}>
              <div>
                <h2 className='med' >Electronic Benefit Verification</h2>
              </div></Col>
            <Col offset={6} span={9} className='rightPattCol'>
              <div className='RightPatt'>
                <Row className='rightPattRow'>
                  <Col span={2}>
                    <div className='plu' >
                      <Settings className='headerIc' style={{ fontSize: "25px" }} onClick={ebvGateway} />
                      {/* <p >New</p> */}
                    </div >
                  </Col>
                  <Col span={2}>
                    <div className='plu' >
                      <Notifications className='headerIc' style={{ fontSize: "25px" }} />
                      {/* <p >History</p> */}
                    </div>
                  </Col>
                  {roles == "superadmin" ? (<>

                    <Col span={8}>

                      <div className='newheaderwholesuperadmin'>
                        <IconButton className='admins' onClick={handleClick}>
                          <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                          <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                            Super Admin
                          </div>
                        </IconButton>

                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                              Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                            </div>
                          </Typography>
                        </Popover>
                      </div>
                    </Col>
                  </>)
                    :
                    ""
                  }

                  {roles == "HCP%20Admin" ? (<>

                    <Col span={8}>

                      <div>
                        <IconButton className='admins' onClick={handleClick}>
                          <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                          <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                            HCP Admin
                          </div>
                        </IconButton>

                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                              Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                            </div>
                          </Typography>
                        </Popover>
                      </div>
                    </Col>
                  </>)
                    :
                    ""
                  }
                  {roles == "HCP%20Executive" ? (<>
                    <Col span={2}>
                      <div>
                        <IconButton className='admins' onClick={handleClick}>
                          <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                          <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                            HCP Executive
                          </div>
                        </IconButton>

                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            {/* Content of the dropdown */}
                            <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                              Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                            </div>
                          </Typography>
                        </Popover>
                      </div>
                    </Col>
                  </>)
                    :
                    ""
                  }
                  {roles == "Support%20Executive%20-%20creator" ? (<>
                    <Col span={8}>
                      <div>
                        <IconButton className='admins' onClick={handleClick}>
                          <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                          <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                            Support Creator
                          </div>
                        </IconButton>

                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                              Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                            </div>
                          </Typography>
                        </Popover>
                      </div>
                    </Col>
                  </>)
                    :
                    ""
                  }

                  {roles == "Support%20Executive%20-%20Reviewer" ? (<>

                    <Col span={8}>

                      <div>
                        <IconButton className='admins' onClick={handleClick}>
                          <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                          <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                            Support Reviewer
                          </div>
                        </IconButton>

                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                              Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                            </div>
                          </Typography>
                        </Popover>
                      </div>
                    </Col>
                  </>)
                    :
                    ""
                  }
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default NewHeader