// import React from 'react';
import "./AddNewUser.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import { json, useNavigate, useParams } from "react-router";
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState,useRef } from 'react';
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack, useEventCallback } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import axios from "axios";
import { message, Upload } from "antd";
import { LoadingButton } from '@mui/lab';
import { BaseUrl } from '../../../config'
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { useAuth } from '../../../Newlogin/Authprovider'
import { Controller, useForm } from "react-hook-form";
import dayjs from 'dayjs';


const customStyles = {
  width: 500,
  height: 60,
  position: 'relative',
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",
  transform: 'translate(15px, 2px)',
  pointerEvents: 'none',
};


const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
};

const inputStyle2 = {
  width: '500px',
  backgroundColor: 'white',
  marginLeft: '1%'
};

const containerStyle = {
  width: "90%",
  margin: 'auto',
  marginTop: "1%",
  marginLeft: "7%",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "8px"
};
const stackStyle = {
  display: "flex",
  gap: "5px",
  marginTop: "0%",
  justifyContent: "center",
  marginTop: "2%"


}

const stackStyleTop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  // margin:" 0 auto",
  justifyContent: "center",

  marginTop: "2%",
  // marginBottom:"5px"
}
// const stackStyledrop = {

//   display: "flex",
//   justifyContent: "left",
//   // gap:"5px",
//   margin: "auto",
//   marginTop: "2%",
//   marginLeft: "8%"
// }

const stackStyledrop = {
  display: "flex",
  flexDirection: "column", // Change to column to center the single input
  alignItems: "center", // Center items horizontally
  gap: "5px",
  marginTop: "2%",
  marginRight: "520px",  // Use a fixed value instead of a percentage
};

const stackStylebot = {
  display: "flex",
  justifyContent: "left",
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"

}

const EditAddNewUsers = () => {
  const success = () => {
    message.open({
      type: "success",
      content: "New User was created Successfully",
    });
    //     messageApi.success("Logged in successfully")
  };
  const error = () => {
    message.open({
      type: "error",
      content: "Email already exists",
    });
    // messageApi.error("Invalid username or otp")
  };

  const requiredError = () => {
    message.open({
      type: "error",
      content: "Please fill the required field",
    });
  };
  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  const handleChange1 = (event) => {
    sethcp(event.target.value);
  };

  const handleChange2 = (event) => {
    setrole(event.target.value);
  };

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
  if (!isAuthenticated()) {

    navigate('/');
  }


  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);
  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);

  const [email, setemail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [dob, setdob] = useState(null);
  const [hcp, sethcp] = useState([]);
  const [status, setstatus] = useState('');
  const [role, setrole] = useState('');
  const [selectedHcp, setSelectedHcp] = useState('');
  const [selectedlist, setSelectedlist] = useState('')
  const [last, setlast] = useState('');
  // const [isEmailValid, setIsEmailValid] = useState(true);
  // const [roles, setroles] = useState('');

  const { control, formState: { errors } } = useForm();
  const [dobError, setDobError] = useState(false);


  const Navigate = useNavigate();
  console.log()
  // useEffect(()=> {

  const validateForm = () => {
    let isValid = true;

    // Validate Email
    if (!validateEmail(email)) {
      console.log(1)
      setIsEmailValid(false);
      isValid = false;
    } else {
      console.log(2)
      setIsEmailValid(true);
    }

    // Validate First Name
    if (!validateFirstname(firstname)) {
      console.log(3)
      setIsFirstnameValid(false);
      isValid = false;
    } else {
      console.log(4)
      setIsFirstnameValid(true);
    }

    // Validate Last Name
    if (!validateFirstname1(lastname)) {
      console.log(5)
      setIsLastnameValid(false);
      isValid = false;
    } else {
      console.log(6)
      setIsLastnameValid(true);
    }

    if (!validatestatus(status)) {
      console.log(7)
      setIsstatusValid(false);
      isValid = false;
    } else {
      console.log(8)
      setIsstatusValid(true);
    }
    if (!validatehosp(selectedHcp)) {
      setIshospValid(false);
      console.log(9)
      isValid = false;
    } else {
      console.log(10)
      setIshospValid(true);
    }

    if (!validaterole(role)) {
      console.log(role)
      console.log(11)
      setIsroleValid(false);
      isValid = false;
    } else {
      console.log(12)
      setIsroleValid(true);
    }
    console.log(dob, 'dateof birth')

    if (!dob) {
      console.log("qwertyu")
      setDobError(true)
      setIsDobValid(false);
      isValid = false;
    } else {
      setDobError(false);
    }

    return isValid;
  };


  const managnment = window.location.href
  const my_list = managnment.split("/")
  const role1 = my_list[4]
  // const handleSubmit = () => {
  //   // if (validateForm()) {

  //     const createUrl = ${BaseUrl}/add-users/create;
  //     const newUserData = {
  //       emailId: email,
  //       firstName: firstname,
  //       lastName: lastname,
  //       dateOfBirth: dob,
  //       status: status,
  //       hcpOffice: selectedHcp,
  //       hcpId: "",
  //       role: role
  //     };
  //     axios.post(createUrl, newUserData)
  //       .then((res) => {
  //         if (res.data.status === "Ok") {
  //           Navigate("/tablenew?/" + my_list[4] + "");
  //           success();
  //         } else {
  //           error();
  //         }
  //       })

  //     const url8 = ${BaseUrl}/email-service;

  //     const body = {
  //       "email": email,
  //     };

  //     axios
  //       .post(url8, body)
  //       .then((res) => {
  //       })
  //       .catch((error) => {
  //         error();
  //       });
  //   // }
  // }
  const handleSubmit = () => {
    const isvalidateForm = validateForm();
    console.log(isvalidateForm)
    const newUserData = {
      emailId: email,
      firstName: firstname,
      lastName: lastname,
      dateOfBirth: dob,
      status: status,
      hcpOffice: selectedHcp,
      hcpId: "",
      role: role
    };
    console.log(newUserData)
    if (isvalidateForm) {
      // Rest of your code for form submission
      const createUrl = `${BaseUrl}/add-users/update`;
      const newUserData = {
        emailId: email,
        firstName: firstname,
        lastName: lastname,
        dateOfBirth: dob,
        status: status,
        hcpOffice: selectedHcp,
        hcpId: "",
        role: role,
        masterId: my_list[5]
      };
      axios.post(createUrl, newUserData)
        .then((res) => {
          if (res.data.status === "Ok") {
            window.location.href = "/tablenew?/" + my_list[4];
            success();
          } else {
            error();
          }
        })

      const url8 = `${BaseUrl}/email-service`;

      const body = {
        "email": email,
      };

      axios
        .post(url8, body)
        .then((res) => {
        })
        .catch((error) => {
          error();
        });
    } else {
      requiredError()
    }
  };


  useEffect(() => {
    const masterId = my_list[5]
    if (masterId) {
      let url2 = `${BaseUrl}/add-users/findOne`
      let body = {
        // "Id": my_list[0],
        "masterId": my_list[5]
      }
      axios
        .post(url2, body)
        .then((res) => {
          setemail(res.data.data[0].emailId)
          setfirstname(res.data.data[0].firstName)
          setlastname(res.data.data[0].lastName)
          setdob(res.data.data[0].dateOfBirth)
          setstatus(res.data.data[0].status)
          setSelectedHcp(res.data.data[0].hcpOffice)
          setrole(res.data.data[0].role)
        })
    }
  }, [])


  const [isFirstnameValid, setIsFirstnameValid] = useState(true);

  const validateFirstname = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z]+$/;
    return lettersOnlyRegex.test(value);
  };

  const handleFirstnameChange = (e) => {
    const newFirstname = e.target.value;
    setfirstname(newFirstname);
    setIsFirstnameValid(validateFirstname(newFirstname));
  };

  const [isDobValid, setIsDobValid] = useState(false);
  const [isLastnameValid, setIsLastnameValid] = useState(true);
  const [isstatusValid, setIsstatusValid] = useState(true);
  const [ishospValid, setIshospValid] = useState(true);
  const [isroleValid, setIsroleValid] = useState(true);

  const validateFirstname1 = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z]+$/;
    return lettersOnlyRegex.test(value);
  };

  const handleLastnameChange = (e) => {
    const newLastname = e.target.value;
    setlastname(newLastname);
    setIsLastnameValid(validateFirstname1(newLastname));
  };


  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const validatestatus = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z]+$/;
    return lettersOnlyRegex.test(value);
  };
  const validatehosp = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z ]+$/;
    return lettersOnlyRegex.test(value);
  };

  const validaterole = (value) => {
    const lettersOnlyRegex = /[ A-Za-z@ ]/i;
    return lettersOnlyRegex.test(value);
  };

  const handleChange4 = (event) => {
    setSelectedHcp(event.target.value);
  };
  useEffect(() => {
    hpName()
  }, [hcp])
  const hpName = async () => {
    if (my_list[4] == "superadmin") {

      // useEffect(() => {
      const url = `${BaseUrl}/hospitals/all-names`;

      await axios.get(url).then((res) => {
        sethcp(res.data.hospitalNames);
      });
      // }, []);
    }
    else {
      const url6 = `${BaseUrl}/hospitals/last-inserted-hospital-name`;
      await axios
        .get(url6)
        .then((res,) => {
          setSelectedHcp(res.data.lastInsertedHospitalName
          )
        })
    }
  }
  // useEffect(() => {
  //   const hpName = async () => {
  //     if (my_list[4] === "superadmin") {
  //       const url = `${BaseUrl}/hospitals/all-names`;

  //       try {
  //         const res = await axios.get(url);
  //         sethcp(res.data.hospitalNames);
  //       } catch (error) {
  //         // Handle error
  //         console.error('Error fetching hospital names:', error);
  //       }
  //     } else {
  //       const url6 = `${BaseUrl}/hospitals/last-inserted-hospital-name`;
  //       try {
  //         const res = await axios.get(url6);
  //         setSelectedHcp(res.data.lastInsertedHospitalName);
  //       } catch (error) {
  //         // Handle error
  //         console.error('Error fetching last inserted hospital name:', error);
  //       }
  //     }
  //   };

  //   hpName(); // Call the function directly inside the useEffect

  // }, []);

  const handleReset = () => {
    setemail('');
    setfirstname('');
    setlastname('');
    setdob(null);
    sethcp([]);
    setstatus('');
    setrole('');
    setSelectedHcp('');
    setSelectedlist('');
    setlast('');
    setIsEmailValid(true);
    setIsFirstnameValid(true);
    setIsLastnameValid(true);
    setIsroleValid(true)
    setIshospValid(true);
    setIsstatusValid(true);
    setDobError(false)

  };
  useEffect(() => {
    console.log("dob:", dob);
  }, [dob]);


  const validateDate = (date) => {
    console.log(date !== null && date !== undefined, "date !== null && date !== undefined;")
    // Assuming date is valid if it's not null or undefined
    return date !== null && date !== undefined;
  };

  function isDateValid(dateStr) {
    const validdate = !isNaN(new Date(dateStr))
    console.log(!isNaN(new Date(dateStr)), "!isNaN(new Date(dateStr))")
    const setBoolean = validdate === true ? false : true
    return setBoolean;
  }

  const onChangeStartDate = (date) => {
    // Update the state (dob) with the selected date
    // setdob(date);

    // if (!dateIsValid(date)) {
    //   setDobError(true);
    // } else {
    //   setDobError(false);
    // }

    // setDobError(!validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));

    // setDobError(validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));
    console.log('Selected Date:', date);

    if (date) {
      setDobError(isDateValid(date));
      setdob(date);
    } else {
      // Handle the case when date is null or undefined
      setDobError(false); // or any other appropriate handling
      setdob(null);
    }


  };



  const dateIsValid = (date) => {
    // Your validation logic here
    return date !== null; // Example: Require a non-null date
  };



  const handleInputChange = (inputValue, reason) => {
    // Prevent any changes to the input value
    return inputValue;
  };
  // const success = () => {
  //   message.open({
  //     type: "success",
  //     content: "SuccessFully Updated",
  //   });
  //   //     messageApi.success("Logged in successfully")
  // };
  // const error = () => {
  //   message.open({
  //     type: "error",
  //     content: "Failed to Updated",
  //   });
  //   // messageApi.error("Invalid username or otp")
  // };

  // const handleChange = (event) => {
  //   setstatus(event.target.value);
  // };

  // const handleChange1 = (event) => {
  //   sethcp(event.target.value);
  // };

  // const handleChange2 = (event) => {
  //   setrole(event.target.value);
  // };

  // const { isAuthenticated } = useAuth();
  // const navigate = useNavigate();
  // // useEffect(() => {
  // if (!isAuthenticated()) {

  //   navigate('/');
  // }
  // // }, [isAuthenticated, navigate]);

  // const [email, setemail] = useState('');
  // const [firstname, setfirstname] = useState('');
  // const [lastname, setlastname] = useState('');
  // // const [dob, setdob] = useState(new Date());
  // const [dob, setdob] = useState(null);
  // const [hcp, sethcp] = useState([]);
  // const [status, setstatus] = useState('');
  // const [role, setrole] = useState('');
  // const [hcpOffice, sethcpOffice] = useState('');
  // const [selectedlist, setSelectedlist] = useState('')
  // const [last, setlast] = useState('');
  // // const [isEmailValid, setIsEmailValid] = useState(true);
  // // const [roles, setroles] = useState('');
  // const [dobError, setDobError] = useState(false);
  // const [isDobValid, setIsDobValid] = useState(false);
  // const [selectedHcp, setSelectedHcp] = useState('');

  // const Navigate = useNavigate();
  // console.log()

  // const validateForm = () => {
  //   let isValid = true;

  //   // Validate Email
  //   if (!validateEmail(email)) {
  //     setIsEmailValid(false);
  //     isValid = false;
  //   } else {
  //     setIsEmailValid(true);
  //   }

  //   // Validate First Name
  //   if (!validateFirstname(firstname)) {
  //     setIsFirstnameValid(false);
  //     isValid = false;
  //   } else {
  //     setIsFirstnameValid(true);
  //   }

  //   // Validate Last Name
  //   if (!validateFirstname1(lastname)) {
  //     setIsLastnameValid(false);
  //     isValid = false;
  //   } else {
  //     setIsLastnameValid(true);
  //   }
  //   if (!validatestatus(status)) {
  //     setIsstatusValid(false);
  //     isValid = false;
  //   } else {
  //     setIsstatusValid(true);
  //   }
  //   if (!validatehosp(hcpOffice)) {
  //     setIshospValid(false);
  //     isValid = false;
  //   } else {
  //     setIshospValid(true);
  //   }

  //   if (!validaterole(role)) {
  //     setIsroleValid(false);
  //     isValid = false;
  //   } else {
  //     setIsroleValid(true);
  //   }

  //   if (!dob) {
  //     console.log("qwertyu")
  //     setDobError(true)
  //     setIsDobValid(false);
  //     isValid = false;
  //   } else {
  //     setDobError(true);
  //   }


  //   return isValid;
  // };
  // // useEffect(()=> {

  // const managnment = window.location.href
  // const my_list = managnment.split("/")
  // const role1 = my_list[4]
  // const handleSubmit = () => {
  //   let masterId = my_list[5]
  //   if (validateForm()) {
  //     if (masterId) {
  //       const updateUrl = `${BaseUrl}/add-users/update`;
  //       const updatedUserData = {
  //         masterId: my_list[5],
  //         emailId: email,
  //         firstName: firstname,
  //         lastName: lastname,
  //         dateOfBirth: { dob },
  //         status: status,
  //         hcpOffice: hcpOffice,
  //         hcpId: "",
  //         role: role
  //       };

  //       axios.post(updateUrl, updatedUserData)
  //         .then((res) => {
  //           if (res.data.status === "Ok") {
  //             Navigate("/tablenew?/" + my_list[4] + "")
  //             success();
  //           }

  //         })

  //         .catch((error) => {
  //           error();

  //         });
  //     } else {
  //       const createUrl = `${BaseUrl}/add-users/create`;
  //       const newUserData = {
  //         emailId: email,
  //         firstName: firstname,
  //         lastName: lastname,
  //         dateOfBirth: { dob },
  //         status: status,
  //         hcpOffice: hcpOffice,
  //         hcpId: "",
  //         role: role
  //       };
  //       axios.post(createUrl, newUserData)
  //         .then((res) => {
  //           if (res.data.status === "Ok") {
  //             Navigate("/tablenew?/" + my_list[4] + "");
  //             success();
  //           } else {
  //             error();
  //           }

  //         })

  //       const url8 = `${BaseUrl}/email-service`;

  //       const body = {
  //         "email": email,
  //       };

  //       axios
  //         .post(url8, body)
  //         .then((res) => {
  //         })
  //         .catch((error) => {
  //           error();
  //         });
  //     }
  //   }
  // };
  // useEffect(() => {
  //   const masterId = my_list[5]
  //   if (masterId) {
  //     let url2 = `${BaseUrl}/add-users/findOne`
  //     let body = {
  //       // "Id": my_list[0],
  //       "masterId": my_list[5]
  //     }
  //     axios
  //       .post(url2, body)
  //       .then((res) => {
  //         setemail(res.data.data[0].emailId)
  //         setfirstname(res.data.data[0].firstName)
  //         setlastname(res.data.data[0].lastName)
  //         setdob(res.data.data[0].dateOfBirth)
  //         setstatus(res.data.data[0].status)
  //         sethcpOffice(res.data.data[0].hcpOffice)
  //         setrole(res.data.data[0].role)
  //       })
  //   }
  // }, [])
  // console.log(dob, "datttt")



  // const [isFirstnameValid, setIsFirstnameValid] = useState(true);

  // const validateFirstname = (value) => {
  //   const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
  //   return lettersOnlyRegex.test(value);
  // };

  // const handleFirstnameChange = (e) => {
  //   const newFirstname = e.target.value;
  //   setfirstname(newFirstname);
  //   setIsFirstnameValid(validateFirstname(newFirstname));
  // };

  // const [isLastnameValid, setIsLastnameValid] = useState(true);
  // const [isstatusValid, setIsstatusValid] = useState(true);
  // const [ishospValid, setIshospValid] = useState(true);
  // const [isroleValid, setIsroleValid] = useState(true);

  // const validateFirstname1 = (value) => {
  //   const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
  //   return lettersOnlyRegex.test(value);
  // };
  // const validatestatus = (value) => {
  //   const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
  //   return lettersOnlyRegex.test(value);
  // };
  // const validatehosp = (value) => {
  //   const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
  //   return lettersOnlyRegex.test(value);
  // };

  // const validaterole = (value) => {
  //   const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
  //   return lettersOnlyRegex.test(value);
  // };

  // const [isEmailValid, setIsEmailValid] = useState(true);

  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // const handleEmailChange = (e) => {
  //   const newEmail = e.target.value;
  //   setemail(newEmail);
  //   setIsEmailValid(validateEmail(newEmail));
  // };

  // const handleLastnameChange = (e) => {
  //   const newLastname = e.target.value;
  //   setlastname(newLastname);
  //   setIsLastnameValid(validateFirstname1(newLastname));
  // };

  // const handleChange4 = (event) => {
  //   sethcpOffice(event.target.value);
  // };
  // useEffect(() => {
  //   hcpName()
  // }, [])
  // const hcpName = async () => {
  //   if (my_list[4] == "superadmin") {

  //     // useEffect(() => {
  //     const url = `${BaseUrl}/hospitals/all-names`;

  //     await axios.get(url).then((res) => {
  //       sethcp(res.data.hospitalNames);
  //     });
  //     // }, []);
  //   }
  //   else {
  //     const url6 = `${BaseUrl}/hospitals/last-inserted-hospital-name`;
  //     await axios
  //       .get(url6)
  //       .then((res,) => {
  //         sethcpOffice(res.data.lastInsertedHospitalName
  //         )
  //       })
  //   }
  // }


  // const handleReset = () => {
  //   setemail('');
  //   setfirstname('');
  //   setlastname('');
  //   setdob(null);
  //   sethcp([]);
  //   setstatus('');
  //   setrole('');
  //   sethcpOffice('');
  //   setSelectedlist('');
  //   setlast('');
  //   setIsEmailValid(true);
  //   setIsFirstnameValid(true);
  //   setIsLastnameValid(true);
  // };
  // useEffect(() => {
  //   console.log("dob:", dob);
  // }, [dob]);

  // const onChangeStartDate = (date) => {
  //   // Update the state (dob) with the selected date
  //   setdob(date);
  // };



  return (
    <div className='picontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='minihead'>
      <div style={{ marginTop: "0.4%" }}>
  <WestOutlined
    style={{ color: "#1DA0E1", marginTop: "1%" }}
    onClick={(e) => {
      window.location.href = "/tablenew?/" + my_list[4] + "";
      // window.location.reload();
    }}
  />
</div>
        <div className='miniheadtitle'>
          Edit User
        </div>
      </div>
      <div>
        <div style={containerStyle} >
          <div style={{ paddingTop: "20px", marginLeft: "8%" }}><b>Personal Information</b></div>
          {/* <div className="containerlogin"
            style={containerStyle} > */}
          <Grid className='logingrid' >
            <Stack direction="row" spacing={1} style={stackStyleTop} className='top'  >

              <TextField
                sx={{ width: '500px' }}
                label="Email ID"
                id="email"
                variant="outlined"
                value={email}
                error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                placeholder="Enter your Email"
                onChange={handleEmailChange}
                defaultValue="Small"
                size="small"
                InputLabelProps={{
                  style: {
                    color: "gray", // Set the color based on the validation status
                  },
                }}
              />

              <TextField
                sx={{ width: '500px' }}
                label="First Name"
                id="npi"
                variant="outlined"
                value={firstname}
                error={!isFirstnameValid}
                // helperText={!isFirstnameValid ? 'required feild' : ''}
                placeholder="Enter your First Name"
                onChange={handleFirstnameChange}
                defaultValue="Small"
                size="small"
                InputLabelProps={{
                  style: {
                    color: "gray", // Set the color based on the validation status
                  },
                }}
              />
            </Stack>


            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '500px' }}
                label="Last Name"
                id="npi"
                variant="outlined"
                value={lastname}
                error={!isLastnameValid}
                // helperText={!isLastnameValid ? 'Only letters are allowed' : ''}
                placeholder="Enter your Last Name"
                onChange={handleLastnameChange}
                defaultValue="Small"
                size="small"
                InputLabelProps={{
                  style: {
                    color: "gray", // Set the color based on the validation status
                  },
                }}
              />

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DemoContainer components={["DatePicker"]}>
    <div >
      <DatePicker
        textFieldStyle={{ width: '500px' }}
        disableFuture
        inputFormat="MM/DD/YYYY"
        className='date-picker-width'
        // error={!isDobValid}
        slotProps={{ textField: { size: 'small', fullWidth: true } }}
        label="DOB"
        rules={{ required: 'Please select a date' }}
        value={dob ? moment(dob) : null}
        onChange={onChangeStartDate}
      // error={dobError} // Set error prop based on dobError state
      // status={dobError ? "error" : ""}

      />
    </div>
  </DemoContainer>
</LocalizationProvider> */}

              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                // allowClear={false}
                format={"MM/DD/YYYY"}
                placeholder='Date'
                variant="outlined"
                // value={dayjs(dob)}
                value={dob ? dayjs(dob) : null}
                onChange={onChangeStartDate}
                error={dobError} // Set error prop based on dobError state
                status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                rules={[
                  {
                    required: true,
                    message: 'Please select a date!',
                  }
                ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              // color={dobError ? '#d32f2f' : 'black'}
              />


              {/* <DatePicker
  textFieldStyle={{ width: '500px' }}
  disableFuture
  inputFormat="MM/DD/YYYY"
  className='date-picker-width'
  error={true}
  slotProps={{ textField: { size: 'small', fullWidth: true } }}
  label="DOB"
  onChange={(newValue) => setdob(newValue.format("MM/DD/YYYY"))}
  renderInput={(props) => (
    <TextField
      {...props}
      helperText={isDobValid ? "" : "Please select a date"}
      error={true}
    />
  )}
/> */}
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >
              <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  id="option1"
                  style={inputStyle}
                  sx={{ width: '100%' }}
                  value={status}
                  error={!isstatusValid}
                  helperText={!isstatusValid ? 'Please select value ' : ''}
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="InActive">Inactive</MenuItem>
                </Select>
              </FormControl>

              {my_list[4] === "HCP%20Admin"  ? (
                <>

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="outlined">
                    <InputLabel>HCP Hospital</InputLabel>
                    <Select
                      id="option1"
                      style={inputStyle}
                      sx={{ width: '100%' }}
                      label="HCP Office"
                      onChange={handleChange4}
                      value={selectedHcp}
                      error={!ishospValid}
                      helperText={!ishospValid ? 'Please select value ' : ''}

                    >
                      {my_list[4] === "HCP%20Admin" ? (
                        <MenuItem value={selectedHcp}>
                          {selectedHcp}
                        </MenuItem>
                      ) : (
                        hcp?.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>HCP Hospital</InputLabel>
                    <Select
                      id="option1"
                      style={inputStyle}
                      sx={{ width: '100%' }}
                      label="HCP Office"
                      onChange={handleChange4}
                      value={selectedHcp}
                      error={!ishospValid}
                      helperText={!ishospValid ? 'Please select value ' : ''}
                    >
                      {hcp?.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          {selectedHcp}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Stack>

            {my_list[4] == "HCP%20Admin" ? (<>
              <Stack direction="row" style={stackStyledrop} className='top'  >

                <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined" style={inputStyle2}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    id="option1"
                    sx={{ width: '100%', }}
                    label="Role"
                    onChange={handleChange2}
                    value={role}
                    error={!isroleValid}
                    helperText={!isroleValid ? 'Please select value ' : ''}
                  >
                    <MenuItem value="HCP Executive">HCP Executive</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </>)
              :
              (<><Stack direction="row" style={stackStyledrop} className='top'  >

                <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined" style={inputStyle2}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    id="option1"
                    sx={{ width: '100%', }}
                    label="Role"
                    onChange={handleChange2}
                    value={role}
                    error={!isroleValid}
                    helperText={!isroleValid ? 'Please select value ' : ''}

                  >
                    <MenuItem value="HCP Admin">HCP Admin</MenuItem>
                    <MenuItem value="HCP Executive">HCP Executive</MenuItem>
                    <MenuItem value="Support Executive - Creator">Support Executive - Creator</MenuItem>
                    <MenuItem value="Support Executive - Reviewer">Support Executive - Reviewer</MenuItem>
                    <MenuItem value="Support Executive - Approver">Support Executive - Approver</MenuItem>
                    <MenuItem value="QA">QA</MenuItem>
                    <MenuItem value="Dev-ops Engineer">Dev-ops Engineer</MenuItem>
                  </Select>
                </FormControl>


              </Stack></>)
            }
            <Stack direction="row" spacing={3} style={{ marginTop: "3%", justifyContent: "center", paddingBottom: "1%" }}>
              <Grid>
                <button className='clear' onClick={handleReset}>Reset</button>
              </Grid>
              <Grid>
                <button className='add' onClick={(e) => Navigate("/tablenew?/" + my_list[4] + "")}>Cancel</button>
              </Grid>
              <Grid>
                <button className="submit" onClick={handleSubmit}>Update</button>
              </Grid>
            </Stack>
          </Grid>
          <div>
          </div>
          {/* </div> */}
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditAddNewUsers