import React, { useEffect, useState,useRef } from 'react';
import NewHeader from '../../NewHeader';
import "./gatewayconfig.css";
import Sidebar1 from '../../Sidebar1';
import { Col, Row } from 'antd';
import Pagination from '@mui/material/Pagination';
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { BaseUrl } from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'



const Gatewayconfig = () => {
  // const Navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  // useEffect(() => {
  if (!isAuthenticated()) {

    navigate('/');
  }

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);
  // }, [isAuthenticated, navigate]);

  const managnment = window.location.href;
  const my_list = managnment.split("/");
  const [data, setdata] = useState([]);
  const [status, setstatus] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingData, setEditingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('one');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([])
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };

  useEffect(() => {
    getData()

  }, [])

  const handleEdit = (row) => {
    setEditingData(row);
    const editUrl = `/adduser/${my_list[4]}/${row.masterId}`;
    navigate(editUrl);

  }



  const getData = () => {
    const url8 = `${BaseUrl}/interface-vendor/findAll`;
    axios
      .get(url8)
      .then((res) => {
        setdata(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/add-users/delete`;
    const requestBody = {
      masterId: id
    };

    axios.post(deleteUrl, requestBody)
      .then((response) => {
        // const updatedTableData = data.filter((e) => e.masterId !== id);
        // setdata(updatedTableData);
        // setFilteredData(updatedTableData);
        getData()
      })
      .catch(e => {
        console.error("Error deleting:", e);
      });
  };


  const handlenavigate = () => {
    navigate("/addUsers?/" + my_list[4]);
  };


  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.vendorName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };

  return (
    <div className='gatewayconfigpicontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='gatewayconfigminiheadtable'>
        <Col span={6}>
          <div className='gatewayconfigminiheadtitle'>
            Gateway Admin Configuration
          </div>
        </Col>

        {/* <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="#1da0e1"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab style={{ color: "#1da0e1" }} value="one" label="Gateway Mapping" />
              <Tab style={{ color: "#1da0e1" }} value="two" label="Gateway Insurance Mapping" />
            </Tabs>
          </Box> */}
        <div className='gatewayconfigtopnewdash'>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="#1da0e1"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            {/* <Tab style={{ color: "#1da0e1" }} value="one" label="Gateway Mapping" />
            <Tab style={{ color: "#1da0e1" }} value="two" label="Gateway Insurance Mapping" /> */}
            <Tab
              sx={{
                color: value === 'one' ? '#1da0e1' : 'gray'
              }}
              value="one"
              label="Gateway Mapping"
            />
            <Tab
              sx={{
                color: value === 'two' ? '#1da0e1' : 'gray'
              }}
              value="two"
              label="Gateway Insurance Mapping"
            />
          </Tabs>
        </div>


        <Row className='gatewayconfigminiheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}
              placeholder='Search For InterFace Name'
              style={{
                margin: '0 auto',
                width: "100%",
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px",
                marginRight: "500px"
              }}
            />
          </Col>
          {/* <Col>
             <div  className="filterdiv" >
              <div  className='filtertitle'>Filter</div>
              <FilterAltOffOutlined  className='filtericon'/>
             </div>
             </Col> */}
        </Row>
      </div>
      {value === "one" ? (<>
        <div>
          <TableContainer className="gatewayconfigtablecont" style={{ width: "90%" }} component={Paper}>
            {loading ? (<Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
            </Box>) :
              (<>
                <Table>
                  <TableHead className="gatewayconfigtablerowm">
                    <TableRow>
                      <TableCell><b>S.No</b></TableCell>
                      <TableCell><b>InterFace Name</b></TableCell>
                      <TableCell><b>Instance</b></TableCell>
                      <TableCell><b>Status</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {row.vendorName}
                          </TableCell>
                          <TableCell>{row.instance}</TableCell>
                          <TableCell >
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={row.status || ''} // Set the default value based on row.status
                              >
                                <FormControlLabel
                                  value="Active"
                                  control={<Radio color="success" />}
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="In Active"
                                  control={<Radio color="error" />}
                                  label="In Active"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>))) : (
                      data.slice(startIndex, endIndex).map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {row.vendorName}
                          </TableCell>
                          <TableCell>{row.instance}</TableCell>
                          <TableCell>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={row.status || ''}
                              >
                                <FormControlLabel
                                  value="Active"
                                  control={<Radio color="success" />}
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="In Active"
                                  control={<Radio color="error" />}
                                  label="In Active"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </>)}
          </TableContainer>
        </div>
      </>)
        :
        ""
      }
      {value === "two" ? (<>
        <div>
          <TableContainer className="gatewayconfigtablecont" style={{ width: "90%" }} component={Paper}>
            {loading ? (<Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
            </Box>) :
              (<>
                <Table>
                  <TableHead className="gatewayconfigtablerowm">
                    <TableRow>
                      <TableCell><b>S.No</b></TableCell>
                      <TableCell><b>InsuranceName</b></TableCell>
                      <TableCell><b>InterfaceName</b></TableCell>

                      <TableCell><b>Status</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.slice(startIndex - 1, endIndex).map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{startIndex + index}</TableCell>
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell>
                            {row.vendorName}
                          </TableCell>
                          <TableCell>{row.instance}</TableCell>
                          <TableCell >
                            <FormControl>

                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={row.status || ''} // Set the default value based on row.status
                              >
                                <FormControlLabel
                                  value="Active"
                                  control={<Radio color="success" />}
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="In Active"
                                  control={<Radio color="error" />}
                                  label="In Active"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>))) : (
                      data.slice(startIndex - 1, endIndex).map((row, index) => (
                        <TableRow key={row.id}>
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell>{startIndex + index}</TableCell>
                          <TableCell>
                            {row.vendorName}
                          </TableCell>
                          <TableCell>{row.instance}</TableCell>
                          <TableCell>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={row.status || ''} // Set the default value based on row.status
                              >
                                <FormControlLabel
                                  value="Active"
                                  control={<Radio color="success" />}
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="In Active"
                                  control={<Radio color="error" />}
                                  label="In Active"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </>)}
          </TableContainer>
        </div>
      </>)
        :
        ""
      }

      <div className='pagination'>
        <div>
          <Pagination color="primary"
            count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
            onChange={handlePageChange}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
          />
        </div>
      </div>
    </div>
  );
}

export default Gatewayconfig;
