import React, { useEffect, useState,useRef } from 'react';
import NewHeader from '../../NewHeader';
import "./hcpconfig.css";
import Sidebar1 from '../../Sidebar1';
import { Col, Row } from 'antd';
import Pagination from '@mui/material/Pagination';
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { BaseUrl } from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'


const Hcpconfig = () => {
  // const Navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  // useEffect(() => {
  if (!isAuthenticated()) {

    navigate('/');
  }


  const managnment = window.location.href;
  const my_list = managnment.split("/");
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingData, setEditingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);

  const handleSearchChange = (value) => {
    setSearchText(value);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };

  useEffect(() => {
    getData()

  }, [])

  const handleEdit = (row) => {
    setEditingData(row);
    const editUrl = `/adduser/${my_list[4]}/${row.masterId}`;
    navigate(editUrl);

  }

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);


  const getData = () => {
    const url8 = `${BaseUrl}/coverage-details/findAll`;
    axios
      .get(url8)
      .then((res) => {
        setdata(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }


  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/add-users/delete`;
    const requestBody = {
      masterId: id
    };

    axios.post(deleteUrl, requestBody)
      .then((response) => {
        // const updatedTableData = data.filter((e) => e.masterId !== id);
        // setdata(updatedTableData);
        // setFilteredData(updatedTableData);
        getData()
      })
      .catch(e => {
        console.error("Error deleting:", e);
      });
  };



  const handlenavigate = () => {
    navigate("/addUsers?/" + my_list[4]);
  };
  const [filteredData, setFilteredData] = useState([])

  const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.insuranceName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };

  const [selectedValue, setSelectedValue] = useState(null); // Default value

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };



  return (
    <div className='hcpconfigpicontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='hcpconfigminiheadtable'>
        <Col span={6}>
          <div className='hcpconfigminiheadtitle'>
            Hcp Admin Configuration
          </div>
        </Col>
        <Row className='hcpconfigminiheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}
              placeholder='Search For Insurance Name'

              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>
          {/* <Col>
             <div  className="filterdiv" >
              <div  className='filtertitle'>Filter</div>
              <FilterAltOffOutlined  className='filtericon'/>
             </div>
             </Col> */}
        </Row>
      </div>

      <div>
        <TableContainer className="hcpconfigtablecont" style={{ width: "90%" }} component={Paper}>
          {loading ? (<Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
          </Box>) :
            (<>
              <Table>
                <TableHead className="hcpconfigtablerowm">
                  <TableRow>
                    <TableCell><b>S.No</b></TableCell>
                    <TableCell><b>InsuranceName</b></TableCell>
                    <TableCell><b>State</b></TableCell>
                    <TableCell><b>LOB</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id}>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>
                          {row.insuranceName}
                        </TableCell>
                        <TableCell>{row.State}</TableCell>
                        <TableCell>
                          <RadioGroup
                            size="small"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onClick={handleChange}
                          // Set the default value based on row.status
                          >
                            <FormControlLabel
                              value="Medicare Plans"
                              control={<Radio color="success" />}
                              label="Medicare Plans"

                            />
                            <FormControlLabel
                              value="Medicaid Plans"
                              control={<Radio color="success" />}
                              label="Medicaid Plans"
                            />
                            <FormControlLabel
                              value="Market Plans"
                              control={<Radio color="success" />}
                              label="Market Plans"
                            />

                          </RadioGroup>
                        </TableCell>
                      </TableRow>))) : (
                    data.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id}>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>
                          {row.insuranceName}
                        </TableCell>
                        <TableCell>{row.State}</TableCell>

                        <TableCell>
                          <RadioGroup style={{ display: "flex", justifyContent: "center" }}
                            size="small"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Medicare Plans"
                              control={<Radio color="success" />}
                              label="Medicare Plans"
                              onClick={handleChange}
                            />
                            <FormControlLabel
                              value="Medicaid Plans"
                              control={<Radio color="success" />}
                              label="Medicaid Plans"
                            />
                            <FormControlLabel
                              value="Market Plans"
                              control={<Radio color="success" />}
                              label="Market Plans"
                            />
                          </RadioGroup>
                        </TableCell>
                      </TableRow>
                    )))}
                </TableBody>
              </Table>
            </>)}
        </TableContainer>
      </div>

      <div className='pagination'>
        <div>
          <Pagination color="primary"
            count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
            onChange={handlePageChange}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
          />
        </div>
      </div>
    </div>
  );
}

export default Hcpconfig;
