// import React from 'react'
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import "./addplan.css"
// import FlagIcon from '@mui/icons-material/Flag';
import { Col, Row, Badge } from 'antd'
import Pagination from '@mui/material/Pagination';
import SearchBar from "material-ui-search-bar";
import { AccountBoxOutlined, MailOutline, MailOutlineOutlined, NotificationAddOutlined, Notifications, Settings } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
import { AccountCircleOutlined, Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import { useBadge } from '../Badgecontext';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Popover, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'


const AddPlan = () => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate()
  // const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }
  // }, [isAuthenticated, navigate]);

  const [data, setdata] = useState([]);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roles, setrole] = useState("");
  const { badgeCount } = useBadge();

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1); // Subtract 1 from newPage to match zero-based indexing
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    navigate("/")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);

  const open = Boolean(anchorEl);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handlenavigate = () => {
    navigate("/approver?/" + my_list[4] + "")
  }
  const handlenavigatelog = () => {
    navigate("/main1?/" + my_list[4] + "")
  }
  const handleDeny = (id) => {
    const updatedData = data.filter((row) => row.id !== id);
    setdata(updatedData);
  };

  const managnment = window.location.href

  const my_list = managnment.split("/")

  useEffect(() => {
    const managnment = window.location.href

    const my_list = managnment.split("/")
    const role = my_list[4]
    setrole(role)
  }, []);

  const tableUrl = `${BaseUrl}/dev-approver/findAll`;

  axios.get(tableUrl)
    .then((res) => {
      setdata(res.data.data);
    })

  return (
    <div className='addplanpicontainer'>
      <div className="addplanHcontainer">
        <Row >
          <Col span={5} className='leftPattCol' >
            <div className="leftContain">
              {/* <h1 >MEDICAL EBV</h1>
                     <p>Patient support program</p> */}
              <div>
                <img className='headerlog' src='logoipsum-297 (2) 1.png' />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <h2 className='med' >Electonic Benefit verification</h2>
            </div></Col>
          <Col offset={6} span={7} className='rightPattCol'>
            <div className='RightPatt'>
              <Row className='rightPattRow'>
                <Col span={4}>
                  <div className='plu' >
                    <Settings className='headerIc' style={{ fontSize: "25px" }} />
                    {/* <p >New</p> */}
                  </div >
                </Col>
                <Col span={4}>
                  <div className='plu'  >
                    <MailOutline className='headerIc' style={{ fontSize: "25px" }} />
                    {/* <p >History</p> */}
                  </div>
                </Col>
                {roles === "Support%20Executive%20-%20Reviewer" ? (<>
                  <Col span={4}>
                    <div className='plu' onClick={handlenavigate}>
                      <Badge count={badgeCount}>
                        <Notifications className='headerIc' style={{ fontSize: '25px', color: "#1DA0E1" }} />
                      </Badge>
                    </div>
                  </Col>
                </>)
                  :
                  null}
                <Col offset={1} span={8}>
                  <div>
                    <IconButton className='admins' onClick={handleClick}>
                      <AccountCircleOutlinedIcon className='headerIcon' style={{ fontSize: '25px', color: ' #1DA0E1' }} />
                      <div className='supersdm' style={{ marginTop: '4%', marginLeft: '4%' }}>
                        Support Reviewer
                      </div>
                    </IconButton>

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        {/* Content of the dropdown */}
                        <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                          Logout <ExitToAppIcon style={{ marginLeft: '4px', color: '#1DA0E1' }} />
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Sidebar1 />

      <div className='miniheadtable'>
        <div className='miniheadtitle'>
          <Col>
            <b>
              Add Plan/Change
            </b>
          </Col>
        </div>
        <Row className='miniheadright'>
          <Col>
            <SearchBar
              onChange={() => console.log('onChange')}
              onRequestSearch={() => console.log('onRequestSearch')}
              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>


          <Col>
            <div className="addplanfilterdiv" >
              <div className='addplanfiltertitle'>Filter</div>
              <FilterAltOffOutlined className='filtericon' />
            </div>
          </Col>

          <Col className='addplanaddnewusecol'>
            <div className='addplanaddnewusecoldiv' onClick={handlenavigate}>
              Add Plan/Change
            </div>
          </Col>
        </Row>
      </div>


      <div>
        <TableContainer className="addplantablecont" style={{ width: "90%" }} component={Paper}>
          <Table>
            <TableHead className="addplantablerowm" >
              <TableRow >
                <TableCell><b>S.No</b></TableCell>
                {/* <TableCell>S.No</TableCell> */}
                <TableCell><b>Plan Name</b></TableCell>
                {/* <TableCell>Hcp Office</TableCell> */}
                <TableCell><b>Insurance Name</b></TableCell>
                {/* <TableCell><b>Status</b></TableCell> */}
                <TableCell><b>State</b></TableCell>
                <TableCell><b>Action</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(startIndex, endIndex).map((row, index) => (
                <TableRow key={row.id} >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.planName}</TableCell>
                  <TableCell>{row.insuranceName}</TableCell>
                  {/* <TableCell>{row.Response}</TableCell> */}
                  <TableCell>{row.state}</TableCell>
                  <TableCell style={{ gap: "2%" }}>
                    <div style={{ gap: "2%", display: "flex" }}>
                      <button className='approve' >
                        <div onClick={(e) => navigate("/approver?/" + my_list[4] + "/" + row.masterId)}>View</div>
                      </button>
                      {/* <button className='deny'>
                  <div onClick={() => handleDeny(row.id)}>Deny</div>
                  </button> */}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='addplanpagination'>
        <div style={{ marginLeft: "45%", justifyContent: "center", textAlign: "center", paddingTop: "3%", paddingBottom: "1%" }}>
          <Pagination color="primary" style={{ backgroundColor: "white", width: "180px", borderRadius: "10px" }} count={Math.ceil(data.length / rowsPerPage)} onChange={handlePageChange} page={page + 1} />
        </div>
      </div>
    </div>
  )
}

export default AddPlan;
