// import React from 'react'
import NewHeader from '../../NewHeader'
import "./Supportcreater.css"
import Sidebar1 from '../../Sidebar1'
// import FlagIcon from '@mui/icons-material/Flag';
import Pagination from '@mui/material/Pagination';
import { Col, Row } from 'antd'
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'



const CreateTable = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate()
 
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);
  const [data, setdata] = useState([]);
  const [ids, setid] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([])
  // const navigate = useNavigate()

  const managnment = window.location.href
  const my_list = managnment.split("/")
  const [page, setPage] = useState(0);
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchChange = (value) => {
    setSearchText(value);
  };
  const [searchText, setSearchText] = useState('');
  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1); // Subtract 1 from newPage to match zero-based indexing
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const tableUrl = `${BaseUrl}/coverage-details/findAll`;
  axios.get(tableUrl)
    .then((res) => {
      setLoading(false);
      setdata(res.data.data);
      setid(res.data.data.masterId)
    })
  const handlenavigate = () => {
    navigate("/deny?/" + my_list[4] + "")
  }

  const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.insuranceName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };

  return (
    <div className='supportcreaterpicontainer' style={{ overflow: "scroll" }}>
      <NewHeader />
      <Sidebar1 />
      <div className='supportcreaterminiheadtable'>
        <div className='supportcreaterminiheadtitle'>
          <Col>
            <b>
              Add Plan/Change
            </b>
          </Col>
        </div>
        <Row className='supportcreaterminiheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}

              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>
          <Col>
            <div className="supportcreaterfilterdiv" >
              <div className='supportcreaterfiltertitle'>Filter</div>
              <FilterAltOffOutlined className='supportcreaterfiltericon' />
            </div>
          </Col>

          <Col className='supportcreateraddnewusecol'>
            <div className='supportcreateraddnewusecoldiv' onClick={handlenavigate} >
              Deny Records
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <TableContainer className="supportcreatertablecont" style={{ width: "90%" }} component={Paper}>
          {loading ? (<Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
          </Box>) :
            (<>
              <Table>
                <TableHead className="supportcreatertablerowm" >
                  <TableRow >
                    <TableCell><b>S.No</b></TableCell>
                    {/* <TableCell>S.No</TableCell> */}
                    <TableCell><b>Plan Name</b></TableCell>
                    {/* <TableCell>Hcp Office</TableCell> */}
                    <TableCell><b>Insurance Name</b></TableCell>
                    <TableCell><b>Status</b></TableCell>
                    <TableCell><b>State</b></TableCell>
                    <TableCell><b>Action</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData.map((row, index) => (
                      <TableRow key={row.id} >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.PlanName}</TableCell>
                        <TableCell>{row.insuranceName}</TableCell>
                        <TableCell>{row.Response}</TableCell>
                        <TableCell>{row.State}</TableCell>
                        <TableCell>
                          <IconButton aria-label="View">
                            <Visibility onClick={(e) => navigate("/ebvinfo?/" + my_list[4] + "/" + row.masterId)} />
                          </IconButton>
                          <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={(e) => navigate("/createmain?/" + my_list[4] + "/" + row.masterId)}>
                            <Edit />
                          </IconButton>
                          <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>))) : (
                    data.slice(startIndex, endIndex).map((row, index) => (
                      <TableRow key={row.id} >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.PlanName}</TableCell>
                        <TableCell>{row.insuranceName}</TableCell>
                        <TableCell>{row.Response}</TableCell>
                        <TableCell>{row.State}</TableCell>
                        <TableCell>
                          <IconButton aria-label="View">
                            <Visibility onClick={(e) => navigate("/ebvinfo?/" + my_list[4] + "/" + row.masterId)} />
                          </IconButton>
                          <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={(e) => navigate("/createmain?/" + my_list[4] + "/" + row.masterId)}>
                            <Edit />
                          </IconButton>
                          <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )))}
                </TableBody>
              </Table>
            </>)}
        </TableContainer>
      </div>

      <div className='supportcreaterpagination'>
        <div style={{ marginLeft: "43%", justifyContent: "center", textAlign: "center", paddingTop: "3%", paddingBottom: "1%" }}>
          <Pagination color="primary" style={{ backgroundColor: "white", width: "200px", borderRadius: "10px" }} count={Math.ceil(data.length / rowsPerPage)} onChange={handlePageChange} page={page + 1} />
        </div>
      </div>

    </div>
  )
}

export default CreateTable
