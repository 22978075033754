import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const savedToken = localStorage.getItem('expires');
  console.log(savedToken,"savedToken")
  useEffect(() => {
    // Check for saved token in local storage or cookie on page load
  
   
    if (savedToken) {
      setAccessToken(savedToken);
    }
  }, []);
 

  const login = (token) => {
    setAccessToken(token);
    
    localStorage.setItem('accessToken', token);
  };

  const logout = () => {
    setAccessToken(null);
  
    localStorage.removeItem('expires');
  };



const isAuthenticated = () => {
    if (!accessToken) {
      return false;
    }
  
    try {
   
      const decodedToken = jwtDecode(accessToken);
  
    
      const isTokenExpired = decodedToken.exp * 1000 < Date.now();

      return !isTokenExpired;
    } catch (error) {
     
      console.error("Error decoding token:", error);
  
      
      return false;
    }
  };
  

  console.log("isAuthenticated()",isAuthenticated())
  
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};