
// import React from 'react';
import "./AddNewHospital.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import { json, useNavigate, useParams } from "react-router";
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../../../config'
import { DatePicker, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas'

import { message, Upload, Modal, Input, Checkbox } from "antd";
import dayjs from 'dayjs';
import { useAuth } from '../../../Newlogin/Authprovider'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



const customStyles = {
  width: 500, // Adjust the width as needed
  height: 60, // Adjust the height as needed
  position: 'relative', // Needed for label placement
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
  pointerEvents: 'none', // To allow clicking through the label
};
const inputStyle2 = {

  width: '500px',
  // height:"50px",
  marginTop: "1%",
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};

const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
  // textAlign:'center',
  // height: '40px',
};

const containerStyle = {
  width: "90%",
  margin: 'auto',
  marginTop: "1%",
  marginLeft: "7%",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "8px",
  // height: "65vh"
};

const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  // marginTop: "2%",
  // margin:" 0 auto",
  justifyContent: "center",
  marginTop: "1%"
}

const stackStyle2 = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  marginTop: "0%",
}

const stackStyleTop = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1%",
}

const stackStyledrop = {
  display: "flex",
  margin: "auto",
  marginTop: "2%",
  marginLeft: "4%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "left",
  display: "flex",
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const Priorrequest = () => {
  const success = () => {
    message.open({
      type: "success",
      content: (
        <div>
          <p>The Prior Auth electronic Form has been sent to the HCP for verification</p>
          <p>Post HCP approval, the Prior Auth will be electronically submitted to the payer gateway!</p>
        </div>
      ),
    });
  };
  const errors = () => {
    message.open({
      type: "error",
      content: "Failed to Updated",
    });
  };
  const requiredError = () => {
    message.open({
      type: "error",
      content: "Please fill the required field",
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    
    setIsModalOpen(false);
    const create = `${BaseUrl}/prior-authorization/create`;

    const bodyprior = {
      "renderPriorAuthorization": renprovider,
      "renderFirstName": renfirst,
      "renderLastName": renlast,
      "renderNpiNumber": rennpi,
      "renderAddress": renadd,
      "renderZipcode": renzip,
      "renderState": renstate,
      "renderCity": rencity,
      "taxiId": rentaxi,
      "facilityId": renfac,
      "referPriorAuthorization": refprovider,
      "referFirstName": reffirst,
      "referLastName": reflast,
      "referNpiNumber": refnpi,
      "referAddress": refadd,
      "referZipcode": refzip,
      "referCity": refcity,
      "referState": refstate,
      "startDob": formattedDate,
      "endDob": formattedDate1,
      "firstName": subfirst,
      "lastName": sublast,
      "dob": formattedDate2,
      "memberId": submember,
      "drugName": subdrug,
      "emailId": subemail,
      "strength": substrength,
      "quantity": subquantity,
      "clinicalNotes": clinicalNotes
    };

    axios
      .post(create, bodyprior)
      .then((res) => {
        const id = res.data.data[0].masterId;
        setmaster(id);

        if (res.data.status === "Ok") {

          

          


        }
      })
      .catch((error) => {
        error();
      });

    // Delay the email service API call by 3 seconds

    const url8 = `${BaseUrl}/email-service`;
    const body = {
      // "email": "ragavaprasanna.v@valuehealthsol.com",
      "email": SenderEmail,

      "subject": "Request to verify Prior Auth submission details",
      "template": `${template}\n\n`
    };

    axios
      .post(url8, body)
      .then((res) => {
        Navigate(`/PriorTable?/HCP%20Executive`);
      success();
        // handleUploadAndSend();
        // Handle response if needed
      })
      .catch((error) => {
        // Handle error if needed
        error();
      });





  };

  const handleNpiIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue = sanitizedValue.slice(0, 10);

    setnpiId(truncatedValue);

    if (sanitizedValue.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };
  // const Navigate = useNavigate();

  const handleNumberChange = (e) => {
    const sanitizedValue1 = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue1 = sanitizedValue1.slice(0, 10);

    setnumber(truncatedValue1);

    if (sanitizedValue1.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };



  const handleChange = (event) => {
    setstatus(event.target.value);
  };
  const { TextArea } = Input;

  //   const { isAuthenticated } = useAuth();
  //   const navigate = useNavigate();
  // useEffect(() => {
  // if (!isAuthenticated()) {

  //   navigate('/');
  // }
  // const handleChange1 = (event) => {
  //   setcontact(event.target.value);
  // };
  const [isValidateNpiId, setIsValidateNpiId] = useState(true)
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [isstatusValid, setIsstatusValid] = useState(true);
  const [isHospitalValid, setIsHospitalValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStateValid, setIsStateValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isServiceTypeValid, setIsServiceTypeValid] = useState(true);
  const [trimmedDataURL, settrimmedDataURL] = useState()
  const [Signature, setSignature] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const validate = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
    return lettersOnlyRegex.test(value);
  };
  const npiIdValidate = (v) => {
    const NumberRegex = /^\d{1,10}$/;
    return NumberRegex.test(v)
  }

  const numberValidate = (value) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(value)
  }



  const sigPad = {}
  const clear = () => {
    Signature.clear()
  }
  const trim = () => {
    settrimmedDataURL(
      Signature.getTrimmedCanvas().toDataURL('image/png')

    )
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSignModalCancel = () => {
    setIsSignModalOpen(false);
  }



  const [address, setaddress] = useState('');
  const [npiId, setnpiId] = useState('');
  const [hospitalname, sethospitalname] = useState('');
  const [city, setcity] = useState('');
  const [hcp, sethcp] = useState('');
  const [zipcode, setzipcode] = useState('');
  const [role, setrole] = useState('');
  const [state, setstate] = useState('');
  const [status, setstatus] = useState('');
  const [number, setnumber] = useState('');
  const [email, setemail] = useState('');
  const [hospitaltype, sethospitaltype] = useState("");
  const [error, setError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  //   const [member, setmember] = useState('');
  //   const [membername, setmembername] = useState('');
  //   const [npinumber, setnpinumber] = useState('');
  //   const [memberaddress, setmemberaddress] = useState('');
  //   const [memberzip, setmemberzip] = useState('');
  //   const [memberstate, setmemberstate] = useState('');
  //   const [membercity, setmembercity] = useState('');
  //   const [memberall, setmemberall] = useState('');
  //   const [memberphone, setmemberphone] = useState('');
  //   const [membermed, setmembermed] = useState('');
  //   const [memberdir, setmemberdir] = useState('');
  //   const [memberstr, setmemberstr] = useState('');
  //   const [memberqu, setmemberqu] = useState('');
  //   const [memberdob, setmemberdob] = useState('');
  //   const [memberdob, setmemberdob] = useState('');

  const [renprovider, setrenprovider] = useState('');
  const [renfirst, setrenfirst] = useState('');
  const [renlast, setrenlast] = useState('');
  const [rennpi, setrennpi] = useState('');
  const [renadd, setrenadd] = useState('');
  const [renstate, setrenstate] = useState('');
  const [renzip, setrenzip] = useState('');
  const [rencity, setrencity] = useState('');
  const [rentaxi, setrentaxi] = useState('');
  const [renfac, setrenfac] = useState('');
  const [refprovider, setrefprovider] = useState('');
  const [reffirst, setreffirst] = useState('');
  const [reflast, setreflast] = useState('');
  const [refnpi, setrefnpi] = useState('');
  const [refadd, setrefadd] = useState('');
  const [refstate, setrefstate] = useState('');
  const [refzip, setrefzip] = useState('');
  const [refcity, setrefcity] = useState('');
  const [refdobstart, setrefdobstart] = useState(null);
  const [refdobend, setrefdobend] = useState(null);
  const [subfirst, setsubfirst] = useState('');
  const [sublast, setsublast] = useState('');
  const [subdob, setsubdob] = useState('');
  const [submember, setsubmember] = useState('');
  const [subdrug, setsubdrug] = useState('');
  const [subemail, setsubemail] = useState('');
  const [substrength, setstrength] = useState('');
  const [subquantity, setQuantity] = useState('');
  const [master, setmaster] = useState('');
  const [SenderEmail, setSenderEmail] = useState('');

  useEffect(() => {
    let drugname = (localStorage.getItem("drug"));
    let JsonData = (localStorage.getItem("NpiJson"));
    const decodedJSON = JSON.parse(decodeURIComponent(JsonData))
    console.log(decodedJSON, "chan")
    console.log(drug, "drug")
    setsubdrug(drugname)
    setrenprovider("")
    setrenfirst(decodedJSON.firstname)
    setrenlast(decodedJSON.lastname)
    setrennpi(decodedJSON.key)
    setrenadd("PO BOX 29130")
    setrencity(decodedJSON.city)
    setrenzip(decodedJSON.zipcode)
    setrenstate(decodedJSON.state)
    setrentaxi("")
    setrenfac("")
    setrefprovider("PER")
    setreffirst("James")
    setreflast("s")
    setrefnpi("1063764280")
    setrefadd("111 south ")
    setrefstate("AR")
    setrefzip("71903")
    setrefcity("Arkansas City")
    setrefdobstart("03/03/1990")
    setrefdobend("06/08/2000")
    setsubfirst(first)
    setsublast(last)
    setsubdob(date)
    setsubemail(decodedJSON.email)
    setstrength("200mg")
    setsubmember(subscrib)
    setQuantity("7")



  }, [])









  const Navigate = useNavigate();

  const managnment = window.location.href

  const my_list = managnment.split("/")

  let drug = (localStorage.getItem("drug"));
  let Email = (localStorage.getItem("SubEmail"));
  console.log(drug, "drug")

  let payer = (localStorage.getItem("payers"));

  console.log(payer, "Payersss")


  let first = (localStorage.getItem("firstnames"));

  console.log(first, "firstsss")


  let last = (localStorage.getItem("lastnames"));

  console.log(last, "lastsss")


  let subscrib = (localStorage.getItem("subscriberids"));

  console.log(subscrib, "subscriberids")

  let date = (localStorage.getItem("dobs"));

  console.log(date, "dob")


  useEffect(() => {

    //   setmember("Ram Yadlapalli")
    //   setmembername("48627401700")
    //   setnpinumber("1063764280")
    //   setmemberaddress("907 BARN OWL WAY")
    //   setmemberzip("08886")
    //   setmemberstate("Illinois")
    //   setmembercity("Stewartsville")
    //   setmemberall("Skin Rashes")
    //   setmemberphone("1800-454-343")
    //   setmembermed(drug)
    //   setmemberdir("tablet")
    //   setmemberstr("200mg")
    //   setmemberqu("7")
    //   setmemberdob("03/03/1960")
    //   1800-454-343
  }, [])

  // const link = "http://localhost:3000/Authorization"+master
  // const redirectUrl = "http://localhost:3000/Authorization?/"+subemail+"";
  const redirectUrl = "https://ebi.valuehealthsolutions.com/Authorization?/" + subemail + "";

  const template = `Dear Dr. ${renlast},\n
The drug prescribed for the following patient requires prior authorization approval from the payer. We have auto-filled the prior auth form for you.\n
Patient Name: ${subfirst}\n
Drug Name: ${subdrug}\n
Insurance: ${payer}\n
Could you please verify the details and attach relevant clinical and supporting documents from the below link:\n${redirectUrl}\n
Upon your approval, we will be electronically submitting the prior auth request with the payer.\n
Thank you for your support!\n
Thank you,\nPSP Support Team.`;






  const startDob = refdobstart;
  const formattedDate = new Date(startDob).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

  const endDob = refdobend;
  const formattedDate1 = new Date(endDob).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

  const Dob = subdob;
  const formattedDate2 = new Date(Dob).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');





  if (zipcode) {
    const url1 = `${BaseUrl}/hospitals/state-city`;

    const body = {
      "zipCode": zipcode,
    };

    axios
      .post(url1, body)
      .then((res) => {
        setcity(res.data.city)
        setstate(res.data.state)
      })
  }
  useEffect(() => {
    const masterId = my_list[5]
    console.log("wertyujik", masterId)
    if (masterId) {
      const getOneHospital = async () => {
        const url = `${BaseUrl}/hospitals/findOne`;
        let response = await axios.post(url, { masterId: my_list[5] })
        const dataArray = response.data.data[0]

        setaddress(dataArray.address);
        setnpiId('');
        sethospitalname(dataArray.hospitalName);
        setcity(dataArray.city);
        sethcp('');
        setzipcode(dataArray.zipCode);
        setrole('');
        setstate(dataArray.state);
        setstatus(dataArray.status);
        setnumber(dataArray.phoneNumber);
        setemail(dataArray.email);
        sethospitaltype('');
      }
      getOneHospital()
    }
  }, [])

  useEffect(() => {

    const url8 = `${BaseUrl}/hospitals/generate-hcp-office`;

    axios
      .get(url8)
      .then((res) => {
        console.log(res, "aaaaaaa")
        sethcp(res.data.hcpid)
        // setcity(res.data.city)
        // setstate(res.data.state)
      })
  }, [])

  const hospital = [
    { label: "Chemotherapy", id: 1 },
    { label: "PrimaryCare", id: 2 },
    { label: "Chiropractic", id: 3 },
    { label: "GeneralOfficeBenefits30", id: 4 },
    { label: "Psychotherapy", id: 5 },
    { label: "Allergies", id: 6 },
    { label: "GeneralOfficeBenefitsMaternity", id: 7 },
    { label: "Infertility83", id: 8 },
    { label: "GynecologicalBT", id: 9 },
    { label: "HomeHealthCare42", id: 10 },
    { label: "Anesthesia", id: 11 },
    { label: "Telemedicine", id: 12 },
    { label: "Pharmacy", id: 13 },
    { label: "PsychiatricA4", id: 14 },
    { label: "XRay", id: 15 },
  ];


  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };



  const validateForm = () => {
    let isValid = true;

    if (!validateEmail(email)) {
      console.log(1)
      setIsEmailValid(false);
      isValid = false;
    } else {
      console.log(2)
      setIsEmailValid(true);
    }

    if (!npiIdValidate(npiId)) {
      setIsValidateNpiId(false);
      isValid = false;
    } else {
      setIsValidateNpiId(true);
    }

    if (!validate(hospitalname)) {
      console.log(3)
      setIsHospitalValid(false);
      isValid = false;
    } else {
      console.log(4)
      setIsHospitalValid(true);
    }

    if (!validate(status)) {
      console.log(5)
      console.log(status)
      setIsstatusValid(false);
      isValid = false;
    } else {
      console.log(6)
      setIsstatusValid(true);
    }
    if (!validate(address)) {
      console.log(7)
      setIsAddressValid(false);
      isValid = false;
    } else {
      console.log(8)
      setIsAddressValid(true);
    }

    if (!validate(zipcode)) {
      console.log(9)
      setIsZipCodeValid(false);
      isValid = false;
    } else {
      console.log(10)
      setIsZipCodeValid(true);
    }


    if (!validate(state)) {
      console.log(11)
      setIsStateValid(false);
      isValid = false;
    } else {
      console.log(12)
      setIsStateValid(true);
    }
    if (!validate(city)) {
      console.log(13)
      setIsCityValid(false);
      isValid = false;
    } else {
      console.log(14)
      setIsCityValid(true);
    }
    if (!numberValidate(number)) {
      console.log(15)
      setIsNumberValid(false);
      isValid = false;
    } else {
      console.log(16)
      setIsNumberValid(true);
    }
    if (!validate(hospitaltype)) {
      console.log(17)
      console.log(hospitaltype)
      setIsServiceTypeValid(false);
      isValid = false;
    } else {
      console.log(18)
      setIsServiceTypeValid(true);
    }
    return isValid;
  };


  const resetForm = () => {
    setnpiId('');
    // sethcp('');
    setstatus('');
    sethospitalname('');
    setaddress('');
    setcity('');
    setzipcode('');
    setstate('');
    setnumber('');
    setemail('');
    sethospitaltype(""); // Reset hospitaltype here
    setError('');
    setIsEmailValid(true);
    setIsValidateNpiId(true);
    setIsHospitalValid(true);
    setIsServiceTypeValid(true)
    setIsNumberValid(true)
    setIsCityValid(true)
    setIsStateValid(true)
    setIsZipCodeValid(true)
    setIsAddressValid(true)
    setIsstatusValid(true)
  };

  const onChangeStartDate = (date) => {
    // Update the state (dob) with the selected date
    // setdob(date);

    // if (!dateIsValid(date)) {
    //   setDobError(true);
    // } else {
    //   setDobError(false);
    // }

    // setDobError(!validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));

    // setDobError(validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));
    console.log('Selected Date:', date);

    if (date) {
      // setDobError(isDateValid(date));
      setrefdobstart(date);
    } else {
      // Handle the case when date is null or undefined
      // setDobError(false); // or any other appropriate handling
      // setdob(null);
    }


  };


  const onChangeStartDate1 = (date) => {
    // Update the state (dob) with the selected date
    // setdob(date);

    // if (!dateIsValid(date)) {
    //   setDobError(true);
    // } else {
    //   setDobError(false);
    // }

    // setDobError(!validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));

    // setDobError(validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));
    console.log('Selected Date:', date);

    if (date) {
      // setDobError(isDateValid(date));
      setrefdobend(date);
    } else {
      // Handle the case when date is null or undefined
      // setDobError(false); // or any other appropriate handling
      // setdob(null);
    }


  };
  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const [showInputs, setShowInputs] = useState(false);
  const toggleInputs = () => {
    setShowInputs(!showInputs);
  };
  const onChangeStartDate2 = (date) => {
    // Update the state (dob) with the selected date
    // setdob(date);

    // if (!dateIsValid(date)) {
    //   setDobError(true);
    // } else {
    //   setDobError(false);
    // }

    // setDobError(!validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));

    // setDobError(validateDate(date));
    // setdob(date.format("MM/DD/YYYY"));
    console.log('Selected Date:', date);

    if (date) {
      // setDobError(isDateValid(date));
      setsubdob(date);
    } else {
      // Handle the case when date is null or undefined
      // setDobError(false); // or any other appropriate handling
      // setdob(null);
    }


  };
  const onChange = () => {

  }

  const [clinicalNotes, setClinicalNotes] = useState('');
  const [pdfContent, setPdfContent] = useState(false);

  const downloadPDF = () => {
    const input = document.getElementById('content-to-download');

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.8); // Adjust image quality

        // Create a PDF using jsPDF with compression
        const pdf = new jsPDF({
          orientation: 'portrait', // or 'landscape'
          unit: 'px',
          format: 'a4',
          compress: true // Enable PDF compression
        });

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = canvas.height * imgWidth / canvas.width;

        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);

        // Save the PDF
        pdf.save('prior_authorization_form_copy.pdf');
      });
  };



  const [paramCode, setParamCode] = useState('');
  const [ file, setFile ] = useState(null);
  const [ bearer, setBearer ] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get('code');
    const state = urlParams.get('state');

    console.log(code, 'paramCode')


    if (code) {
      setParamCode(code);
      exchangeCodeForToken(code);
    } else{
      setParamCode('');
    }


  }, [])

  const [ isSignModalOpen, setIsSignModalOpen ] = useState('');
  const [ adobeEmail, setAdobeEmail ] = useState('')


  const handleClick = (e) => {

    // if( paramCode ){
      setIsModalOpen(true);
    // } else{
    //   initiateOAuth();
    // }

  };

  const handleESignClick = (e) => {

    if( paramCode ){
      setIsSignModalOpen(true);
    } else{
      initiateOAuth();
    }

  };

  console.log( !paramCode, 'paramCodeFunction')

  const initiateOAuth = () => {
    const clientId = 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083';
    const redirectUri = encodeURIComponent('https://ebi.valuehealthsolutions.com/Priorrequest?/HCP%20Executive/');
    const responseType = 'code';
    const scope = 'user_login:self+agreement_read:account+agreement_write:account';
    const adobeAuthUrl = `https://secure.in1.adobesign.com/public/oauth/v2?
redirect_uri=${redirectUri}&
response_type=code&client_id=${clientId}&scope=user_login:self+agreement_read:self+agreement_write:self&state=S6YQD7KDA556DIV6NAU4ELTGSIV26ZNMXDSF7WIEEP0ZLQCLDQ89OYG78C3K9SROC8DXCGRVSGKU1IT1`;

    window.location.href = adobeAuthUrl;
    // console.log(redirectUri, '"adobeAuthUrl"')
  };

  async function exchangeCodeForToken(authCode) {

    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', authCode);
    params.append('client_id', 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083');
    params.append('client_secret', '9chxWJjipetgYhlsnPit-Rb4EOtR3xfZ');
    params.append('redirect_uri', 'https://ebi.valuehealthsolutions.com/Priorrequest?/HCP%20Executive/');

    console.log(params, 'oAuthParam')

    try {
      const response = await axios.post('https://api.in1.adobesign.com/oauth/v2/token', params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      console.log('Access Token:', response);

      if (response.status === 200) {
        const refreshToken = response.data.refresh_token;
        const clientId = 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083';
        const clientSecret = '9chxWJjipetgYhlsnPit-Rb4EOtR3xfZ';
        refreshAccessToken(refreshToken, clientId, clientSecret);
      }

      return response.data;
    } catch (error) {
      console.error('Failed to exchange code for token:', error.response.data);
      throw error.message;
    }
  }

  async function refreshAccessToken(refreshToken, clientId, clientSecret) {
    const url = 'https://api.in1.adobesign.com/oauth/v2/refresh';
    const params = new URLSearchParams();
    params.append('refresh_token', refreshToken);
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);
    params.append('grant_type', 'refresh_token');

    try {
      const response = await axios.post(url, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      console.log('New Access Token:', response);
      setBearer(response.data.access_token);
      // Optionally, return the new access token or handle it according to your application's logic
      return response.data;
    } catch (error) {
      console.error('Failed to refresh token:', error.response ? error.response.data : error.message);
      throw error;
    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }


  const handleUploadAndSend = async () => {
    // if (!formData.has('File') || !formData.has('RecipientEmail')) {
    // if (!formData.has('File')) {
    //     alert('Please provide a file and a recipient email.');
    //     return;
    // }

    if (!file || !adobeEmail) {
      alert('Please provide a file and a recipient email.');
      return;
    }

    try {

      const formData = new FormData();
      formData.append('File', file);
      const uploadConfig = {
        headers: {
          //Bearer Token 
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const uploadResponse = await axios.post('https://api.in1.adobesign.com/api/rest/v6/transientDocuments', formData, uploadConfig);
      const transientDocumentId = uploadResponse.data.transientDocumentId;

      // Construct agreementData for sending the document for signing
      const agreementData = {
        fileInfos: [{
          transientDocumentId: transientDocumentId
        }],
        name: 'Please Sign This Document',
        participantSetsInfo: [{
          order: 1,
          role: 'SIGNER',
          memberInfos: [{
            // email: formData.get('RecipientEmail')
            email: adobeEmail
          }]
        }],
        signatureType: 'ESIGN',
        state: 'IN_PROCESS'
      };

      const agreementConfig = {
        headers: {
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'application/json'
        }
      };

      const agreementRequest = await axios.post('https://api.in1.adobesign.com/api/rest/v6/agreements', agreementData, agreementConfig);
      const agreementResponse = agreementRequest
      console.log(agreementResponse.data, 'agreementResponse')

      alert('Document sent for signing successfully.');
      setIsSignModalOpen(false);
      
      // handleOk();
    } catch (error) {
      console.error('Error processing document:', error);
      alert('Failed to send document for signing. Please try again E-Sign!');
      Navigate('/Priorrequest?/HCP%20Executive');
      setParamCode('');
      setIsSignModalOpen(false);
    }
  };

  


  return (
    <div className='addnewhospitalpicontainer'
    // style={{ overflow: "scroll" }}
    >
      {
        // <div
        // // style={{ display: 'none' }}
        // >
        <div id="content-to-download" className="downloadPdfcssChanges"
        // style={{ position: 'absolute', left: '-9999px', top: '-9999px'}}
        >
          <div style={{ border: '1px solid #000', borderRadius: '15px', padding: '25px' }}>
            <h2 className="pdfmainHeading">Electronic Benefit Verification</h2>
            <hr />
            <h3 className="pdfSubMainHeading">Verify the Prior Authorization Details</h3>

            <h3 className="pdfRequesterHeading">Requester Details</h3>
            <p><strong>First Name:</strong> {renfirst}</p>
            <p><strong>Last Name:</strong> {renlast}</p>
            <p><strong>NPI:</strong> {rennpi}</p>
            <p><strong>Facility NPI:</strong> {renfac}</p>
            <p><strong>Address:</strong> {renadd}</p>
            <p><strong>City:</strong> {rencity}</p>
            <p><strong>State:</strong> {renstate}</p>
            <p><strong>Zip code:</strong> {renzip}</p>
            <hr />

            <h3 className="pdfRequesterHeading">Subscriber Details</h3>
            <p><strong>First Name:</strong> {subfirst}</p>
            <p><strong>Last Name:</strong> {sublast}</p>
            <p><strong>DOB:</strong> {subdob}</p>
            <p><strong>Member ID:</strong> {submember}</p>
            <p><strong>Drug Name:</strong> {subdrug}</p>
            <p><strong>Email:</strong> {subemail}</p>
            {/* <p><strong>Drug Dosage Strength:</strong> {renstate}</p>
            <p><strong>Quantity:</strong> {renzip}</p>
            <p><strong>Prescription Start Date:</strong> {renzip}</p>
            <p><strong>Prescription End Date:</strong> {renzip}</p> */}
            {
              clinicalNotes !== '' ?
                <p><strong>Clinincal Notes:</strong> {clinicalNotes}</p>
                :
                <></>
            }

            <p style={{ fontSize: 25, color: '#858585', marginLeft: 0 }} >
              <b>Note:</b> <br />If you have additional clinical documents supporting this prior authorization request, please upload them. Providing all relevant documents upfront can help expedite the review process and reduce the need for multiple review calls from payers
            </p>


            <Checkbox onChange={onChange} defaultChecked>
              <p style={{ margin: 0 }}>
                I agree to provide consent for processing the information for prior authorization request.
              </p>
            </Checkbox>

          </div>

        </div>
        // </div>

      }

      <Modal width={600} open={isSignModalOpen} onOk={handleUploadAndSend} onCancel={handleSignModalCancel}>
        <p>Please provide document</p>

        <input style={{ marginBottom: 20 }} type="file" onChange={handleFileChange} />
        <div style={{ marginTop: 10 }}>
          <TextField
            lg={{ width: '90%' }}

            style={inputStyle}
            label="Email"
            id="npi"
            variant="outlined"
            onChange={(e) => setAdobeEmail(e.target.value)}
            size="small"
          />
        </div>
      </Modal>
      <Modal width={600} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Please provide HCP provide Email</p>

        {/* <input style={{ marginBottom: 20 }} type="file" onChange={handleFileChange} /> */}
        <div style={{ marginTop: 10 }}>
          <TextField
            lg={{ width: '90%' }}

            style={inputStyle}
            label="Email"
            id="npi"
            variant="outlined"
            onChange={(e) => setSenderEmail(e.target.value)}
            size="small"
          />
        </div>
      </Modal>
      <NewHeader />
      <Sidebar1 />
      <div className='addnewhospitalminihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/drugpverify?/" + my_list[4] + "")} />
        </div>
        <div className='addnewhospitalminiheadtitle'>
          Verify the Prior Authorization Details
        </div>


      </div>
      <div>
        <div style={containerStyle} >
          <div style={{ paddingTop: "20px", marginLeft: "6%" }}><b></b></div>
          {/* <div className="containerlogin"
           style={containerStyle} 
           > */}
          <Grid className='addnewhospitallogingrid'
          // style={{ overflow: "scroll" }}
          >

            <p style={{ marginLeft: "105px", fontWeight: "bold", marginBottom: "25px" }}>Requester</p>

            <Stack direction="row" spacing={1} style={stackStyleTop} className='top'>
              {/* <TextField
                sx={{ width: '41%' }}
                style={inputStyle}
                label="PriorAuthProviderType"
                id="Member Name"
                variant="outlined"
                value={renprovider}
                onChange={(e) => setrenprovider(e.target.value)}
                size="small"
              /> */}

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="First Name"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={renfirst}
                // placeholder="Member ID"
                onChange={(e) => setrenfirst(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="NPI"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={rennpi}
                // placeholder="NPI ID"
                onChange={(e) => setrennpi(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'>

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Last Name"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={renlast}
                // placeholder="NPI ID"
                onChange={(e) => setrenlast(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="FacilityNPI"
                id="email"
                variant="outlined"
                value={renfac}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                onChange={(e) => setrenfac(e.target.value)}
                // defaultValue="Small"
                size="small"
              />




              {/* <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DOB'
                variant="outlined"
                value={memberdob ? dayjs(memberdob) : null}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              /> */}

            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Address"
                required
                // error={!isAddressValid}
                // helperText={!isAddressValid ? 'required field !' : ''}
                id="email"
                variant="outlined"
                value={renadd}
                // placeholder="Enter your Address"
                onChange={(e) => setrenadd(e.target.value)}
                // defaultValue="Small"
                size="small"
              />


              {/* <Tooltip title="Zipcode Map With State & City"> */}

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Zip Code"
                id="npi"
                required
                //   error={!isZipCodeValid}
                // helperText={!isZipCodeValid ? 'required field !' : ''}
                variant="outlined"
                value={renzip}
                //   placeholder="Enter your Zip Code"
                onChange={(e) => setrenzip(e.target.value)}
                //   defaultValue="Small"
                size="small"
              />
              {/* </Tooltip> */}
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                // error={!isStateValid}
                // helperText={!isStateValid ? 'required field !' : ''}
                // disabled={true}
                label="State"
                required
                id="email"
                variant="outlined"
                value={renstate}
                // placeholder="Enter your State"
                onChange={(e) => setrenstate(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="City"
                id="npi"
                required
                variant="outlined"
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                value={rencity}
                // placeholder="Enter your City"
                onChange={(e) => setrencity(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle}>
              {/* <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="TaxId"
                id="Allergies"
                variant="outlined"
                value={rentaxi}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                // placeholder="Enter your Allergies"
                onChange={(e) => setrentaxi(e.target.value)}
                // defaultValue="Small"
                size="small"
              /> */}


            </Stack>
            <Button
              // variant=""
              onClick={toggleInputs}
              style={{ marginLeft: "99px", marginTop: "8px" }}
            >
              {showInputs ? 'Close ▲' : ' add Referring provider ▼'}
            </Button>

            {showInputs && (
              <>
                <p style={{ marginLeft: "109px", fontWeight: "bold", marginTop: "8px", marginBottom: "25px" }}>Referring Provider</p>
                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="PriorAuthProviderType"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefprovider(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="First Name"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setreffirst(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Last Name"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setreflast(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="NPI"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefnpi(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Address"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefadd(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Zip Code"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefzip(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="State"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefstate(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="City"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefcity(e.target.value)}
                    size="small"
                  />
                </Stack>


              </>
            )}

            <p style={{ marginLeft: "109px", fontWeight: "bold", marginTop: "8px", marginBottom: "25px" }}>Subscriber</p>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                // error={!isStateValid}
                // helperText={!isStateValid ? 'required field !' : ''}
                // disabled={true}
                label="First Name"
                id="email"
                required
                variant="outlined"
                value={subfirst}
                //   placeholder="Enter your State"
                onChange={(e) => setsubfirst(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Last Name"
                id="npi"
                required
                variant="outlined"
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                value={sublast}
                //   placeholder="Enter your City"
                onChange={(e) => setsublast(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DOB'
                variant="outlined"
                label="Date of Birth"
                required
                value={subdob ? dayjs(subdob) : null}
                onChange={onChangeStartDate2}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Member ID"
                id="npi"
                required
                variant="outlined"
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                value={submember}
                //   placeholder="Enter your City"
                onChange={(e) => setsubmember(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Drug Name"
                id="Allergies"
                required
                variant="outlined"
                value={subdrug}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                // placeholder="Enter your Allergies"
                onChange={(e) => setsubdrug(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Email"
                id="email"
                variant="outlined"
                value={subemail}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                onChange={(e) => setsubemail(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label=" Drug Dosage Strength"
                id="Allergies"
                variant="outlined"
                // value={substrength}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                // placeholder="Enter your Allergies"
                onChange={(e) => setstrength(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Quantity"
                id="email"
                variant="outlined"
                // value={subquantity}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                onChange={(e) => setQuantity(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'>
              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='Prescription start date'
                label='Dos Start Date' // Add label here
                variant="outlined"
                format="YYYY-MM-DD"
                // value={refdobstart ? dayjs(refdobstart) : null}
                onChange={onChangeStartDate}
                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />
              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='Prescription end date'
                variant="outlined"
                // value={refdobend ? dayjs(refdobend) : null}
                onChange={onChangeStartDate1}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />
            </Stack>





            <Col offset={10} >



              {/* <div className='Signature-btn'> */}






              {/* <div className='sign-img'>

                {trimmedDataURL
        ? <img  alt='signature'
          src={trimmedDataURL} />
        : null}
               </div>
             <Button variant='outlined' onClick={showModal} icon={<PlusOutlined />}>Add signature</Button>



            </div> */}
            </Col>










            <div style={{ marginLeft: "13%", marginTop: "10px", marginRight: "100px", marginBottom: '20px' }}>

              <p style={{ fontSize: 12, color: '#858585' }} >
                <b>Note:</b> <br />If you have additional clinical documents supporting this prior authorization request, please upload them. Providing all relevant documents upfront can help expedite the review process and reduce the need for multiple review calls from payers
              </p>
            </div>
            <Stack
              fullWidth
              style={{
                // width: '200px',
                height: '50px',
                // border: '2.5px dashed orange',
                borderRadius: '0',
                marginTop: '1.5%',
                display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                justifyContent: 'space-between',
                // textAlign: 'center',
                // marginLeft:'400px',

              }}
              onDragOver={(e) => e.preventDefault()}
            // onDrop={handleFileDrop}
            >
              {/* Your existing form and input elements */}

              <form>
                {/* Your file input */}
                {/* Add an onChange event handler to trigger the Snackbar */}
                <input
                  type="file"
                  multiple
                  onChange={(event) => handleOpenSnackbar('Document uploaded successfully!')}
                  style={{ display: 'none' }}
                />
                {/* Your drag and drop area */}
                <div
                  // style={{ cursor: 'pointer' }}

                  style={{ display: "flex", marginLeft: "13%" }}
                >



                  <div onClick={() => document.querySelector('input[type="file"]').click()}


                    onDragOver={(e) => e.preventDefault()}
                    type="submit" style={{ border: "2.5px dashed #1da0e1", width: "290px", textAlign: "center", color: "#1da0e1", }} >
                    Click to upload clinical documents

                    <img style={{ width: '50px', height: '50px' }} src="/adds.png" alt="" />




                  </div>
                  <div style={{ marginLeft: 100, width: "628px" }} >

                    <TextArea draggable={false} id="myTextArea" color="#000" rows={4}
                      placeholder="Add Clinical Notes, If any" onChange={(e) => setClinicalNotes(e.target.value)} />
                  </div>





                </div>
              </form>




            </Stack>

            <div style={{ width: "1025px", marginLeft: "13%", marginTop: "70px", marginRight: "100px" }}>

              <Checkbox onChange={onChange} defaultChecked>
                <p>
                  I agree to provide consent for processing the information for prior authorization request.
                </p>
              </Checkbox>
            </div>

            <Stack direction="row" spacing={3} style={{ marginTop: "5%", justifyContent: "center", paddingBottom: "1%" }}>
              {/* <Grid>
                <button className='addnewhospitalclear' onClick={resetForm}>Reset</button>
              </Grid>

              <Grid>
                <button className='addnewhospitaladd' onClick={(e) => Navigate("/hospitalnew?/" + my_list[4] + "")}>Cancel</button>
              </Grid> */}

              <Grid>
                <button className="addnewhospitalsubmit" style={{ backgroundColor: "#1DA0E1" }} onClick={() => handleClick("Submit")}>
                  
                  Send to HCP
                  </button>
                <button className="addnewhospitalsubmit" style={{ backgroundColor: "#1DA0E1", marginLeft: 20 }} onClick={() => handleESignClick()}>
                  {
                    paramCode ?
                    'Proceed to E-Sign'
                    :
                    'E-Sign'
                  }
                </button>
                <button
                  className="addnewhospitalsubmit"
                  style={{ backgroundColor: "#1DA0E1", marginLeft: 20 }}
                  onClick={downloadPDF}
                >
                  Download
                </button>
                <button
                  onClick={() => window.print()}
                  className="addnewhospitalsubmit"
                  style={{ backgroundColor: "#1DA0E1", marginLeft: 20 }}
                >
                  Print
                </button>
              </Grid>
            </Stack>
          </Grid>
          {/* <div> */}
        </div>
      </div>
      <div>
      </div>
    </div>
    // </div>
    // </div>
  )
}

export default Priorrequest