// import React from 'react'
import NewHeader from '../../NewHeader'
import "./NewUser.css"
import Sidebar1 from '../../Sidebar1'
import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { Col, Row, Modal, message, Select } from 'antd'
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { ID } from '@aws-amplify/datastore/lib-esm/util';
import { BaseUrl } from '../../../config'
import FlagIcon from '@mui/icons-material/Flag';
import SendIcon from '@mui/icons-material/Send';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";


import { useAuth } from '../../../Newlogin/Authprovider'
const Priortable = () => {

  const navigate = useNavigate()
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filteredData, setFilteredData] = useState([])
  const [filteredEbvData, setfilteredEbvData] = useState([])
  const [filteredSbcData, setfilteredSbcData] = useState([])
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState(null);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);
  const [TableType, setTableType] = useState('ebv')
  const [pastatus, setpastatus] = useState("")

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };

  const success = () => {
    message.open({
      type: "success",
      content: "Sucessfully Send Mail",
    });
  };

  const managnment = window.location.href
  const my_list = managnment.split("/")
  useEffect(() => {
    getData()
    getEBVData()
    getSBCData()
  }, [])

  const getData = () => {
    const url8 = `${BaseUrl}/prior-authorization/findAll`; //ePA
    axios
      .get(url8)
      .then((res) => {
        setLoading(false);
        // setFilteredData(res.data.data)
        const sortedData = res.data.data.sort((a, b) => {
          // Replace 'createdAt' with the field you want to sort by
          // If sorting by string field like 'insuranceName', use localeCompare for case-insensitive comparison
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setFilteredData(sortedData);
        console.log(res.data.data, "ebvData")
      })
      .catch((error) => {
      });
  }

  const getSBCData = () => {
    const url8 = `${BaseUrl}/benefit-summary`; //ePA
    axios
      .get(url8)
      .then((res) => {
        setLoading(false);
        const sortedData = res.data.data.sort((a, b) => {
          // Replace 'createdAt' with the field you want to sort by
          // If sorting by string field like 'insuranceName', use localeCompare for case-insensitive comparison
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setfilteredSbcData(sortedData)
      })
      .catch((error) => {
      });

  }

  const getEBVData = () => {

    const tableUrl = `${BaseUrl}/drugs-details/findAll`; //eBV
    axios.get(tableUrl)
      .then((res) => {
        setLoading(false);
        setdata(res.data.data);
        console.log(res.data.data, "dattaaa")
      })

    if (!dates && !searchText) {
      setfilteredEbvData([]);  // No dates and no search text, show an empty array
    } else {
      const filteredRows = data.filter((row) => {
        const isDateInRange = !dates || (
          new Date(row.createdAt) >= new Date(dates[0]) &&
          new Date(row.createdAt) <= new Date(dates[1])
        );
        const isSearchTextMatch = !searchText || (
          row.insuranceName.toLowerCase().includes(searchText.toLowerCase())
        );
        return isDateInRange && isSearchTextMatch;
      });

      setfilteredEbvData(filteredRows);
    }

  }

  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/hospitals/delete`;
    const requestBody = {
      masterId: id
    };

    axios.post(deleteUrl, requestBody)
      .then((response) => {
        getData()
      })
      .catch(e => {
        console.error("Error deleting:", e);
      });
  };

  let payer = (localStorage.getItem("payers"));

  console.log(payer, "Payersss")



  const handleSendEmail = (row) => {
    const emailServiceUrl = `${BaseUrl}/email-service`;
    // const redirectUrl = `http://localhost:3000/Authorization?/${row.emailId}`;
    const redirectUrl = `https://ebi.valuehealthsolutions.com/Authorization/${row.emailId}`;


    const template = "Dear Dr. " + row.renderLastName + ",\n" +
      "The drug prescribed for the following patient requires prior authorization approval from the payer. We have auto-filled the prior auth form for you.\n" +
      "Patient Name: " + row.firstName + "\n" +
      "Drug Name: " + row.drugName + "\n" +
      "Insurance: " + payer + "\n" +
      "Could you please verify the details and attach relevant clinical and supporting documents from the below link:\n" +
      redirectUrl + "\n" +
      "Upon your approval, we will be electronically submitting the prior auth request with the payer.\n" +
      "Thank you for your support!\n" +
      "Thank you,\nPSP Support Team.";

    const body = {
      "email": "pscagenthealthitplatform@gmail.com", // Change this to the recipient email addres
      "subject": "Request to verify Prior Auth submission details",
      "template": template + "\n"
    };

    axios
      .post(emailServiceUrl, body)
      .then((res) => {
        console.log("Email sent successfully:", res.data);
        success();
        // Optionally, you can show a success message to the user
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        // Optionally, you can show an error message to the user
      });
  };
  const handleSearchChange = (value) => {
    setSearchText(value);
  };




  const status = [
    { label: "Sent to HCP", value: 1 },
    { label: "Request Placed", value: 2 },
    { label: "Sent to Payer", value: 3 },
    { label: "Status inquiry", value: 4 },
    { label: "Request for details", value: 5 },
    { label: "Request Approved", value: 6 },
    { label: "Request Denied", value: 7 },

  ];
  const status1 = [
    { label: "Not Assigned", value: 'Not Assigned'},
    { label: "Assigned - Inprogress", value: 'Assigned - Inprogress' },
    { label: "Completed", value: 'Completed' },
    { label: "Report Sent", value: 'Report Sent'},

  ];
  const [searchText, setSearchText] = useState('');
  const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.drugName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/Full_Summary of Benefits and Coverage Report.pdf';
    link.download = 'Full_Summary of Benefits and Coverage Report.pdf';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onChangeModal = (e) => {

    setTableType(e.target.value)

  }
  console.log(TableType, "tabletype")


  const YourComponent = ({ startIndex, index }) => (
    <TableCell>{`PV${(startIndex + index).toString().padStart(3, '0')}`}</TableCell>
  );
  const NumberComponent = ({ startIndex, index }) => (
    <TableCell>{`${(startIndex + index).toString().padStart(3, '0')}`}</TableCell>
  );



  const filterData = () => {

  };

  const getTextColor = (response) => {
    switch (response) {
      case 'not covered':
        return 'red';
      case 'covered':
        return 'green';
      case 'covered with condition':
        return 'grey';
      case 'detail not available':
        return 'orange';
      default:
        return 'inherit';
    }
  };
  const [rowStatuses, setRowStatuses] = useState({});
  const HandleSelect = ( newStatus,rowId) => {
    const updatedData = filteredSbcData.map(item => {
      if (item.request_id === rowId) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setfilteredSbcData(updatedData);
  };


  const getStatusValue = (status) => {
    if (status === 'Request Placed') return 2;
    if (status === '') return 1;
    return 1; // Default to Pending if status is null or undefined
  };

  const getStatusValue1 = (status) => {
    if (status === 'Request Placed') return 2;
    if (status === '') return 1;
    return 1; // Default to Pending if status is null or undefined
  };

 

  return (
    <div className='newuserpicontainer'>
      <NewHeader />
      <Sidebar1 />
      <div className='newuserminiheadtable'>
        <div className='newuserminiheadtitle' style={{ fontWeight: 500 }}>
          <Col>
            <h2 className='newusermed' >Transactions</h2>
          </Col>
        </div>
        <Row className='newuserminiheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}
              placeholder='Search For Drug name'
              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px",
                marginLeft: "215px"
              }}
            />
          </Col>
        </Row>
      </div>

      <div>



        <TableContainer className="newusertablecont" style={{ width: "90%" }} component={Paper}>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="ebv"
            name="radio-buttons-group"
            onChange={onChangeModal}
            style={{ marginLeft: 40, marginTop: 10 }}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                value="ebv"
                control={<Radio />}
                label="eBV"
                style={{ marginRight: 25 }}

              />
              <FormControlLabel
                value="epa"
                control={<Radio />}
                label="ePA"
                style={{ marginRight: 25 }}

              />
              <FormControlLabel
                value="sbc"
                control={<Radio />}
                label="SBC"
                style={{ marginRight: 25 }}

              />
            </div>
          </RadioGroup>
          <div>
            {
              TableType === 'ebv' ?

                <>
                  {loading ?
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
                    </Box>
                    :
                    <>
                      <Table>
                        <TableHead className="tablerowm" >
                          <TableRow style={{ height: "5px" }}>
                            <TableCell><b>Case Id</b></TableCell>
                            {/* <TableCell>S.No</TableCell> */}
                            <TableCell><b>Insurance Name</b></TableCell>
                            {/* <TableCell>Hcp Office</TableCell> */}
                            <TableCell><b>Plan Name</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Drugname</b></TableCell>
                            <TableCell><b>state</b></TableCell>
                            <TableCell><b>Action</b></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {(filteredEbvData && filteredEbvData.length > 0 ? filteredEbvData : data)
                            .slice(startIndex - 1, endIndex)
                            .map((row, index) => (
                              <TableRow key={row.id}>
                                {/* <TableCell>{index + 1}</TableCell> */}
                                <YourComponent startIndex={startIndex} index={index} />

                                <TableCell>{row.insuranceName}</TableCell>
                                <TableCell>{row.planeName}</TableCell>
                                <TableCell>
                                  {row.Description === ('not covered' || 'covered' || 'covered with condition' || 'detail not available') && (
                                    // <FlagIcon style={{ color: getTextColor(row.Description), marginRight: '4px' }} />
                                    <p></p>
                                  )}
                                  <span style={{ color: getTextColor(row.Description) }}>{row.Description}</span>
                                </TableCell>
                                <TableCell>{row.drugName}</TableCell>
                                <TableCell>{row.state}</TableCell>
                                <TableCell>
                                  <Tooltip title="Description">
                                    <IconButton aria-label="View"
                                    //  onClick={(e) => navigate("/ebvrequest?/" + my_list[4] + "/" + row.userid)}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip title="Send">
                                    <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={(e) => navigate("/drugpverify?/" + my_list[4])}>
                                      <SendIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                  {/* <Tooltip title="Delete">
                        <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                          <Delete />
                        </IconButton>
                        </Tooltip> */}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>


                    </>
                  }
                </>
                :
                TableType == 'epa' ?
                  <>




                    <Table>
                      <TableHead className="newusertablerowm" >
                        <TableRow  >
                          <TableCell><b>Request Id</b></TableCell>
                          <TableCell><b>Patient Name</b></TableCell>
                          <TableCell><b>Provider Name</b></TableCell>
                          <TableCell><b>Drug Name</b></TableCell>
                          <TableCell><b>Status</b></TableCell>
                          <TableCell><b>Request Time</b></TableCell>
                          <TableCell><b>Request Date</b></TableCell>
                          <TableCell><b>Actions</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData.length > 0 ? (

                          filteredData.slice(startIndex - 1, endIndex).map((row, index) => (

                            <TableRow key={row.id} >
                              <TableCell>{row.masterId}</TableCell>
                              {/* <NumberComponent startIndex={startIndex} index={index} /> */}
                              <TableCell>{row.firstName}</TableCell>
                              <TableCell>{row.renderFirstName}</TableCell>
                              <TableCell>{row.drugName}</TableCell>
                              {/* <TableCell>{row.status}</TableCell> */}
                              <TableCell>
                                {/* <FormControl sx={{ mt: 3 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        id="combo-box-demo"
                        options={status}
                        value={row.status ? [row.status] : [row.status]}
                        className='Transactions'
                        sx={{ width: 200 }}
                        onChange={(event, newValue) => handleStatusChange(row.id, newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                      />
                    </FormControl>  */}
                                <Select
                                  defaultValue={getStatusValue(row.status)}
                                  style={{
                                    width: 200,
                                  }}
                                  options={status}
                                // value={row.status ? 2 : 1 }
                                />

                              </TableCell>
                              <TableCell>{row.currentTime}</TableCell>
                              <TableCell>{row.currentDate}</TableCell>
                              <TableCell>
                                <Tooltip title="Description">
                                  <IconButton aria-label="View">
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>


                                <>
                                  <Tooltip title="Send Reminder">
                                    <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={() => handleSendEmail(row)}>
                                      <SendIcon />
                                    </IconButton>
                                  </Tooltip>

                                </>


                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          data.slice(startIndex - 1, endIndex).map((row, index) => (

                            <TableRow key={row.id} >
                              {/* <TableCell>{row.masterId}</TableCell> */}
                              <TableCell>{row.firstName}</TableCell>
                              <TableCell>{row.renderFirstName}</TableCell>
                              <TableCell>{row.drugName}</TableCell>
                              <TableCell>{row.currentTime}</TableCell>
                              <TableCell>{row.currentDate}</TableCell>
                              <TableCell>
                                <Tooltip title="Download Full SBC">
                                  <Button
                                    // variant="outlined"
                                    color="primary"
                                    onClick={handleDownload}
                                  >
                                    <SimCardDownloadIcon
                                      style={{ color: "#00ADF2" }}
                                    />
                                  </Button>
                                </Tooltip>
                                <Tooltip title=" Send Reminder">
                                  <IconButton
                                    aria-label="Edit"
                                    style={{ color: "#00ADF2" }}
                                    onClick={() => handleSendEmail(row)}
                                    className="send-mail-button"
                                  >
                                    <SendIcon />
                                  </IconButton>
                                </Tooltip>

                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </>
                  :
                  TableType == 'sbc' ?
                    <>
                      {/* sbc */}
                      <Table>
                        <TableHead className="newusertablerowm" >
                          <TableRow >
                            <TableCell><b>Request Id</b></TableCell>
                            {/* <TableCell><b>HCP Email</b></TableCell>
                            <TableCell><b>HCP Fax</b></TableCell> */}
                            <TableCell><b>Member Name</b></TableCell>
                            <TableCell><b>Drug Name</b></TableCell>
                            <TableCell><b>Insurance Name</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Request Time</b></TableCell>
                            <TableCell><b>Request Date</b></TableCell>
                            <TableCell><b>Actions</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredSbcData.length > 0 ? (
                            filteredSbcData.slice(startIndex - 1, endIndex).map((row, index) => (
                              <TableRow key={row.request_id} >
                                <TableCell>{row.request_id}</TableCell>
                                {/* <TableCell>{row.payer_email === "" ? <> - </> : row.payer_email}</TableCell>
                                <TableCell>{row.payer_fax === "" ? <> - </> : row.payer_fax}</TableCell> */}
                                <TableCell>{row.MemberName}</TableCell>
                                <TableCell>{row.Drugname}</TableCell>
                                <TableCell>{row.InsuranceName}</TableCell>
                                <TableCell>
                                  {/* <FormControl sx={{ mt: 3 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        id="combo-box-demo"
                        options={status1}
                        defaultValue={['Sent to HCP']}
                        className='Transactions'
                        sx={{ width: 200 }}
                        onChange={(event, newValue) => handleStatusChange(row.id, newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                      />
                    </FormControl>  */}

                                  <Select
                                    defaultValue={row.status}
                                    style={{
                                      width: 200,
                                    }}
                                    onChange={(label) => HandleSelect(label,row.request_id)}
                                    options={status1}
                                  // value={row.status ? 2 : 1 }
                                  />
                                </TableCell>
                                <TableCell>{row.currentTime}</TableCell>
                                <TableCell>{row.currentDate}</TableCell>
                                <TableCell>
                                  <Tooltip title="Description">
                                    <IconButton aria-label="View">
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>

                                  {/* <Tooltip title="Download Full ePA">
                                    <Button
                                      // variant="outlined"
                                      color="primary"
                                      onClick={handleDownload}
                                    >
                                      <SimCardDownloadIcon 
                                      style={{ color: "#00ADF2" }}
                                      />
                                    </Button>
                                  </Tooltip> */}


                                  <Tooltip title="Download Full ePA">
                                    {
                                      row.status === 'Completed' ?
                                        <>
                                          <Button
                                            // variant="outlined"
                                            color="primary"
                                            onClick={handleDownload}
                                          >
                                            <SimCardDownloadIcon
                                              style={{ color: "#00ADF2" }}
                                            />
                                          </Button>
                                          <Tooltip title=" Send Reminder">
                                            <IconButton
                                              aria-label="Edit"
                                              style={{ color: "#00ADF2" }}
                                              // onClick={() => handleSendEmail(row)}
                                              className="send-mail-button"
                                            >
                                              <SendIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                        :
                                        <></>
                                    }
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            data.slice(startIndex - 1, endIndex).map((row, index) => (
                              <TableRow key={row.id} >
                                <TableCell>{row.masterId}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.renderFirstName}</TableCell>
                                <TableCell>{row.drugName}</TableCell>
                                <TableCell>{row.currentTime}</TableCell>
                                <TableCell>{row.currentDate}</TableCell>
                                <TableCell>
                                  <Tooltip title="Download Full SBC">
                                    <Button
                                      // variant="outlined"
                                      color="primary"
                                      onClick={handleDownload}
                                    >
                                      <SimCardDownloadIcon
                                        style={{ color: "#00ADF2" }}
                                      />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title=" Send Reminder">
                                    <IconButton
                                      aria-label="Edit"
                                      style={{ color: "#00ADF2" }}
                                      onClick={() => handleSendEmail(row)}
                                      className="send-mail-button"
                                    >
                                      <SendIcon />
                                    </IconButton>
                                  </Tooltip>

                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </>
                    : <></>

            }
          </div>
        </TableContainer>
      </div>
      <div className='pagination'>
        <div>

          {
            TableType == 'ebv' ?
              <Pagination
                color="primary"
                count={filteredEbvData.length > 0 ? Math.ceil(filteredEbvData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
                onChange={handlePageChange}
                page={page + 1}
                boundaryCount={1}
                siblingCount={1}
              />
              :
              TableType == 'epa' ?
                <Pagination
                  color="primary"
                  count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
                  onChange={handlePageChange}
                  page={page + 1}
                  boundaryCount={1}
                  siblingCount={1}
                />
                : <Pagination
                  color="primary"
                  count={filteredSbcData.length > 0 ? Math.ceil(filteredSbcData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
                  onChange={handlePageChange}
                  page={page + 1}
                  boundaryCount={1}
                  siblingCount={1}
                />
          }

        </div>
      </div>
    </div>
  )
}

export default Priortable;
