import React, { useState,useEffect } from 'react'
import { Col, Row } from 'antd';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router';
import './Newlogin.css'
import { TextField } from '@mui/material';
import { message, Upload,Modal } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import OtpInput from 'react-otp-input';
import { Margin } from '@mui/icons-material';
import { Card } from "antd";
import {BaseUrl} from '../config'
import { jwtDecode } from 'jwt-decode';
import {useAuth} from '../Newlogin/Authprovider'
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
// import GoogleLogin from 'react-google-login';

const Newlogin = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [email, setemail] = useState('');
    const [role, setrole] = useState('');
    const [response, setresponse] = useState('');
    const [expires, setexpires] = useState('');
    // const [ hideOtpBtn, setHideOtpBtn ] = useState(true)
    const [searchLoading, setSearchLoading] = useState(false);
    const [hideOtpBtn, setHideOtpBtn] = useState(true)
    // const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        message.open({
            type: "success",
            content: "Sucessfully Send Mail",
        });
    };
    const error = () => {
        message.open({
            type: "error",
            content: "Failed to Send Mail",
        });
    };

    // const responseMessage = async (response) => {
    //     // dispatch({ type: "SET_LOADING", payload: true });
    //     console.log(response)
    //     const userObject = jwtDecode(response.credential);
    //     console.log(userObject)
    
    //     let googleResponse = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${response.credential}`);
    //     // let googleResponse = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
    //     //   headers: {
    //     //     Authorization: `Bearer ${response.credential}`,
    //     //     Accept: 'application/json'
    //     //   }
    //     // })
    //     console.log(googleResponse)
    
    //     let googleData = googleResponse.data.email;
    //     console.log(googleData,"googleData")

    //     let result = JSON.stringify(googleResponse.data)
    //     console.log(result,"result")


    //     let emailid = result.email
    //     console.log(emailid,"emailid")

    //     let url2 = `${BaseUrl}/add-users/findemail`
    //     let body = {
    //         "emailId": email
    //     }
    //     axios
    //         .post(url2, body)
    //         .then((res) => {
    //             setrole(res.data.data[0].role)
    //         })

            

            


    

    //     // if(googleResponse.data){
    //     //     Navigate(`/graph?/superadmin`)
            
    //     // }
    //     // console.log(res.data)
    //     // let values = {
    //     //   email: googleData.email
    //     // };
    //     // let result = await loginGoogle(values);
    //     // if(result?.status !== "SUCCESS"){
    //     //   dispatch({ type: "SET_LOADING", payload: false });
    //     //   return toast.error("Something went wrong.",{toast:"login-error"});
    //     // }
    //     // dispatch({ type: "SET_IS_LOGGED_IN", payload: true });
    //     // dispatch({ type: "SET_USER", payload: result?.data?.user });
    //     // dispatch({ type: "SET_EMPLOYEE", payload: result?.data?.employee });
    //     // dispatch({ type: "SET_PERMISSION", payload: result?.data?.permission });
    //     // dispatch({ type: "SET_ROLE", payload: result?.data?.role });
    //     // dispatch({ type: "SET_LOADING", payload: false });
    //     // return navigate("/panel/dashboard");    
    //   };
    //   const errorMessage = (error) => {
    //     console.log(error);
    //   };

    // const clientId = '574274476352-ab6nrsaaaq8pdj7m0umnd83snug5ie4p.apps.googleusercontent.com';

    // const handleSuccess = (response) => {
    //     console.log('Google Sign-In successful:', response);
    //     // Handle successful sign-in (e.g., update state, etc.)
    // };

    // const handleFailure = (error) => {
    //     console.error('Google Sign-In failed:', error);
    //     // Handle sign-in failure
    // };


    
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('ID');
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    const Navigate = useNavigate();
    let url2 = `${BaseUrl}/add-users/findemail`
    let body = {
        "emailId": email
    }
    axios
        .post(url2, body)
        .then((res) => {
            setrole(res.data.data[0].role)
        })

    const handleClickss = (e) => {
     
        // onLogin()
        const redirectUrl = "/newloginss?/"+email+"/"+role;

        // Redirect to the new URL
        window.location.href = redirectUrl;
    }

    const handleClick = (e) => {
        setSearchLoading(true);
        let url2 = `${BaseUrl}/evb-login/create`
        let body = {
            "userName": email
        }
          axios
            .post(url2, body)
            .then((res) => {
                console.log(res,"wwwww")
                setresponse(res.data.status);
                // setexpires()
                localStorage.setItem('expires',res.data.data[0].accessToken)
                if (res.data.status === 'Ok') {
                    setHideOtpBtn(false);
                    // onLogin();
                } else {
                    setHideOtpBtn(true);
                }

                if (res.data.status === "Ok") {
                    success();
                }
                else {
                    error()
                }
            }).catch((e) => {
                setSearchLoading(false);
                error()
            })
    }

   useEffect( () =>  {
      localStorage.setItem('expires', expires)
   },[expires])
   useEffect( () =>  {
    localStorage.setItem('gmail', email)
 },[email])
 useEffect( () =>  {
    localStorage.setItem('role', role)
 },[expires])
  console.log("expires",expires)

  const responseGoogle = (response) => {
    console.log(response);
    // Handle the response, e.g., send it to your backend for authentication
  };

//   const responseMessage="sucess"

//   const errorMessage="error"

 
   
 

    return (
        <>
            <div className='loginNewLandingpage'>
                <img style={{ height: "100vh" , width:"100%"}} src='Mask group 1.jpg'></img>
            </div>
            <Row className='loginabs' 
            >
                    <div className='logincontentmain' style={{ height: '300px', width: '500px', backgroundColor: 'white' }}>
                        <div className='image'>
                            <img src="logoipsum.jpg" alt="" />
                        </div>
                        <br />
                        <br />
                        {response == "Ok" ? (<>
                            <div className='Text'>
                                <span >OTP has Been Successfully Sent to your Email Id,</span>
                            </div>
                            <div className='Texts'>
                                <span >Please Click Next to Enter Your OTP.</span>
                            </div>
                            <br />
                            <br />
                            <Row >
                                <LoadingButton style={{ marginLeft: '150px', width: '220px', backgroundColor: '#1DA0E1' }}
                                    // disabled={response === !"" ? true : false}
                                    className="newUser"
                                    loadingPosition="start"
                                    variant="contained"
                                    onClick={() => handleClickss("Submit")}
                                //   loading={searchLoading}
                                //   style={{
                                //     marginTop: "10%",
                                //     marginLeft: "-5px",
                                //     // marginBottom: "50px",
                                //   }}
                                >
                                    Next
                                </LoadingButton>
                            </Row>
                        </>)
                            : ""
                        }
                        {
                            hideOtpBtn == true ? (
                                <div>
                                    <div className='loginmain'>
                                        <span >Login</span>
                                    </div>
                                    <Row style={{ marginTop: "7%", marginBottom: '4%', justifyContent: "center" }}>
                                        <TextField id="fullWidth"
                                            // error={FirstNameError}
                                            className='loginUsernameInput'
                                            label="Email"
                                            onChange={(e) => setemail(e.target.value)} variant="outlined"
                                        />
                                    </Row>

                                    <Row >
                                        <LoadingButton style={{ marginLeft: '150px', width: '220px', backgroundColor: '#1DA0E1' }}
                                            // disabled={response === !"" ? true : false}
                                            className="newUser"
                                            loadingPosition="start"
                                            variant="contained"
                                            onClick={() => handleClick("Submit")}
                                            loading={searchLoading}
                                        //   style={{
                                        //     marginTop: "10%",
                                        //     marginLeft: "-5px",
                                        //     // marginBottom: "50px",
                                        //   }}
                                        >
                                            Get Otp
                                        </LoadingButton>
                                    </Row>

                                    {/* <div style = {{marginLeft:"130px",marginTop:"15px",width:"400px"}}>

                         <GoogleOAuthProvider clientId='548991948586-2l2if90fholqlohdaftric7983gbva3c.apps.googleusercontent.com'>
                    <GoogleLogin style={{marginLeft:"60px"}} onSuccess={responseMessage} onError={errorMessage}   />
                    </GoogleOAuthProvider>;
                    </div>  */}
                                    

                                    {/* <Modal
        title="Session Timeout"
        visible={showSessionTimeoutModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Your session has timed out. Please log in again.</p>
      </Modal> */}
                                </div>
                            )
                                :
                                <></>
                        }
                         
                    </div>
                   

                    <div>
                   
        </div>
                {/* </Col> */}
            </Row>
        </>
    )
}
export default Newlogin