import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import { Col, Select, Row, Tabs, DatePicker, Space,Modal } from 'antd';
import { WestOutlined } from '@mui/icons-material';
import { Column } from '@ant-design/plots';
import axios from "axios";
import "./Dasboard.css"
import { BaseUrl } from '../../../config'
import { message, Upload } from "antd";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Newlogin/Authprovider'
// import IdleTimeout from '../../../Newlogin/IdleTimeout';





const Graph = () => {
  const chartRef = useRef(null);
  const { Option } = Select;
  const [activeTab, setActiveTab] = useState("1");
  const [covered, setcovered] = useState('');
  const [notcovered, setnotcovered] = useState('');
  const [coveredwcondition, setcoveredwcondition] = useState('');
  const [detailna, setdetailna] = useState('');
  const [selectedOption, setSelectedOption] = useState("select");
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null)
  const [coveredjan, setcoveredjan] = useState('');
  const [coveredfeb, setcoveredfeb] = useState('');
  const [coveredmarch, setcoveredmarch] = useState('');
  const [coveredapr, setcoveredapr] = useState('');
  const [coveredmay, setcoveredmay] = useState('');
  const [coveredjune, setcoveredjune] = useState('');
  const [coveredjuly, setcoveredjuly] = useState('');
  const [coveredaug, setcoveredaug] = useState('');
  const [coveredsep, setcoveredsep] = useState('');
  const [coveredoct, setcoveredoct] = useState('');
  const [coverednov, setcoverednov] = useState('');
  const [covereddec, setcovereddec] = useState('');
  const [notcoveredjan, setnotcoveredjan] = useState('');
  const [notcoveredfeb, setnotcoveredfeb] = useState('');
  const [notcoveredmarch, setnotcoveredmarch] = useState('');
  const [notcoveredapr, setnotcoveredapr] = useState('');
  const [notcoveredmay, setnotcoveredmay] = useState('');
  const [notcoveredjune, setnotcoveredjune] = useState('');
  const [notcoveredjuly, setnotcoveredjuly] = useState('');
  const [notcoveredaug, setnotcoveredaug] = useState('');
  const [notcoveredsep, setnotcoveredsep] = useState('');
  const [notcoveredoct, setnotcoveredoct] = useState('');
  const [notcoverednov, setnotcoverednov] = useState('');
  const [notcovereddec, setnotcovereddec] = useState('');
  const [coveredwithconditionjan, setcoveredwithconditionjan] = useState('');
  const [coveredwithconditionfeb, setcoveredwithconditionfeb] = useState('');
  const [coveredwithconditionmarch, setcoveredwithconditionmarch] = useState('');
  const [coveredwithconditionapr, setcoveredwithconditionapr] = useState('');
  const [coveredwithconditionmay, setcoveredwithconditionmay] = useState('');
  const [coveredwithconditionjune, setcoveredwithconditionjune] = useState('');
  const [coveredwithconditionjuly, setcoveredwithconditionjuly] = useState('');
  const [coveredwithconditionaug, setcoveredwithconditionaug] = useState('');
  const [coveredwithconditionsep, setcoveredwithconditionsep] = useState('');
  const [coveredwithconditionoct, setcoveredwithconditionoct] = useState('');
  const [coveredwithconditionnov, setcoveredwithconditionnov] = useState('');
  const [coveredwithconditiondec, setcoveredwithconditiondec] = useState('');
  // const[detailnajan,]
  const [detailnajan, setdetailnajan] = useState('');
  const [detailnafeb, setdetailnafeb] = useState('');
  const [detailnamarch, setdetailnamarch] = useState('');
  const [detailnaapr, setdetailnaapr] = useState('');
  const [detailnamay, setdetailnamay] = useState('');
  const [detailnajune, setdetailnajune] = useState('');
  const [detailnajuly, setdetailnajuly] = useState('');
  const [detailnaaug, setdetailnaaug] = useState('');
  const [detailnasep, setdetailnasep] = useState('');
  const [detailnaoct, setdetailnaoct] = useState('');
  const [detailnanov, setdetailnanov] = useState('');
  const [detailnadec, setdetailnadec] = useState('');
  const [response, setResponse] = useState([])

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    // const Modal = ({ isOpen }) => {
    //   return (
    //     <div className={`modal ${isOpen ? 'open' : ''}`}>
    //       <div className="modal-content">
    //         <img src="your_image_url" alt="Logout" />
    //         <p>Logging out...</p>
    //       </div>
    //     </div>
    //   );
    // };

    // useEffect(()=>{

    // },[IdleTimeout])
    // const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);

    // const handleModalOk = () => {
    //   // Redirect to login page or handle logout logic here
    //   setShowSessionTimeoutModal(false);
    //   // Navigate('/');
    // };
  
    // const handleModalCancel = () => {
    //   setShowSessionTimeoutModal(false);


    // };
    

    // const { logout } = useAuth(); // Assuming you have a logout function in your AuthContext

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);
  //   const timeoutRef = useRef(null);

    

  

  console.log(response)
  const handleSelectChange = (value) => {
    setSelectedDate(null);
    setSelectedOption(value);

  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      console.log(date.format("YYYY-MM-DD"))
      setSelectedDate(date);
      setFilterDate(date.format("YYYY-MM-DD"))
      setSelectedOption('select');
    }
  };


  const Navigate = useNavigate();

  

  useEffect(() => {

    if (selectedDate) {
      const getDateRes = async () => {
        const url = `${BaseUrl}/coverage-details/count-responses-by-date/${filterDate}`
        try {
          const res = await axios.post(url)
          const response = res.data
          console.log(res.data, "from date filter")
          setcovered(response.covered)
          setnotcovered(response.notCovered)
          setcoveredwcondition(response.coveredWithCondition)
          setdetailna(response.detailNotAvailable)
        } catch (error) {

        }
      }
      getDateRes()
    } else {
      const getTable = async () => {
        const tableUrl = `${BaseUrl}/coverage-details/count-responses-by-month`;
        try {
          const response = await axios.get(tableUrl);
          const responseData = response.data;
          console.log(responseData, "responsedata")
          setResponse(responseData)
          setcovered(responseData.datecovered || 0);
          // console.log(responseData.covered, "covered")
          setcoveredwcondition(responseData.coveredWithCondition || 0);
          setnotcovered(responseData.notCovered || 0);
          setdetailna(responseData.detailNotAvailable || 0);
          setcoveredjan(responseData.data[0]?.['01-2024'].covered)
          // console.log(responseData.data[0]?.['01-2024'].covered, "coveredjan")

          setcoveredfeb(responseData.data[0]?.['02-2024'].covered)
          setcoveredmarch(responseData.data[0]?.['03-2024'].covered)
          setcoveredapr(responseData.data[0]?.['04-2024'].covered)
          setcoveredmay(responseData.data[0]?.['05-2024'].covered)
          setcoveredjune(responseData.data[0]?.['06-2024'].covered)
          setcoveredjuly(responseData.data[0]?.['07-2024'].covered)
          setcoveredaug(responseData.data[0]?.['08-2024'].covered)
          setcoveredsep(responseData.data[0]?.['09-2024'].covered)
          setcoveredoct(responseData.data[0]?.['10-2024'].covered)
          setcoverednov(responseData.data[0]?.['11-2024'].covered)
          setcovereddec(responseData.data[0]?.['12-2024'].covered)
          console.log(responseData.data[0]?.['01-2024'].covered, "coveredjan")
          setnotcoveredjan(responseData.data[0]?.['01-2024'].notCovered)
          setnotcoveredfeb(responseData.data[0]?.['02-2024'].notCovered)
          setnotcoveredmarch(responseData.data[0]?.['03-2024'].notCovered)
          setnotcoveredapr(responseData.data[0]?.['04-2024'].notCovered)
          setnotcoveredmay(responseData.data[0]?.['05-2024'].notCovered)
          setnotcoveredjune(responseData.data[0]?.['06-2024'].notCovered)
          setnotcoveredjuly(responseData.data[0]?.['07-2024'].notCovered)
          setnotcoveredaug(responseData.data[0]?.['08-2024'].notCovered)
          setnotcoveredsep(responseData.data[0]?.['09-2024'].notCovered)
          setnotcoveredoct(responseData.data[0]?.['10-2024'].notCovered)
          setnotcoverednov(responseData.data[0]?.['11-2024'].notCovered)
          setnotcovereddec(responseData.data[0]?.['12-2024'].notCovered)
          setcoveredwithconditionjan(responseData.data[0]?.['01-2024'].coveredWithCondition)
          setcoveredwithconditionfeb(responseData.data[0]?.['02-2024'].coveredWithCondition)
          setcoveredwithconditionmarch(responseData.data[0]?.['03-2024'].coveredWithCondition)
          setcoveredwithconditionapr(responseData.data[0]?.['04-2024'].coveredWithCondition)
          setcoveredwithconditionmay(responseData.data[0]?.['05-2024'].coveredWithCondition)
          setcoveredwithconditionjune(responseData.data[0]?.['06-2024'].coveredWithCondition)
          setcoveredwithconditionjuly(responseData.data[0]?.['07-2024'].coveredWithCondition)
          setcoveredwithconditionaug(responseData.data[0]?.['08-2024'].coveredWithCondition)
          setcoveredwithconditionsep(responseData.data[0]?.['09-2024'].coveredWithCondition)
          setcoveredwithconditionoct(responseData.data[0]?.['10-2024'].coveredWithCondition)
          setcoveredwithconditionnov(responseData.data[0]?.['11-2024'].coveredWithCondition)
          setcoveredwithconditiondec(responseData.data[0]?.['12-2024'].coveredWithCondition)
          setdetailnajan(responseData.data[0]?.['01-2024'].detailNotAvailable)
          setdetailnafeb(responseData.data[0]?.['02-2024'].detailNotAvailable)
          setdetailnamarch(responseData.data[0]?.['03-2024'].detailNotAvailable)
          setdetailnaapr(responseData.data[0]?.['04-2024'].detailNotAvailable)
          setdetailnamay(responseData.data[0]?.['05-2024'].detailNotAvailable)
          setdetailnajune(responseData.data[0]?.['06-2024'].detailNotAvailable)
          setdetailnajuly(responseData.data[0]?.['07-2024'].detailNotAvailable)
          setdetailnaaug(responseData.data[0]?.['08-2024'].detailNotAvailable)
          setdetailnasep(responseData.data[0]?.['09-2024'].detailNotAvailable)
          setdetailnaoct(responseData.data[0]?.['10-2024'].detailNotAvailable)
          setdetailnanov(responseData.data[0]?.['11-2024'].detailNotAvailable)
          setdetailnadec(responseData.data[0]?.['12-2024'].detailNotAvailable)
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error appropriately, e.g., show an error message to the user
        }
      };
      getTable();
    }

    // http://localhost:3005/
  }, [selectedDate, selectedOption])


  // console.log(data.11-2023)
  const tooltipStyles = `
    .chartjs-tooltip {
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
    }
  `;
  const TabPane = Tabs;
  let data
  if (selectedDate) {
    data = [
      {
        type: filterDate,
        sales: covered,
      },
    ];
  } else {
    data = [
      {
        type: 'Jan',
        sales: coveredjan,
      },
      {
        type: 'Feb',
        sales: coveredfeb,
      },
      {
        type: 'Mar',
        sales: coveredmarch,
      },
      {
        type: 'Apr',
        sales: coveredapr,
      },
      {
        type: 'May',
        sales: coveredmay,
      },
      {
        type: 'Jun',
        sales: coveredjune,
      },
      {
        type: 'Jul',
        sales: coveredjuly,
      },
      {
        type: 'Aug',
        sales: coveredaug,
      },
      {
        type: 'Sep',
        sales: coveredsep,
      },
      {
        type: 'Aug',
        sales: coveredaug,
      },
      {
        type: 'Oct',
        sales: coveredoct,
      },
      {
        type: 'Nov',
        sales: coverednov,
      },
      {
        type: 'Dec',
        sales: covereddec,
      },
    ];
  }
  const config = {
    data: data,
    height: 350,
    width: 1040,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'center',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,

      },
    },
    xAxis: {
      label: {
        beginAtZero: true,
        autoHide: true,
        autoRotate: false,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 30,
        height: 40,
      },
    },
    meta: {
      type: {
        alias: 'numbers',
      },
      sales: {
        alias: 'Percentage',
      },
    },
    tooltip: {
      customContent: (title, context) => {
        const data = context[0]?.data;
        // if (!data) return;
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              <li
                className="g2-tooltip-list-item"
                style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
              >
                <span className="g2-tooltip-marker" style={{ backgroundColor: "#6294FA" }}></span>
                <span
                  style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between', color: "black" }}
                >
                  <span style={{ marginRight: 5 }}></span>
                  <span className="g2-tooltip-list-item-value">{data?.sales} Requests</span>
                </span>
              </li>
            </ul>
          </>
        );
      },
    },

  };

  let data1
  if (selectedDate) {
    data1 = [
      {
        type: filterDate,
        sales: coveredwcondition,
      },
    ];
  } else {
    data1 = [
      {
        type: 'Jan',
        sales: coveredwithconditionjan,
      },
      {
        type: 'Feb',
        sales: coveredwithconditionfeb,
      },
      {
        type: 'Mar',
        sales: coveredwithconditionmarch,
      },
      {
        type: 'Apr',
        sales: coveredwithconditionapr,
      },
      {
        type: 'May',
        sales: coveredwithconditionmay,
      },
      {
        type: 'Jun',
        sales: coveredwithconditionjune,
      },
      {
        type: 'Jul',
        sales: coveredwithconditionjuly,
      },
      {
        type: 'Aug',
        sales: coveredwithconditionaug,
      },
      {
        type: 'Sep',
        sales: coveredwithconditionsep,
      },
      {
        type: 'Oct',
        sales: coveredwithconditionoct,
      },
      {
        type: 'Nov',
        sales: coveredwithconditionnov,
      },
      {
        type: 'Dec',
        sales: coveredwithconditiondec,
      },
    ];
  }

  const config1 = {
    data: data1,
    height: 350,
    width: 1040,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'center',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        beginAtZero: true,
        autoHide: true,
        autoRotate: false,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 30,
        height: 40,
      },
    },
    meta: {
      type: {
        alias: 'numbers',
      },
      sales: {
        alias: 'Request',
      },
    },
    tooltip: {
      customContent: (title, context) => {
        const data = context[0]?.data;
        // if (!data) return;
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              <li
                className="g2-tooltip-list-item"
                style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
              >
                <span className="g2-tooltip-marker" style={{ backgroundColor: "#6294FA" }}></span>
                <span
                  style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between', color: "black" }}
                >
                  <span style={{ marginRight: 5 }}></span>
                  <span className="g2-tooltip-list-item-value">{data?.sales} Requests</span>
                </span>
              </li>
            </ul>
          </>
        );
      },
    },

  };

  let data2
  if (selectedDate) {
    data2 = [
      {
        type: filterDate,
        sales: notcovered,
      },
    ];
  } else {

    data2 = [
      {
        type: 'Jan',
        sales: notcoveredjan,
      },
      {
        type: 'Feb',
        sales: notcoveredfeb,
      },
      {
        type: 'Mar',
        sales: notcoveredmarch,
      },
      {
        type: 'Apr',
        sales: notcoveredapr,
      },
      {
        type: 'May',
        sales: notcoveredmay,
      },
      {
        type: 'Jun',
        sales: notcoveredjune,
      },
      {
        type: 'Jul',
        sales: notcoveredjuly,
      },
      {
        type: 'Aug',
        sales: notcoveredaug,
      },
      {
        type: 'Sep',
        sales: notcoveredsep,
      },
      {
        type: 'Oct',
        sales: notcoveredoct,
      },
      {
        type: 'Nov',
        sales: notcoverednov,
      },
      {
        type: 'Dec',
        sales: notcovereddec,
      },
    ];
  }
  const config2 = {
    data: data2,
    height: 350,
    width: 1040,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'center',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        beginAtZero: true,
        autoHide: true,
        autoRotate: false,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 30,
        height: 40,
      },
    },
    meta: {
      type: {
        alias: 'numbers',
      },
      sales: {
        alias: 'Request',
      },
    },
    tooltip: {
      customContent: (title, context) => {
        const data = context[0]?.data;
        // if (!data) return;
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              <li
                className="g2-tooltip-list-item"
                style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
              >
                <span className="g2-tooltip-marker" style={{ backgroundColor: "#6294FA" }}></span>
                <span
                  style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between', color: "black" }}
                >
                  <span style={{ marginRight: 5 }}></span>
                  <span className="g2-tooltip-list-item-value">{data?.sales} Requests</span>
                </span>
              </li>
            </ul>
          </>
        );
      },
    },

  };
  let data3
  if (selectedDate) {
    data3 = [
      {
        type: filterDate,
        sales: detailna,
      },
    ];
  } else {
    data3 = [
      {
        type: 'Jan',
        sales: detailnajan,
      },
      {
        type: 'Feb',
        sales: detailnafeb,
      },
      {
        type: 'Mar',
        sales: detailnamarch,
      },
      {
        type: 'Apr',
        sales: detailnaapr,
      },
      {
        type: 'May',
        sales: detailnamay,
      },
      {
        type: 'Jun',
        sales: detailnajune,
      },
      {
        type: 'Jul',
        sales: detailnajuly,
      },
      {
        type: 'Aug',
        sales: detailnaaug,
      },
      {
        type: 'Sep',
        sales: detailnasep,
      },
      {
        type: 'Oct',
        sales: detailnaoct,
      },
      {
        type: 'Nov',
        sales: detailnanov,
      },
      {
        type: 'Dec',
        sales: detailnadec,
      },
    ];

  }
  const config3 = {
    data: data3,
    height: 350,
    width: 1040,
    xField: 'type',
    yField: 'sales',
    label: {
      // formatter: (v) => v,
      position: 'center',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        // formatter: val => `${val}°C`,
        beginAtZero: true,
        autoHide: true,
        autoRotate: false,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 30,
        height: 40,
      },
    },
    meta: {
      type: {
        alias: 'numbers',
      },
      sales: {
        alias: 'Request',
      },
    },
    // tooltip: {
    //   callbacks: {
    //     label: (context) => {
    //       const salesPercentage = `${context.dataset.data[context.dataIndex].sales}%`;
    //       return `Request: ${salesPercentage} `+"%";
    //     },
    //   },
    // },
    // tooltip: {
    //   formatter: (datum) => {
    //     const percentage = `${datum.sales}%`;
    //     return `Request: ${percentage}`;
    //   },
    // }
    tooltip: {
      customContent: (title, context) => {
        const data = context[0]?.data;
        // if (!data) return;
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              <li
                className="g2-tooltip-list-item"
                style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
              >
                <span className="g2-tooltip-marker" style={{ backgroundColor: "#6294FA" }}></span>
                <span
                  style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between', color: "black" }}
                >
                  <span style={{ marginRight: 5 }}></span>
                  <span className="g2-tooltip-list-item-value">{data?.sales} Requests</span>
                </span>
              </li>
            </ul>
          </>
        );
      },
    },

  };


  const items = [
    {
      key: 0,
      label: 'Covered',
      children: <Column {...config} />,
    },
    {
      key: 1,
      label: 'Covered with Condition',
      children: <Column {...config1} />,
    },
    {
      key: 2,
      label: 'Coverage NA',
      children: <Column {...config2} />,
    },
    {
      key: 3,
      label: 'Detail NA',
      children: <Column {...config3} />,
    },
  ];
  const onChange = (key) => {
    setActiveTab(key)
  };

  return (
    
    <div className='dashboardpicontainer'>
      
      <NewHeader />
      <Sidebar1 />
      
      <div className='dashboardminihead1'>
        <div className='dashboardminihead2'>
          <div className='dashboardminiheadtitle'>
            Dashboard
            {/* <div className='timeline' style={{ marginLeft: '957%' }}>Timeline</div> */}
          </div>
          <div className='dashboardrightdivdash' >
            <div className='dashboardtimeline'>Timeline</div>
            <Space>
              <Select
                defaultValue={"Select"}
                style={{ width: '150px' }}
                value={selectedOption}
                onSelect={handleSelectChange}
              >
                <Option value="Today">Today</Option>
                <Option value="Tomorrow">Tomorrow</Option>
                <Option value="Yesterday">Yesterday</Option>
                <Option value="ThisWeek">This Week</Option>
                <Option value="Year">This Year</Option>
                <Option value="Thismonth">This Month</Option>
              </Select>
              <DatePicker
                picker="date"
                // dropdownAlign={{ points: ['tl', 'bl'], offset: [0, 4] }}
                style={{
                  marginLeft: '8px',
                  // position: 'relative', 
                  // left: '-100px', 
                  // width: '50%',
                }}
                value={selectedDate}
                popupStyle={{ left: '80%' }}
                onChange={handleDateChange}
              />
            </Space>
          </div>
        </div>
      </div>

      {/* <Modal
        title="Session Timeout"
        visible={showSessionTimeoutModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Your session has timed out. Please log in again.</p>
      </Modal> */}

      <div className='dashboardtopnewdash'>
        <Tabs
          // style={{ width: "100%" }}
          defaultActiveKey={activeTab}
          items={items}
          key={activeTab}
          onChange={onChange}
        // indicatorSize={(origin) => origin - 4}
        />
      </div>

    </div>
    
    
  );
};

export default Graph;