// import React from 'react';
// import '../Pverify/NewScreens/Addusertable/'
// import NewHeader from '../../NewHeader';
// import Sidebar1 from '../../Sidebar1';
import { json, useNavigate, useParams } from "react-router";
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from 'react';
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack, useEventCallback } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import axios from "axios";
import { message, Upload } from "antd";
import { LoadingButton } from '@mui/lab';
import {BaseUrl} from '../config'
// import { useAuth } from '../Newlogin/Authprovider';


const customStyles = {
  width: 500,
  height: 60,
  position: 'relative',
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",
  transform: 'translate(15px, 2px)',
  pointerEvents: 'none',
};


const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
};

const inputStyle2 = {
  width: '500px',
  backgroundColor: 'white',
  marginLeft: '1%'
};

const containerStyle = {
  width: "90%",
  margin: 'auto',
  marginTop: "1%",
  marginLeft: "7%",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "8px"
};
const stackStyle = {
  display: "flex",
  gap: "5px",
  marginTop: "0%",
  justifyContent: "center",
  marginTop: "2%"


}

const stackStyleTop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  // margin:" 0 auto",
  justifyContent: "center",

  marginTop: "2%",
  // marginBottom:"5px"
}
// const stackStyledrop = {

//   display: "flex",
//   justifyContent: "left",
//   // gap:"5px",
//   margin: "auto",
//   marginTop: "2%",
//   marginLeft: "8%"
// }

const stackStyledrop = {
  display: "flex",
  flexDirection: "column", // Change to column to center the single input
  alignItems: "center", // Center items horizontally
  gap: "5px",
  marginTop: "2%",
  marginRight: "520px",  // Use a fixed value instead of a percentage
};

const stackStylebot = {
  display: "flex",
  justifyContent: "left",
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"

}

const Dropdown = () => {
  const success = () => {
    message.open({
      type: "success",
      content: "New User was created Successfully",
    });
    //     messageApi.success("Logged in successfully")
  };
  const error = () => {
    message.open({
      type: "error",
      content: "Email already exists",
    });
    // messageApi.error("Invalid username or otp")
  };

  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  const handleChange1 = (event) => {
    sethcp(event.target.value);
  };

  const handleChange2 = (event) => {
    setrole(event.target.value);
  };

  // const { isAuthenticated } = useAuth();
  // const navigate = useNavigate();
  // // useEffect(() => {
  //   if (!isAuthenticated()) {
  
  //     navigate('/');
  //   }
  // }, [isAuthenticated, navigate]);

  const [email, setemail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [dob, setdob] = useState('');
  const [hcp, sethcp] = useState([]);
  const [status, setstatus] = useState('');
  const [drop, setdrop] = useState('');
  const [role, setrole] = useState('');
  const [selectedHcp, setSelectedHcp] = useState('');
  const [selectedlist, setSelectedlist] = useState('')
  const [last, setlast] = useState('');
  // const [isEmailValid, setIsEmailValid] = useState(true);
  // const [roles, setroles] = useState('');


 

 
useEffect(()=>{
  const url8 = "https://hr-agent-prod.precium.ai/Job_Description";

  axios
    .get(url8)
    .then((res) => {
      console.log(res.data, "zzzzzz");
  
      // Assuming job titles are stored in an array under the 'data' property
      const jobTitles = res.data.map((job) => job.job_title);
      setdrop(jobTitles)
      
      console.log("Job Titles:", jobTitles);
  
      // setLoading(false);
      // setdata(res.data.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    });
  },[])

 




  return (
    <div className='picontainer'>
      {/* <NewHeader />
      <Sidebar1 /> */}
      {/* <div className='minihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/tablenew?/" + my_list[4] + "")} />
        </div>
        <div className='miniheadtitle'>
          Add New User
        </div>
      </div> */}
      <div>
        <div style={containerStyle} >
          {/* <div style={{ paddingTop: "20px", marginLeft: "8%" }}><b>Personal Information</b></div> */}
          {/* <div className="containerlogin"
            style={containerStyle} > */}
          <Grid className='logingrid' >
            {/* <Stack direction="row" spacing={1} style={stackStyleTop} className='top'  > */}

              {/* <TextField
                sx={{ width: '500px' }}
                label="Email ID"
                id="email"
                variant="outlined"
                value={email}
                error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                placeholder="Enter your Email"
                onChange={handleEmailChange}
                defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '500px' }}
                label="First Name"
                id="npi"
                variant="outlined"
                value={firstname}
                error={!isFirstnameValid}
                // helperText={!isFirstnameValid ? 'required feild' : ''}
                placeholder="Enter your First Name"
                onChange={handleFirstnameChange}
                defaultValue="Small"
                size="small"
              />
            </Stack>


            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '500px' }}
                label="Last Name"
                id="npi"
                variant="outlined"
                value={lastname}
                error={!isLastnameValid}
                // helperText={!isLastnameValid ? 'Only letters are allowed' : ''}
                placeholder="Enter your Last Name"
                onChange={handleLastnameChange}
                defaultValue="Small"
                size="small"
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <div >
                    <DatePicker
                      textFieldStyle={{ width: '500px' }}
                      disableFuture
                      inputFormat="MM/DD/YYYY"
                      className='date-picker-width'
                      slotProps={{ textField: { size: 'small', fullWidth: true } }}
                      label="DOB"
                      onChange={(newValue) =>
                        setdob(newValue.format("MM/DD/YYYY"))
                      }
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </Stack> */}

            {/* <Stack direction="row" spacing={1} style={stackStyle} className='top'  >
              <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  id="option1"
                  style={inputStyle}
                  sx={{ width: '100%' }}
                  value={status}
                  error={!isstatusValid}
                  helperText={!isstatusValid ?'Please select value ' : ''}
                  label="status"
                  onChange={handleChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="In Active">Inactive</MenuItem>
                </Select>
              </FormControl> */}

              {/* {my_list[4] === "HCP%20Admin" || my_list[4].trim() === "HCP%20Admin" ? (
                <>

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="outlined">
                    <InputLabel>HCP Hospital</InputLabel>
                    <Select
                      id="option1"
                      style={inputStyle}
                      sx={{ width: '100%' }}
                      label="HCP Office"
                      onChange={handleChange4}
                      value={selectedHcp}
                      error={!ishospValid}
                      helperText={!ishospValid ?'Please select value ' : ''}
                    >
                      {my_list[4] === "HCP%20Admin" ? (
                        <MenuItem value={selectedHcp}>
                          {selectedHcp}
                        </MenuItem>
                      ) : (
                        hcp?.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>HCP Hospital</InputLabel>
                    <Select
                      id="option1"
                      style={inputStyle}
                      sx={{ width: '100%' }}
                      label="HCP Office"
                      onChange={handleChange4}
                      value={selectedHcp}
                      error={!ishospValid}
                      helperText={!ishospValid ?'Please select value ' : ''}
                    >
                      {hcp?.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Stack> */}

            {/* {my_list[4] == "HCP%20Admin" ? (<> */}
              <Stack direction="row"  style={stackStyledrop} className='top'  >
              <FormControl sx={{ mt: 3 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={drop}
                        value ={drop}
                        
                        sx={{ width: 413 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="job"
                           
                          />
                        )}
                      />
                    </FormControl>
              </Stack>
            {/* </>)
              :
              (<><Stack direction="row"  style={stackStyledrop} className='top'  >

              <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined" style={inputStyle2}>
                <InputLabel>Role</InputLabel>
                <Select
                  id="option1"
                  sx={{ width: '100%', }}
                  label="Role"
                  onChange={handleChange2}
                  value={role}
                  error={!isroleValid}
                  helperText={!isroleValid ?'Please select value ' : ''}
                 
                >
                  <MenuItem value="HCP Admin">HCP Admin</MenuItem>
                  <MenuItem value="HCP Executive">HCP Executive</MenuItem>
                  <MenuItem value="Support Executive - Creator">Support Executive - Creator</MenuItem>
                  <MenuItem value="Support Executive - Reviewer">Support Executive - Reviewer</MenuItem>
                  <MenuItem value="Support Executive - Approver">Support Executive - Approver</MenuItem>
                  <MenuItem value="QA">QA</MenuItem>
                  <MenuItem value="Dev-ops Engineer">Dev-ops Engineer</MenuItem>
                </Select>
              </FormControl>
              

            </Stack></>)
            } */}
            {/* <Stack direction="row" spacing={3} style={{ marginTop: "3%", justifyContent: "center", paddingBottom: "1%" }}>
              <Grid>
                <button className='clear' onClick={handleReset}>Reset</button>
              </Grid>
              <Grid>
                <button className='add' onClick={(e) => Navigate("/tablenew?/" + my_list[4] + "")}>Cancel</button>
              </Grid>
              <Grid>
                <button className="submit" onClick={handleSubmit}  >Submit</button>
              </Grid>
            </Stack> */}
          </Grid>
          <div>
          </div>
          {/* </div> */}
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dropdown