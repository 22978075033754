// import React from 'react';
import "./ebvrequest.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import { json, useNavigate, useParams } from "react-router";
import axios from "axios";
import React, { useEffect, useState,useRef } from 'react';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'



const customStyles = {
  width: 500, // Adjust the width as needed
  height: 60, // Adjust the height as needed
  position: 'relative', // Needed for label placement
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
  pointerEvents: 'none', // To allow clicking through the label
};


const inputStyle = {

  width: '500px',
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};
const containerStyle = {

  // alignItems: 'center',
  minHeight: '65vh',
  width: "90%",
  margin: 'auto',
  marginTop: "3%",
  marginLeft: "7%",
  backgroundColor: "white",
  borderRadius: "8px"
};
const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}
const stackStyleTop = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",

  marginTop: "5%",
  // marginBottom:"5px"
}
const stackStyledrop = {

  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "right",
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  margin: "auto",
  marginTop: "2%"

}

const EbvRequest = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }

    useEffect(() => {
      let timeoutId;
  
      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // logout(); // Logout the user
          navigate('/'); // Redirect to login page
        }, 30 * 60 * 1000); // 5 minutes
      };
  
      const handleUserActivity = () => {
        resetTimeout();
      };
  
      resetTimeout(); // Initial call to start the timeout
  
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('mousedown', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
  
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('mousedown', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }, [navigate]);

    // const timeoutRef = useRef(null);

    // const logout = () => {
    //     console.log('Logging out due to inactivity');
    //     localStorage.clear();
    //     navigate('/');
    // };
    
    // const resetTimeout = () => {
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
    // };
    
    // const handleUserActivity = () => {
    //     console.log('User activity detected, resetting idle timeout');
    //     resetTimeout();
    // };
    
    // useEffect(() => {
    //     resetTimeout();
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('mousedown', handleUserActivity);
    //     window.addEventListener('keypress', handleUserActivity);
    //     window.addEventListener('touchstart', handleUserActivity);
    
    //     return () => {
    //         clearTimeout(timeoutRef.current);
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('mousedown', handleUserActivity);
    //         window.removeEventListener('keypress', handleUserActivity);
    //         window.removeEventListener('touchstart', handleUserActivity);
    //     };
    // }, []);
  // }, [isAuthenticated, navigate]);
  const [caseid, setcaseid] = useState([]);
  const [insurance, setinsurance] = useState([]);
  const [status, setstatus] = useState([]);
  const [plan, setplan] = useState([]);
  const [fname, setfname] = useState([]);
  const [lname, setlname] = useState([]);
  const [dob, setdob] = useState([]);
  //   const [subscriber, setsubscriberid] = useState([]);
  const managnment = window.location.href
  const my_list = managnment.split("/")
  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")
    let url2 = `${BaseUrl}/coverage-details/findOne`
    let body = {
      // "Id": my_list[0],
      "masterId": my_list[5]
    }

    axios
      .post(url2, JSON.stringify(body))
      .then((res) => {
        setcaseid(my_list[5])
        setinsurance(res.data.data[0].insuranceName)
        setstatus(res.data.data[0].Response)
        setplan(res.data.data[0].PlanName)
        setfname(res.data.data[0].Description)
        setlname(res.data.data[0].link)
        setdob(res.data.data[0].createdAt)
      })
  }, [])

  const Navigate = useNavigate();

  return (
    <div className='ebvrequestpicontainer'>
      <NewHeader />
      <Sidebar1 />

      <div className='ebvrequestminihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/ebvtable?/" + my_list[4] + "")} />
        </div>
        <div className='ebvrequestminiheadtitle'>
          eBV Details
        </div>
      </div>
      <div>
        <div className="ebvrequestcontainerlogin" style={{
          width: "90%",
          margin: 'auto',
          marginTop: "3%",
          marginLeft: "7%",
          backgroundColor: "white",
          borderRadius: "8px"
        }} >

          <div>
            <div className='ebvrequestpersonal'>eBV Information</div>
            <Row className='ebvrequestuserdet'>
              {/* <Col  span={6}  className='userdetCol'>
                <div className='userdetCol1'>Case Id</div>
                <div className='userdetCol2'>{caseid}</div>
            </Col> */}
              <Col span={6} className='ebvrequestuserdetCol'>
                <div className='ebvrequestuserdetCol1'>Insurance Name</div>
                <div className='ebvrequestuserdetCol2'>{insurance}</div>
              </Col>
              <Col span={6} className='ebvrequestuserdetCol'>
                <div className='ebvrequestuserdetCol1'>Status</div>
                <div className='ebvrequestuserdetCol2'>{status}</div>
              </Col>
              <Col span={6} className='ebvrequestuserdetCol'>
                <div className='ebvrequestuserdetCol1'>Plan Name</div>
                <div className='ebvrequestuserdetCol2'>{plan}</div>
              </Col>

            </Row>
          </div>
          <hr style={{ backgroundColor: "#C8EDFF", height: "4px", border: "none", marginTop: "3%" }} />
          <Row className='ebvrequestuserdet2'>
            <Col span={5} className='ebvrequestuserdetCol'>
              <div className='ebvrequestuserdetCol1'>Description</div>
              <div className='ebvrequestuserdetCol2'>{fname}</div>
            </Col>
            <Col span={11} className='ebvrequestuserdetCol'>
              <div className='ebvrequestuserdetCol1'>Link</div>
              <div className='ebvrequestuserdetCol2'>{lname}</div>
            </Col>
            <Col offset={2} span={6} className='ebvrequestuserdetCol'>
              <div className='ebvrequestuserdetCol1'>date</div>
              <div className='ebvrequestuserdetCol2'>{dob}</div>
            </Col>
          </Row>

          <hr style={{ backgroundColor: "#C8EDFF", height: "4px", border: "none", marginTop: "3%" }} />

          {/* <Row className='userdet3'>
          </Row> */}

          {/* <Row className='userdet4'>

            {/* <Col  span={6}  className='userdetCol11'>
                <div className='userdetCol11' >Edit Hospital</div>
        </Col> */}

          {/* </Row>  */}
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EbvRequest 