// import React from 'react'
import NewHeader from '../../NewHeader'
import "./NewUser.css"
import Sidebar1 from '../../Sidebar1'
import React, { useEffect, useState,useRef } from 'react';
import Pagination from '@mui/material/Pagination';
import { Col, Row, Modal} from 'antd'
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { ID } from '@aws-amplify/datastore/lib-esm/util';
import {BaseUrl} from '../../../config'


import { useAuth } from '../../../Newlogin/Authprovider'
const NewUsers = () => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate()
  // useEffect(() => {
    if (!isAuthenticated()) {
  
      navigate('/');
    }
  // }, [isAuthenticated, navigate]);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true);

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage - 1);
  // };

  // const startIndex = page * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;


  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };


  const managnment = window.location.href
  const my_list = managnment.split("/")
  useEffect(() => {
    getData()
  }, [])

  const [editingData, setEditingData] = useState(null);

  const handleEdit = (row) => {
    setEditingData(row);
    const editUrl = `/editaddhospital/${my_list[4]}/${row.masterId}`;
    navigate(editUrl);
  }
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // logout(); // Logout the user
        navigate('/'); // Redirect to login page
      }, 30 * 60 * 1000); // 5 minutes
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    resetTimeout(); // Initial call to start the timeout

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [navigate]);

  // const timeoutRef = useRef(null);

  // const logout = () => {
  //     console.log('Logging out due to inactivity');
  //     localStorage.clear();
  //     navigate('/');
  // };
  
  // const resetTimeout = () => {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  // };
  
  // const handleUserActivity = () => {
  //     console.log('User activity detected, resetting idle timeout');
  //     resetTimeout();
  // };
  
  // useEffect(() => {
  //     resetTimeout();
  
  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('mousedown', handleUserActivity);
  //     window.addEventListener('keypress', handleUserActivity);
  //     window.addEventListener('touchstart', handleUserActivity);
  
  //     return () => {
  //         clearTimeout(timeoutRef.current);
  //         window.removeEventListener('mousemove', handleUserActivity);
  //         window.removeEventListener('mousedown', handleUserActivity);
  //         window.removeEventListener('keypress', handleUserActivity);
  //         window.removeEventListener('touchstart', handleUserActivity);
  //     };
  // }, []);

  let lastinsert =  (localStorage.getItem("lastinsert"));
  console.log(lastinsert,"ssssyyyy")
  

  const getData = () => {
    const url8 = `${BaseUrl}/hospitals/findAll`;
    axios
      .get(url8)
      .then((res) => {
        setLoading(false);
        setdata(res.data.data)
      })
      .catch((error) => {
      });
  }

  const handleDelete = (id) => {
    const deleteUrl = `${BaseUrl}/hospitals/delete`;
    const requestBody = {
      masterId: id
    };

    axios.post(deleteUrl, requestBody)
      .then((response) => {
        getData()
      })
      .catch(e => {
        console.error("Error deleting:", e);
      });
  };

  // const navigate = useNavigate()
  const handlenavigate = () => {
    navigate("/addhospital?/" + my_list[4] + "")
  }

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const [searchText, setSearchText] = useState('');
  const tableSearch = () => {
    if (!searchText) {
      setFilteredData([]);
    } else {
      const lowerCaseValue = searchText.toLowerCase();
      const filteredRows = data.filter((row) =>
        row.hospitalName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredRows);
    }
  };

  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
    useEffect(() => {
        const sessionTimeout = setTimeout(() => {
          setShowSessionTimeoutModal(true);
        }, 2 * 60 * 1000);
    
        return () => clearTimeout(sessionTimeout);
      }, []);
    
      const handleModalOk = () => {
        // Redirect to login page or handle logout logic here
        setShowSessionTimeoutModal(false);
        navigate('/');
      };
    
      // const handleModalCancel = () => {
      //   setShowSessionTimeoutModal(false);
      // };
    

  return (
    <div className='newuserpicontainer' 
    // style={{ overflow: "scroll" }}
     >
      <NewHeader />
      <Sidebar1 />
      <div className='newuserminiheadtable'>
        <div className='newuserminiheadtitle'>
          <Col>
          <h2 className='newusermed'>
              Hospital
            </h2>
          </Col>
        </div>
        <Row className='newuserminiheadright'>
          <Col>
            <SearchBar
              value={searchText}
              onChange={(newValue) => handleSearchChange(newValue)}
              onRequestSearch={tableSearch}
              placeholder='Search For Hospital Name'

              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>

          <Modal
        title="Session Timeout"
        visible={showSessionTimeoutModal}
        onOk={handleModalOk}
        // onCancel={handleModalCancel}
      >
        <p>Your session has timed out. Please log in again.</p>
      </Modal>

          <Col className='newuseraddnewusecol' style={{
            left: "25%",
            paddingTop: "1%"
          }}>
            <div className='newuseraddnewusecoldiv' onClick={handlenavigate}>
              Add New Hospital
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <TableContainer className="newusertablecont" style={{ width: "90%" }} component={Paper}>
          {loading ? (<Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
          </Box>) :
            (<>
              <Table>
                <TableHead className="newusertablerowm" >
                  <TableRow >
                    <TableCell><b>S.No</b></TableCell>
                    <TableCell><b>NPI ID</b></TableCell>
                    {/* <TableCell>Hcp Office</TableCell> */}
                    {/* <TableCell>HCP ID</TableCell> */}
                    <TableCell><b>Hospital Name</b></TableCell>
                    <TableCell><b>State</b></TableCell>
                    <TableCell><b>Actions</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id} >
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>{row.npiId}</TableCell>
                        {/* <TableCell>{row.hcpId}</TableCell> */}
                        <TableCell>{row.hospitalName}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        {/* <TableCell>{row.state}</TableCell> */}
                        <TableCell>
                        <Tooltip title="Description">
                          <IconButton aria-label="View">
                            <Visibility onClick={(e) => navigate("/Hospitalinfo?/" + my_list[4] + "/" + row.masterId)} />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                          <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={() => handleEdit(row)} >
                            <Edit />
                          </IconButton>
                          </Tooltip>
                          {/* <IconButton aria-label="Delete" style={{ color: "#FF4500" }}   >
                            <Delete onClick={() => handleDelete(row.masterId)} />
                          </IconButton>  */}
                        </TableCell>
                      </TableRow>))) : (
                    data.slice(startIndex - 1, endIndex).map((row, index) => (
                      <TableRow key={row.id} >
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{startIndex + index}</TableCell>
                        <TableCell>{row.npiId}</TableCell>
                        {/* <TableCell>{row.hcpId}</TableCell> */}
                        <TableCell>{row.hospitalName}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        {/* <TableCell>{row.state}</TableCell> */}
                        <TableCell>
                          <Tooltip title="Description">
                            <IconButton aria-label="View">
                              <Visibility onClick={(e) => navigate("/Hospitalinfo?/" + my_list[4] + "/" + row.masterId)} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={() => handleEdit(row)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Delete">
                            <IconButton aria-label="Delete" style={{ color: "#FF4500" }}   >
                              {/* <IconButton aria-label="Delete" style={{color:"#FF4500"}}  onClick={()=>handleDelete(row.masterId)} > */}
                              {/* <Delete onClick={() => handleDelete(row.masterId)} />
                            </IconButton>
                          </Tooltip> */} 
                        </TableCell>
                      </TableRow>
                    )))}
                </TableBody>
              </Table>
            </>)}
        </TableContainer>
      </div>
      <div className='pagination'>
        <div>
          <Pagination color="primary"
          count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
          onChange={handlePageChange}
          page={page + 1}
          boundaryCount={1}
          siblingCount={1}
          />
        </div>
       
      </div>
      
    </div>
  )
}

export default NewUsers

