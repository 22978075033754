// import { useState } from "react";
// import MenuIcon from '@mui/icons-material/Menu';
import { UploadOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { VerifiedOutlined } from "@mui/icons-material";
import { Avatar, Card, Col, Popover, Row } from "antd";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Modal } from 'antd';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { green } from '@ant-design/colors';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
// import { Col, Row } from 'antd';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import PersonIcon from '@mui/icons-material/Person';
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
// import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { json, useNavigate, useParams } from "react-router";
import axios from "axios";
import "./Pverify.css";
import TextArea from "antd/es/input/TextArea";
import Autocomplete from "@mui/material/Autocomplete";
import { WestOutlined } from '@mui/icons-material';
import { useEffect } from "react";
import { Switch, Checkbox } from "antd";
import { red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from '@mui/icons-material/Call';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Header from "./Header";
import { InputAdornment, List, ListItem, ListItemText, Paper, Link, CircularProgress } from '@mui/material';
// import Footer from './Footer';
// import Button from '@mui/material/Button';
// import MenuIcon from '@mui/icons-material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Sidebar.css";
import drugCosts from '../drugCosts.json';
import "./sidebar1.css";
import SearchIcon from '@mui/icons-material/Search';
// import Fingerprint2 from 'fingerprintjs2';
// import crypto from 'crypto-browserify';
import AWS from 'aws-sdk';





// import "./Pverify.css";
import Dragger from "antd/es/upload/Dragger";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import Sidebar from "./Sidebar";
import { VerifiedUserOutlined } from "@mui/icons-material";
import NewHeader from "./NewHeader";
import Sidebar1 from "./Sidebar1";
import { Progress } from 'antd';
// import CryptoJS from 'crypto-js';
import QRCode from 'qrcode.react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CryptoJS from 'crypto-js';
import { BaseUrl, DocUrl } from '../config'
import { Alert, Flex, Spin } from 'antd';
import { REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../config";
// import { createDecipheriv, randomBytes } from 'crypto'
// import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';
const DrugPverify = () => {
  const [isOpened, setIsOpened] = useState(false);

  const [ArrayBuffer, setArrayBuffer] = useState();
  const [InsuranceType, setInsuranceType] = useState();
  const [InsuranceNumber, setInsuranceNumber] = useState("");
  const [payerCode, setpayerCode] = useState("");
  const [copayStatus, setcopayStatus] = useState("");
  const [payerName, setpayerName] = useState("");
  const [balance, setbalance] = useState("");
  const [remainDeduct, setRemainDeduct] = useState("");
  const [percentDeduct, setPercentDeduct] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [insuId, setinsuId] = useState("");
  const [ImageUrl, setImageUrl] = useState("");
  const [planname, setPlanName] = useState("");
  const [drugname, setDrugName] = useState("");
  const [payer, setPayer] = useState([]);
  const [cigna, setCigna] = useState([]);
  const [emblemhealth, setemblemhealth] = useState([]);
  const [anthembluecrossblueshield, setanthembluecrossblueshield] = useState(
    []
  );
  const [upmc, setupmc] = useState([]);
  const [geisinger, setgeisinger] = useState([]);
  const [amerihealth, setamerihealth] = useState([]);
  const [imperialhealth, setimperialhealth] = useState([]);
  const [hyperlinks, setlink] = useState([]);
  const [byalors, setbyalors] = useState([]);
  const [clover, setclover] = useState([]);
  const [wellcare, setwellcare] = useState([]);
  const [health, sethealth] = useState([]);
  const [aetna, setaetna] = useState([]);
  const [ambetter, setambetter] = useState([]);
  const [unitedhealthcare, setunitedhealthcare] = useState([]);
  const [plan, setPlan] = useState([]);
  const [payerlist, setPayerlist] = useState([]);
  const [responseCovered, setresponseCovered] = useState("");
  const [color, setcolor] = useState("");
  const [state, setstate] = useState([]);
  const [icon1, seticon1] = useState(false);
  const [icon2, seticon2] = useState(false);
  const [icon3, seticon3] = useState(false);
  const [icon4, seticon4] = useState(false);
  const [icon5, seticon5] = useState(false);
  const [subscriberid, setSubscriberid] = useState("");
  const [npiJson, setnpi] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [drugs, setdrugs] = useState("")
  const [dob, setdob] = useState("");
  const [dragger, setdragger] = useState("Auto");
  const [newyork, setnewyork] = useState([]);
  const [newjersey, setjersey] = useState([]);
  const [pennsylvania, setpennsylvania] = useState([]);
  const [michigan, setmichigan] = useState([]);
  const [texas, settexas] = useState([]);
  const [illinois, setillinois] = useState([]);
  const [illatena, setillatena] = useState([]);
  const [illamber, setillamber] = useState([]);
  const [illablue, setillablue] = useState([]);
  const [illacigna, setillacigna] = useState([]);
  const [illaoscar, setillaoscar] = useState([]);
  const [illaunited, setillaunited] = useState([]);
  const [illawell, setillawell] = useState([]);
  const [migaetna, setmigaetna] = useState([]);
  const [migamb, setmigamb] = useState([]);
  const [migblue, setmigblue] = useState([]);
  const [mighap, setmighap] = useState([]);
  const [migoscar, setmigoscar] = useState([]);
  const [migunited, setmigunited] = useState([]);
  const [migwell, setmigwell] = useState([]);
  const [newaetna, setnewaetna] = useState([]);
  const [newamb, setnewamb] = useState([]);
  const [newblue, setnewblue] = useState([]);
  const [newcigna, setnewcigna] = useState([]);
  const [newclover, setnewclover] = useState([]);
  const [newoscar, setnewoscar] = useState([]);
  const [newunited, setnewunited] = useState([]);
  const [newwell, setnewwell] = useState([]);
  const [newamer, setnewamer] = useState([]);
  const [yorkcigna, setyorkcigna] = useState([]);
  const [yorkemb, setyorkemb] = useState([]);
  const [yorkoscar, setyorkoscar] = useState([]);
  const [yorkwell, setyorkwell] = useState([]);
  const [yorkaetna, setyorkaetna] = useState([]);
  const [yorkheal, setyorkheal] = useState([]);
  const [yorkunited, setyorkunited] = useState([]);
  const [penaetna, setpenaetna] = useState([]);
  const [penamb, setpenamb] = useState([]);
  const [penblue, setpenblue] = useState([]);
  const [pencigna, setpencigna] = useState([]);
  const [penclover, setpenclover] = useState([]);
  const [penoscar, setpenoscar] = useState([]);
  const [penupmc, setpenupmc] = useState([]);
  const [penunited, setpenunited] = useState([]);
  const [penges, setpenges] = useState([]);
  const [texaetna, settexaetna] = useState([]);
  const [texamb, settexamb] = useState([]);
  const [texbay, settexbay] = useState([]);
  const [texblue, settexblue] = useState([]);
  const [texcigna, settexcigna] = useState([]);
  const [texclover, settexclover] = useState([]);
  const [teximp, setteximp] = useState([]);
  const [texoscar, settexoscar] = useState([]);
  const [texunited, settexunited] = useState([]);
  const [texwell, settexwell] = useState([]);
  // const [responseCovered, setResponseCovered] = useState('');
  const [pverify, setPverify] = useState('');
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressStatus, setProgressStatus] = useState('normal');
  const [pcode, setpcode] = useState("");
  const [progress, setProgress] = useState(0);
  const [roles, setrole] = useState("");
  const [pracode, setpracode] = useState("");
  const [familyind, setfamilyind] = useState("");
  const [familyindrem, setfamilyindrem] = useState("");
  const [isSubscriberPatient, setIsSubscriberPatient] = useState("true");
  const [ebv, setebv] = useState("");
  const [ebvId, setebvId] = useState("");
  const [ebvendpoint, setebvendpoint] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  const [ambebv, setambebv] = useState("");
  const [embebv, setembebv] = useState("");
  const [geisebv, setgeiebv] = useState("");
  const [bayebv, setbayebv] = useState("");
  const [upebv, setupebv] = useState("");
  const [clebv, setclebv] = useState("");
  const [ciebv, setciebv] = useState("");
  const [unebv, setunebv] = useState("");
  const [amebv, setamebv] = useState("");
  const [atebv, setatebv] = useState("");
  const [imebv, setimebv] = useState("");
  const [welebv, setwelebv] = useState("");
  const [haebv, sethaebv] = useState("");
  const [blebv, setblebv] = useState("");
  const [heebv, setheebv] = useState("");
  const [ocebv, setocebv] = useState("");
  const [ebvresponse, setebvresponse] = useState("");
  const [ebvlink, setebvlink] = useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [sbc, setsbc] = useState("");

  const [Email, setEmail] = useState('')
  const [BtnVisible, setBtnVisible] = useState(true)
  const [IsPharmacyBenifit, setIsPharmacyBenifit] = useState(false)
  const [Page, setPage] = useState('1')
  const [gridLoading, setGridLoading] = useState(false);

  // const [textFieldValue, setTextFieldValue] = React.useState('');

  const [State, setState] = useState({
    npiId: '',
    address: '',
    qrCodeData: '',
  });


  // useEffect(() => {
  //   if (timer > 0) {
  //     const timerId = setTimeout(() => {
  //       setTimer(timer - 1);
  //     }, 1000);

  //     return () => clearTimeout(timerId);
  //   } else {
  //     setIsButtonDisabled(true);
  //   }
  // }, [timer]);

  // const encryptPayer = (data) => {
  //   const secretKey = '123567890';
  //   const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
  //   return encryptedData;
  // };

  const UnitedHealthCare = [

    { label: "UnitedHealthcare Dual Complete Choice (PPO D-SNP)", id: 1 },
    { label: "United Healthcare Medicare Advantage Choice plan1(Regional PPO)", id: 2 },
    { label: "UnitedHealthcare Dual Complete (HMO-POS D-SNP)", id: 3 },
  ]

  const Aetnaopt = [
    { label: "Aetna Medicare Premier (HMO-POS)", id: 1 },
    { label: " Aetna Medicare Value (PPO)", id: 2 },
    { label: "Aetna Medicare Prime Plan (HMO)", id: 3 },
    { label: "Aetna Medicare Select Plan (HMO)", id: 4 },

  ]

  const [isBlackTheme, setIsBlackTheme] = useState(false);

  const toggleTheme = () => {
    setIsBlackTheme(!isBlackTheme);
  };
  const generateQRCodeData = () => {
    // Generate QR code data based on hospital information
    const qrData = `http://52.87.151.167:9000/`; // Add necessary data
    setState(prevState => ({
      ...prevState,
      qrCodeData: qrData,
    }));
  };
  console.log(State, "stateee")
  // useEffect(() => {
  //   generateQRCodeData();
  // }, [State]);
  useEffect(() => {
    let urls = `${BaseUrl}/ebvgateway-mapping-insurance-name/` + payer;
    axios.post(urls)
      .then((res) => {
        setebv(res.data.data)
      })
  }, [payer])

  useEffect(() => {
    localStorage.setItem('drug', drugs)
  }, [drugs])

  useEffect(() => {
    localStorage.setItem('payers', payer)
  }, [payer])

  useEffect(() => {
    localStorage.setItem('firstnames', firstname)
  }, [firstname])

  useEffect(() => {
    localStorage.setItem('lastnames', lastname)
  }, [lastname])

  useEffect(() => {
    localStorage.setItem('subscriberids', subscriberid)
  }, [subscriberid])

  useEffect(() => {
    localStorage.setItem('dobs', dob)
  }, [dob])


  const [selectedDate, setSelectedDate] = useState(null);


  const Navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("ID");
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const showModal1 = () => {
    setOpen1(true);
  };

  // const handleOk = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     setOpen1(false);
  //   }, 3000);
  // };

  // const handleOk1 = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     setOpen(false);
  //   }, 3000);
  // };
  // const handleCancel = () => {
  //   setOpen1(false);
  // };

  // const handleCancel1 = () => {
  //   setOpen1(false);
  // };
  useEffect(() => {
    // Directly use the imported JSON data
    setDrugData(drugCosts);
  }, []);

  useEffect(() => {
    const managnment = window.location.href
    const my_list = managnment.split("/")
    const role = my_list[4]
    setrole(role)
    // const pra = my_list[5]
    // setpracode(pra)
  }, [])

  const success = () => {
    message.open({
      type: "success",
      content: "Benefit Verified Successfully",
    });
  };
  const prior = () => {
    message.open({
      type: "success",
      content: "Prior Authorization Request Form  Sent To Your Mail",
    });
  };
  const error = () => {
    message.open({
      type: "error",
      content: "Your Subscriber Id Not Match For Selected Insurance Name",
    });
  }

  const successs = () => {
    message.open({
      type: "success",
      content: "Coverage Determination Done Successfully",
    });
    //     messageApi.success("Logged in successfully")
  };

  useEffect(() => {
    const managnment = window.location.href
    console.log(managnment, "id")
    const my_list = managnment.split("/")
    console.log(my_list[4], "my_list")
    const npi = my_list[4]
    localStorage.setItem("NpiJson", npi);
    const decodedJSON = JSON.parse(decodeURIComponent(my_list[4]))
    setnpi(decodedJSON)

  }, []);

  useEffect(() => {
    let map =
      `${BaseUrl}/states-mapping`;

    axios.get(map).then((res) => {
      const blueCrossBlueShieldValue = res.data.data.Illinois['BlueCross BlueShield']
      const cignaValue = res.data.data.Illinois['Cigna Healthcare']
      const oscarValue = res.data.data.Illinois['Oscar Health']
      const unitedvalue = res.data.data.Illinois['United Healthcare']
      const blueCrossmig = res.data.data.Michigan['BlueCross BlueShield']
      const migoscarValue = res.data.data.Michigan['Oscar Health']
      const migunitedvalue = res.data.data.Michigan['United Healthcare']
      const newAetnaValue = res.data.data['New Jersey'].Aetna
      const newambValue = res.data.data['New Jersey'].Ambetter
      const newbluevalue = res.data.data['New Jersey']['BlueCross BlueShield']
      const newcignavalue = res.data.data['New Jersey']['Cigna Healthcare']
      const newclovervalue = res.data.data['New Jersey']['Clover Health']
      const newoscarvalue = res.data.data['New Jersey']['Oscar Health']
      const newunitedvalue = res.data.data['New Jersey']['United Healthcare']
      const newwellvalue = res.data.data['New jersey'].Wellcare
      const newamervalue = res.data.data['New jersey'].AmeriHealth
      const yorkcignavalue = res.data.data['New York']['Cigna Healthcare']
      const yorkembvalue = res.data.data['New York']['Emblem Health']
      const yorkoscarvalue = res.data.data['New York']['Oscar Health']
      const yorkwellvalue = res.data.data['New York'].Wellcare
      const yorkaetnavalue = res.data.data['New York'].Aetna
      const yorkhealvalue = res.data.data['New York']['Health First']
      const yorkunitedvalue = res.data.data['New York']['United Healthcare']
      const blueCrosspen = res.data.data.Pennsylvania['BlueCross BlueShield']
      const cignapen = res.data.data.Pennsylvania['Cigna Healthcare']
      const cloverpen = res.data.data.Pennsylvania['Clover Health']
      const oscarpen = res.data.data.Pennsylvania['Oscar Health']
      const upmcpen = res.data.data.Pennsylvania['UPMC Health']
      const unitedcpen = res.data.data.Pennsylvania['United Healthcare']
      const gespen = res.data.data.Pennslyvania['Geisinger Health']
      const texbayvalue = res.data.data.Texas['Baylor white and scott']
      const texbluevalue = res.data.data.Texas['BlueCross BlueShield']
      const texcignavalue = res.data.data.Texas['Cigna Healthcare']
      const texclovervalue = res.data.data.Texas['Clover Health']
      const teximpvalue = res.data.data.Texas['Imperial Health']
      const texoscarvalue = res.data.data.Texas['Oscar Health']
      const texunitedvalue = res.data.data.Texas['United Healthcare']

      setillatena(res.data.data.Illinois.Aetna)
      setillamber(res.data.data.Illinois.Ambetter)
      setillablue(blueCrossBlueShieldValue)
      setillacigna(cignaValue)
      setillaoscar(oscarValue)
      setillaunited(unitedvalue)
      setillawell(res.data.data.Illinois.Wellcare)
      setmigaetna(res.data.data.Michigan.Aetna)
      setmigamb(res.data.data.Michigan.Ambetter)
      setmigblue(blueCrossmig)
      setmighap(res.data.data.Michigan.HAP)
      setmigoscar(migoscarValue)
      setmigunited(migunitedvalue)
      setmigwell(res.data.data.Michigan.Wellcare)
      setnewaetna(newAetnaValue)
      setnewamb(newambValue)
      setnewblue(newbluevalue)
      setnewcigna(newcignavalue)
      setnewclover(newclovervalue)
      setnewoscar(newoscarvalue)
      setnewunited(newunitedvalue)
      setnewwell(newwellvalue)
      setnewamer(newamervalue)
      setyorkcigna(yorkcignavalue)
      setyorkemb(yorkembvalue)
      setyorkoscar(yorkoscarvalue)
      setyorkwell(yorkwellvalue)
      setyorkaetna(yorkaetnavalue)
      setyorkheal(yorkhealvalue)
      setyorkunited(yorkunitedvalue)
      setpenaetna(res.data.data.Pennsylvania.Aetna)
      setpenamb(res.data.data.Pennsylvania.Ambetter)
      setpenblue(blueCrosspen)
      setpencigna(cignapen)
      setpenclover(cloverpen)
      setpenoscar(oscarpen)
      setpenupmc(upmcpen)
      setpenunited(unitedcpen)
      setpenges(gespen)
      settexaetna(res.data.data.Texas.Aetna)
      settexamb(res.data.data.Texas.Ambetter)
      settexbay(texbayvalue)
      settexblue(texbluevalue)
      settexcigna(texcignavalue)
      settexclover(texclovervalue)
      setteximp(teximpvalue)
      settexoscar(texoscarvalue)
      settexunited(texunitedvalue)
      settexwell(res.data.data.Texas.Wellcare)
    })
  }, [])

  useEffect(() => {

    if (state == "Illinois" && payer == "Aetna") {
      setPlan(illatena)
    } else if (state == "Illinois" && payer == "Ambetter") {
      setPlan(illamber)
    } else if (state == "Illinois" && payer == "BluecrossBlueshield") {
      setPlan(illablue)
    } else if (state == "Illinois" && payer == "Cigna") {
      setPlan(illacigna)
    } else if (state == "Illinois" && payer == "OscarHealth") {
      setPlan(illaoscar)
    } else if (state == "Illinois" && payer == "UnitedHealthCare") {
      setPlan(illaunited)
    } else if (state == "Illinois" && payer == "Wellcare") {
      setPlan(illawell)
    } else if (state == "Michigan" && payer == "Aetna") {
      setPlan(migaetna)
    } else if (state == "Michigan" && payer == "Ambetter") {
      setPlan(migamb)
    } else if (state == "Michigan" && payer == "BluecrossBlueshield") {
      setPlan(migblue)
    } else if (state == "Michigan" && payer == "HAP") {
      setPlan(mighap)
    } else if (state == "Michigan" && payer == "OscarHealth") {
      setPlan(migoscar)
    } else if (state == "Michigan" && payer == "UnitedHealthCare") {
      setPlan(migunited)
    } else if (state == "Michigan" && payer == "Wellcare") {
      setPlan(migwell)
    } else if (state == "New Jersey" && payer == "Aetna") {
      setPlan(newaetna)
    } else if (state == "New Jersey" && payer == "Ambetter") {
      setPlan(newamb)
    } else if (state == "New Jersey" && payer == "BluecrossBlueshield") {
      setPlan(newblue)
    } else if (state == "New Jersey" && payer == "Cigna") {
      setPlan(newcigna)
    } else if (state == "New Jersey" && payer == "CloverHealth") {
      setPlan(newclover)
    } else if (state == "New Jersey" && payer == "OscarHealth") {
      setPlan(newoscar)
    } else if (state == "New Jersey" && payer == "UnitedHealthCare") {
      setPlan(newunited)
    } else if (state == "New Jersey" && payer == "Wellcare") {
      setPlan(newwell)
    } else if (state == "New Jersey" && payer == "Amerihealth") {
      setPlan(newamer)
    } else if (state == "New York" && payer == "Cigna") {
      setPlan(yorkcigna)
    } else if (state == "New York" && payer == "EmblemHealth") {
      setPlan(yorkemb)
    } else if (state == "New York" && payer == "OscarHealth") {
      setPlan(yorkoscar)
    } else if (state == "New York" && payer == "Wellcare") {
      setPlan(yorkwell)
    } else if (state == "New York" && payer == "Aetna") {
      setPlan(yorkaetna)
    } else if (state == "New York" && payer == "HealthFirst") {
      setPlan(yorkheal)
    } else if (state == "New York" && payer == "UnitedHealthCare") {
      setPlan(yorkunited)
    } else if (state == "Pennsylvania" && payer == "Aetna") {
      setPlan(penaetna)
    } else if (state == "Pennsylvania" && payer == "Ambetter") {
      setPlan(penamb)
    } else if (state == "Pennsylvania" && payer == "BluecrossBlueshield") {
      setPlan(penblue)
    } else if (state == "Pennsylvania" && payer == "Cigna") {
      setPlan(pencigna)
    } else if (state == "Pennsylvania" && payer == "CloverHealth") {
      setPlan(penclover)
    } else if (state == "Pennsylvania" && payer == "OscarHealth") {
      setPlan(penoscar)
    } else if (state == "Pennsylvania" && payer == "UPMChealth") {
      setPlan(penupmc)
    } else if (state == "Pennsylvania" && payer == "UnitedHealthCare") {
      setPlan(penunited)
    } else if (state == "Pennsylvania" && payer == "GeisingerHealth") {
      setPlan(penges)
    } else if (state == "Texas" && payer == "Aetna") {
      setPlan(texaetna)
    } else if (state == "Texas" && payer == "Ambetter") {
      setPlan(texamb)
    } else if (state == "Texas" && payer == "BaylorScottandWhite") {
      setPlan(texbay)
    } else if (state == "Texas" && payer == "BluecrossBlueshield") {
      setPlan(texblue)
    } else if (state == "Texas" && payer == "Cigna") {
      setPlan(texcigna)
    } else if (state == "Texas" && payer == "CloverHealth") {
      setPlan(texclover)
    } else if (state == "Texas" && payer == "ImperialHealth") {
      setPlan(teximp)
    } else if (state == "Texas" && payer == "OscarHealth") {
      setPlan(texoscar)
    } else if (state == "Texas" && payer == "UnitedHealthCare") {
      setPlan(texunited)
    } else if (state == "Texas" && payer == "Wellcare") {

      setPlan(texwell)
    }

  }, [state, payer])

  useEffect(() => {
    let url5 =
      `${BaseUrl}/plan-list`;

    axios.get(url5).then((res) => {
      setCigna(res.data.data.cigna_list);
      setaetna(res.data.data.aetna_list);
      setemblemhealth(res.data.data.emblem_list);
      setanthembluecrossblueshield(
        res.data.data.blue_cross
      );
      setambetter(
        res.data.data.ambetter_list
      );
      setunitedhealthcare(
        res.data.data.united_healthcare_list
      );
      setupmc(
        res.data.data.upmc_list
      );
      setgeisinger(
        res.data.data.geisinger_list
      );
      setamerihealth(
        res.data.data.amerihealth_list
      );
      setimperialhealth(
        res.data.data.imperial_list
      );
      setbyalors(
        res.data.data.byalors_list
      );
      setclover(
        res.data.data.clover_health
      );
      setwellcare(
        res.data.data.wellcare
      );
      sethealth(
        res.data.data.health_first
      );
    });
  }, []);

  useEffect(() => {
    let url8 =
      `${BaseUrl}/state-list`;

    axios.get(url8).then((res) => {
      setnewyork(res.data.data.new_york);
      setjersey(res.data.data.new_jersey);
      setpennsylvania(res.data.data.pennsylvania);
      setmichigan(
        res.data.data.michigan
      );
      settexas(
        res.data.data.texas
      );
      setillinois(
        res.data.data.illinois
      );
    });
  }, []);

  useEffect(() => {
    if (state === "New York") {
      setPayerlist(newyork);
    }
    else if (state === "New Jersey") {
      setPayerlist(newjersey);
    }
    else if (state === "Pennsylvania") {
      setPayerlist(pennsylvania);
    }
    else if (state === "Michigan") {
      setPayerlist(michigan);
    }
    else if (state === "Texas") {
      setPayerlist(texas);
    }
    else if (state === "Illinois") {
      setPayerlist(illinois);
    }

  }, [state]);

  useEffect(() => {
    let urls = `${BaseUrl}/hospitals/last-inserted-servicetype`;
    axios.get(urls)
      .then((res) => {
        setpracode(res.data.lastInsertedServiceType)
      })
  }, [])
  useEffect(() => {

    if (ebv === "Change Healthcare") {
      setebvId("CH0001")
      setebvendpoint("/ChangeHealthcare")
      setebvlink("https://www.changehealthcare.com/")
    }
    else if (ebv === "Availity") {
      setebvId("AV0001")
      setebvendpoint("/Availity")
      setebvlink("https://www.availity.com/")
    }
    else if (ebv === "Zirmed") {
      setebvId("ZD0001")
      setebvendpoint("/Zirmed")
      setebvlink("https://login.zirmed.com/ui")
    }
    else if (ebv === "pVerify") {
      setebvId("PV0001")
      setebvendpoint("/pVerify")
      setebvlink("https://www.pverify.com/")
    }
    else if (ebv === "Office Ally") {
      setebvId("OA0001")
      setebvendpoint("/Officeally")
      setebvlink("https://cms.officeally.com/")
    }
  }, [ebv])

  // useEffect(() => {

  //   const encryptedValue = '549635353181f1341f4c9b3b6b547a327105708fe68ba7ac97d3a69a253359222b24ceeeb2e748e1bddfc590ed80b6a430ce64527487cc804876aedc41ea1a1e799d4f3bb6edaaf0a61e3d6866bc255f2c50240f656134f3fcb93be60843338f05e8179093500be739e1a28be1a03c9405da1f3a37b95200b5f318ebbf683e3003318fd29faa10bdb7810b5f61d8716015d2a549203c1990346df438f3f99dd89a578a756e343524670fc0c54db335063501baa564d757adbc97527a07bbebbf59411babd3218360bb5f129545c809dcb5362faf49c8740180649562a03019bc5ac73cdb051a609b6c007e9f21fa386099694c71fcfa19e0fc4651174a15b3ddd5bb105650c49ca417af38545323911fdd8bf7a4bbe70fdd69ceaa094691db9f1ae101b6ab3bdd5b13a30c9334410686ba5cdca869ba7edce143fc9841c6553eb78103ba249bc302426dd6747e51c4895c823154b62b6eaa5605dbcae561b1de03c219cd4786364840ebf01de2004dbfb1bba602a08daa58dedeeba27bad31ed35beab741916e04b0b16d65cbf3eef1b4191663bab9efe323522e1dffb2b4e7d8347fcc1659e807fb160ead6731da8a0135b414ba59547a867320c01c14d787f02162c9786844858dcf01f011b7118abab537af2523ec2934ad3f8ab7f1e751c31a930cf980dd35583127d2b15257b9b73ba426a659726e702bc6ff95c96eb09b92d10521207b5d61e6c543d3db64db8c5be5b49fc2e8af239bbc8f2266a5ee7b6e62cf63c785c55779f356203e0f7f311b756301a97494bbac7d55a3a4c8091';
  //   const key = 'randomBytes(32)';
  //   const iv = 'randomBytes(16)';
  //   // Assuming 'encryptedValue', 'key', and 'iv' are available in your component
  //   const encryptedBytes = Uint8Array.from(atob(encryptedValue), c => c.charCodeAt(0));
  //   const keyBytes = Uint8Array.from(Buffer.from(key, 'hex'));
  //   const ivBytes = Uint8Array.from(Buffer.from(iv, 'hex'));

  //   const decipher = crypto.createDecipheriv('aes-256-cbc', keyBytes, ivBytes);

  //   let decrypted = decipher.update(encryptedBytes, null, 'utf8');
  //   decrypted += decipher.final('utf8');

  //   console.log('Decrypted:', decrypted);


  // }, []);


  // const decryptValue = (encryptedValue) => {
  //   const key = 'randomBytes(32)'; // Use the same key you used for encryption
  //   const iv = 'randomBytes(16)'; // Use the same initialization vector you used for encryption

  //   const bytes = CryptoJS.AES.decrypt(encryptedValue, key, { iv: iv });
  //   const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  //   return decryptedData;
  // };



  // useEffect(()=>{
  // let url4 = `${BaseUrl}/hospitals/get-id`;
  // let body4 = {
  //   "code": pracode
  // };

  // axios.post(url4, body4)
  //   .then((res) => {
  //     setpcode(res.data)
  //   })
  // },[])

  function xorDecrypt(encryptedData, xorKey) {
    let result = '';
    for (let i = 0; i < encryptedData.length; i++) {
      result += String.fromCharCode(encryptedData.charCodeAt(i) ^ xorKey);
    }
    return result;
  }



  const [ServiceCoveredOutNet, setServiceCoveredOutNet] = useState('')
  const [CoPayInNet, setCoPayInNet] = useState('')
  const [ServiceCoveredInNet, setServiceCoveredInNet] = useState('')
  const [copayStatusout, setcopayStatusout] = useState('')
  const [RemainDeductOut, setRemainDeductOut] = useState('')
  const [balanceOut, setbalanceOut] = useState('')
  const [IndividualDeductibleRemainingInNet, setIndividualDeductibleRemainingInNet] = useState('')
  const [IndividualDeductibleRemainingOutNet, setIndividualDeductibleRemainingOutNet] = useState('')
  const [IndividualOOP_InNet, setIndividualOOP_InNet] = useState('')
  const [IndividualOOP_OutNet, setIndividualOOP_OutNet] = useState('')
  const [IndividualOOPRemainingOutNet, setIndividualOOPRemainingOutNet] = useState('')
  const [IndividualOOPRemainingInNet, setIndividualOOPRemainingInNet] = useState('')
  const [FamilyOOPRemainingInNet, setFamilyOOPRemainingInNet] = useState('')
  const [FamilyOOPRemainingOutNet, setFamilyOOPRemainingOutNet] = useState('')
  const [FamilyDeductibleInNet, setFamilyDeductibleInNet] = useState('')
  const [FamilyDeductibleOutNet, setFamilyDeductibleOutNet] = useState('')
  const [FamilyDeductibleRemainingInNet, setFamilyDeductibleRemainingInNet] = useState('')
  const [FamilyDeductibleRemainingOutNet, setFamilyDeductibleRemainingOutNet] = useState('')
  const [FamilyOOPInNet, setFamilyOOPInNet] = useState('')
  const [FamilyOOPOutNet, setFamilyOOPOutNet] = useState('')
  const [DrugCost, setDrugCost] = useState('')
  const [NetworkStatus, setNetworkStatus] = useState('')
  const [PolicyType, setPolicyType] = useState('')
  const [DrugData, setDrugData] = useState([])
  const [SbcVisible, setSbcVisible] = useState(false)
  const [Payout, setPayout] = useState(true)



  const handleClick = (e) => {

    setSearchLoading(true);
    setSbcVisible(false)
    setGridLoading(true)

    let emblem;

    if (payer === "EmblemHealth") {
      emblem = "000101";
      setpayerCode(emblem);
    }
    if (payer === "Unitedhealthcare") {
      emblem = "00192";
      setpayerCode(emblem);
    }
    if (payer === "Cigna") {
      emblem = "00004";
      setpayerCode("00004");
    }

    if (payer === "Aetna") {
      emblem = "00001";
      setpayerCode("00001");
    }
    if (payer == 'MEDICARE HEALTH INSURANCE') {

    }
    console.log(payer, "payer")
    console.log(payerCode, "payerCode")

    if (e === "Submit") {


      // console.log(payer,"emblem")


      let url4 = `${BaseUrl}/pverify`;



      let body4 = {
        firstName: firstname,
        dob: dob,
        lastName: lastname,
        subscriberID: subscriberid,
        payerCode:emblem,
        payerName1: payer,
        practicecode: "17",
        isSubscriberPatient: isSubscriberPatient,
        npiNumber: npiJson.key

      };

      axios.post(url4, body4)

        .then((res) => {
          console.log(res, "arr")

          const encryptedValue = res.data.data[0].encrypted;

          // const encryptedData = '...'; // Your encrypted data here
          const xorKey = 0x5A; // Use the same XOR key

          const decryptedDataString = xorDecrypt(encryptedValue, xorKey);
          const decryptedData = JSON.parse(decryptedDataString);
          console.log(decryptedData, "decryptedData")
          if (npiJson.key !== '1063764280') {
            setNetworkStatus('Out-Network')
            setPayout(false)
          } else {

            setNetworkStatus('In-Network')
          }

          let copay = res.data.CopayStatus;
          console.log(decryptedData, "decryptedData")
          setcopayStatus(decryptedData.CopayStatus);
          setcopayStatusout(decryptedData.CoPayOutNet);
          setbalance(decryptedData.IndividualDeductibleInNet);
          setbalanceOut(decryptedData.IndividualDeductibleOutNet);
          setRemainDeduct(decryptedData.IndividualOOPRemainingInNet);
          setRemainDeductOut(decryptedData.IndividualOOPRemainingOutNet);
          setIndividualDeductibleRemainingOutNet(decryptedData.IndividualDeductibleRemainingOutNet)
          setIndividualDeductibleRemainingInNet(decryptedData.IndividualDeductibleRemainingInNet)
          setFamilyOOPRemainingInNet(decryptedData.FamilyOOPRemainingInNet)
          setFamilyOOPRemainingOutNet(decryptedData.FamilyOOPRemainingOutNet)
          setFamilyDeductibleInNet(decryptedData.FamilyDeductibleInNet)
          setFamilyDeductibleOutNet(decryptedData.
            FamilyDeductibleOutNet)
          setFamilyDeductibleRemainingOutNet(decryptedData.FamilyDeductibleRemainingOutNet)
          setFamilyDeductibleRemainingInNet(decryptedData.FamilyDeductibleRemainingInNet)

          setIndividualOOP_InNet(decryptedData.IndividualOOP_InNet)
          setIndividualOOP_OutNet(decryptedData.IndividualOOP_OutNet)
          setIndividualOOPRemainingInNet(decryptedData.IndividualOOPRemainingInNet)
          setIndividualOOPRemainingOutNet(decryptedData.IndividualOOPRemainingOutNet)
          setPercentDeduct(decryptedData.Coinsurancerelatedinformation);
          setebvresponse(res.data.data[0].message)
          setcoinsurance(decryptedData.Coinsurancerelatedinformation)
          setFamilyOOPInNet(decryptedData.FamilyOOPInNet)
          setFamilyOOPOutNet(decryptedData.FamilyOOPOutNet)
          setCoPayInNet(decryptedData.CoPayInNet)
          seteligibility(decryptedData.PlanCoverageSummaryStatus)
          setServiceCoveredOutNet(decryptedData.ServiceCoveredOutNet)
          setServiceCoveredInNet(decryptedData.ServiceCoveredInNet)
          setPolicyType(decryptedData.PolicyType)

          const drug = drugs.trim();
          if (DrugData[drug]) {
            setDrugCost(DrugData[drug].cost);
            setIsPharmacyBenifit(true);
          } else {
            setIsPharmacyBenifit(true);
          }

          if (drugs === 'botox') {
            setDrugCost('$1060')
            setIsPharmacyBenifit(false)
          } if(drugs === 'euflexxa') {
            setDrugCost('$1429')
            setIsPharmacyBenifit(false)
          } if(drugs === 'baclofen') {
            setDrugCost('$871.98')
            setIsPharmacyBenifit(false)
          } if(drugs === 'dysport') {
            setDrugCost('$1400')
            setIsPharmacyBenifit(false)
          } if(drugs === 'solifenacin') {
            setDrugCost('$339.99')
            setIsPharmacyBenifit(false)
          } if(drugs === 'orphenadrine') {
            setDrugCost('$222.50')
            setIsPharmacyBenifit(false)
          } if(drugs === 'oxytrol') {
            setDrugCost('$824.68')
            setIsPharmacyBenifit(false)
          } if(drugs === 'qbrexza') {
            setDrugCost('$853.35')
            setIsPharmacyBenifit(false)
          }

          setPverify(res.data.data[0].message
          );
          setfamilyind(decryptedData.FamilyIndividualOOP_InNet)
          setfamilyindrem(decryptedData.FamilyIndividualOOPRemainingInNet)

          if (res.data.data[0].message === 'Pverify Verified Sucessfully') {
            setProgressPercent(60)
            let plan
            // percentDeduct(60)
            // success();
            if (payer === "unitedhealthcare") {
              setPlanName("AARP Medicare Advantage from UHC IL-0001 (HMO-POS)")
            }
            if (payer === "EmblemHealth") {
              plan = "emblemhealth essential plan 1"
              setPlanName("emblemhealth essential plan 1")
            }
            if (payer === "Cigna") {
              plan = "CHOICE FUND HSA OPEN ACCESS PLUS"
              setPlanName("CHOICE FUND HSA OPEN ACCESS PLUS")
            }

            if (payer === "Aetna") {
              plan = "AETNA CHOICE POS II"
              setPlanName("AETNA CHOICE POS II")
            }
            let url2 = `${BaseUrl}/drugs-details`;
            let planName = planname;
            // let DrugName = drugname;
            // let payer1 = payer;
            let body = {
              State: state,
              PayerName: payer,
              PlanName: plan,
              DrugName: drugs,
            };

            axios.post(url2, body)
              .then((res) => {
                // console.log(res.data.data[0].encrypted,"newcoverage")

                const encryptedValue = res.data.data[0].encrypted;
                if (encryptedValue) {
                  setSbcVisible(true)

                  setBtnVisible(false)
                }

                // const encryptedData = '...'; // Your encrypted data here
                const xorKey = 0x5A; // Use the same XOR key

                const decryptedDataString = xorDecrypt(encryptedValue, xorKey);
                const decryptedData = JSON.parse(decryptedDataString);

                console.log(decryptedData, "decryptedData1")


                // const algorithm = 'aes-256-cbc';
                // const key = randomBytes(32); // 256-bit key for AES-256
                // const iv = randomBytes(16);  // Initialization vector
                // const cipher = createCipheriv(algorithm, key, iv);

                let response = decryptedData.Description;
                console.log(response, "summary")
                setresponseCovered(response);
                let description = decryptedData.summary;
                setlink(decryptedData.link[0]);
                setSummary(description);
                setsbc(decryptedData.sbcSummary)
                if (res.data) {
                  setProgressPercent(40 + 60)
                  successs()
                  // percentDeduct(40)
                }
              })

              .catch((e) => {
                error();
              })
              .finally(() => {
                setSearchLoading(false);
                setGridLoading(false)
              });
          }
        })
        .catch((err) => {
          console.error('Error in coverage details API:', err);
          error();
          setSearchLoading(false);
          setGridLoading(false)
        });
    }
  }


  console.log(drugs, 'pverifyDrugs')

  useEffect(() => {
    if (responseCovered == "covered") {
      // setcolor("red")
      seticon1(true);
      seticon2(false);
      seticon3(false);
      seticon4(false)
      seticon5(false)
    } else if (responseCovered == "Covered with Conditions") {
      seticon1(false);
      seticon2(true);
      seticon3(false);
      seticon4(false)
      seticon5(false)
    } else if (responseCovered == "covered with condition") {
      seticon1(false);
      seticon2(false);
      seticon3(false);
      seticon4(true)
      seticon5(false)
    } else if (responseCovered == "not covered") {
      seticon1(false);
      seticon2(false);
      seticon3(true);
      seticon4(false)
      seticon5(false)
    } else {
      seticon1(false);
      seticon2(false);
      seticon3(false);
      seticon4(false)
      seticon5(true)

    }
  }, [responseCovered]);

  const handleChange = (event) => {
    setIsSubscriberPatient(event.target.checked);
  };
  // ]

  const handleReset = () => {
    setPayer("");
    setDrugName("");
    setPlanName("");
  };

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const toggleHistory = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const handle = () => {
    setLoading(true);
    if (id) {
      const my_list = id.split(",");
      let UpdateUrl = `https://3a5zllfhz25x3w3npn4bmxcx4a0aqxml.lambda-url.us-east-1.on.aws/`;

      let body = {
        // "Id": my_list[0],
        Insurance__c: InsuranceType,
        Insurance_company_name__c: "001Dn00000PuD5RIAV",
        Policyholder_same_as_the_patient__c: "yes",
        Policy_ID__c: InsuranceNumber,
        Lead__c: my_list[1],
      };
      axios.post(UpdateUrl, JSON.stringify(body)).then((res) => {
        setLoading(false);
        if (res.data.status === "Ok") {
          //   Navigate("/InsuranceInfo ")
          success();
        } else {
          error();
        }
      });
    } else {
      let FirstName = localStorage.getItem("firstName");
      let LastName = localStorage.getItem("lastName");
      let PhoneNumber = localStorage.getItem("PhoneNumber");
      let MOC = localStorage.getItem("MOC");
      let Dob = localStorage.getItem("Dob");
      let Email = localStorage.getItem("Email");
      let Street = localStorage.getItem("Street");
      let City = localStorage.getItem("City");
      let State = localStorage.getItem("State");
      let PinCode = localStorage.getItem("PinCode");
      let Country = localStorage.getItem("Country");
      let Doc = localStorage.getItem("Doc");

      let IMAGEURL = `https://56ozi7f5v4gcnefxk34263duhy0mgqql.lambda-url.us-east-1.on.aws/`;
      axios
        .post(
          IMAGEURL,
          JSON.stringify({
            imagename: insuId,
          })
        )
        .then((res) => {
          const imagee = res.data;
          setImageUrl(imagee);
          const URL = `https://iafkrrf5icamvacwr62l56famm0icgvu.lambda-url.us-east-1.on.aws/`;
          let requestData = {
            LastName: "",
            Status: "",
            MobilePhone: "",
            Email: "",
            FirstName: "",
            HealthCloudGA__BirthDate__c: "",
            Street: "",
            Country: "",
            PostalCode: "",
            City: "",
            State: "",
            Care_Program__c: "",
            LeadSource: "",
            Registered_By__c: "",
            Is_the_Patient_Insured__c: "",
            HealthCloudGA__Gender__c: "",
            Preferred_Method_of_Contact__c: "",
          };
          const reqbody = JSON.stringify(requestData);
          let requestHeaders = {
            "Content-Type": "application/json",
          };
          axios.post(URL, JSON.stringify(requestData)).then((res) => {
            setLoading(false);
            const Token = res.data.id;
            if (res.data.status == "ok" || "ERROR") {
              Navigate("/NewUser");
              success();
              let CONSENT_URL = `https://r7arhgcvxmhmsioku52d6fz7ta0edaje.lambda-url.us-east-1.on.aws/`;
              let Body = {
                Consent_Name__c: "0cIDn000000tUOoMAM",
                Date_Of_The_Consent__c: Doc,
                Lead__c: Token,
              };
              axios.post(CONSENT_URL, JSON.stringify(Body)).then((res) => {
                if (res.data.status == "ok" || "ERROR") {
                  success();
                } else {
                  error();
                }
              });
            }
            if (res.data.status == "ERROR") {
              error();
            }
          });
        });
    }
  };
  // }

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });

  const s3 = new AWS.S3();

  const S3Upload = (files) => {
    // Ensure 'files' is an array
    if (!Array.isArray(files)) {
      files = [files];
    }

    const uploadPromises = files.map((file) => {
      const params = {
        Bucket: 'ebv-extract',
        Key: `${file.name}`,
        Body: file.originFileObj,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on('httpUploadProgress', (progress) => {
            const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
            setProgress(uploadedPercentage - 20);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
      });
    });

    setLoading(true);

    Promise.all(uploadPromises)
      .then((results) => {
        setLoading(false);
        console.log(results[0].Location, 's3UrlResults')
        const imageDirList = results.map(result => result.Location);

        let body = {
          "image_dir_list": imageDirList
        };

        axios.post(`${DocUrl}upload/`, body,{
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // CORS header
          },
        }
       )
          .then((res) => {
            setProgress(100)
            setLoading(false);
            console.log(res.data.text, "upload")

            setfirstname(res.data.text?.First_Name)
            setlastname(res.data.text?.Last_Name)
            setPayer(res.data.text.Insurance_company_name)
            if (res.data.text.Member_id) {
              setSubscriberid(res.data.text.Member_id);
            }
            else if (res.data.text.Member_id) {
              setSubscriberid(res.data.text.Member_ID);
            }
            else if (res.data.text.Health_ID) {
              setSubscriberid(res.data.text.Health_ID);
            }
            else if (res.data.text.POLICY_NUMBER) {
              setSubscriberid(res.data.text.POLICY_NUMBER);
            }
            else if (res.data.text.MEDICAL_CLAIM_NUMBER) {
              setSubscriberid(res.data.text.MEDICAL_CLAIM_NUMBER);
            }
            else if (res.data.text.Member_ID) {
              setSubscriberid(res.data.text.Member_ID);
            }
            else if (res.data.text.UnitedHealthcare_Group_Number) {
              setSubscriberid(res.data.text.UnitedHealthcare_Group_Number);
            }
            else if (res.data.text.ID_) {
              setSubscriberid(res.data.text.ID_);
            }
            else if (res.data.text.Medical_Record_No) {
              setSubscriberid(res.data.text.Medical_Record_No);
            }
            else if (res.data.text.Subscriber_Policy_ID_) {
              setSubscriberid(res.data.text.Subscriber_Policy_ID_);
            }
            else if (res.data.text.ID_NUMBER) {
              setSubscriberid(res.data.text.ID_NUMBER);
            }
            else if (res.data.text["ID NUMBER"]) {
              setSubscriberid(res.data.text["ID NUMBER"]);
            }
            setdragger("Mannual");

            if (subscriberid) {
              setProgress(40)
            }
            const insur = res.data.text.Insurance_company_name
            if (res.data.text.Insurance_company_name == "United Healthcare") {
              setPayer("unitedhealthcare")
            }

            if (res.data.text.Insurance_company_name == "EmblemHealth HIP HMO BENEFITS PROGRAM") {
              setPayer("EmblemHealth")
            }
            if (res.data.text.Insurance_company_name == "Cigna") {
              setPayer("cigna")
            }
            const fullName = res.data.text.Member;
            const name1 = res.data.text.MEMBER
            console.log(name1, "name")
            if (fullName) {
              const nameParts = fullName.split(" ");

              console.log(nameParts, "tttt")
              setfirstname(nameParts[0]);
              setlastname(nameParts.slice(1).join(" "));
              // setpayerName(insurerName)
            }
            else {

              const Parts = name1.split(" ");

              console.log(Parts, "tttt")
              setfirstname(Parts[0]);
              setlastname(Parts.slice(2).join(" "));

            }
          })
      })
      .catch((err) => {
        console.log(err, "upload err");
        setLoading(false);
      });
  };


  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      setLoading(true);
      reader.readAsDataURL(file);
      reader.onload = () => {
        // resolve(reader.result);
        // setArrayBuffer(reader.result)
        let S3_URL = `https://p44t3bnlhrchrz67hg33wkpawy0pqmel.lambda-url.us-east-1.on.aws/`;
        axios
          .post(
            S3_URL,
            JSON.stringify({
              file: file,
            })
          )
          .then((res) => {
            const imagegeturl = res.data.uploadResult.key;
            console.log(res.data.uploadResult.Location[0], "image")

            setProgress(60)

            let body = {
              "image_dir_list": [
                res.data.uploadResult.Location
              ]
            }
            axios
              .post(`${DocUrl}upload/`, body)
              .then((res) => {
                console.log(res, "ressss")
              })


            axios
              .get(
                `https://6kia4hf4gqt277xfeihcw3a7je0xnuxy.lambda-url.us-east-1.on.aws/`
              )
              .then((res) => {
                setLoading(false);
                if (res.data.Member_id) {
                  setSubscriberid(res.data.Member_id);
                }
                else if (res.data.Member_id) {
                  setSubscriberid(res.data.Member_ID);
                }
                else if (res.data.Health_ID) {
                  setSubscriberid(res.data.Health_ID);
                }
                else if (res.data.POLICY_NUMBER) {
                  setSubscriberid(res.data.POLICY_NUMBER);
                }
                else if (res.data.MEDICAL_CLAIM_NUMBER) {
                  setSubscriberid(res.data.MEDICAL_CLAIM_NUMBER);
                }
                else if (res.data.Member_ID) {
                  setSubscriberid(res.data.Member_ID);
                }
                else if (res.data.UnitedHealthcare_Group_Number) {
                  setSubscriberid(res.data.UnitedHealthcare_Group_Number);
                }
                else if (res.data.ID_) {
                  setSubscriberid(res.data.ID_);
                }
                else if (res.data.Medical_Record_No) {
                  setSubscriberid(res.data.Medical_Record_No);
                }
                else if (res.data.Subscriber_Policy_ID_) {
                  setSubscriberid(res.data.Subscriber_Policy_ID_);
                }
                else if (res.data.ID_NUMBER) {
                  setSubscriberid(res.data.ID_NUMBER);
                }
                else if (res.data["ID NUMBER"]) {
                  setSubscriberid(res.data["ID NUMBER"]);
                }
                setdragger("Mannual");

                if (subscriberid) {
                  setProgress(40)
                }
                const insur = res.data.insurer_name
                if (res.data.insurer_name == "United Healthcare") {
                  setPayer("unitedhealthcare")
                }

                if (res.data.insurer_name == "EmblemHealth HIP HMO BENEFITS PROGRAM") {
                  setPayer("EmblemHealth")
                }
                const fullName = res.data.Member;
                const name1 = res.data.MEMBER
                console.log(name1, "name")
                if (fullName) {
                  const nameParts = fullName.split(" ");

                  console.log(nameParts, "tttt")
                  setfirstname(nameParts[0]);
                  setlastname(nameParts.slice(1).join(" "));
                  // setpayerName(insurerName)
                }
                else {

                  const Parts = name1.split(" ");

                  console.log(Parts, "tttt")
                  setfirstname(Parts[0]);
                  setlastname(Parts.slice(2).join(" "));

                }
              });
          });
      };
      reader.onerror = (error) => reject(error);

      if (ArrayBuffer) {
      }
    });
  };

  // const values = [
  //   { name: 'smith', id: '1', caregiver: 'John Doe' },
  //   { name: 'johnson', id: '2', caregiver: 'Jane Smith' },
  //   { name: 'williams', id: '3', caregiver: 'Alice Johnson' },
  //   { name: 'george', id: '4', caregiver: 'Bob Brown' },
  //   { name: 'paul', id: '5', caregiver: 'Eve Davis' },
  //   { name: 'johnson', id: '6', caregiver: 'yytyt' },
  //   { name: 'johnson', id: '7', caregiver: 'yryry' }
  // ];

  //  const [textFieldValue, setTextFieldValue] = useState('');

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedValue, setSelectedValue] = useState(null);
  // // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setTextFieldValue(value);
  //   setIsLoading(true);

  //   // Simulate an asynchronous operation (e.g., API call) to fetch data
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // };

  // const handleClosePopover = () => {
  //   setAnchorEl(null);
  // };

  // const handleListItemClick = (value) => {
  //   setSelectedValue(value);
  //   setAnchorEl(null); // Close the dropdown
  //   setIsModalOpen(true); // Open the modal
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // const handlePatientNameClick = (name) => {
  //   setTextFieldValue(name);
  //   setIsModalOpen(false); // Close the modal
  // };

  // useEffect(() => {
  //   if (!isModalOpen) {
  //     setAnchorEl(null); // Close the dropdown when modal is closed
  //   }
  // }, [isModalOpen]);

  // const filteredValues = values.filter(value =>
  //   value.name.toLowerCase().includes(textFieldValue.toLowerCase())
  // );
  const props = {
    name: "file",
    multiple: true,

    onChange(info) {
      let file = info.file;
      let array_length = info.fileList.length;
      S3Upload(info.fileList)
      console.log(info, "base64")

      if (info.file.status === "error") {
        console.log(

          info,
          "base64"
        );
      }
      // let base64image = getBase64(
      //   info.fileList[array_length - 1].originFileObj
      // );
      // let base64Image = getBase64(file);
    },

    onDrop(e) { },
  };

  // useEffect(() => {
  //   const captureFingerprint = async () => {
  //     const fp = await Fingerprint2.getPromise();
  //     console.log(fp,"54555");
  //     // You can send the fingerprint data to your server for storage or processing
  //   };
  //   captureFingerprint();
  // }, []);


  const options1 = [
    { label: "EmblemHealth", id: 1 },
    { label: "UnitedHealthCare", id: 2 },
    { label: "Cigna", id: 3 },
    { label: "BluecrossBlueshield", id: 4 },
    { label: "Aetna", id: 5 },
    { label: "Ambetter", id: 6 },
    { label: "UPMChealth", id: 7 },
    { label: "GeisingerHealth", id: 8 },
    { label: "Amerihealth", id: 9 },
    { label: "ImperialHealth", id: 10 },
    { label: "BaylorScottandWhite", id: 11 },
    { label: "CloverHealth", id: 12 },
    { label: "Wellcare", id: 13 },
    { label: "HealthFirst", id: 14 },
  ];
  const options2 = [
    { label: "EmblemHealth Platinum", id: 1 },
    { label: "EmblemHealth Gold", id: 2 },
    { label: "EmblemHealth Silver", id: 3 },
    { label: "EmblemHealth Bronze", id: 4 },
    { label: "EmblemHealth Basic (Catastrophic)", id: 5 },
    { label: "Aetna Medicare Primier (HMO-POS)", id: 6 },
    { label: "Aetna Medicare Value (PPO)", id: 7 },
    { label: "Aetna Medicare Premier Plus (PPO)", id: 8 },
    { label: "Aetna Medicare Eagle (PPO)", id: 9 },
    { label: "SilverScript SmartSaver (PDP)", id: 10 },
    { label: "SilverScript Plus (PDP)", id: 11 },
    { label: "Aetna Medicare Prime Plan (HMO)", id: 12 },
    { label: "Aetna Medicare Select Plan (HMO)", id: 13 },
    { label: "Aetna Medicare Plus Plan (HMO)", id: 14 },
    { label: "Aetna Medicare Choice Plan (PPO)", id: 15 },
    { label: "Aetna Medicare Elite Plan 3 (PPO)", id: 16 },
    { label: "Aetna Medicare Assure Plan (HMO D-SNP)", id: 17 },
    { label: "Aetna Medicare Essential Plan (PPO)", id: 18 },
    { label: "Aetna Medicare Eagle Plus Plan (PPO)", id: 19 },
    { label: "Aetna Medicare Elite (PPO)", id: 20 },
    { label: "Aetna Medicare Prime (HMO-POS)", id: 21 },
  ];

  const states = [
    { label: "New York", id: 1 },
    { label: "New Jersey", id: 2 },
    { label: "Pennsylvania", id: 3 },
    { label: "Michigan", id: 4 },
    { label: "Texas", id: 5 },
    { label: "Illinois", id: 6 },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const content = (
    <>
      <div className="accountPopover">
        <h3>Account</h3>
        <h3 onClick={() => navigate("/")}>Logout</h3>
      </div>
      {/* <div>Logout</div> */}
    </>
  );
  const onChange = (e) => {
    console.log(`switch to ${e.target.value}`);
    setdragger(e.target.value);
  };
  const handleClick1 = (e) => {
    localStorage.setItem('SubEmail', Email)
    window.open(`https://ebi.valuehealthsolutions.com/Priorrequest?/HCP%20Executive`, '_blank');
    // navigate(`/Priorrequest?/HCP%20Executive`)
    // Priorrequest?/HCP%20Executive

    // const url8 = `${BaseUrl}/email-service`;

    //   const body = {
    //     "email": "ragavaprasanna.v@valuehealthsol.com",
    //     "subject":"Prior Authorization Request Form",
    //     "template":"https://www.uhcprovider.com/content/dam/provider/docs/public/prior-auth/uhccp-pharmacy-forms/PA-Request-Form-UHC-Community-Plan.pdf"
    //   };

    //   axios
    //     .post(url8, body)
    //     .then((res) => {
    //       console.log(res.data,"emailservice")
    //       prior()

    //     })
    //     .catch((error) => {
    //       error();
    //     });


  }
  const data = {
    eligibility: 'Active',
    pcpCopay: '',
    specialistOfficeCopay: '$75',
    individualDeductible: '$2,500.00',
    individualDeductibleRemaining: '$2,500.00',
    familyDeductible: '$5,000.00',
    familyDeductibleRemaining: '$4,739.42',
    familyOopMax: '$18,200.00',
    familyOopRemaining: '$15,401.31'
  };
  const [eligibility, seteligibility] = useState('')
  const [familyOopMax, setfamilyOopMax] = useState('')
  const [coinsurance, setcoinsurance] = useState('')
  const [isModalOpencall, setIsModalOpencall] = useState(false);
  const [isMail, setisMail] = useState(true)
  const [Emails, setEmails] = useState('')
  const [Fax, setFax] = useState('')
  const showModalcall = () => {
    setIsModalOpencall(true);
  };
  const handleOk = () => {
    setIsModalOpencall(false);
    setEmails('')
    setFax("")
    setPage('1')
    setisMail(true)
  };
  const handleCancel = () => {
    setIsModalOpencall(false);
  };
  const onChangeModal = (e) => {
    if (e.target.value === 'email') {
      setisMail(true)
    } else {
      setisMail(false)
    }
  }
  const HandleModalClick = () => {
    let body = {
      payer_email:Emails ,
  status: "Not Assigned",
  payer_fax: Fax,
  Drugname: drugs,
  InsuranceName: payer ,
  MemberId: subscriberid,
  MemberName: firstname
    }
    axios
    .post(`${BaseUrl}/benefit-summary/create`,body )
    .then((res) => {
      setPage('2')
      setEmails('')
      setFax("")
      setisMail(true)
console.log(res,"")
    })
    .catch((err) => {

    })
  }


  return (
    <div className="pverifyApp" style={{ overflow: "scroll" }}>
      <Modal width={600} footer={false} open={isModalOpencall} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ margin: '20px' }}>
          {
            Page === '1' ?
              <>
                <h3>
                  Please select your prefered communication mode
                </h3>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="email"
                  name="radio-buttons-group"
                  onChange={onChangeModal}
                >
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label="Email"

                    />
                    <FormControlLabel
                      value="fax"
                      control={<Radio />}
                      label="Fax"

                    />
                  </div>
                </RadioGroup>
                {
                  !isMail ?
                    <TextField
                      onChange={(e) => setFax(e.target.value)}
                      sx={{ width: "100%" }}
                      fullWidth
                      required
                      label="Fax Number"
                      variant="outlined"
                    />
                    :
                    <TextField
                      onChange={(e) => setEmails(e.target.value)}
                      sx={{ width: "100%" }}
                      fullWidth
                      label="Email"
                      required
                      variant="outlined"
                    />
                }
                <Button
                  className="ModalBTN"
                  type="primary"

                  onClick={ HandleModalClick}
                >
                  Submit
                </Button>

              </>
              :
              <>

                <p style={{ fontSize: '16px' }}>
                  Thanks for submitting your request. Your request has been received, and you will receive an update shortly.
                </p>
                <Button
                  className="ModalBTN"
                  type="primary"
                  onClick={handleOk}
                >
                  Ok
                </Button>
              </>

          }

        </div>
      </Modal>
      {/* style={ overflow: "scroll" } */}
      <NewHeader />
      <Sidebar1 />

      <div className='minihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "#1DA0E1", marginTop: "1%" }} onClick={(e) => Navigate("/ebvtable?/" + roles + "")} />
        </div>
        <div className='miniheadtitle'>
          Patient Insurance Details
        </div>

      </div>
      <div className="container">
        {/* <aside className={`${isOpened ? "opened" : ""} drawer`}></aside> */}
        <div
          className={`main ${isBlackTheme ? 'black-theme' : 'white-theme'}`}
        >
          <div>
            <Grid
              container
              //  className={`form ${isBlackTheme ? 'black-theme' : 'white-theme'}`}
              className="form"
              spacing={2}
              style={{ margin: "auto" }}
            >
              <Grid style={{ marginLeft: 100 }} item xs={12} md={4} lg={4}>
                {/* <FormLabel id="demo-radio-buttons-group-label" style={{}}>

                  Insurance Info
                </FormLabel> */}


                {/* <Checkbox onChange={onChange}>Enter Manually</Checkbox> */}
                {roles == "superadmin" ? (
                  <>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Auto"

                      name="radio-buttons-group"
                      onChange={onChange}
                      disabled={true}
                    >
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value="Auto"
                          control={<Radio />}
                          label="Auto"
                          disabled={true}
                        />
                        <FormControlLabel
                          value="Mannual"
                          control={<Radio />}
                          label="Manual"
                          disabled={true}
                        />
                      </div>
                    </RadioGroup>
                  </>
                )
                  :
                  <><RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Auto"
                    name="radio-buttons-group"
                    onChange={onChange}
                  >
                    <div style={{ display: "flex" }}>
                      <FormControlLabel
                        value="Auto"
                        control={<Radio />}
                        label="Auto"
                      />
                      <FormControlLabel
                        value="Mannual"
                        control={<Radio />}
                        label="Manual"
                      />
                    </div>
                  </RadioGroup></>
                }

                {dragger === "Auto" ? (

                  <>
                    {roles === "superadmin" ? (
                      <>
                        <Row className="shield-div" style={{}}>
                          <Col>
                            <Dragger
                              disabled={true}
                              showUploadList={false} // Ensures file list is not displayed
                              style={{ margin: 0 }}
                              beforeUpload={() => false} // Prevents the default upload behavior
                            >
                              <p style={{ margin: 0 }}>Please Upload PNG, JPEG or PDF within 5MB</p>
                              <div className="border">
                                <p className="ant-upload-drag-icon" style={{ display: "none" }}>
                                  <Player
                                    style={{ width: "42%" }}
                                    src="https://lottie.host/b48fc081-a04a-4101-a78f-0b3d709fe41b/Ryh4hpyN6Q.json"
                                    className="player"
                                    loop
                                    autoplay
                                  />
                                </p>
                                <p className="ant-upload-text">Select file</p>
                              </div>
                            </Dragger>
                          </Col>
                        </Row>
                      </>
                    ) :
                      <><Row className="shield-div" style={{}}>
                        <Col>
                          <Dragger
                            customRequest={({ onSuccess }) => onSuccess("ok")}
                            {...props} style={{ margin: 0 }}>
                            <p style={{ margin: 0 }}>
                              Please Upload PNG, JPEG or PDF within 5MB
                            </p>
                            <div className="border">
                              <p className="ant-upload-drag-icon">
                                <Player
                                  style={{ width: "80%" }}
                                  src="https://lottie.host/b48fc081-a04a-4101-a78f-0b3d709fe41b/Ryh4hpyN6Q.json"
                                  className="player"
                                  loop
                                  autoplay
                                />
                              </p>
                              <p className="ant-upload-text">Select file</p>
                            </div>
                          </Dragger>
                        </Col>
                      </Row></>
                    }


                    <br />
                    <div className="progress">

                      <Progress percent={progress} steps={5} strokeColor={['orange', 'orange', 'orange']} />
                    </div>
                  </>
                ) : (
                  <>
                    <Box sx={{}}>
                      <TextField
                        sx={{ minWidth: 400 }}
                        fullWidth
                        label="Subscriber Id"
                        variant="outlined"
                        value={subscriberid}
                        onChange={(e) => setSubscriberid(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{ mt: 2, minWidth: 400 }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TextField
                      autoComplete="off"
                        onChange={(e) => setfirstname(e.target.value)}
                        sx={{ width: "100%" }}
                        value={firstname}
                        label=" First Name"
                        variant="outlined"
                      />
                      <TextField
                        value={lastname}
                        onChange={(e) => setlastname(e.target.value)}
                        sx={{ width: "100%", marginLeft: "10px" }}
                        fullWidth
                        label=" Last Name"
                        variant="outlined"
                      />
                    </Box>
                    {/* <div>Fingerprint Capture</div> */}

                    <Box className="pverifyDatepicker" sx={{ mt: 1 }}>
                      {/* <TextField  sx={{ minWidth: 400 }} fullWidth label="DOB" variant="outlined" /> */}

                      <LocalizationProvider
                        sx={{ minWidth: 300 }}
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disableFuture
                            inputFormat="MM/DD/YYYY"
                            onChange={(newValue) =>
                              setdob(newValue.format("MM/DD/YYYY"))
                            }
                            label="DOB"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>

                    <FormControl sx={{ mt: 3 }}>
                      <Box
                        sx={{ minWidth: 420 }}
                        style={{ display: "flex", flexDirection: "row", width: "200px" }}
                      >
                        <TextField
                          value={drugs}
                          onChange={(e) => {
                            setBtnVisible(true);
                            let value = e.target.value;
                            let lowerCase = value.toLocaleLowerCase();
                            setdrugs(lowerCase)
                          }}
                          autoComplete="off"

                          sx={{ width: "98%" }}
                          fullWidth
                          label=" Drug Name"
                          variant="outlined"
                        />
                      </Box>
                    </FormControl>


                    <FormControl sx={{ mt: 3 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={states}
                        disabled={roles === "superadmin"}
                        sx={{ width: 413 }}
                        renderInput={(params) => (
                          <TextField
                          autoComplete="off"
                            {...params}
                            label="State"
                            onSelect={(e) => setstate(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={payerlist}
                        disabled={roles === "superadmin"}

                        value={payer}
                        sx={{ width: 413 }}
                        renderInput={(params) => (
                          <TextField
                          autoComplete="off"
                            {...params}
                            label="Insurance Name"
                            onSelect={(e) => setPayer(e.target.value)}
                          />
                        )}
                      />


                    </FormControl>


                    <FormControl sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={plan}
                        value={planname}
                        // disabled={true}
                        disabled={roles === "superadmin" || "HCP%20Executive"}
                        sx={{ width: 413, marginBottom: "10px" }}
                        renderInput={(params) => (
                          <TextField
                          autoComplete="off"
                            {...params}
                            label="Plan Name"
                            onSelect={(e) => setPlanName(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                    <Box
                      sx={{ mt: 2, minWidth: 413 }}
                      style={{ display: "flex", flexDirection: "row", width: "200px" }}
                    >
                      <TextField
                      autoComplete="off"
                        disabled={true}
                        // onChange={(e) => setfirstname(e.target.value)}
                        sx={{ width: "100%", fontWeight: "bolder", fontSize: "24px" }}
                        value={ebv}
                        inputProps={{ style: { fontSize: 15, fontWeight: '600' } }}
                        label="eBV Gateway"
                        variant="outlined"
                      />
                    </Box>
                    <FormControlLabel
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      value="end"
                      control={<Checkbox style={{ marginRight: "5px" }} checked={isSubscriberPatient} onChange={handleChange} />}
                      label="The Subscriber is Patient"
                      labelPlacement="end"
                    />

                  </>
                )}
                <div>
                  {
                    BtnVisible ?
                      <LoadingButton
                        // disabled={response === !"" ? true : false}
                        className="savenxt"

                        loadingPosition="start"
                        variant="contained"
                        onClick={() => handleClick("Submit")}
                        loading={searchLoading}
                        style={{
                          // marginLeft: "850px",
                          display: "flex",
                          marginTop: 10,
                          width: '199px',
                          justifyContent: "center",
                          background: '#1DA0E1',
                          textTransform: "none",
                          flexFlow: "column"
                          // marginBottom: "50px",
                        }}
                      >
                        {
                          progressPercent == 100 ?
                            <> Submit eBV Request</>
                            :
                            <>
                              {
                                progressPercent ?
                                  <Progress
                                    style={{ width: '150px', color: '#fff' }}
                                    percent={progressPercent} strokeColor="orange" />
                                  :
                                  <> Submit eBV Request </>

                              }
                            </>
                        }
                      </LoadingButton>
                      :

                      <Button
                        type="primary"
                        style={{
                          display: "flex",
                          marginTop: 10,
                          width: '199px',
                          justifyContent: "center",
                          background: '#1DA0E1',
                        }}
                        className="savenxt"
                        onClick={handleRefresh}>Reset</Button>
                  }
                </div>
              </Grid>

              <Grid item xs={4} style={{ marginTop: 5 }} >

                {/* <ReportGmailerrorredIcon style={{ marginLeft: "500px", fontSize: "30px", color: "darkgreen" }}  /> */}

                <Col span={8}>

                  <div style={{ marginLeft: "25px" }} >
                    <Spin spinning={gridLoading} tip="Loading..." size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px', marginLeft: "290px" }}>

                      {/* <h3 style={{marginRight:"50px"}}>Coverage Details</h3> */}
                      {/* <div>
                <a onClick={showModal}>details</a>
                </div> */}


                      <div>
                        {
                          SbcVisible ?
                            <Card className="pverifyCard1" style={{ width: "660px", border: "1px solid #1da0e1" }}  >
                              <div className="coverage-columns">


                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={8}>Eligibility & Coverage Status</Col>
                                  <Col style={{ color: " #003effbd", fontWeight: 700 }} span={6}>{eligibility} </Col>


                                </Row>
                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={8}>Policy Type</Col>
                                  <Col style={{ color: " #003effbd", fontWeight: 700 }} span={6}>{PolicyType} </Col>
                                </Row>

                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={8}>Provider Network Status</Col>
                                  <Col style={{ color: "#0b9509", fontWeight: 800 }} span={6}>{NetworkStatus}</Col>

                                </Row>

                                {/* <Row>
                                <Col style={{ fontWeight: 500 }} span={8}>Coinsurance</Col>
                                <Col style={{ color: " #003effbd", fontWeight: 700 }} span={6}>{coinsurance} </Col>
                              </Row> */}



                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={8}>Drug Coverage Status</Col>
                                  <Col style={{ color: " #003effbd", fontWeight: 700 }} span={8}>{responseCovered.toUpperCase()}</Col>

                                </Row>



                                {(responseCovered === "covered" || responseCovered === "covered with condition") && NetworkStatus === "In-Network" ? (
                                  <Row>
                                    <Col style={{ fontWeight: 500 }} span={8}>Estimated Drug cost</Col>
                                    <Col style={{ color: "#003effbd", fontWeight: 700 }} span={6}>{DrugCost}</Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col style={{ fontWeight: 500 }} span={8}>Estimated Drug cost</Col>
                                    <Col style={{ color: "#003effbd", fontWeight: 700 }} span={6}>Depends on provider charges</Col>
                                  </Row>
                                )}



                                <Row>
                                  <Col span={10}></Col>
                                  <Col style={{ fontWeight: 800 }} span={4}> Maximum  </Col>
                                  <Col style={{ fontWeight: 800 }} span={4}> Remaining  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={10}>Copay</Col>
                                  {
                                    Payout ?
                                      (
                                        copayStatus !== "$0.00" || null ? (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{copayStatus !== null ? copayStatus : "-"}</Col>
                                          </>
                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      ) : (
                                        copayStatusout !== "$0.00" || null ? (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{copayStatusout !== null ? copayStatusout : "-"}</Col>
                                          </>
                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      )}




                                </Row>
                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={10}>Individual Deductible</Col>


                                  {Payout ? (
                                    balance !== "$0.00" || null ? (
                                      <>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{balance !== null ? balance : "-"}</Col>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{IndividualDeductibleRemainingInNet !== null ? IndividualDeductibleRemainingInNet : "-"}</Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                      </>
                                    )
                                  ) : (
                                    balanceOut !== "$0.00" || null ? (
                                      <>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{balanceOut !== null ? balanceOut : "-"}</Col>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{IndividualDeductibleRemainingOutNet !== null ? IndividualDeductibleRemainingOutNet : "-"}</Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                        <Col style={{ color: "#003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                      </>
                                    )
                                  )}

                                </Row>

                                {/* <Row>
                            <Col style={{ fontWeight: 500 }} span={10}>Individual Deductible Remaining</Col>
                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualDeductibleRemainingInNet}</Col>
                            
                          </Row> */}

                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={10}>Individual OOP</Col>
                                  {
                                    Payout ? (
                                      IndividualOOP_InNet !== "$0.00" || null ? (
                                        <>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualOOP_InNet !== null ? IndividualOOP_InNet : "-"}</Col>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualOOPRemainingInNet !== null ? IndividualOOPRemainingInNet : "-"}</Col>
                                        </>
                                      ) : (
                                        <>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                        </>
                                      )
                                    ) : (
                                      IndividualOOP_OutNet !== "$0.00" || null ? (
                                        <>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualOOP_OutNet !== null ? IndividualOOP_OutNet : "-"}</Col>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualOOPRemainingOutNet !== null ? IndividualOOPRemainingOutNet : "-"}</Col>
                                        </>
                                      ) : (
                                        <>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                        </>
                                      )
                                    )
                                  }


                                </Row>
                                {/* <Row>
                            <Col style={{ fontWeight: 500 }} span={10}>Individual OOP Remaining</Col>
                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{IndividualOOPRemainingInNet}</Col>
                           
                          </Row> */}


                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={10}>Family Deductible</Col>
                                  {
                                    Payout ?
                                      (
                                        FamilyDeductibleInNet !== "$0.00" || null ? (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyDeductibleInNet !== null ? FamilyDeductibleInNet : "-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyDeductibleRemainingInNet !== null ? FamilyDeductibleRemainingInNet : "-"}</Col>
                                          </>
                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      ) : (
                                        FamilyDeductibleOutNet !== "$0.00" || null ? (
                                          <>

                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyDeductibleOutNet !== null ? FamilyDeductibleOutNet : "-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyDeductibleRemainingOutNet !== null ? FamilyDeductibleRemainingOutNet : "-"}</Col>
                                          </>

                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      )
                                  }


                                </Row>

                                {/* <Row>
                            <Col style={{ fontWeight: 500 }} span={10}>Family Deductible Remaining</Col>
                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyDeductibleRemainingInNet}</Col>
                          </Row> */}


                                <Row>
                                  <Col style={{ fontWeight: 500 }} span={10}>Family OOP Max</Col>
                                  {
                                    Payout ?
                                      (
                                        FamilyOOPInNet !== "$0.00" || null ? (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyOOPInNet !== null ? FamilyOOPInNet : "-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}> {FamilyOOPRemainingInNet !== null ? FamilyOOPRemainingInNet : "-"}</Col>
                                          </>
                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      ) : (
                                        FamilyOOPOutNet !== "$0.00" || null ? (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}> {FamilyOOPOutNet !== null ? FamilyOOPOutNet : "-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyOOPRemainingOutNet !== null ? FamilyOOPRemainingOutNet : "-"}</Col>
                                          </>

                                        ) : (
                                          <>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{"-"}</Col>
                                          </>
                                        )
                                      )
                                  }

                                </Row>
                                {/* <Row>
                            <Col style={{ fontWeight: 500 }} span={10}>Family OOP Remaining</Col>
                            <Col style={{ color: " #003effbd", fontWeight: 700 }} span={4}>{FamilyOOPRemainingInNet}</Col>
                          </Row> */}

                                <Row>


                                </Row>

                              </div>
                            </Card>
                            :
                            <></>
                        }

                      </div>
                    </Spin>


                  </div>

                </Col>
                {SbcVisible
                  ?


                  <Col span={8}>
                    <div>
                      <Col span={24}>
                        <div style={{ marginTop: "8%", marginLeft: "25px" }} className="leftdiv">
                          {/* <Row className="induranceInfo">
                            <h3> Insurance Info </h3>
                          </Row> */}
                          <Card className="pverifyCard" >

                            <div>
                              {summary && (
                                <div>
                                  {/* <div style={{ fontWeight: "bold", fontSize: 20 }}>Summary</div> */}
                                  <div style={{ fontWeight: "bold" }}>Drug Formulary Status:</div>
                                  <div>{summary}</div>
                                </div>
                              )}
                              {/* {sbc && (
                                <div>
                                  <div style={{ marginTop: "20px", fontWeight: "bold" }}>SBC:</div>
                                  <div>{sbc}</div>
                                </div>
                              )} */}
                              {/* {hyperlinks && (
                                <div style={{ width: "90%", overflow: "hidden", marginTop: "20px" }}>
                                  <div style={{ fontWeight: "bold" }}>Reference Link:</div>
                                  <a href={hyperlinks} target="_blank" rel="noopener noreferrer">
                                    {hyperlinks}
                                  </a>
                                </div>
                              )} */}

                            </div>

                          </Card>

                        </div>
                      </Col>
                    </div>
                  </Col>
                  :
                  <></>
                }

                <Col offset={1} span={24}>
                  {
                    SbcVisible ?


                      <Card className="CallCard" style={{ background: "#1DA0E11A", marginTop: 10, marginLeft: 7, width: 660 }}>


                        <p style={{ padding: 5 }} >
                          Please submit a full benefit report request. We will get back to you shortly with the full SBC information via email/Fax.
                        </p>




                        <Button
                          onClick={showModalcall}
                          className="callBTN">
                          Request Full SBC
                        </Button>

                        <Row span={10} className="">


                          {icon1 ? (
                            <span >
                              <p>
                                Looking for additional support? Click here to see if you qualify for patient support programs that can provide you with extra assistance
                              </p>
                              <span style={{ color: "black", justifyContent: "center", marginTop: "20px" }}>

                                {responseCovered === "covered" && (
                                  <Button style={{ background: '#1DA0E1', marginBottom: "10px", marginTop: "10px" }} type="primary" >
                                    Check if Qualify for Copay?
                                  </Button>
                                )}

                              </span>

                            </span>
                          ) : (
                            ""
                          )}
                          {icon2 ? (
                            <span >
                              <WarningAmberIcon
                                style={{ gap: "30px", color: "orange", position: "relative", top: "7px" }}
                              />

                            </span>
                          ) : (
                            ""
                          )}
                          {icon4 ? (
                            <span >
                              <p>
                                Need approval for your drug? Click here to initiate a Prior Authorization request
                              </p>
                              <span style={{ color: "black", justifyContent: "center" }}>

                                {responseCovered === "covered with condition" && (
                                  <Button style={{ background: '#1DA0E1', marginBottom: "10px", marginTop: "10px" }} type="primary" onClick={handleClick1}>
                                    Prepare Prior Auth Request
                                  </Button>

                                )}
                              </span>

                            </span>
                          ) : (
                            ""
                          )}

                          {icon3 ? (
                            <span >
                              <p>
                                Is the drug not covered by your payer or approval denied? Click here to submit an appeal.
                              </p>
                              <span style={{ color: "black" }}>

                                {/* {responseCovered === "not covered" && (
        <Button style={{ background:'#1DA0E1', marginBottom: "10px", marginTop: "10px" }} type="primary" >
          Check if Qualify for Free Program?
        </Button>
      )} */}
                                {responseCovered === "not covered" && (
                                  <Button style={{ background: '#1DA0E1', marginBottom: "10px", marginTop: "10px" }} type="primary" >
                                    Request for Appeal
                                  </Button>
                                )}

                              </span>

                            </span>

                          ) : (
                            ""
                          )}
                          {icon5 ? (
                            <span >
                              {/* <CloseIcon
      style={{ gap: "30px", color: "red", top: "10px", position: "relative", top: "7px" }}
    /> */}
                              <span style={{ marginLeft: "3px", color: "black", justifyContent: "center", display: "flex" }}>
                                {" "}

                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </Row>

                      </Card>
                      :
                      <></>
                  }
                </Col>
                {
                  BtnVisible ?


                    <div className="savenxtdiv">

                    </div>
                    :
                    <div style={{ marginLeft: 18 }} >
                      {/* <Card className="LastCard" style={{background:"#1DA0E11A",marginTop:10,marginLeft:7,width:660}}>
              
   </Card> */}
                    </div>

                }
                <div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

    </div>
  );
};

export default DrugPverify;