
// import React from 'react';
import "./AddNewHospital.css"
import NewHeader from '../../NewHeader';
import Sidebar1 from '../../Sidebar1';
import { json, useNavigate, useParams } from "react-router";
// import "../main.css"
// import "../Sidebar.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Grid, MenuItem, Stack, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Col, Row } from 'antd';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WestOutlined } from '@mui/icons-material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../../../config'
import { DatePicker, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas'

import { message, Upload, Modal, Checkbox } from "antd";
import dayjs from 'dayjs';
import { useAuth } from '../../../Newlogin/Authprovider'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Input } from 'antd';

const { TextArea } = Input;



const customStyles = {
  width: 500, // Adjust the width as needed
  height: 60, // Adjust the height as needed
  position: 'relative', // Needed for label placement
};

const customInputLabelStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: "14px",
  color: "#797E81",

  transform: 'translate(15px, 2px)', // Adjust the vertical position of the label
  pointerEvents: 'none', // To allow clicking through the label
};
const inputStyle2 = {

  width: '500px',
  // height:"50px",
  marginTop: "1%",
  // height:"100px",
  backgroundColor: 'white',
  // margin:'auto', // Adjust the width as needed
  //     justifyContent:"center"
};

const inputStyle = {
  width: '500px',
  backgroundColor: 'white',
  // textAlign:'center',
  // height: '40px',
};

const containerStyle = {
  width: "90%",
  margin: 'auto',
  marginTop: "1%",
  marginLeft: "7%",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "8px",
  // height: "65vh"
};

const stackStyle = {
  display: "flex",
  // justifyContent:"space-between"
  gap: "5px",
  // marginTop: "2%",
  // margin:" 0 auto",
  justifyContent: "center",
  marginTop: "1%"
}

const stackStyle2 = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  marginTop: "0%",
}

const stackStyleTop = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1%",
}

const stackStyledrop = {
  display: "flex",
  margin: "auto",
  marginTop: "2%",
  marginLeft: "4%"
}

const stackStylebot = {
  display: "flex",
  justifyContent: "left",
  display: "flex",
  gap: "5px",
  margin: "auto",
  marginTop: "2%"
}

const Authorization = () => {
  const success = () => {
    message.open({
      type: "success",

      content: "HCP placed PA request successfully",
    });
  };
  const errors = () => {
    message.open({
      type: "error",
      content: "Failed to Updated",
    });
  };
  const requiredError = () => {
    message.open({
      type: "error",
      content: "Please fill the required field",
    });
  };

  const [ paramCode, setParamCode ] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get('code');
    const state = urlParams.get('state');

    console.log(code, 'paramCode')

    
    if( code ) {
      setParamCode(code);
      exchangeCodeForToken(code);
    }


  }, [])

  const showModal = () => {
    if( paramCode ){
      setIsModalOpen(true);
    } else{
      initiateOAuth();
    }
  };

  // console.log(paramCode, 'paramCode')



  //   const handleOk = () => {
  //     setIsModalOpen(false);
  //     trim()
  //     console.log(Signature,"signature")
  //     clear()

  //   };

  const handleNpiIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue = sanitizedValue.slice(0, 10);

    setnpiId(truncatedValue);

    if (sanitizedValue.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };

  const handleNumberChange = (e) => {
    const sanitizedValue1 = e.target.value.replace(/[^0-9]/g, '');
    const truncatedValue1 = sanitizedValue1.slice(0, 10);

    setnumber(truncatedValue1);

    if (sanitizedValue1.length > 10) {
      setError('Max 10 characters allowed');
    } else {
      setError('');
    }
  };



  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  //   const { isAuthenticated } = useAuth();
  //   const navigate = useNavigate();
  // useEffect(() => {
  // if (!isAuthenticated()) {

  //   navigate('/');
  // }
  // const handleChange1 = (event) => {
  //   setcontact(event.target.value);
  // };
  const [isValidateNpiId, setIsValidateNpiId] = useState(true)
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [isstatusValid, setIsstatusValid] = useState(true);
  const [isHospitalValid, setIsHospitalValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSign, setIsModalOpenSign] = useState(false);
  const [isStateValid, setIsStateValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isServiceTypeValid, setIsServiceTypeValid] = useState(true);
  const [trimmedDataURL, settrimmedDataURL] = useState()
  const [Signature, setSignature] = useState("")

  const onChange = () => {

  }
  const validate = (value) => {
    const lettersOnlyRegex = /^[a-zA-Z\s\S]+$/;
    return lettersOnlyRegex.test(value);
  };
  const npiIdValidate = (v) => {
    const NumberRegex = /^\d{1,10}$/;
    return NumberRegex.test(v)
  }

  const numberValidate = (value) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(value)
  }

  const handleOk = () => {
    setIsModalOpen(false);
    setFile(null);
    setSenderEmail('')
    // trim()
    // console.log(Signature, "signature")
    // clear()

  };

  const handleOkSign = () => {
    setIsModalOpenSign(false);
    trim()
    console.log(Signature, "signature")
    clear()
  }

  const handleCancelSign = () => {
    setIsModalOpenSign(false);
  }


  const sigPad = {}
  const clear = () => {
    Signature.clear()
  }
  const trim = () => {
    settrimmedDataURL(
      Signature.getTrimmedCanvas().toDataURL('image/png')

    )
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setSenderEmail('');
    setFile(null);
  };



  const [address, setaddress] = useState('');
  const [npiId, setnpiId] = useState('');
  const [hospitalname, sethospitalname] = useState('');
  const [city, setcity] = useState('');
  const [hcp, sethcp] = useState('');
  const [zipcode, setzipcode] = useState('');
  const [role, setrole] = useState('');
  const [state, setstate] = useState('');
  const [status, setstatus] = useState('');
  const [number, setnumber] = useState('');
  const [email, setemail] = useState('');
  const [hospitaltype, sethospitaltype] = useState("");
  const [error, setError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  //   const [member, setmember] = useState('');
  //   const [membername, setmembername] = useState('');
  //   const [npinumber, setnpinumber] = useState('');
  //   const [memberaddress, setmemberaddress] = useState('');
  //   const [memberzip, setmemberzip] = useState('');
  //   const [memberstate, setmemberstate] = useState('');
  //   const [membercity, setmembercity] = useState('');
  //   const [memberall, setmemberall] = useState('');
  //   const [memberphone, setmemberphone] = useState('');
  //   const [membermed, setmembermed] = useState('');
  //   const [memberdir, setmemberdir] = useState('');
  //   const [memberstr, setmemberstr] = useState('');
  //   const [memberqu, setmemberqu] = useState('');
  //   const [memberdob, setmemberdob] = useState('');
  //   const [memberdob, setmemberdob] = useState('');

  const [renprovider, setrenprovider] = useState('');
  const [renfirst, setrenfirst] = useState('');
  const [renlast, setrenlast] = useState('');
  const [rennpi, setrennpi] = useState('');
  const [renadd, setrenadd] = useState('');
  const [renstate, setrenstate] = useState('');
  const [renzip, setrenzip] = useState('');
  const [rencity, setrencity] = useState('');
  const [rentaxi, setrentaxi] = useState('');
  const [renfac, setrenfac] = useState('');
  const [refprovider, setrefprovider] = useState('');
  const [reffirst, setreffirst] = useState('');
  const [reflast, setreflast] = useState('');
  const [refnpi, setrefnpi] = useState('');
  const [refadd, setrefadd] = useState('');
  const [refstate, setrefstate] = useState('');
  const [refzip, setrefzip] = useState('');
  const [refcity, setrefcity] = useState('');
  const [refdobstart, setrefdobstart] = useState('');
  const [refdobend, setrefdobend] = useState('');
  const [subfirst, setsubfirst] = useState('');
  const [sublast, setsublast] = useState('');
  const [subdob, setsubdob] = useState('');
  const [submember, setsubmember] = useState('');
  const [subdrug, setsubdrug] = useState('');
  const [subemail, setsubemail] = useState('');
  const [substrength, setstrength] = useState('');
  const [subquantity, setQuantity] = useState('');
  const [ clinicalNotes, setClinincalNotes ] = useState('');
  const [MasterID, setMasterID] = useState('')
  const [uploadedFileNames, setUploadedFileNames] = useState([]);










  const Navigate = useNavigate();

  const managnment = window.location.href

  const my_list = managnment.split("/")
  console.log(my_list[4], "fffffffff")

  let drug = (localStorage.getItem("drug"));
  console.log(drug, "drug")

  useEffect(() => {

    //   setmember("Ram Yadlapalli")
    //   setmembername("48627401700")
    //   setnpinumber("1063764280")
    //   setmemberaddress("907 BARN OWL WAY")
    //   setmemberzip("08886")
    //   setmemberstate("Illinois")
    //   setmembercity("Stewartsville")
    //   setmemberall("Skin Rashes")
    //   setmemberphone("1800-454-343")
    //   setmembermed(drug)
    //   setmemberdir("tablet")
    //   setmemberstr("200mg")
    //   setmemberqu("7")
    //   setmemberdob("03/03/1960")
    //   1800-454-343
  }, [])

  const template = `RenderingProvider  
                        PriorAuthProviderType: ${renprovider}\n
                        First Name: ${renfirst}\n
                        Last Name: ${renlast}\n
                        NPI: ${rennpi}\n
                        Address: ${renadd}\n
                        Zip Code: ${renzip}\n
                        City: ${rencity}\n
                        State: ${renstate}\n
                        TaxiId: ${rentaxi}\n
                        Facilitynpi: ${renfac}`;

  const template1 = `ReferringProvider  
                        PriorAuthProviderType: ${refprovider}\n
                        First Name: ${reffirst}\n
                        Last Name: ${reflast}\n
                        NPI: ${refnpi}\n
                        Address: ${refadd}\n
                        Zip Code: ${refzip}\n
                        City: ${refcity}\n
                        State: ${refstate}\n`;

  const template3 = `Subscriber

  
                       
                        First Name: ${subfirst}\n
                        Last Name: ${sublast}\n
                        Member Id: ${submember}\n
                        Drug Name :${subdrug}\n
                        Email Id :${subemail}\n`;


  //   const handleClick = (e) => {

  const create = `${BaseUrl}/prior-authorization/findemail`;


  const bodyprior = {
    "emailId": my_list[4],

  };

  axios
    .post(create, bodyprior)
    .then((res) => {
      console.log(res, "yyryryr")
      setrenprovider(res.data.data[0].renderPriorAuthorization)
      setrenfirst(res.data.data[0].renderFirstName)
      setrenlast(res.data.data[0].renderLastName)
      setrennpi(res.data.data[0].renderNpiNumber)
      setrenadd(res.data.data[0].renderAddress)
      setrenzip(res.data.data[0].renderZipcode)
      setrenstate(res.data.data[0].renderState)
      setrencity(res.data.data[0].renderCity)
      setrentaxi(res.data.data[0].taxiId)
      setrenfac(res.data.data[0].facilityId)
      // setrefprovider(res.data.data[0].referPriorAuthorization)
      // setreffirst(res.data.data[0].referFirstName)
      // setreflast(res.data.data[0].referLastName)
      // setrefnpi(res.data.data[0].referNpiNumber)
      // setrefadd(res.data.data[0].referAddress)
      // setrefcity(res.data.data[0].referCity)
      // setrefzip(res.data.data[0].referZipcode)
      // setrefstate(res.data.data[0].referState)
      setrefdobstart(res.data.data[0].Startdob)
      setrefdobend(res.data.data[0].Enddob)
      setsubfirst(res.data.data[0].firstName)
      setsublast(res.data.data[0].lastName)
      setsubdob(res.data.data[0].dob)
      setsubmember(res.data.data[0].memberId)
      setsubdrug(res.data.data[0].drugName)
      setsubemail(res.data.data[0].emailId)
      setstrength(res.data.data[0].strength)
      setMasterID(res.data.data[0].masterId)
      // setsubmember("48627401700")
      setQuantity(res.data.data[0].quantity)
      setClinincalNotes(res.data.data[0].clinical_notes);


      // if(res.data.status=="Ok"){
      //   navigator("/PriorTable")
      // }
    })
    .catch((error) => {
      error();
    });



  // const url8 = `${BaseUrl}/email-service`;

  // const body = {
  //     "email": "ragavaprasanna.v@valuehealthsol.com",
  //     "subject": "Prior Authorization",
  //     "template": `${template}\n\n${template1}\n\n${template3}`
  // };

  //   axios
  //     .post(url8, body)
  //     .then((res) => {
  //     })
  //     .catch((error) => {
  //       error();
  //     });


  // success();

  // Delay the navigation by 4 seconds
  // setTimeout(() => {
  //   Navigate("/drugpverify");
  // }, 5000);

  // The rest of your code...






  if (zipcode) {
    const url1 = `${BaseUrl}/hospitals/state-city`;

    const body = {
      "zipCode": zipcode,
    };

    axios
      .post(url1, body)
      .then((res) => {
        setcity(res.data.city)
        setstate(res.data.state)
      })
  }
  useEffect(() => {
    const masterId = my_list[5]
    console.log("wertyujik", masterId)
    if (masterId) {
      const getOneHospital = async () => {
        const url = `${BaseUrl}/hospitals/findOne`;
        let response = await axios.post(url, { masterId: my_list[5] })
        const dataArray = response.data.data[0]

        setaddress(dataArray.address);
        setnpiId('');
        sethospitalname(dataArray.hospitalName);
        setcity(dataArray.city);
        sethcp('');
        setzipcode(dataArray.zipCode);
        setrole('');
        setstate(dataArray.state);
        setstatus(dataArray.status);
        setnumber(dataArray.phoneNumber);
        setemail(dataArray.email);
        sethospitaltype('');
      }
      getOneHospital()
    }
  }, [])

  useEffect(() => {

    const url8 = `${BaseUrl}/hospitals/generate-hcp-office`;

    axios
      .get(url8)
      .then((res) => {
        console.log(res, "aaaaaaa")
        sethcp(res.data.hcpid)
        // setcity(res.data.city)
        // setstate(res.data.state)
      })
  }, [])

  const hospital = [
    { label: "Chemotherapy", id: 1 },
    { label: "PrimaryCare", id: 2 },
    { label: "Chiropractic", id: 3 },
    { label: "GeneralOfficeBenefits30", id: 4 },
    { label: "Psychotherapy", id: 5 },
    { label: "Allergies", id: 6 },
    { label: "GeneralOfficeBenefitsMaternity", id: 7 },
    { label: "Infertility83", id: 8 },
    { label: "GynecologicalBT", id: 9 },
    { label: "HomeHealthCare42", id: 10 },
    { label: "Anesthesia", id: 11 },
    { label: "Telemedicine", id: 12 },
    { label: "Pharmacy", id: 13 },
    { label: "PsychiatricA4", id: 14 },
    { label: "XRay", id: 15 },
  ];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //   const [snackbarMessage, setSnackbarMessage] = useState('');



  const validateForm = () => {
    let isValid = true;

    if (!validateEmail(email)) {
      console.log(1)
      setIsEmailValid(false);
      isValid = false;
    } else {
      console.log(2)
      setIsEmailValid(true);
    }

    if (!npiIdValidate(npiId)) {
      setIsValidateNpiId(false);
      isValid = false;
    } else {
      setIsValidateNpiId(true);
    }

    if (!validate(hospitalname)) {
      console.log(3)
      setIsHospitalValid(false);
      isValid = false;
    } else {
      console.log(4)
      setIsHospitalValid(true);
    }

    if (!validate(status)) {
      console.log(5)
      console.log(status)
      setIsstatusValid(false);
      isValid = false;
    } else {
      console.log(6)
      setIsstatusValid(true);
    }
    if (!validate(address)) {
      console.log(7)
      setIsAddressValid(false);
      isValid = false;
    } else {
      console.log(8)
      setIsAddressValid(true);
    }

    if (!validate(zipcode)) {
      console.log(9)
      setIsZipCodeValid(false);
      isValid = false;
    } else {
      console.log(10)
      setIsZipCodeValid(true);
    }


    if (!validate(state)) {
      console.log(11)
      setIsStateValid(false);
      isValid = false;
    } else {
      console.log(12)
      setIsStateValid(true);
    }
    if (!validate(city)) {
      console.log(13)
      setIsCityValid(false);
      isValid = false;
    } else {
      console.log(14)
      setIsCityValid(true);
    }
    if (!numberValidate(number)) {
      console.log(15)
      setIsNumberValid(false);
      isValid = false;
    } else {
      console.log(16)
      setIsNumberValid(true);
    }
    if (!validate(hospitaltype)) {
      console.log(17)
      console.log(hospitaltype)
      setIsServiceTypeValid(false);
      isValid = false;
    } else {
      console.log(18)
      setIsServiceTypeValid(true);
    }
    return isValid;
  };

  const handleClick1 = (e) => {
let body ={
  masterId: MasterID,
  status:'Request Placed'
}
    axios
    .post(`${BaseUrl}/prior-authorization/update`,body)
    .then((res) => {
      
      success()
    })
    .catch((err) => {

    })
  }

  const [showInputs, setShowInputs] = useState(false);
  const toggleInputs = () => {
    setShowInputs(!showInputs);
  };
  const resetForm = () => {
    setnpiId('');
    // sethcp('');
    setstatus('');
    sethospitalname('');
    setaddress('');
    setcity('');
    setzipcode('');
    setstate('');
    setnumber('');
    setemail('');
    sethospitaltype(""); // Reset hospitaltype here
    setError('');
    setIsEmailValid(true);
    setIsValidateNpiId(true);
    setIsHospitalValid(true);
    setIsServiceTypeValid(true)
    setIsNumberValid(true)
    setIsCityValid(true)
    setIsStateValid(true)
    setIsZipCodeValid(true)
    setIsAddressValid(true)
    setIsstatusValid(true)
  };


  // ========================Adobe E-Sign=====================


  const [bearer, setBearer] = useState('');
  const [file, setFile] = useState(null);
  const [SenderEmail, setSenderEmail] = useState('');


  console.log(file, 'senderFile')

  const initiateOAuth = () => {
    const clientId = 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083';
    const redirectUri = encodeURIComponent('https://ebi.valuehealthsolutions.com/Authorization/');
    const responseType = 'code';
    const scope = 'user_login:self+agreement_read:account+agreement_write:account';
    const adobeAuthUrl = `https://secure.in1.adobesign.com/public/oauth/v2?
redirect_uri=${redirectUri}&
response_type=code&client_id=${clientId}&scope=user_login:self+agreement_read:self+agreement_write:self&state=S6YQD7KDA556DIV6NAU4ELTGSIV26ZNMXDSF7WIEEP0ZLQCLDQ89OYG78C3K9SROC8DXCGRVSGKU1IT1`;

    window.location.href = adobeAuthUrl;
    // console.log(redirectUri, '"adobeAuthUrl"')
  };

  async function exchangeCodeForToken(authCode) {

    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', authCode);
    params.append('client_id', 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083');
    params.append('client_secret', '9chxWJjipetgYhlsnPit-Rb4EOtR3xfZ');
    params.append('redirect_uri', 'https://ebi.valuehealthsolutions.com/Authorization/');

    console.log(params, 'oAuthParam')

    try {
      const response = await axios.post('https://api.in1.adobesign.com/oauth/v2/token', params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      console.log('Access Token:', response);

      if (response.status === 200) {
        const refreshToken = response.data.refresh_token;
        const clientId = 'ats-ef4c7365-e55a-43de-8c0c-d4ea65f28083';
        const clientSecret = '9chxWJjipetgYhlsnPit-Rb4EOtR3xfZ';
        refreshAccessToken(refreshToken, clientId, clientSecret);
      }

      return response.data;
    } catch (error) {
      console.error('Failed to exchange code for token:', error.response.data);
      throw error.message;
    }
  }

  async function refreshAccessToken(refreshToken, clientId, clientSecret) {
    const url = 'https://api.in1.adobesign.com/oauth/v2/refresh';
    const params = new URLSearchParams();
    params.append('refresh_token', refreshToken);
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);
    params.append('grant_type', 'refresh_token');

    try {
      const response = await axios.post(url, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      console.log('New Access Token:', response);
      setBearer(response.data.access_token);
      // Optionally, return the new access token or handle it according to your application's logic
      return response.data;
    } catch (error) {
      console.error('Failed to refresh token:', error.response ? error.response.data : error.message);
      throw error;
    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }


  const handleUploadAndSend = async () => {
    // if (!formData.has('File') || !formData.has('RecipientEmail')) {
    // if (!formData.has('File')) {
    //     alert('Please provide a file and a recipient email.');
    //     return;
    // }

    if (!file || !SenderEmail) {
      alert('Please provide a file and a recipient email.');
      return;
    }

    try {
      // debugger

      // const s3FileUrl = 'https://s3.amazonaws.com/trainingnexus/DLP/0d948c91-d41f-4c8e-bf18-2df361f49f87/Value%20Health%20-%20HIPAA%20Compliance%20Training_BCP.pptx%20%283%29.pdf'
      const formData = new FormData();
      formData.append('File', file);
      const uploadConfig = {
        headers: {
          //Bearer Token 
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      // const transientDocumentData = {
      //   fileInfos: [{
      //     mimeType: 'application/pdf', // Adjust MIME type as per your file type
      //     name: 'document.pdf', // Adjust filename as per your file
      //     transientDocumentId: '1',
      //     documentURL: s3FileUrl // S3 URL of the document
      //   }],
      //   name: 'Sample Agreement',
      //   signatureType: 'ESIGN',
      //   state: 'IN_PROCESS',
      //   // Additional metadata if needed
      // };

      const uploadResponse = await axios.post('https://api.in1.adobesign.com/api/rest/v6/transientDocuments', formData, uploadConfig);
      const transientDocumentId = uploadResponse.data.transientDocumentId;

      // Construct agreementData for sending the document for signing
      const agreementData = {
        fileInfos: [{
          transientDocumentId: transientDocumentId
        }],
        name: 'Please Sign This Document',
        participantSetsInfo: [{
          order: 1,
          role: 'SIGNER',
          memberInfos: [{
            // email: formData.get('RecipientEmail')
            email: SenderEmail
          }]
        }],
        signatureType: 'ESIGN',
        state: 'IN_PROCESS'
      };

      const agreementConfig = {
        headers: {
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'application/json'
        }
      };

      const agreementRequest = await axios.post('https://api.in1.adobesign.com/api/rest/v6/agreements', agreementData, agreementConfig);
      const agreementResponse = agreementRequest
      console.log(agreementResponse.data, 'agreementResponse')

      alert('Document sent for signing successfully.');
      handleOk();
    } catch (error) {
      console.error('Error processing document:', error);
      alert('Failed to send document for signing.');
    }
  };

  return (
    <div className='addnewhospitalpicontainer'
    // style={{backgroundColor:"orange"}}
    // style={{ overflow: "scroll" }}
    >
      <NewHeader />
      <Sidebar1 />
      <div className='addnewhospitalminihead'>
        <div style={{ marginTop: "0.4%" }}>
          <WestOutlined style={{ color: "orange", marginTop: "1%" }} onClick={(e) => Navigate("/PriorTable?/" + my_list[4] + "")} />
        </div>
        <div className='addnewhospitalminiheadtitle'>
          Please complete the PA request form for Patient {subfirst} for the drug {subdrug} and electronically sign so we can proceed with the PA process.
        </div>
      </div>
      <div>
        <div style={containerStyle} >
          <div style={{ paddingTop: "20px", marginLeft: "6%" }}><b></b></div>
          {/* <div className="containerlogin"
           style={containerStyle} 
           > */}
          <Grid className='addnewhospitallogingrid'
          // style={{ overflow: "scroll" }}
          >
            <p style={{ marginLeft: "105px", fontWeight: "bold" }}>Requester</p>
            <p style={{ color: '#a1a1a1', fontSize: 12, marginLeft: '105px' }}>
              (Please verify the information for accuracy)
            </p>
            <Stack direction="row" spacing={1} style={stackStyleTop} className='top'>
              {/* <TextField
                sx={{ width: '41%' }}
                style={inputStyle}
                label="PriorAuthProviderType"
                id="Member Name"
                variant="outlined"
                value={renprovider}
                onChange={(e) => setrenprovider(e.target.value)}
                size="small"
              /> */}

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="First Name"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={renfirst}
                // placeholder="Member ID"
                onChange={(e) => setrenfirst(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="NPI"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={rennpi}
                // placeholder="NPI ID"
                onChange={(e) => setrennpi(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'>

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Last Name"
                required
                // disabled={true}
                id="npi"
                variant="outlined"
                value={renlast}
                // placeholder="NPI ID"
                onChange={(e) => setrenlast(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="FacilityNPI"
                id="email"
                variant="outlined"
                value={renfac}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                onChange={(e) => setrenfac(e.target.value)}
                // defaultValue="Small"
                size="small"
              />




              {/* <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DOB'
                variant="outlined"
                value={memberdob ? dayjs(memberdob) : null}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              /> */}

            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Address"
                required
                // error={!isAddressValid}
                // helperText={!isAddressValid ? 'required field !' : ''}
                id="email"
                variant="outlined"
                value={renadd}
                // placeholder="Enter your Address"
                onChange={(e) => setrenadd(e.target.value)}
                // defaultValue="Small"
                size="small"
              />


              {/* <Tooltip title="Zipcode Map With State & City"> */}

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Zip Code"
                id="npi"
                required
                //   error={!isZipCodeValid}
                // helperText={!isZipCodeValid ? 'required field !' : ''}
                variant="outlined"
                value={renzip}
                //   placeholder="Enter your Zip Code"
                onChange={(e) => setrenzip(e.target.value)}
                //   defaultValue="Small"
                size="small"
              />
              {/* </Tooltip> */}
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                // error={!isStateValid}
                // helperText={!isStateValid ? 'required field !' : ''}
                // disabled={true}
                label="State"
                required
                id="email"
                variant="outlined"
                value={renstate}
                // placeholder="Enter your State"
                onChange={(e) => setrenstate(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="City"
                id="npi"
                required
                variant="outlined"
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                value={rencity}
                // placeholder="Enter your City"
                onChange={(e) => setrencity(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>


            <Button
              // variant=""
              onClick={toggleInputs}
              style={{ marginLeft: "99px", marginTop: "8px" }}
            >
              {showInputs ? 'Close ▲' : 'add Referring provider ▼ '}
            </Button>

            {showInputs && (
              <>
                <p style={{ marginLeft: "109px", fontWeight: "bold", marginTop: "8px", marginBottom: "25px" }}>Referring Provider</p>
                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="PriorAuthProviderType"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefprovider(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="First Name"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setreffirst(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Last Name"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setreflast(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="NPI"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefnpi(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Address"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefadd(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="Zip Code"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefzip(e.target.value)}
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={1} style={stackStyle} className='top'>
                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="State"
                    id="email"
                    variant="outlined"
                    onChange={(e) => setrefstate(e.target.value)}
                    size="small"
                  />

                  <TextField
                    sx={{ width: '100%' }}
                    style={inputStyle}
                    label="City"
                    id="npi"
                    variant="outlined"
                    onChange={(e) => setrefcity(e.target.value)}
                    size="small"
                  />
                </Stack>


              </>
            )}


            <p style={{ marginLeft: "109px", fontWeight: "bold", marginTop: "8px", marginBottom: "25px" }}>Subscriber</p>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                value={subfirst}
                // error={!isStateValid}
                // helperText={!isStateValid ? 'required field !' : ''}
                // disabled={true}
                label="First Name"
                id="email"
                required
                variant="outlined"
                //   value={memberstate}
                //   placeholder="Enter your State"
                onChange={(e) => setsubfirst(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                required
                label="Last Name"
                id="npi"
                variant="outlined"
                value={sublast}
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                //   value={membercity}
                //   placeholder="Enter your City"
                onChange={(e) => setsublast(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DOB'
                required
                variant="outlined"
                value={subdob ? dayjs(subdob) : null}
                // value={memberdob ? dayjs(memberdob) : null}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                required
                label="Member ID"
                id="npi"
                variant="outlined"
                value={submember}
                // error={!isCityValid}
                // helperText={!isCityValid ? 'required field !' : ''}
                // disabled={true}
                //   value={membercity}
                //   placeholder="Enter your City"
                onChange={(e) => setsubmember(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                required
                label="Drug Name"
                id="Allergies"
                variant="outlined"
                value={subdrug}
                // value={memberall}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                // placeholder="Enter your Allergies"
                onChange={(e) => setsubdrug(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Email"
                id="email"
                variant="outlined"
                value={subemail}
                // value={memberphone}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                onChange={(e) => setsubemail(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>

            <Stack direction="row" spacing={1} style={stackStyle}>
              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Strength"
                id="Allergies"
                variant="outlined"
                value={substrength}
                // value={memberall}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? 'Invalid email format' : ''}
                // placeholder="Enter your Allergies"
                onChange={(e) => setsubdrug(e.target.value)}
                // defaultValue="Small"
                size="small"
              />

              <TextField
                sx={{ width: '100%' }}
                style={inputStyle}
                label="Quantity"
                id="email"
                variant="outlined"
                value={subquantity}
                // value={memberphone}
                // error={!isNumberValid}
                // helperText={!isNumberValid ? 'Invalid number format' : ''}
                // placeholder="Enter your Mobile Number"
                // onChange={(e) => setsubemail(e.target.value)}
                // defaultValue="Small"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1} style={stackStyle} className='top'  >

              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DosStartDate'
                variant="outlined"
                required
                value={refdobstart ? dayjs(refdobstart) : null}
                // value={memberdob ? dayjs(memberdob) : null}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />

              <DatePicker
                style={{
                  width: "500px",
                  // borderColor: dobError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  // color: 'black',
                }}
                placeholder='DosEndDate'
                variant="outlined"
                required
                value={refdobend ? dayjs(refdobend) : null}
                // value={memberdob ? dayjs(memberdob) : null}

                // Use the moment object directly
                // onChange={onChangeStartDate}
                // error={dobError} // Set error prop based on dobError state
                // status={dobError ? "error" : ""}
                // helperText={dobError ? 'Please select a valid date' : ''}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please select a date!',
                //   }
                // ]}
                inputStyle={{ color: 'black' }}
                suffixIconStyle={{ color: 'black' }}
              />


            </Stack>
            <Stack>
              <div style={{ width: "1025px", marginLeft: "13%", marginTop: "10px", marginRight: "100px" }}>
                <TextArea id="myTextArea" rows={4}
                  value={clinicalNotes}
                  placeholder="Clinical Notes" />
                <p style={{ fontSize: 12, color: '#858585' }} >
                  <b>Note:</b> <br />If you have additional clinical documents supporting this prior authorization request, please upload them. Providing all relevant documents upfront can help expedite the review process and reduce the need for multiple review calls from payers
                </p>
              </div>
            </Stack>


            <Stack
              fullWidth
              style={{
                // width: '200px',
                height: '50px',
                // border: '2.5px dashed orange',
                borderRadius: '0',
                marginTop: '1.5%',
                display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                justifyContent: 'space-between',
                // textAlign: 'center',
                // marginLeft:'400px',

              }}
              onDragOver={(e) => e.preventDefault()}
            // onDrop={handleFileDrop}
            >
              {/* Your existing form and input elements */}

              <form>
                {/* Your file input */}
                {/* Add an onChange event handler to trigger the Snackbar */}
                <input
                  type="file"
                  multiple
                  onChange={(event) => handleOpenSnackbar('Document uploaded successfully!')}
                  style={{ display: 'none' }}
                />
                {/* Your drag and drop area */}
                <div
                  // style={{ cursor: 'pointer' }}

                  style={{ display: "flex", gap: "10%", margin: "auto", marginLeft: "13%" }}
                >



                  <div onClick={() => document.querySelector('input[type="file"]').click()}


                    onDragOver={(e) => e.preventDefault()}
                    type="submit" style={{ border: "2.5px dashed #1da0e1", width: "290px", textAlign: "center", color: "#1da0e1", }} >
                    Click to upload support document

                    <img style={{ width: '50px', height: '50px' }} src="/adds.png" alt="" />


                  </div>


                  <div>
                    <Button style={{ height: '94px', marginLeft: "2px", borderColor: "#1da0e1", color: "#1da0e1", width: "290px" }} variant='outlined' onClick={() => setIsModalOpenSign(true)} icon={<PlusOutlined />}> Click to {trimmedDataURL ? 'edit' : 'add'} signature</Button>
                  </div>
                  {/* <div>
                    <Button style={{ height: '94px', marginLeft: "2px", borderColor: "#1da0e1", color: "#1da0e1", width: "290px" }} variant='outlined' onClick={showModal} icon={<PlusOutlined />}>Adobe Secure Sign</Button>
                  </div> */}
                  {/* <Button onClick={initiateOAuth}>Initiate OAuth</Button>
                  <Button onClick={() => exchangeCodeForToken('CBNCKBAAHBCAABAACbfQPyPbZIcVykd7zcbTp_mKwFW79Qhv')}>Get Token From Code</Button> */}



                </div>
              </form>


              {/* {uploadedFileNames.length > 0 && (
    <div>
      <h3>Uploaded Files:</h3>
      <ul>
        {uploadedFileNames.map((fileName, index) => (
          <li key={index}>{fileName}</li>
        ))}
      </ul>
    </div>
  )} */}




              {/* Snackbar to display success message */}
              {/* <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000} // Adjust duration as needed
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
  /> */}

            </Stack>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={() => setOpenSnackbar(false)}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <Modal title="Add E-Signature" open={isModalOpenSign} onOk={handleOkSign} onCancel={handleCancelSign} >
              <div className='Signature-pad'>
                <SignatureCanvas penColor='black'
                  canvasProps={{ width: 465, height: 200, className: 'sigCanvas' }}
                  ref={(e) => { setSignature(e) }}
                />
              </div>
            </Modal>

            <Modal title="Add Signature" open={isModalOpen} onOk={handleUploadAndSend} onCancel={handleCancel}>
              {/* <div className='Signature-pad'>
                <SignatureCanvas penColor='black'
                  canvasProps={{ width: 465, height: 200, className: 'sigCanvas' }}
                  ref={(e) => { setSignature(e) }}
                />
              </div> */}

              <p>Please provide a document</p>
              {/* <input
                type="file"
                multiple
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: 'none' }}
              />

              <div onClick={() => document.querySelector('input[type="file"]').click()}


                onDragOver={(e) => e.preventDefault()}
                type="submit" style={{ border: "2.5px dashed #1da0e1", width: "290px", textAlign: "center", color: "#1da0e1", }} >
                Click to upload clinical documents

                <img style={{ width: '50px', height: '50px' }} src="/adds.png" alt="" />




              </div> */}

              <input style={{ marginBottom: 20 }} type="file" onChange={handleFileChange} />

              <div style={{ marginTop: 10 }}>
                <TextField
                  lg={{ width: '60%' }}

                  style={{ width: '400px' }}
                  label="Email"
                  id="npi"
                  variant="outlined"
                  onChange={(e) => setSenderEmail(e.target.value)}
                  size="small"
                />
              </div>

            </Modal>

            <Col offset={10} >



              <div className='Signature-btn'>
                <div className='sign-img'>
                  {trimmedDataURL

                    ?
                    <>
                      <p style={{ width: '93px', marginTop: '10%' }}>Signature : </p>
                      <img alt='signature'
                        src={trimmedDataURL} style={{ width: '80px', height: 'auto', borderBottom: '1px solid #1da0e1', padding: '0 13px 5px 10px' }} />
                    </>
                    : null}
                </div>
              </div>
            </Col>

            <div style={{ width: "1025px", marginLeft: "13%", marginTop: "10px", marginRight: "100px" }}>

              <Checkbox onChange={onChange} defaultChecked>
                <p>
                  I agree to provide consent for processing the information for prior authorization request.
                </p>
              </Checkbox>
            </div>




            <Stack direction="row" spacing={3} style={{ marginTop: "1%", justifyContent: "center", paddingBottom: "1%" }}>


              <Grid>
                <button className="addnewhospitalsubmit" style={{ width: "532px", marginLeft: "57px", marginBottom: "50px", backgroundColor: "#1DA0E1" }} onClick={handleClick1}>Electronically Place PA Request</button>
              </Grid>
            </Stack>
          </Grid>
          {/* <div> */}
        </div>
      </div>
      <div>
      </div>
    </div>
    // </div>
    // </div>
  )
}

export default Authorization