// import React from 'react'
import NewHeader from '../../NewHeader'
import "./editevbrequest.css";
// import "./NewTable.css"
import Sidebar1 from '../../Sidebar1'
// import FlagIcon from '@mui/icons-material/Flag';
import Pagination from '@mui/material/Pagination';
import { Col, Row } from 'antd'
import SearchBar from "material-ui-search-bar";
import { Delete, Edit, FilterAltOffOutlined, Visibility } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import FlagIcon from '@mui/icons-material/Flag';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from 'antd';
import {BaseUrl} from '../../../config'
import { useAuth } from '../../../Newlogin/Authprovider'

const { RangePicker } = DatePicker;

const CreatePlan = () => {

  // const { isAuthenticated } = useAuth();
  const navigate = useNavigate()

  
  // useEffect(() => {
    // if (!isAuthenticated()) {
  
    //   navigate('/');
    // }
  // }, [isAuthenticated, navigate]);

  const [data, setdata] = useState([]);
  const [status, setstatus] = useState([]);
  const [page, setPage] = useState(0);
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([])
  const [Doc, setDoc] = useState("")
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);


  const handleSearchChange = (value) => {
    setSearchText(value);
  };
  const [searchText, setSearchText] = useState();

  

  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(8);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    setStartIndex((newPage - 1) * rowsPerPage + 1);
    setEndIndex(Math.min(newPage * rowsPerPage, data.length));
  };




  const managnment = window.location.href

  const my_list = managnment.split("/")

  const handlenavigate = () => {
    navigate("/drugpverify?/" + my_list[4] + "")
  }



  useEffect(() => {
    const getApi = async () => {
      const tableUrl = `${BaseUrl}/drugs-details/findAll`;
      await axios.get(tableUrl)
        .then((res) => {
          setLoading(false);
          setdata(res.data.data);
        })
    }
    getApi()

    const filterData = () => {
      if (!dates && !searchText) {
        setFilteredData([]);  // No dates and no search text, show an empty array
      } else {
        const filteredRows = data.filter((row) => {
          const isDateInRange = !dates || (
            new Date(row.createdAt) >= new Date(dates[0]) &&
            new Date(row.createdAt) <= new Date(dates[1])
          );
          const isSearchTextMatch = !searchText || (
            row.insuranceName.toLowerCase().includes(searchText.toLowerCase())
          );
          return isDateInRange && isSearchTextMatch;
        });
        setFilteredData(filteredRows);
      }
    };

    filterData();  
  }, [data, dates, searchText])


  const getTextColor = (response) => {
    switch (response) {
      case 'not covered':
        return 'red';
      case 'covered':
        return 'green';
      case 'covered with condition':
        return 'grey';
      case 'detail not available':
        return 'orange';
      default:
        return 'inherit';
    }
  };
  const textColor = getTextColor("Not Covered" || "Covered" || "Covered with Condition" || "Detail Not available");

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const YourComponent = ({ startIndex, index }) => (
    <TableCell>{`PV${(startIndex + index).toString().padStart(3, '0')}`}</TableCell>
  );


  return (
    <div className='picontainer' 
    // style={{ overflow: "scroll" }}
    >
      <NewHeader />
      <Sidebar1 />
      <div className='miniheadtable'
        style={{
          display: "flex",
          /* justify-content: space-between; */
          gap: "3%",
          width: "90%",
          height: "60px",
          paddingLeft: "20px",
          backgroundColor: "white",
          marginLeft: "7%",
          marginTop: "1%",
          borderRadius: "8px",
          /* justify-content: center; */
          alignItems: "center",
        }}
      >
        <div className='miniheadtitle'>
          <Col>
          <h2 className='editevbrequestmed'>
              eBV Request
          </h2>
          </Col>
        </div>
        <Row className='miniheadright'>
          <Col style={{
            marginTop: "1%"
          }}>
            <SearchBar
              value={searchText}
              onChange={(newValue) => setSearchText(newValue)}
              placeholder='Search For Insurance Name'
              onRequestSearch={() => {
              }}
              style={{
                margin: '0 auto',
                width: 300,
                height: "38px",
                backgroundColor: "#C8EDFF",
                borderRadius: "10px"
              }}
            />
          </Col>
          <RangePicker
            value={dates}
            onChange={(val) => setDates(val)}
            style={{ marginLeft: '10px' }}
          />
          {/* <Col>
             <div  className="filterdiv" >
              <div  className='filtertitle'>Filter</div>
              <FilterAltOffOutlined  className='filtericon'/>
             </div>
             </Col> */}

          <Col className='addnewusecol' style={{
            // left: "18%",
            paddingTop: "1%",
            marginTop: "1%"
          }}>
            <div className='addnewusecoldiv' onClick={handlenavigate}>
              Add New eBV Request
            </div>
          </Col>
        </Row>
      </div>
      

      <div>
        <TableContainer className="tablecont" style={{ width: "90%" }} component={Paper}>
          {loading ? (<Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ margin: "auto", justifyContent: "center", alignItems: "center", marginTop: "5%" }} />
          </Box>) :
            (<>
              <Table>
                <TableHead className="tablerowm" >
                  <TableRow style={{ height: "5px" }}>
                    <TableCell><b>Case Id</b></TableCell>
                    {/* <TableCell>S.No</TableCell> */}
                    <TableCell><b>Insurance Name</b></TableCell>
                    {/* <TableCell>Hcp Office</TableCell> */}
                    <TableCell><b>Plan Name</b></TableCell>
                    <TableCell><b>Status</b></TableCell>
                    <TableCell><b>Drugname</b></TableCell>
                    <TableCell><b>state</b></TableCell>
                    <TableCell><b>Action</b></TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                {(filteredData && filteredData.length > 0 ? filteredData : data)
                .slice(startIndex - 1, endIndex)
                .map((row, index) => (
                    <TableRow key={row.id}>
                      {/* <TableCell>{index + 1}</TableCell> */}
                      <YourComponent startIndex={startIndex} index={index} />
                      
                      <TableCell>{row.insuranceName}</TableCell>
                      <TableCell>{row.planeName}</TableCell>
                      <TableCell>
                        {row.Description === ('not covered' || 'covered' || 'covered with condition' || 'detail not available') && (
                          <FlagIcon style={{ color: getTextColor(row.Description), marginRight: '4px' }} />
                        )}
                        <span style={{ color: getTextColor(row.Description) }}>{row.Description}</span>
                      </TableCell>
                      <TableCell>{row.drugName}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>
                      {/* <Tooltip title="Description">
                        <IconButton aria-label="View" onClick={(e) => navigate("/ebvrequest?/" + my_list[4] + "/" + row.userid)}>
                          <Visibility />
                        </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Send">
                        <IconButton aria-label="Edit" style={{ color: "#00ADF2" }} onClick={(e) => navigate("/drugpverify?/" + my_list[4])}>
                        <SendIcon />
                        </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Delete">
                        <IconButton aria-label="Delete" style={{ color: "#FF4500" }}>
                          <Delete />
                        </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>)}
        </TableContainer>

      </div>

      <div className='pagination'>
        <div>
          <Pagination color="primary" 
            // count={Math.ceil(data.length / rowsPerPage)}
            // count={Math.ceil(filteredData.length / rowsPerPage)}
            count={filteredData.length > 0 ? Math.ceil(filteredData.length / rowsPerPage) : Math.ceil(data.length / rowsPerPage)}
            onChange={handlePageChange}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
            />
        </div>
      </div>
    </div>
  )
}

export default CreatePlan

